import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import { validateReduxHandlers } from 'src/redux/helpers'
import { createSelector } from 'reselect'
import usersRedux from 'src/redux/users'

const { types: UsersTypes } = usersRedux

const STATE_KEY = 'chartData'
const initialState = {
  period: 1,
  transactionType: 'merchant',
  chartData: { data: [], transactionNum: 0, total: 0 }
}

const selectors = {
  period: createSelector(
    R.path([STATE_KEY, 'period']),
    R.identity
  ),
  transactionType: createSelector(
    R.path([STATE_KEY, 'transactionType']),
    R.identity
  ),
  chartData: createSelector(
    R.path([STATE_KEY, 'chartData']),
    R.identity
  )
}

const getChartData = (state, _args) => state

const getChartDataSuccess = (state, { results, period, transactionType }) =>
  R.merge(state, {
    chartData: results,
    period,
    transactionType
  })

const getChartDataFail = (state, _args) => state

const { Types, Creators } = createActions({
  getChartData: ['period', 'transactionType'],
  getChartDataSuccess: ['results', 'period', 'transactionType'],
  getChartDataFail: ['error']
})

const logout = () => initialState

const handlers = {
  [Types.GET_CHART_DATA]: getChartData,
  [Types.GET_CHART_DATA_SUCCESS]: getChartDataSuccess,
  [Types.GET_CHART_DATA_FAIL]: getChartDataFail,
  [UsersTypes.USERS_LOGOUT]: logout
}

validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
