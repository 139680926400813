import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Tab } from '@material-ui/core'
import TabsComponent from 'src/components/tabs'
import EnableTeller from 'src/pages/settings/enableTeller'
import CompanyInformation from 'src/pages/settings/companyInformation'
import Location from 'src/pages/settings/location'
import CurrencyInformation from 'src/pages/settings/currencyInformation'
import resourcesRedux from 'src/redux/resources'
import * as R from 'ramda'
import { connect } from 'react-redux'

const Settings = ({ isTellerEnabled }) => {
  const renderTabs = tab => {
    switch (tab) {
      case 0:
        return <EnableTeller />
      case 1:
        return <CompanyInformation />
      case 2:
        return <Location />
      case 3:
        return <CurrencyInformation />
      default:
        return null
    }
  }

  return (
    <TabsComponent
      title={'Account Settings'}
      renderTabs={renderTabs}
      tabsLabels={[
        <Tab label='Teller Network' />,
        <Tab label='Company Information' />,
        <Tab label='Location' />,
        <Tab label='mMoney Teller Limits' disabled={!isTellerEnabled} />
      ]}
    />
  )
}

Settings.propTypes = {
  isTellerEnabled: PropTypes.bool.isRequired
}

const styles = theme => ({})

const mapStateToProps = state => ({
  isTellerEnabled: resourcesRedux.selectors.isTellerEnabled(state)
})

export default R.pipe(
  withStyles(styles),
  connect(mapStateToProps)
)(Settings)
