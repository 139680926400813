import React from 'react'
import { Grid, Card, Typography, withStyles, Icon } from '@material-ui/core'
import PropTypes from 'prop-types'
import AddEditButton from 'src/components/buttons/addEditButton'

const VerificationsStep = ({
  classes,
  headerTitle,
  buttonLabel,
  limitAmount,
  requirementsList,
  adnotationList,
  statementsList,
  onButtonClick
}) => (
  <>
    <Grid container>
      <Card direction='column' className={classes.card}>
        <Grid
          container
          className={classes.header}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='button'>{headerTitle}</Typography>
          </Grid>
          <Grid item>
            <AddEditButton onClick={onButtonClick}>
              {buttonLabel}
              <Icon className={classes.rightIcon}>send</Icon>
            </AddEditButton>
          </Grid>
        </Grid>
        <Grid container direction='column' className={classes.info}>
          <Grid item>
            <Typography variant='button'>AUTHORIZED SERVICES</Typography>
          </Grid>
          <Grid item className={classes.sendLimit}>
            <Typography className={classes.content}>
              Send Limit - <b>{limitAmount} BBD or equivalent per month</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction='column' className={classes.info}>
          <Grid item>
            <Typography variant='button'>REQUIREMENTS</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.content}>
              {requirementsList.map((item, index) => (
                <li key={index}>
                  {item.text}
                  <sup>{item.adnotation}</sup>
                </li>
              ))}
            </Typography>
            <Grid container direction='column' className={classes.lastElements}>
              {statementsList &&
                statementsList.map((item, index) => (
                  <Grid item key={index}>
                    <Typography className={classes.content}>{item}</Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='column' className={classes.adnotatnions}>
          <Typography variant='caption'>
            {adnotationList &&
              adnotationList.map((item, index) => (
                <Grid item key={index}>
                  <sup>{item.adnotation}</sup>
                  {item.text}
                </Grid>
              ))}
          </Typography>
        </Grid>
      </Card>
    </Grid>
  </>
)
VerificationsStep.propTypes = {
  classes: PropTypes.object.isRequired,
  headerTitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  limitAmount: PropTypes.string.isRequired,
  requirementsList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      adnotation: PropTypes.string
    })
  ),
  adnotationList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      adnotation: PropTypes.string
    })
  ),
  statementsList: PropTypes.arrayOf(PropTypes.string)
}

const styles = theme => ({
  card: {
    flex: 1,
    backgroundColor: theme.palette.lights.lightGrey
  },
  header: {
    backgroundColor: theme.palette.lights.white,
    height: 70,
    padding: 20
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  info: {
    padding: '20px 20px 0 20px'
  },
  sendLimit: {
    paddingTop: theme.spacing(1)
  },
  content: {
    color: '#a6a6a6'
  },
  adnotatnions: {
    padding: 20
  },
  lastElements: {
    marginLeft: 20
  }
})

export default withStyles(styles)(VerificationsStep)
