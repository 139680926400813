import { compareDesc, parseISO } from 'date-fns'

export function fmtErrors (errors) {
  let errorTxt = ''
  for (let e in errors) {
    if (e) {
      for (let i = 0; i < errors[e].length; i++) {
        errorTxt += '' + e + ' ' + errors[e][i] + ' \r\n'
      }
    }
  }
  return errorTxt + ''
}

export const mapRoles = permissions =>
  permissions
    .sort((a, b) => compareDesc(parseISO(a.updated_at), parseISO(b.updated_at)))
    .map(role => ({ label: role.title, value: role.id, ...role }))

export const filterRoles = ({ roles, permissions }) =>
  roles
    .map(role => permissions.find(perm => perm.id === role))
    .filter(role => !!role)
    .map(filteredRole => ({
      value: filteredRole.id,
      label: filteredRole.title
    }))

export const mappingEmployees = ({ employees, permissions }) =>
  employees
    .sort((a, b) =>
      compareDesc(parseISO(a.inserted_at), parseISO(b.inserted_at))
    )
    .map(employee => ({
      name: `${employee.first_name} ${employee.last_name}`,
      filteredRoles: filterRoles({ roles: employee.roles, permissions }),
      firstName: employee.first_name,
      lastName: employee.last_name,
      email: employee.email,
      id: employee.id,
      verified: employee.verified,
      active: employee.active
    }))

export const mapPermissions = ({ permissions }) =>
  Object.keys(permissions)
    .filter(
      perm => perm !== 'title' && perm !== 'user_id' && perm !== 'company'
    )
    .map(perm => ({
      key: perm,
      value: perm.replace(/_/g, ' ').toUpperCase()
    }))

export const mapCompany = company => ({
  active: company.active,
  id: company.id,
  phoneNumber: company.number,
  isTeller: company.teller,
  name: company.name,
  categoryId: company.category_id,
  contactEmail: company.contact_email,
  defaultCurrency: company.default_currency,
  currencies:
    company.currencies &&
    company.currencies.map(currency => ({
      active: currency.active,
      available: currency.available,
      availableFloat: currency.available_float,
      code: currency.code,
      id: currency.id,
      img: currency.img,
      name: currency.name,
      pending: currency.pending,
      prefix: currency.prefix,
      maxDeposit: currency.max_deposit,
      maxDepositFloat: currency.max_deposit_float,
      maxWithdraw: currency.max_withdraw,
      maxWithdrawFloat: currency.max_withdraw_float,
      refundFee: currency.refund_fee,
      total: currency.total,
      totalFloat: currency.total_float
    }))
})

export const mergeCurrencies = (input, payload) => {
  return input.map(currencyState => {
    const currencyToUpdate = payload.find(
      currencyUpdate => currencyUpdate.code === currencyState.code
    )
    const currencyMapped = {
      availableFloat: currencyToUpdate.available_float,
      totalFloat: currencyToUpdate.total_float,
      ...currencyToUpdate
    }
    return { ...currencyState, ...currencyMapped }
  })
}

export const isInvoicePaid = ({ paidFloat, priceFloat }) => {
  const diff = priceFloat - paidFloat
  return diff <= 0
}
