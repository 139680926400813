import React from 'react'
import { Grid, TextField, Typography, withStyles } from '@material-ui/core'
import startAdornment from 'src/components/inputFields/startAdornment'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import Select from 'src/components/forms/entry/Select'
import * as R from 'ramda'
import { bpMaxSM } from 'src/tools/screenSize'

export const allStatusesItem = { label: 'All', value: 'all' }

const statuses = [
  { value: 'all', label: 'All' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'active', label: 'Active' }
]
const SearchSelect = ({
  selectedStatus,
  setSelectedStatus,
  searchedQuery,
  setSearchedQuery,
  classes
}) => (
  <Grid
    container
    spacing={1}
    justifyContent='space-between'
    className={classes.searchSelect}
  >
    <Grid item container direction='column' xs={12} md={6}>
      <Typography variant='button'>Search:</Typography>
      <TextField
        InputProps={startAdornment(<SearchIcon />, classes.adornment)}
        variant='outlined'
        className={classes.search}
        placeholder='Find a Payment'
        value={searchedQuery}
        onChange={event => setSearchedQuery(event.target.value)}
      />
    </Grid>
    <Grid
      item
      container
      xs={12}
      md={3}
      direction='column'
      alignItems='flex-start'
    >
      <Typography variant='button'>Status:</Typography>
      <Select
        name='statusSelect'
        variant='outlined'
        className={classes.search}
        options={statuses}
        isMulti={false}
        customStyles={{ input: provided => ({ ...provided, minWidth: 100 }) }}
        value={selectedStatus}
        onChange={value => setSelectedStatus(value)}
      />
    </Grid>
  </Grid>
)

SearchSelect.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  searchSelect: {
    flexDirection: 'row',
    padding: theme.spacing(3),
    [`${bpMaxSM}`]: {
      padding: theme.spacing(2),
      flexDirection: 'column'
    }
  },
  search: {
    minWidth: '100px',
    backgroundColor: theme.palette.lights.white
  },
  adornment: {
    color: '#999999'
  }
})

export default R.pipe(withStyles(styles))(SearchSelect)
