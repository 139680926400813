import React from 'react'
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Icon,
  Typography,
  withStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'

const DocumentPreview = ({ classes, file, handleRemove }) => (
  <Card>
    {file.type.includes('image') ? (
      <CardMedia
        className={classes.document}
        image={file.preview}
        title={file.name}
      />
    ) : (
      <CardContent className={classes.document}>
        <Grid container justifyContent='center' alignItems='center'>
          <Icon fontSize='large'>insert_drive_file</Icon>
          <Typography
            className={classes.fileName}
            noWrap
            title={file.name}
            variant='caption'
          >
            {file.name}
          </Typography>
        </Grid>
      </CardContent>
    )}
    <Grid container justifyContent='center'>
      <Button color='primary' type='submit' onClick={handleRemove}>
        Remove
      </Button>
    </Grid>
  </Card>
)

const styles = theme => ({
  document: {
    width: 120,
    height: 120
  },
  fileName: {
    marginTop: theme.spacing(2)
  },
  icon: {
    fontSize: 48
  }
})

DocumentPreview.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    preview: PropTypes.string
  }),
  handleRemove: PropTypes.func.isRequired,
  classes: PropTypes.object
}
export default withStyles(styles)(DocumentPreview)
