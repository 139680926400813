import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, withStyles } from '@material-ui/core'
import * as R from 'ramda'

const EmptyListComponent = ({ classes, message }) => {
  return (
    <Grid container direction='row' className={classes.emptyListElement}>
      <Typography className={classes.text}>{message}</Typography>
    </Grid>
  )
}

EmptyListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
}

const styles = theme => ({
  emptyListElement: {
    padding: '20px 35px',
    alignContent: 'center',
    justifyContent: 'center'
  },
  text: {
    color: theme.palette.lights.grey2,
    fontSize: 20
  }
})

export default R.pipe(withStyles(styles))(EmptyListComponent)
