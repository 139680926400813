import React, { useEffect } from 'react'
import Page from 'src/components/page'
import { Grid, useMediaQuery, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import VerificationsStep from 'src/pages/verifications/verifications-step'
import VerificationXS from 'src/pages/verifications/verifications-xs'

import { actions } from 'src/redux'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTheme } from '@material-ui/core/styles'

const stepOneList = [
  { text: 'Business Owner First and Last Name' },
  { text: 'Legal Business Name' },
  { text: 'Business Trading Name' },
  { text: 'Business Address' },
  { text: 'USD Value of Monthly Transactions' },
  { text: 'Business Type' },
  { text: 'Contact Phone Number' }
]

const stepTwoList = [
  { text: 'Certificate of Business/ Certificate of Incorporation' },
  { text: 'Valid photo IDs for owners and directors' },
  { text: 'Proof of address for owners and directors' },
  { text: 'Form for Registration of Application', adnotation: ' 1' },
  { text: 'Register/List of Members and Directors', adnotation: ' 2' },
  { text: 'Notice of Directors', adnotation: ' 2' },
  { text: 'Notice of Address', adnotation: ' 2' },
  { text: 'Optional Documentation such as:' }
]

const statements = ['- Financial statements', '- Bank statements']

const adnotations = [
  {
    adnotation: '1 ',
    text: 'Required by all business entities except incorporated companies.'
  },
  { adnotation: '2 ', text: 'Required by incorporated companies.' }
]

const Verifications = ({ classes, getBasicInfo, getVerificationData }) => {
  useEffect(() => {
    getBasicInfo()
    getVerificationData()
  }, [])

  const theme = useTheme()
  const isSecondaryLayout = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Page showBack title='Verification Overview'>
      {isSecondaryLayout ? (
        <VerificationXS
          stepOneList={stepOneList}
          stepTwoList={stepTwoList}
          adnotationList={adnotations}
          statementsList={statements}
        />
      ) : (
        <Grid container direction='row' spacing={2}>
          <Grid item className={classes.step}>
            <VerificationsStep
              headerTitle='Step 1: Business Verification'
              buttonLabel='Edit'
              onButtonClick={() => navigate('verify-basic')}
              limitAmount='500'
              requirementsList={stepOneList}
            />
          </Grid>
          <Grid item className={classes.step}>
            <VerificationsStep
              headerTitle='Step 2: Verify Your Business'
              buttonLabel='Get Verified'
              onButtonClick={() => navigate('verify-business')}
              limitAmount='20,000'
              requirementsList={stepTwoList}
              adnotationList={adnotations}
              statementsList={statements}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  )
}

Verifications.propTypes = {
  classes: PropTypes.object.isRequired,
  getBasicInfo: PropTypes.func.isRequired,
  getVerificationData: PropTypes.func.isRequired
}

const styles = theme => ({
  step: {
    flex: '0.5'
  }
})

const actionCreators = {
  getBasicInfo: actions.compliance.getBasicInfo,
  getVerificationData: actions.compliance.getVerificationData
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(actionCreators, dispatch)

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(Verifications)
