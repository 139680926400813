import 'react-app-polyfill/ie11'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { ApolloProvider } from '@apollo/react-hooks'
import { Provider as ReduxProvider } from 'react-redux'
import { render } from 'react-dom'
import Routes from 'src/routes'
import { store } from 'src/redux'
import theme from 'src/theme'
import SnackBarNotification from 'src/components/notification/SnackBarNotification'
import FirebaseInit, { AnalyticsContext } from 'src/tools/firebase'
import { client } from 'src/services/graphql/client'

import { MuiThemeProvider, CssBaseline } from '@material-ui/core'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

const App = ({ children }) => {
  return (
    <AnalyticsContext.Provider value={FirebaseInit().analytics}>
      <ReduxProvider store={store}>
        <ApolloProvider client={client}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Routes />
              <SnackBarNotification />
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </ReduxProvider>
    </AnalyticsContext.Provider>
  )
}
render(<App />, document.getElementById('root'))
