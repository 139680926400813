import * as R from 'ramda'

export const buildQueryString = query =>
  R.pipe(
    R.defaultTo({}),
    R.toPairs,
    R.map(R.join('=')),
    R.join('&'),
    R.unless(R.isEmpty, R.concat('?'))
  )(query)

export const merchantApiUrl = (path, query) =>
  R.join('', [process.env.REACT_APP_API_HOST, path, buildQueryString(query)])
