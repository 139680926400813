import { activateAccount } from 'src/services/merchant/authentication'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getCategories, signup } from 'src/services/merchant/account'
import { getMerchantBands } from 'src/services/compliance'
import { parseEmailFromToken } from 'src/redux/users/helpers'
import { storeAuthToken } from 'src/tools/token'
import navigate from 'src/tools/navigate-wrapper'
import usersRedux from 'src/redux/users'
import notificationRedux from 'src/redux/notification'
import complianceRedux from 'src/redux/compliance'
const { actions, types } = usersRedux

export function * signupSaga ({ data }) {
  try {
    const { verified, token, success, expired } = yield call(signup, data)

    if (!success) throw new Error('Signup failed')

    yield put(
      actions.usersSignupSuccess(success, data.email, token, verified, expired)
    )
    yield call(storeAuthToken, token)
    yield call(navigate, '/dashboard/overview')

    yield put(complianceRedux.actions.basicVerification(data))
  } catch (e) {
    yield put(
      notificationRedux.actions.notificationShow({
        message: e.message,
        type: 'error'
      })
    )
  }
}

export function * getCategoriesSaga () {
  try {
    const { categories } = yield call(getCategories)
    yield put(actions.getCategoriesSuccess(categories))
  } catch (e) {
    yield put(actions.getCategoriesFail())
  }
}

export function * getMerchantBandsSaga () {
  try {
    const { data } = yield call(getMerchantBands)
    yield put(actions.getMerchantBandsSuccess(data))
  } catch (e) {
    yield put(actions.getMerchantBandsFail())
  }
}

export function * activateAccountSaga ({ activationToken }) {
  try {
    const { token, expired } = yield call(activateAccount, activationToken)
    yield call(storeAuthToken, token)
    const { redirectPath, message } = yield call(tokenSideEffect, token)
    const email = yield call(parseEmailFromToken, token)
    yield put(actions.activateAccountSuccess(email, expired, token))
    yield put(
      notificationRedux.actions.notificationShow({
        message,
        type: 'success'
      })
    )
    yield call(navigate, redirectPath)
  } catch (e) {
    yield put(
      notificationRedux.actions.notificationShow({
        message: 'Account activation failed',
        type: 'error'
      })
    )

    yield call(navigate, '/')
  }
}

export const tokenSideEffect = token => {
  if (!token) {
    return {
      message:
        'Thank you for confirming your email with mMoney. You may now log in',
      redirectPath: '/'
    }
  } else {
    return {
      message: 'Account activation successful',
      redirectPath: '/dashboard/overview'
    }
  }
}

export default function * root () {
  yield takeLatest(types.USERS_SIGNUP, signupSaga)
  yield takeLatest(types.GET_CATEGORIES, getCategoriesSaga)
  yield takeLatest(types.GET_MERCHANT_BANDS, getMerchantBandsSaga)
  yield takeLatest(types.ACTIVATE_ACCOUNT, activateAccountSaga)
}
