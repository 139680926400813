import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import {
  validateReduxHandlers,
  generateDefaultHandler
} from 'src/redux/helpers'
import * as authServices from 'src/services/merchant/authentication'
import * as accountManagement from 'src/services/merchant/account'
import { createSelector } from 'reselect'
import navigate from 'src/tools/navigate-wrapper'
import notificationRedux from 'src/redux/notification'
import {
  storeAuthToken,
  clearAuthToken,
  storePersistLogin,
  clearPersistLogin
} from 'src/tools/token'
import { thousandSeparator } from 'src/redux/users/helpers'

const STATE_KEY = 'users'
const initialState = {
  authentication: {},
  categories: [],
  compliance: {},
  socket: undefined
}

const selectors = {
  hasLoginError: createSelector(
    R.path([STATE_KEY, 'authentication', 'error']),
    error => !R.isNil(error)
  ),
  loginError: createSelector(
    R.pathOr('Unknown error occurred', [STATE_KEY, 'authentication', 'error']),
    R.identity
  ),
  userToken: createSelector(
    R.pathOr(undefined, [STATE_KEY, 'authentication', 'token']),
    R.identity
  ),
  userEmail: createSelector(
    R.pathOr('', [STATE_KEY, 'authentication', 'email']),
    R.identity
  ),
  categoryOptions: createSelector(
    R.path([STATE_KEY, 'categories']),
    categories =>
      categories &&
      categories.map(category => ({
        name: category.title,
        value: category.id
      }))
  ),
  merchantBandOptions: createSelector(
    R.pathOr([], [STATE_KEY, 'compliance', 'merchantBands']),
    merchantBands =>
      merchantBands.map(band => ({
        name: `${thousandSeparator(band.monthlyLow)} - ${thousandSeparator(
          band.monthlyHigh
        )} USD per month`,
        value: band.id
      }))
  ),
  authenticationInfo: createSelector(
    R.path([STATE_KEY, 'authentication']),
    R.identity
  ),
  socket: createSelector(
    R.path([STATE_KEY, 'socket']),
    R.identity
  )
}

const asyncLoginAction = (email, password, persistLogin = 0) =>
  authServices
    .login(email, password)
    .then(({ success, token, verified }) => {
      storePersistLogin(persistLogin)
      storeAuthToken(token)
      navigate('/dashboard/overview')
      return Creators.usersLoginSuccess(email, token, verified)
    })
    .catch(error =>
      R.pipe(
        R.propOr({}, 'body'),
        R.ifElse(
          R.propEq('success', false),
          () =>
            notificationRedux.actions.notificationShow({
              message: 'Invalid Credentials',
              type: 'error'
            }),
          () =>
            notificationRedux.actions.notificationShow({
              message: 'Login Failed',
              type: 'error'
            })
        )
      )(error)
    )

const logoutAction = () => {
  clearAuthToken()
  clearPersistLogin()
  navigate('/')
  return { type: Types.USERS_LOGOUT }
}

const forgotPasswordAction = email =>
  accountManagement
    .forgotPassword(email)
    .then(() =>
      notificationRedux.actions.notificationShow({
        message: 'Please check your email inbox',
        type: 'success'
      })
    )
    .catch(() =>
      notificationRedux.actions.notificationShow({
        message: 'Email not found',
        type: 'error'
      })
    )

const resetPasswordAction = (resetToken, newPassword) =>
  accountManagement
    .resetPassword(resetToken, newPassword)
    .then(() => {
      navigate('/')
      return notificationRedux.actions.notificationShow({
        message: 'Password Reset Complete',
        type: 'success'
      })
    })
    .catch(() =>
      notificationRedux.actions.notificationShow({
        message: 'Password Reset Failed',
        type: 'error'
      })
    )

const resendActivationEmailAction = (email, token) =>
  authServices
    .resendActivationEmail(email, token)
    .then(() =>
      notificationRedux.actions.notificationShow({
        message: 'Email Sent',
        type: 'success'
      })
    )
    .catch(() =>
      notificationRedux.actions.notificationShow({
        message: 'Failed to send email',
        type: 'error'
      })
    )

const { Types, Creators } = createActions({
  usersLogin: asyncLoginAction,
  usersLoginSuccess: ['email', 'token', 'verified'],
  usersLoginFail: ['error'],
  usersLogout: logoutAction,
  usersSignup: ['data'],
  usersSignupSuccess: ['success', 'email', 'token', 'verified', 'expired'],
  usersSignupFail: ['error'],
  usersForgotPassword: forgotPasswordAction,
  usersResetPassword: resetPasswordAction,
  getCategories: [],
  getCategoriesSuccess: ['categories'],
  getCategoriesFail: [],
  getMerchantBands: [],
  getMerchantBandsSuccess: ['data'],
  getMerchantBandsFail: [],
  usersResendEmail: resendActivationEmailAction,
  activateAccount: ['activationToken'],
  activateAccountSuccess: ['email', 'expired', 'token'],
  socketConnected: ['socket']
})

const login = (state, _args) => state

const loginSuccess = (state, { email, token, verified }) =>
  R.evolve({
    authentication: R.merge(R.__, {
      email,
      token,
      verified,
      error: null
    })
  })(state)

const loginFail = (state, { error }) =>
  R.evolve({
    authentication: R.assoc('error', error.message)
  })(state)

const signupSuccess = (state, { verified, email, token, expired }) =>
  R.evolve({
    authentication: R.merge(R.__, {
      verified,
      token,
      tokenExpiry: expired,
      email
    })
  })(state)

const signupFail = (state, { error }) =>
  R.evolve({
    authentication: R.assoc('error', error.message)
  })(state)

const activateAccountSuccess = (state, { email, expired, token }) =>
  R.evolve({
    authentication: R.merge(R.__, {
      email,
      token,
      tokenExpiry: expired,
      verified: true
    })
  })(state)

const getCategoriesSuccess = (state, { categories }) =>
  R.assoc('categories', categories, state)

const getMerchantBandsSuccess = (state, { data }) =>
  R.evolve({
    compliance: R.assoc('merchantBands', data)
  })(state)

const logout = () => initialState

const socketConnected = (state, { socket }) =>
  R.merge(state, {
    socket
  })

const getCategories = generateDefaultHandler()
const getMerchantBands = generateDefaultHandler()
const getMerchantBandsFail = generateDefaultHandler()
const forgotPassword = generateDefaultHandler()
const resetPassword = generateDefaultHandler()
const getCategoriesFail = generateDefaultHandler()
const resendEmail = generateDefaultHandler()
const activateAccount = generateDefaultHandler()
const signup = generateDefaultHandler()

const handlers = {
  [Types.USERS_LOGIN]: login,
  [Types.USERS_LOGIN_FAIL]: loginFail,
  [Types.USERS_LOGIN_SUCCESS]: loginSuccess,
  [Types.USERS_LOGOUT]: logout,
  [Types.USERS_SIGNUP]: signup,
  [Types.USERS_SIGNUP_SUCCESS]: signupSuccess,
  [Types.USERS_SIGNUP_FAIL]: signupFail,
  [Types.USERS_FORGOT_PASSWORD]: forgotPassword,
  [Types.USERS_RESET_PASSWORD]: resetPassword,
  [Types.USERS_RESEND_EMAIL]: resendEmail,
  [Types.GET_CATEGORIES]: getCategories,
  [Types.GET_CATEGORIES_SUCCESS]: getCategoriesSuccess,
  [Types.GET_CATEGORIES_FAIL]: getCategoriesFail,
  [Types.GET_MERCHANT_BANDS]: getMerchantBands,
  [Types.GET_MERCHANT_BANDS_SUCCESS]: getMerchantBandsSuccess,
  [Types.GET_MERCHANT_BANDS_FAIL]: getMerchantBandsFail,
  [Types.ACTIVATE_ACCOUNT]: activateAccount,
  [Types.ACTIVATE_ACCOUNT_SUCCESS]: activateAccountSuccess,
  [Types.SOCKET_CONNECTED]: socketConnected
}
validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
