import React, { useState } from 'react'
import {
  Button,
  Fade,
  Grid,
  Paper,
  Popper,
  Typography,
  withStyles,
  useMediaQuery
} from '@material-ui/core'
import { Link } from '@reach/router'
import navigate from 'src/tools/navigate-wrapper'
import FormItem from 'src/components/forms/form-item'
import { getFormStyles as getSignupFormStyles } from 'src/components/forms/verification/common'
import PropTypes from 'prop-types'
import {
  passwordValidationSchema,
  SetTouchFields
} from 'src/pages/signup/helpers'
import { checkEmailAvailability } from 'src/services/merchant/account'
import { useTheme } from '@material-ui/core/styles'
import * as R from 'ramda'
export const FormFieldNames = [
  'email',
  'password',
  'passwordConfirmation',
  'referralCode',
  'legalAcceptance'
]

const handlePasswordChange = (handleChange, setPasswordChecks) => e => {
  const password = e.target.value
  const options = {
    strict: true,
    abortEarly: false
  }

  passwordValidationSchema
    .validate(password, options)
    .then(_value =>
      setPasswordChecks({
        length: true,
        uppercase: true,
        number: true,
        special: true
      })
    )
    .catch(errors => {
      const errorsString = R.join('', errors.errors)

      const passwordChecks = {
        length: !R.contains('6', errorsString),
        uppercase: !R.contains('uppercase', errorsString),
        number: !R.contains('number', errorsString),
        special: !R.contains('special', errorsString)
      }

      setPasswordChecks(passwordChecks)
    })

  handleChange(e)
}

const LegalAcceptanceInput = ({ classes, formikProps }) => {
  const { handleChange, errors, touched, values } = formikProps
  return (
    <>
      <Grid
        item
        container
        xs
        justifyContent='center'
        alignItems='center'
        className={classes.legal}
      >
        <input
          type='checkbox'
          required
          name='legalAcceptance'
          checked={values.legalAcceptance}
          onChange={handleChange}
        />
        <Typography variant='caption' className={classes.whiteText}>
          I agree to the{' '}
          <Link to='../../terms-and-conditions' className={classes.link}>
            Terms and Conditions
          </Link>{' '}
          and{' '}
          <Link to='../../privacy-policy' className={classes.link}>
            Privacy Policy
          </Link>
        </Typography>
      </Grid>
      {errors.legalAcceptance && touched.legalAcceptance ? (
        <Grid>
          <Typography variant='caption' className={classes.termsErrorMessage}>
            {errors.legalAcceptance}
          </Typography>
        </Grid>
      ) : (
        <span />
      )}
    </>
  )
}

const AccountSetup = ({ classes, ...formikProps }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    uppercase: false,
    number: false,
    special: false
  })

  const {
    handleChange,
    setFieldError,
    validateForm,
    setFieldTouched,
    values
  } = formikProps

  const theme = useTheme()
  const hintPositionLower = useMediaQuery(theme.breakpoints.down('sm'))

  const isEmailAvailable = ({ email }) => {
    const defaultEmailError = 'Email already used'
    checkEmailAvailability(email)
      .then(({ success }) =>
        success
          ? navigate('owner-info')
          : setFieldError('email', defaultEmailError)
      )
      .catch(error =>
        R.pipe(
          R.pathOr(defaultEmailError, ['body', 'error']),
          message => setFieldError('email', message)
        )(error)
      )
  }
  const handleButtonClick = () => {
    validateForm().then(accountErrors => {
      R.when(R.isEmpty, () => isEmailAvailable(values))(accountErrors)
      SetTouchFields(FormFieldNames, setFieldTouched)
    })
  }

  const borderBottomField = `${classes.field} ${classes.borderBottom}`

  return (
    <>
      <Grid container className={classes.container} direction='column'>
        <Grid item>
          <Typography
            variant='h4'
            className={classes.whiteText}
            align='center'
            gutterBottom
          >
            Create your Merchant account
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='subtitle1'
            className={classes.whiteText}
            align='center'
          >
            Signing up for mMoney Merchant is fast and free - no{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='subtitle1'
            className={classes.whiteText}
            align='center'
          >
            commitments or long-term contracts.
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.formContainer}>
        <Paper className={classes.paper}>
          <Grid container direction='column'>
            <Grid item xs className={borderBottomField}>
              <FormItem
                item='Email'
                formik={formikProps}
                variant='standard'
                InputProps={{
                  disableUnderline: true
                }}
                autoComplete='username'
              />
            </Grid>
            <Grid item xs className={borderBottomField}>
              <FormItem
                autoComplete='new-password'
                item='Password'
                formik={formikProps}
                onChange={handlePasswordChange(handleChange, setPasswordChecks)}
                variant='standard'
                InputProps={{
                  disableUnderline: true
                }}
                onFocus={e => setAnchorEl(e.currentTarget)}
                onBlur={() => setAnchorEl(null)} // comment out this to persist popper during development
                type='password'
              />
            </Grid>
            <Grid item xs className={borderBottomField}>
              <FormItem
                autoComplete='new-password'
                item='Password Confirmation'
                formik={formikProps}
                variant='standard'
                InputProps={{
                  disableUnderline: true
                }}
                type='password'
              />
            </Grid>
            <Grid item xs className={classes.field}>
              <FormItem
                item='Referral Code'
                formik={formikProps}
                variant='standard'
                InputProps={{
                  disableUnderline: true
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <LegalAcceptanceInput classes={classes} formikProps={formikProps} />
        <Grid container item justifyContent='center'>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              className={classes.nextStepBtn}
              onClick={handleButtonClick}
              type='button'
            >
              Next Step
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Popper // hintPositionLower
        anchorEl={anchorEl}
        className={classes.popper}
        modifiers={{
          offset: {
            offset: !hintPositionLower && '-10, 35'
          }
        }}
        open={!!anchorEl}
        placement={hintPositionLower ? 'bottom' : 'right'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.popper}>
              <Typography
                component='div'
                variant='caption'
                className={passwordChecks.length ? classes.hintPassText : ''}
              >
                Minimum length 6 characters
              </Typography>
              <Typography
                component='div'
                variant='caption'
                className={passwordChecks.uppercase ? classes.hintPassText : ''}
              >
                At least one uppercase character
              </Typography>
              <Typography
                component='div'
                variant='caption'
                className={passwordChecks.number ? classes.hintPassText : ''}
              >
                At least one number
              </Typography>
              <Typography
                component='div'
                variant='caption'
                className={passwordChecks.special ? classes.hintPassText : ''}
              >
                At least one special character
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

const styles = theme => {
  const extraStyles = getSignupFormStyles(theme)

  return {
    ...extraStyles,
    container: {
      flex: 1
    },
    formContainer: {
      marginTop: 24
    },
    paper: {
      borderRadius: 8
    },
    legal: {
      marginTop: 12,
      '& input': {
        marginRight: 8
      }
    },
    link: {
      color: '#86b4e5',
      textDecoration: 'none',
      fontWeight: 'bold'
    },
    nextStepBtn: {
      width: 205,
      padding: '12px 18px',
      marginTop: 12
    },
    errorMessage: {
      padding: '0 32px 6px',
      color: '#d9534f'
    },
    popper: {
      padding: theme.spacing(1)
    },
    termsErrorMessage: {
      textAlign: 'center',
      color: 'red'
    },
    whiteText: {
      color: 'white'
    },
    hintPassText: {
      color: theme.palette.buttons.green
    }
  }
}

AccountSetup.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AccountSetup)
