import React, { useEffect } from 'react'
import { Grid, Typography, useMediaQuery, withStyles } from '@material-ui/core'
import StyledButton from 'src/components/buttons/styledButton'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { useTheme } from '@material-ui/core/styles'

const ErrorReload = ({ classes, error, logout, notificationShow, reload }) => {
  useEffect(() => {
    if (error) notificationShow({ message: error.message, type: 'error' })
  }, [error])

  const theme = useTheme()
  const isDrawerHidden = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Grid
      className={classes.container}
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Typography gutterBottom variant='h5'>
        Failed to load!
      </Typography>
      <Grid container item justifyContent='center'>
        <StyledButton colorType='blue' onClick={reload}>
          Reload
        </StyledButton>
        {isDrawerHidden && (
          <StyledButton
            colorType='red'
            onClick={logout}
            style={{ marginLeft: theme.spacing(1) }}
          >
            Logout
          </StyledButton>
        )}
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  container: {
    height: '100%',
    width: '100%'
  }
})

const mapDispatchToProps = dispatch => ({
  notificationShow: ({ message, type }) =>
    dispatch(actions.notification.notificationShow({ message, type })),
  logout: () => dispatch(actions.users.usersLogout())
})

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(ErrorReload)
