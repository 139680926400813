import React from 'react'
import {
  Grid,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import * as R from 'ramda'
import { Formik } from 'formik'
import FormikTextField from 'src/components/formik/FormikTextField'
import * as Yup from 'yup'
import StyledButton from 'src/components/buttons/styledButton'

const initialFormValues = () => ({
  password: ''
})

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Password cannot be blank')
})

const handleSubmit = (onConfirm, onCancel, id) => (values, actions) => {
  const onFinish = () => {
    onCancel()
    actions.setSubmitting(false)
  }
  onConfirm(values.password, id, onFinish)
}

const ReportConfirmDialog = ({ open, onCancel, onConfirm, classes, id }) => {
  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
      data-testid='reportConfirmDialog'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        <b>Confirm Action</b>
      </DialogTitle>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialFormValues()}
        isInitialValid={validationSchema.isValidSync(initialFormValues())}
        onSubmit={handleSubmit(onConfirm, onCancel, id)}
      >
        {({ handleSubmit, ...formikProps }) => (
          <form onSubmit={handleSubmit}>
            <Grid container item direction='column'>
              <DialogContent>
                <Grid
                  container
                  direction='column'
                  spacing={1}
                  className={classes.containers}
                >
                  <Grid item>
                    <FormikTextField
                      name='password'
                      fullWidth
                      variant='outlined'
                      placeholder='Password'
                      type='password'
                      formik={formikProps}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  wrap='wrap'
                  justifyContent='space-between'
                />
              </DialogContent>
              <DialogActions className={classes.buttonsConfirm}>
                <Grid
                  container
                  direction='row'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <StyledButton
                      colorType={'red'}
                      onClick={onCancel}
                      className={classes.cancelButton}
                      data-testid='cancel'
                    >
                      Cancel
                    </StyledButton>
                  </Grid>
                  <Grid item>
                    <StyledButton
                      colorType={'green'}
                      type='submit'
                      className={classes.editButton}
                      data-testid='confirm'
                    >
                      Confirm
                    </StyledButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </Grid>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

const styles = theme => ({
  title: {
    paddingBottom: 0
  },
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  },
  cancelButton: {
    backgroundColor: theme.palette.buttons.red,
    color: theme.palette.lights.white
  },
  editButton: {
    backgroundColor: theme.palette.buttons.green,
    color: theme.palette.lights.white
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  }
})

export default R.pipe(withStyles(styles))(ReportConfirmDialog)
