import React from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'

import { connect } from 'react-redux'
import * as R from 'ramda'
import resourcesRedux from 'src/redux/resources'
import { actions } from 'src/redux'

import { makeStyles } from '@material-ui/core/styles'

import SideBar from 'src/components/side-bar'

const drawerWidth = 220

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  }
}))

export const ResponsiveDrawer = props => {
  const { drawerVisible, toggleMenuButton } = props
  const classes = useStyles()

  return (
    <nav className={classes.drawer} aria-label='side drawer'>
      <Hidden smUp implementation='css'>
        <Drawer
          variant='temporary'
          open={drawerVisible}
          onClose={toggleMenuButton}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <SideBar />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation='css'>
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant='permanent'
          open
        >
          <SideBar />
        </Drawer>
      </Hidden>
    </nav>
  )
}

ResponsiveDrawer.propTypes = {
  drawerVisible: PropTypes.bool.isRequired,
  toggleMenuButton: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  drawerVisible: resourcesRedux.selectors.drawerVisible(state)
})

const mapDispatchToProps = dispatch => ({
  toggleMenuButton: () => dispatch(actions.resources.toggleMenuButton())
})

export default R.pipe(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ResponsiveDrawer)
