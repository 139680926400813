import React from 'react'
import * as R from 'ramda'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SimpleForm from './simpleForm'

const handleSubmit = (onDeposit, onCancel) => (values, actions) => {
  const onFinish = () => {
    actions.setSubmitting(false)
  }

  onDeposit(
    {
      amount: values.amount,
      method: 'cash'
    },
    onFinish,
    onCancel
  )
}

export const DepositForm = ({ onConfirmDeposit, onCancel }) => (
  <SimpleForm
    onConfirm={onConfirmDeposit}
    onCancel={onCancel}
    handleSubmit={handleSubmit}
    label='Fund BBD via cash'
    description='Deposit fiat dollars to get digital Barbados dollars.'
    testId={'depositForm'}
  />
)

const mapDispatchToProps = dispatch => ({
  onConfirmDeposit: (payload, onFinish, onCancel) =>
    dispatch(
      actions.transactions.transactionsDeposit(payload, onFinish, onCancel)
    )
})

DepositForm.propTypes = {
  onConfirmDeposit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default R.pipe(
  connect(
    null,
    mapDispatchToProps
  )
)(DepositForm)
