import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DropzoneComponent from 'src/pages/verifications/document/dropzone'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import * as R from 'ramda'

export const AddDocumentModal = ({
  acceptedFileExtensions,
  classes,
  documentTypes,
  handleAddDocuments,
  handleClose,
  hasBase64,
  open,
  promptText,
  title
}) => {
  const [document, setDocument] = useState(undefined)
  const [documentError, setDocumentError] = useState('')
  const [type, setType] = useState(documentTypes[0].value)

  const artefactCleanup = () => {
    if (document && R.hasPath(['file', 'preview'], document)) {
      URL.revokeObjectURL(document.file.preview)
    }
  }

  const onClose = () => {
    artefactCleanup()
    setDocument(undefined)
    setDocumentError('')
    handleClose()
  }

  const handleAddDocument = () => {
    if (!document) return setDocumentError('A valid image is required')

    handleAddDocuments(document, type)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      PaperProps={{ className: classes.container }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid>
          <DropzoneComponent
            acceptedFileExtensions={acceptedFileExtensions}
            document={document}
            errorMessage={documentError}
            handleAddDocument={setDocument}
            handleRemoveDocument={() => setDocument(undefined)}
            hasBase64={hasBase64}
            promptText={promptText}
          />
        </Grid>
        <Typography variant='caption'>SELECT THE TYPE OF DOCUMENT</Typography>
        <ReactSelect
          aria-label='document-type'
          name='documentType'
          id='documentType'
          styles={{
            control: provided => ({ ...provided, minHeight: 55 })
          }}
          defaultValue={documentTypes[0]}
          options={documentTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.value}
          onChange={value => setType(value.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          data-testid='add-button'
          onClick={handleAddDocument}
        >
          Add
        </Button>
        <Button color='primary' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = theme => ({
  container: {
    overflow: 'visible'
  },
  content: {
    width: 520,
    paddingTop: '0px !important',
    overflow: 'visible'
  }
})

AddDocumentModal.propTypes = {
  acceptedFileExtensions: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.node
    })
  ),
  handleAddDocuments: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  hasBase64: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  promptText: PropTypes.string,
  title: PropTypes.string
}

AddDocumentModal.defaultProps = {
  open: false,
  promptText: 'Click or drag to add a document.',
  title: 'Add Document'
}

export default withStyles(styles)(AddDocumentModal)
