import * as R from 'ramda'
export const FRONT_IMAGE_TYPE = 1
export const BACK_IMAGE_TYPE = 2
export const POA_IMAGE_TYPE = 3
export const ID_FRONT = 'front'
export const ID_BACK = 'back'
export const PROOF_OF_ADDRESS = 'poa'
export const ACCEPTED_FILES_EXTENSIONS = ['*.jpeg', '*.jpg', '*.png']
export const ID_TYPES = [
  {
    name: 'Passport',
    value: 'passport'
  },
  {
    name: 'Identity Card',
    value: 'id'
  },
  {
    name: "Driver's License",
    value: 'license'
  }
]

export const POA_TYPES = [
  {
    name: 'Utility Bill',
    value: 1
  },
  {
    name: 'Bank Statement',
    value: 2
  },
  {
    name: 'Credit Card Statement',
    value: 3
  },
  {
    name: 'Phone Bill',
    value: 4
  },
  {
    name: 'Lease Agreement',
    value: 5
  }
]

export const documentTypes = [
  {
    name: 'Valid photo IDs for owners and directors',
    value: '1'
  },
  {
    name: 'Certificate of Business / Certificate of Incorporation',
    value: '4'
  },
  {
    name: 'Proof of address for owners and directors',
    value: '3'
  },
  {
    name: 'Register / List  of Members and Directors',
    value: '5'
  },
  {
    name: 'Bank Statements / Audited Financial Statements',
    value: '6'
  },
  {
    name: 'Other',
    value: '7'
  }
]

export const transformDocuments = documents => {
  const data = new window.FormData()

  documents.forEach(document => {
    data.append(document.file.name, document.documentType)
    data.append('files', document.file)
  })

  return data
}

export const getDocumentTypeTextFromValue = (value, types) =>
  R.pipe(
    R.find(R.propEq('value', value)),
    R.prop('name', R.__)
  )(types)

export const transformBasicInfo = values => ({
  ...values,
  businessType: values.businessType.value,
  category: values.businessCategory.value,
  estimatedAccountActivity: values.estimatedAccountActivity.value,
  parish: values.parish.value,
  country: values.country.code
})

export const transformUserDocuments = userDocuments => {
  const documentArray = R.pipe(
    R.values,
    R.filter(document => document !== undefined)
  )(userDocuments)

  const transformedDocuments = R.map(({ file, documentType }) => {
    return {
      file: file.base64,
      userDocumentTypeId: documentType
    }
  }, documentArray)

  return transformedDocuments
}

export const transformExecutiveDocuments = executiveDocuments =>
  executiveDocuments.map(document => ({
    consumerInfo: document.consumerInfo,
    userDocuments: document.userDocuments
  }))

export const convertToBase64Promise = image => {
  return new Promise(resolve => {
    const reader = new window.FileReader()
    reader.readAsDataURL(image)
    reader.onload = () => resolve(reader.result)
    reader.onerror = () => {
      reader.abort()
      throw new Error('Failed to process image.')
    }
  })
}

export const isOfficersRequired = ({ businessType }) =>
  businessType === 'nonprofit' ||
  businessType === 'foreigncorp' ||
  businessType === 'corporation'
