import React from 'react'
import { Formik } from 'formik'
import {
  Grid,
  Typography,
  withStyles,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import FormikTextField from 'src/components/formik/FormikTextField'
import FormikCheckbox from 'src/components/formik/FormikCheckbox'
import * as Yup from 'yup'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import StyledButton from 'src/components/buttons/styledButton'
import { roleProptype } from 'src/pages/manageMembers/proptypes'

const initialFormValues = ({ availablePerms, role = {} }) => {
  const permissionObj = availablePerms.reduce((obj, { key }) => {
    obj[key] = (role && role[key]) || false
    return obj
  }, {})
  const done = {
    title: role.title || '',
    ...permissionObj
  }
  return done
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Title is required')
})

const handleSubmit = (onConfirm, onCancel, id) => (values, actions) => {
  const onFinish = () => {
    onCancel()
    actions.setSubmitting(false)
  }
  onConfirm({ id, ...values }, onFinish)
}

const roleForm = ({
  onCancel,
  onAddRole,
  onEditRole,
  classes,
  role,
  availablePerms = []
}) => {
  return (
    <Grid>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialFormValues({ availablePerms, role })}
        isInitialValid={validationSchema.isValidSync(
          initialFormValues({ availablePerms, role })
        )}
        onSubmit={handleSubmit(
          onAddRole || onEditRole,
          onCancel,
          role && role.id
        )}
      >
        {({ handleSubmit, ...formikProps }) => (
          <form onSubmit={handleSubmit}>
            <Grid container item direction='column'>
              <DialogContent>
                <Grid
                  container
                  direction='column'
                  spacing={1}
                  className={classes.containers}
                >
                  <Grid item>
                    <Typography
                      variant='caption'
                      className={classes.titleHeader}
                    >
                      TITLE
                    </Typography>
                    <FormikTextField
                      name='title'
                      type='text'
                      variant='outlined'
                      placeholder='Title'
                      fullWidth
                      formik={formikProps}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  wrap='wrap'
                  justifyContent='space-between'
                >
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    wrap='wrap'
                    className={classes.container}
                  >
                    {availablePerms &&
                      availablePerms.map(perm => (
                        <Grid
                          key={perm.key}
                          item
                          container
                          direction='row'
                          xs={12}
                          md={6}
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Grid item>
                            <FormikCheckbox
                              name={perm.key}
                              label={perm.value}
                              inputProps={{ 'data-testid': perm.value }}
                              color='primary'
                              value='Yes'
                              formik={formikProps}
                            />
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.buttonsConfirm}>
                <Grid
                  container
                  direction='row'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <StyledButton onClick={onCancel} colorType={'red'}>
                      Cancel
                    </StyledButton>
                  </Grid>
                  <Grid item>
                    <StyledButton
                      type='submit'
                      colorType={'green'}
                      data-testid='submitAddEditRole'
                    >
                      {onAddRole ? 'Add Role' : 'Save'}
                    </StyledButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  )
}

roleForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAddRole: PropTypes.func,
  onEditRole: PropTypes.func,
  classes: PropTypes.object.isRequired,
  role: roleProptype,
  availablePerms: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
}

const styles = theme => ({
  title: {
    paddingBottom: 0
  },
  titleHeader: {
    fontWeight: 600
  },
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  },
  cancelButton: {
    backgroundColor: theme.palette.buttons.red,
    color: theme.palette.lights.white
  },
  editButton: {
    backgroundColor: theme.palette.buttons.green,
    color: theme.palette.lights.white
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  },
  container: {
    padding: '0 24px 0 24px'
  }
  // root: {
  //   color: theme.palette.buttons.blue[600],
  //   '&$checked': {
  //     color: theme.palette.buttons.blue[500],
  //   },
  // },
  // checked: {},
})

export default R.pipe(withStyles(styles))(roleForm)
