import React from 'react'
import Link from 'src/components/nav-link'
import { Typography, Grid, withStyles } from '@material-ui/core'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from 'src/redux'
import * as R from 'ramda'

const links = [
  { to: 'settings', title: 'General Settings' },
  { to: 'verifications', title: 'Verification' },
  { to: 'manage-api', title: 'API Management' }
]

const DropDown = ({ classes, logout }) => {
  return (
    <Grid container direction='column' className={classes.dropdown}>
      {links.map(({ to, title }) => (
        <Grid item key={to}>
          <Link className={classes.items} to={to}>
            {title}
          </Link>
        </Grid>
      ))}
      <Grid item>
        <Typography
          className={`${classes.items} ${classes.signout}`}
          onClick={logout}
        >
          Signout
        </Typography>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  alternative: {},
  items: {
    padding: '10px 12px',
    color: theme.palette.text.dark,
    fontWeight: '600',
    fontSize: '13px',
    display: 'block',
    borderRadius: '4px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.lights.grey
    }
  },
  signout: {
    borderTop: '1px solid ' + theme.palette.secondary.main
  }
})

const actionCreators = {
  logout: actions.users.usersLogout
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(actionCreators, dispatch)

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(DropDown)
