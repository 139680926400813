import btcIcon from 'src/assets/images/currencies/btc-icon.png'
import bbdIcon from 'src/assets/images/currencies/bbd-icon.png'
import xcdIcon from 'src/assets/images/currencies/ic-ec.png' // TODO: Can remove when merchant backend provides images
import plainIcon from 'src/assets/images/notif-plain.png'
import requireContext from 'require-context.macro'

const flags = importAllFlags(
  requireContext(
    '../../../src/assets/images/flags',
    false,
    /\.(png|jpe?g|svg)$/
  )
)

function importAllFlags (r) {
  let images = {}
  r &&
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item)
      return item
    })
  return images
}

export function getFlagImg (name = '') {
  if (typeof name !== 'string') {
    return plainIcon
  }

  if (name.includes('/')) {
    return getFlagFromBackend(name)
  }

  return getFlagFromFolder(name)
}

function getFlagFromBackend (name = '') {
  const baseURL = process.env.REACT_APP_API_HOST
  const imageUrl = `${name}`

  if (imageUrl.startsWith('/')) {
    const formattedBaseURL = `${baseURL}${
      baseURL.endsWith('/') ? '' : '/'
    }merchant`
    return `${formattedBaseURL}${name}`
  }
}

function getFlagFromFolder (name) {
  const formattedName = name ? name.toLowerCase() : ''
  switch (formattedName) {
    case 'xcd': // TODO: Can remove when merchant backend provides images
      return xcdIcon
    case 'bbd':
      return bbdIcon
    case 'ttd':
      return flags['tt.svg']
    case 'btc':
      return btcIcon
    default:
      return flags[formattedName + '.svg']
  }
}
