import React from 'react'
import NavDropdown from 'src/components/nav-dropdown'
import { Grid, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import * as R from 'ramda'
import resourcesRedux from 'src/redux/resources'
import { actions } from 'src/redux'

const ProfileBadge = ({ classes, userInfo, companyName }) => {
  return (
    <Grid container direction='column' className={classes.container}>
      <Grid item className={classes.options}>
        <NavDropdown />
      </Grid>
      <Grid
        container
        className={classes.profile}
        justifyContent='center'
        alignItems='center'
        item
      >
        <Grid item className={classes.photo}>
          <img
            src='https://static.listionary.com/core/img/default-user.png'
            alt='profile'
          />
        </Grid>
        <Grid item className={classes.info}>
          <span className={classes.userName}>
            {userInfo.firstName} {userInfo.lastName}
          </span>
          <span className={classes.companyName}>{companyName}</span>
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = ({ theme }) => ({
  container: {
    width: 200,
    '&:hover $options': {
      visibility: 'visible',
      opacity: '1'
    },
    position: 'fixed',
    bottom: 10,
    zIndex: 10
  },
  photo: {
    width: 32,
    height: 32,
    borderRadius: 5,
    overflow: 'hidden',
    marginRight: 10,
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  userName: {
    fontWeight: 600,
    fontSize: 15,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'white'
  },
  companyName: {
    fontSize: 12,
    fontWeight: 700,
    color: '#9e9e9e',
    letterSpacing: 0.85
  },
  options: {
    position: 'absolute',
    width: '100%',
    top: '0px',
    transform: 'translate(0, -170px)',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 5px 20px 1px rgba(0, 0, 0, 0.24)',
    visibility: 'hidden',
    opacity: '0',
    transition: 'all 0.2s ease-in-out',
    padding: '6px',
    minWidth: '13px'
  }
})

const mapStateToProps = state => ({
  userInfo: resourcesRedux.selectors.userInfo(state),
  companyName: resourcesRedux.selectors.companyName(state)
})

const mapDispatchToProps = dispatch => ({
  requestMerchantInfo: () => dispatch(actions.resources.getMerchantInfo())
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProfileBadge)
