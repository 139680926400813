export const filterByStatus = (employee, { value }) => {
  if (value === 'active') {
    return !!employee.active
  }
  if (value === 'verified') {
    return !!employee.verified
  }
  if (value === 'inactive') {
    return !employee.active
  }
  if (value === 'unverified') {
    return !employee.verified
  }
}

export const filterBySearch = (employee, searchedEmployee = '') =>
  (employee.firstName + ' ' + employee.lastName)
    .toUpperCase()
    .includes(searchedEmployee.toUpperCase())

export const filterEmployees = (
  employees,
  selectedRole,
  selectedStatus,
  searchedEmployee
) =>
  employees
    .filter(employee =>
      selectedRole.value === 0
        ? employee
        : employee.filteredRoles.find(role => role.value === selectedRole.value)
    )
    .filter(employee =>
      selectedStatus.value === 'all'
        ? employee
        : filterByStatus(employee, selectedStatus)
    )
    .filter(employee =>
      searchedEmployee === '' || !searchedEmployee
        ? employee
        : filterBySearch(employee, searchedEmployee)
    )
