import React from 'react'
import { withStyles, Stepper, Step, StepLabel } from '@material-ui/core'

const StepperComponent = ({
  classes,
  steps,
  stepLabelProps,
  stepProps,
  ...props
}) => (
  <Stepper {...props}>
    {steps.map(label => (
      <Step key={label} {...stepProps}>
        <StepLabel {...stepLabelProps}>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
)

const styles = theme => ({})

Stepper.defaultProps = {
  alternativeLabel: true
}
export default withStyles(styles)(StepperComponent)
