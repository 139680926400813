import React from 'react'

import ProfileBadge from 'src/components/profile-badge'
import Link from 'src/components/nav-link'
import Logo from 'src/assets/images/logo.png'
import { Grid, withStyles } from '@material-ui/core'
// import flags from 'src/tools/featureFlags'

// const tiers = flags.showTiers
//  ? { to: 'merchant-features', title: 'Merchant Features' }
//  : undefined

const links = [
  { to: 'overview', title: 'Dashboard' },
  { to: 'transact', title: 'Transact' },
  { to: 'reports', title: 'Reports' },
  { to: 'manage-members', title: 'Manage Members' },
  { to: 'static', title: 'Static Payments' }
]

// if (tiers) {
//  links.push(tiers)
// }

const SideBar = ({ classes }) => {
  return (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      className={classes.bar}
    >
      <Grid container>
        <Grid container className={classes.logo}>
          <Link to='/dashboard/overview'>
            <img src={Logo} alt='logo' />
          </Link>
        </Grid>

        <Grid container direction='column' className={classes.nav}>
          {links.map(({ to, title }) => (
            <Grid item key={to}>
              <Link className={classes.link} to={to}>
                {title}
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container>
        <ProfileBadge />
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  bar: {
    width: 220,
    height: '100vh',
    background: 'linear-gradient(-170deg, #194896 0%, #241364 85%)', // TODO theme
    padding: '0 15px 24px',
    position: 'fixed'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    marginBottom: 30,
    '& img': { width: '100%', maxWidth: 180 }
  },
  link: {
    padding: '10px 15px',
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.lights.lightGrey,
    display: 'block',
    borderRadius: 4,
    marginBottom: 2,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.darker
    },
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white'
    }
  }
})

export default withStyles(styles)(SideBar)
