import { put, call, takeLatest, select } from 'redux-saga/effects'
import {
  getInvoicesMerchant,
  getInvoicesTeller
} from 'src/services/merchant/transation'
import { actions } from 'src/redux'
import { getAuthToken } from 'src/tools/token'
import chartData from 'src/redux/chart'
import {
  format,
  subDays,
  startOfToday,
  subMonths,
  addHours,
  subYears,
  isSameDay,
  isSameHour,
  isSameMonth,
  isSameYear,
  parseISO
} from 'date-fns'
import * as R from 'ramda'
export const defaultFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"

const getGenerators = period => {
  switch (period) {
    case 0:
      return { getParams: getParamsForToday, getLabels: getLabelsforToday }
    case 1:
      return { getParams: getParamsForWeek, getLabels: getLabelsforWeek }
    case 2:
      return { getParams: getParamsForMonth, getLabels: getLabelsforMonth }
    case 3:
      return { getParams: getParamsForYear, getLabels: getLabelsforYear }
    case 4:
      return { getParams: getParamsForAll, getLabels: getLabelsforAll }
    default:
      return { getParams: getParamsForWeek, getLabels: getLabelsforWeek }
  }
}

const getFilterForLabel = period => {
  switch (period) {
    case 0:
      return isSameHour
    case 1:
    case 2:
      return isSameDay
    case 3:
      return isSameMonth
    case 4:
      return isSameYear
    default:
      return isSameDay
  }
}

export function * getInvoicesSaga ({ token, params }) {
  try {
    const { invoices } = yield call(getInvoicesMerchant, { token, params })
    return invoices
  } catch (e) {
    throw e
  }
}

export function * getDebitsSaga ({ token, params }) {
  try {
    const { debits } = yield call(getInvoicesTeller, { token, params })
    return debits
  } catch (e) {
    throw e
  }
}

const getFetchingSaga = transactionType => {
  try {
    switch (transactionType) {
      case 'teller':
        return { dataFetcherSaga: getDebitsSaga }
      case 'merchant':
        return { dataFetcherSaga: getInvoicesSaga }
      default:
        return { dataFetcherSaga: getInvoicesSaga }
    }
  } catch (e) {
    throw e
  }
}

const getTotalsForLabels = ({
  invoices,
  labels,
  filterForLabels,
  addedProp
}) => {
  const data = labels.map(labelPoint => {
    const { date } = labelPoint
    let count = 0
    const totalForLabel = invoices.reduce((acc, invoice) => {
      const creationDate = parseISO(invoice.updated_at)
      if (filterForLabels(creationDate, date)) {
        count = count + 1
        const amount = invoice[addedProp]
        return +amount + +acc
      } else {
        return acc
      }
    }, 0)

    return { ...labelPoint, totalForLabel, count }
  })

  const total = data.reduce(
    (acc, { totalForLabel }) => +acc + +totalForLabel,
    0
  )
  const transactionNum = invoices && invoices.length

  return { data, transactionNum, total }
}
// there are two lists: 1. labels for the chart, 2. transactions
// we will check with isSameMonth etc. depending on the option (month, week, etc)
// and calculate totals from paid_float or amount_float for each label point

export function * getChartDataSaga ({ period, transactionType }) {
  try {
    const { getParams, getLabels } = yield call(getGenerators, period)
    const token = yield select(getAuthToken)
    const now = yield call(currentDate)
    const params = yield call(getParams, now, transactionType)

    const { dataFetcherSaga } = yield call(getFetchingSaga, transactionType)

    const invoices = yield call(dataFetcherSaga, { token, params })
    const labels = yield call(getLabels, now)
    const filterForLabels = yield call(getFilterForLabel, period)
    const results = yield call(getTotalsForLabels, {
      invoices,
      labels,
      filterForLabels,
      addedProp: transactionType === 'teller' ? 'amount_float' : 'paid_float'
    })

    yield put(actions.chartData.getChartDataSuccess(results, period))
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'fail'
      })
    )
  }
}

export function currentDate () {
  return new Date()
}

export const getLabelsforWeek = now =>
  R.range(0, 7)
    .reverse()
    .map(index => {
      const date = subDays(startOfToday(), index)
      return { toShow: format(date, 'MMM do'), date }
    })

export const getLabelsforMonth = now =>
  R.range(0, 30)
    .reverse()
    .map(index => {
      const date = subDays(startOfToday(), index)
      return { toShow: format(date, 'do'), date }
    })

export const getLabelsforToday = now => {
  return R.range(0, 24).map(index => {
    const date = addHours(startOfToday(), index)
    return { toShow: format(date, 'hh:00 a'), date }
  })
}

export const getLabelsforYear = now =>
  R.range(0, 12)
    .reverse()
    .map(index => {
      const date = subMonths(startOfToday(), index)
      return { toShow: format(date, 'MMM'), date }
    })

export const getLabelsforAll = now =>
  R.range(0, 3)
    .reverse()
    .map(index => {
      const date = subYears(startOfToday(), index)
      return { toShow: format(date, 'yyyy'), date }
    })

export const getParamsForToday = (now, transactionType) => ({
  status: transactionType === 'teller' ? 'accept' : 'confirmed',
  start: format(startOfToday(), defaultFormat),
  stop: format(now, defaultFormat),
  currency: 'BBD',
  page: -1,
  limit: -1
})

export const getParamsForWeek = (now, transactionType) => {
  return {
    status: transactionType === 'teller' ? 'accept' : 'confirmed',
    start: format(subDays(now, 7), defaultFormat),
    stop: format(now, defaultFormat),
    currency: 'BBD',
    page: -1,
    limit: -1
  }
}

export const getParamsForMonth = (now, transactionType) => ({
  status: transactionType === 'teller' ? 'accept' : 'confirmed',
  start: format(subDays(now, 30), defaultFormat),
  stop: format(now, defaultFormat),
  currency: 'BBD',
  page: -1,
  limit: -1
})

export const getParamsForYear = (now, transactionType) => ({
  status: transactionType === 'teller' ? 'accept' : 'confirmed',
  start: format(subMonths(now, 12), defaultFormat),
  stop: format(now, defaultFormat),
  currency: 'BBD',
  page: -1,
  limit: -1
})

export const getParamsForAll = (now, transactionType) => ({
  status: transactionType === 'teller' ? 'accept' : 'confirmed',
  start: format(subYears(now, 3), defaultFormat),
  stop: format(now, defaultFormat),
  currency: 'BBD',
  page: -1,
  limit: -1
})

export default function * root () {
  yield takeLatest(chartData.types.GET_CHART_DATA, getChartDataSaga)
}
