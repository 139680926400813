import ApolloClient from 'apollo-boost'
import { getAuthToken } from 'src/tools/token'

export const client = new ApolloClient({
  request: async operation => {
    const token = getAuthToken()
    operation.setContext({
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: token ? `Bearer ${token}` : ''
      }
    })
  },
  uri: `${process.env.REACT_APP_API_HOST}/merchant/graphql`
})
