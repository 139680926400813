import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import { validateReduxHandlers } from 'src/redux/helpers'
import { createSelector } from 'reselect'
import usersRedux from 'src/redux/users'

const { types: UsersTypes } = usersRedux

const STATE_KEY = 'reports'
const initialState = {
  transactions: [],
  pagination: { total: 0, page: 0, limit: 0 },
  payload: {}
}

const payload = createSelector(
  R.path([STATE_KEY, 'payload']),
  R.identity
)

const transactions = createSelector(
  R.path([STATE_KEY, 'transactions']),
  R.identity
)

const selectors = {
  payload,
  transactions,
  reportExist: createSelector(
    R.path([STATE_KEY, 'transactions']),
    transactions => !!transactions.length
  ),
  reportNeverRun: createSelector(
    R.path([STATE_KEY, 'payload']),
    payload => !Object.keys(payload).length
  ),
  resultsExist: createSelector(
    payload,
    transactions,
    (payload, transactions) =>
      !!Object.keys(payload).length && !!transactions.length
  ),
  total: createSelector(
    R.path([STATE_KEY, 'pagination', 'total']),
    R.identity
  ),
  pageNumber: createSelector(
    R.path([STATE_KEY, 'pagination', 'page']),
    page => page - 1
  ),
  rowsPerPage: createSelector(
    R.path([STATE_KEY, 'pagination', 'limit']),
    R.identity
  )
}

const getReport = (state, _args) => state

const getReportPage = (state, _args) => state

export const getReportSuccess = (
  state,
  { mappedResults, pagination, payload }
) =>
  R.merge(state, {
    transactions: mappedResults,
    pagination,
    payload
  })

const getReportFail = (state, _args) => state

export const clearReport = (state, _args) =>
  R.merge(state, {
    transactions: initialState.transactions,
    pagination: initialState.pagination,
    payload: initialState.payload
  })

const downloadReport = (state, _args) => state
const downloadReportSuccess = (state, _args) => state
const downloadReportFail = (state, _args) => state

const requestInvoiceCancel = (state, _args) => state
const requestInvoiceCancelSuccess = (state, _args) => state
const requestInvoiceCancelFail = (state, _args) => state

const requestInvoiceRefund = (state, _args) => state
const requestInvoiceRefundSuccess = (state, _args) => state
const requestInvoiceRefundFail = (state, _args) => state

const { Types, Creators } = createActions({
  getReport: ['payload'],
  getReportPage: ['payload'],
  getReportSuccess: ['mappedResults', 'pagination', 'payload'],
  getReportFail: ['error'],
  clearReport: [''],
  downloadReport: [''],
  downloadReportSuccess: [''],
  downloadReportFail: [''],
  requestInvoiceCancel: ['password', 'id', 'onFinish'],
  requestInvoiceCancelSuccess: [''],
  requestInvoiceCancelFail: [''],
  requestInvoiceRefund: ['password', 'id', 'onFinish'],
  requestInvoiceRefundSuccess: [''],
  requestInvoiceRefundFail: ['']
})

const logout = () => initialState

const handlers = {
  [Types.GET_REPORT]: getReport,
  [Types.GET_REPORT_PAGE]: getReportPage,
  [Types.GET_REPORT_SUCCESS]: getReportSuccess,
  [Types.GET_REPORT_FAIL]: getReportFail,
  [Types.CLEAR_REPORT]: clearReport,
  [Types.DOWNLOAD_REPORT]: downloadReport,
  [Types.DOWNLOAD_REPORT_FAIL]: downloadReportSuccess,
  [Types.DOWNLOAD_REPORT_SUCCESS]: downloadReportFail,
  [Types.REQUEST_INVOICE_CANCEL]: requestInvoiceCancel,
  [Types.REQUEST_INVOICE_CANCEL_SUCCESS]: requestInvoiceCancelSuccess,
  [Types.REQUEST_INVOICE_CANCEL_FAIL]: requestInvoiceCancelFail,
  [Types.REQUEST_INVOICE_REFUND]: requestInvoiceRefund,
  [Types.REQUEST_INVOICE_REFUND_SUCCESS]: requestInvoiceRefundSuccess,
  [Types.REQUEST_INVOICE_REFUND_FAIL]: requestInvoiceRefundFail,
  [UsersTypes.USERS_LOGOUT]: logout
}

validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
