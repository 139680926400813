import { deleteJSON, getJSON, postJSON } from 'src/services/lib/http'
import { fetchJSONDefaultError } from '../lib/fetch'

export const addApiKeyData = ({ apiKey, token }) =>
  postJSON(
    '/merchant/api-keys',
    {
      receive_funds: apiKey.receiveFunds,
      send_refunds: apiKey.sendRefunds,
      title: apiKey.title
    },
    token,
    fetchJSONDefaultError
  )

export const deleteApiKeyData = ({ id, token }) =>
  deleteJSON('/merchant/api-keys/' + id, null, token, fetchJSONDefaultError)

export const getApiKeysData = ({ token }) =>
  getJSON('/merchant/api-keys', null, token)

export const addWebHookData = ({ webhook, token }) =>
  postJSON('/merchant/webhooks', webhook, token, fetchJSONDefaultError)

export const getWebHooksData = ({ token }) =>
  getJSON('/merchant/webhooks', null, token)

export const deleteWebHookData = ({ id, token }) =>
  deleteJSON('/merchant/webhooks/' + id, null, token, fetchJSONDefaultError)
