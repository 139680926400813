import React from 'react'
import PropTypes from 'prop-types'
import { TextField, withStyles } from '@material-ui/core'
import FormikPropTypes from 'src/components/formik/FormikPropTypes'

const FormikTextField = ({
  name,
  classes,
  formik: { touched, errors, values, handleChange },
  InputProps,
  type,
  ...others
}) => (
  <TextField
    name={name}
    id={name}
    type={type}
    helperText={touched[name] && errors[name]}
    error={touched[name] && !!errors[name]}
    value={values[name]}
    onChange={handleChange}
    InputProps={{ className: classes.input, ...InputProps }}
    FormHelperTextProps={{
      classes: {
        error: classes.error
      }
    }}
    {...others}
  />
)

FormikTextField.propTypes = {
  formik: FormikPropTypes,
  handleChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  error: {
    fontSize: '0.7rem'
  },
  input: {
    fontSize: '0.95rem'
  }
})

export default withStyles(styles)(FormikTextField)
