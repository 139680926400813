import React from 'react'
import { Grid, withStyles, CircularProgress } from '@material-ui/core'
import * as R from 'ramda'

const Loader = ({ classes }) => (
  <Grid
    container
    className={classes.loader}
    justifyContent='center'
    alignContent='center'
    alignItems='center'
  >
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
)

const styles = {
  loader: { minHeight: 300 }
}

export default R.pipe(withStyles(styles))(Loader)
