import React from 'react'
import { withStyles, Dialog, DialogTitle } from '@material-ui/core'
import RoleForm from 'src/pages/manageMembers/roleForm'
import * as R from 'ramda'
import PropTypes from 'prop-types'

export const AddRoleComponent = ({ open, onCancel, classes, ...formProps }) => {
  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        <b>Add Role</b>
      </DialogTitle>
      <RoleForm {...formProps} onCancel={onCancel} />
    </Dialog>
  )
}

AddRoleComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddRole: PropTypes.func.isRequired,
  availablePerms: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
}

const styles = theme => ({
  title: {
    paddingBottom: 0
  },
  titleHeader: {
    fontWeight: 600
  },

  containers: {
    padding: theme.spacing(1)
  },
  container: {
    padding: '0 24px 0 24px'
  }
})

export default R.pipe(withStyles(styles))(AddRoleComponent)
