import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, TextField, withStyles } from '@material-ui/core'

const TextInput = ({ label, classes, name, errors, touched, ...props }) => {
  const errorMessage = touched[name] && errors[name]
  return (
    <Grid container direction='column' className={classes.container}>
      <Grid item>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          InputProps={{ disableUnderline: true, className: classes.input }}
          name={name}
          {...props}
        />
        {errorMessage && (
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  container: {
    padding: '0 32px'
  },
  label: {
    textTransform: 'uppercase',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    marginTop: 8
  },
  input: {
    fontSize: '0.9rem',
    padding: '12px 0'
  },
  errorMessage: {
    color: '#d9534f',
    fontSize: '0.9rem',
    '&::first-letter': {
      textTransform: 'capitalize'
    }
  }
})

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  classes: PropTypes.object
}

TextInput.defaultProps = {
  type: 'text',
  required: false,
  placeholder: '',
  label: '',
  errors: {},
  touched: {}
}

export default withStyles(styles)(TextInput)
