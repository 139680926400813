import React, { useState } from 'react'
import { Grid, Card, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Select from 'src/components/forms/entry/Select'
import { getDisplaySnippet } from 'src/services/merchant/snippet'
import IntegrateCodeSnippet from 'src/pages/manageAPI/integrateCodeSnippetDialog'
import CopyToClipboard from 'src/components/copyToClipboard'
import StyledButton from 'src/components/buttons/styledButton'
import Label from 'src/components/forms/label'

const SelectKey = ({
  classes,
  apiKeyList,
  apiKeySelected,
  setApiKeySelected
}) => {
  const [integrateCodeSnippetOpen, setIntegrateCodeSnippetOpen] = useState(
    false
  )

  const handleIntegrateCodeSnippetClick = () => {
    setIntegrateCodeSnippetOpen(true)
  }

  const handleCancelIntegrateCodeSnippetClick = () => {
    setIntegrateCodeSnippetOpen(false)
  }

  return (
    <Card direction='row' className={classes.card}>
      <Grid container>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <Label>Select An API Key To Use For Online Payments</Label>
          </Grid>
          <Grid item>
            <Select
              name='apiKey'
              options={apiKeyList}
              getOptionLabel={option => option.title}
              getOptionValue={option => option.apiKey}
              placeholder='API Keys'
              customStyles={{ input: () => ({ overflow: 'auto' }) }}
              value={apiKeySelected}
              onChange={value => setApiKeySelected(value)}
              aria-label={'API Keys'}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          spacing={1}
          justifyContent='flex-end'
          className={classes.buttons}
        >
          <Grid item>
            <CopyToClipboard
              style={{ minWidth: 165 }}
              disabled={!apiKeySelected}
              toCopy={getDisplaySnippet(apiKeySelected)}
              beforeCopyLabel='Copy Code Snippet'
              data-testid='copyCodeSnippet'
            />
          </Grid>
          <Grid item>
            <StyledButton
              style={{ minWidth: 150 }}
              disabled={!apiKeySelected}
              onClick={handleIntegrateCodeSnippetClick}
              colorType={'green'}
              data-testid='howToIntegrate'
            >
              How to Integrate
            </StyledButton>
          </Grid>
        </Grid>
        <IntegrateCodeSnippet
          open={integrateCodeSnippetOpen}
          onCancel={handleCancelIntegrateCodeSnippetClick}
          apiKey={apiKeySelected}
        />
      </Grid>
    </Card>
  )
}

SelectKey.propTypes = {
  classes: PropTypes.object.isRequired,
  apiKeyList: PropTypes.arrayOf(
    PropTypes.shape({
      apiKey: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      receiveFunds: PropTypes.bool.isRequired,
      sendRefunds: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  apiKeySelected: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    receiveFunds: PropTypes.bool.isRequired,
    sendRefunds: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
  }),
  setApiKeySelected: PropTypes.func.isRequired
}

const styles = theme => ({
  card: {
    flex: 1,
    marginTop: 15,
    padding: '20px 35px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: 'visible'
  },
  buttons: {
    padding: theme.spacing(1, 0, 0, 1)
  }
})

export default withStyles(styles)(SelectKey)
