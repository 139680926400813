import React from 'react'
import { CircularProgress, Grid, withStyles } from '@material-ui/core'

const Loading = ({ classes }) => (
  <Grid
    className={classes.container}
    container
    direction='column'
    alignItems='center'
    justifyContent='center'
  >
    <CircularProgress data-testid='spinner' size={44} />
  </Grid>
)

const styles = theme => ({
  container: {
    width: '100%',
    height: '100%'
  }
})

export default withStyles(styles)(Loading)
