import React from 'react'
import { Formik } from 'formik'
import {
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@material-ui/core'
import FormikTextField from 'src/components/formik/FormikTextField'
import * as Yup from 'yup'
import * as R from 'ramda'
import Select from 'src/components/forms/entry/Select'
import StyledButton from 'src/components/buttons/styledButton'
import PropTypes from 'prop-types'
import { filteredRoleProptype } from 'src/pages/manageMembers/proptypes'

const initialFormValues = ({
  active = true,
  firstName = '',
  lastName = '',
  email = '',
  filteredRoles = []
}) => ({
  active,
  email,
  firstName,
  lastName,
  filteredRoles
})

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email cannot be blank')
    .email('This email is invalid'),
  firstName: Yup.string()
    .trim()
    .required('First name cannot be blank'),
  lastName: Yup.string()
    .trim()
    .required('Last name cannot be blank')
})

const handleSubmit = (onConfirm, onCancel, user) => (values, actions) => {
  const onFinish = () => {
    onCancel()
    actions.setSubmitting(false)
  }
  onConfirm({ id: user.id, ...values }, onFinish)
}

export const AddEditEmployee = ({
  open,
  onCancel,
  onCreateUser,
  onEditUser,
  editing,
  classes,
  employee = {},
  availableRoles = []
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        {editing ? `Edit` : `Create`}
        {' User'}
      </DialogTitle>
      <Grid>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialFormValues(employee)}
          isInitialValid={validationSchema.isValidSync(
            initialFormValues(employee)
          )}
          onSubmit={handleSubmit(
            editing ? onEditUser : onCreateUser,
            onCancel,
            employee
          )}
        >
          {({ handleSubmit, ...formikProps }) => (
            <form onSubmit={handleSubmit}>
              <Grid container item direction='column'>
                <DialogContent>
                  <Grid
                    container
                    direction='column'
                    spacing={1}
                    className={classes.containers}
                  >
                    <Grid item container direction='row' alignItems='center'>
                      <Switch
                        name='active'
                        checked={formikProps.values['active']}
                        onChange={formikProps.handleChange}
                        value={formikProps.values['active']}
                        className={classes.switch}
                      />
                      <Typography align='center' variant='caption'>
                        ACTIVE
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant='caption'>Roles</Typography>
                      <Select
                        name='filteredRoles'
                        options={availableRoles}
                        placeholder='Roles'
                        isMulti
                        formikProps={formikProps}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant='caption'>EMAIL</Typography>
                      <FormikTextField
                        name='email'
                        type='text'
                        variant='outlined'
                        placeholder='Email'
                        disabled={editing}
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant='caption'>FIRST NAME</Typography>
                      <FormikTextField
                        name='firstName'
                        type='text'
                        variant='outlined'
                        placeholder='First Name'
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant='caption'>LAST NAME</Typography>
                      <FormikTextField
                        name='lastName'
                        type='text'
                        variant='outlined'
                        placeholder='Last Name'
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions className={classes.buttonsConfirm}>
                  <Grid
                    container
                    direction='row'
                    spacing={1}
                    justifyContent='flex-end'
                  >
                    <Grid item>
                      <StyledButton onClick={onCancel} colorType={'red'}>
                        Cancel
                      </StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledButton
                        data-testid='submitButton'
                        type='submit'
                        colorType={'green'}
                      >
                        {editing ? `Edit` : `Create User`}
                      </StyledButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  )
}

AddEditEmployee.propTypes = {
  open: PropTypes.bool.isRequired,
  editing: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreateUser: PropTypes.func.isRequired,
  onEditUser: PropTypes.func.isRequired,
  availableRoles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),
  employee: PropTypes.shape({
    name: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.number,
    verified: PropTypes.bool,
    filteredRoles: PropTypes.arrayOf(filteredRoleProptype)
  })
}

const styles = theme => ({
  card: {
    flex: 1,
    padding: theme.spacing(1)
  },
  title: {
    paddingBottom: 0
  },
  containers: {
    padding: theme.spacing(1)
  },
  switch: {
    color: theme.palette.buttons.green
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  }
})

export default R.pipe(withStyles(styles))(AddEditEmployee)
