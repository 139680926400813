import React from 'react'
import { Grid, Typography, ListItem, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import LabelComponent from 'src/components/labelComponent'
import {
  roleProptype,
  filteredRoleProptype
} from 'src/pages/manageMembers/proptypes'
import StyledButton from 'src/components/buttons/styledButton'
import * as R from 'ramda'
import { bpMaxSM } from 'src/tools/screenSize'

const ResourceItem = ({
  classes,
  name,
  employeefilteredRoles,
  onEditClick,
  showDivider,
  handleDeleteEmployeeClick,
  ...employee
}) => (
  <ListItem divider={showDivider} className={classes.listItem}>
    <Grid item xs={12} md={4}>
      <Typography>
        <b>{name}</b>
      </Typography>
    </Grid>
    <Grid item xs={12} md={3} className={classes.topPadded}>
      {employeefilteredRoles.map(employeeRole => (
        <LabelComponent key={employeeRole.value} label={employeeRole.label} />
      ))}
    </Grid>
    <Grid
      container
      item
      xs={12}
      md={5}
      direction='row'
      justifyContent='flex-end'
      spacing={1}
      className={classes.topPadded}
    >
      <Grid item>
        <StyledButton
          onClick={onEditClick({ roles: employeefilteredRoles, ...employee })}
          colorType={'blue'}
        >
          Edit
        </StyledButton>
      </Grid>
      {!employee.verified && (
        <Grid item>
          <StyledButton
            onClick={handleDeleteEmployeeClick(employee)}
            colorType={'red'}
          >
            Delete
          </StyledButton>
        </Grid>
      )}
    </Grid>
  </ListItem>
)

ResourceItem.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(roleProptype),
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      filteredRoles: PropTypes.arrayOf(filteredRoleProptype),
      employee: PropTypes.object
    })
  ),
  showDivider: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  handleDeleteEmployeeClick: PropTypes.func.isRequired
}

const styles = theme => ({
  listItem: {
    padding: theme.spacing(3),
    flexDirection: 'row',
    [`${bpMaxSM}`]: {
      padding: theme.spacing(2),
      flexDirection: 'column'
    }
  },
  topPadded: {
    [`${bpMaxSM}`]: {
      padding: theme.spacing(1, 0, 0, 0)
    }
  }
})

export default R.pipe(withStyles(styles))(ResourceItem)
