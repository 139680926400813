export const filterByStatus = (payment, { value }) =>
  value === (payment.active ? 'active' : 'inactive')

export const filterBySearch = (payment, searchedPayment = '') =>
  payment.name.toUpperCase().includes(searchedPayment.toUpperCase())

export const filterPayments = (payments, selectedStatus, searchedPayment) =>
  payments
    .filter(payment =>
      selectedStatus.value === 'all'
        ? payment
        : filterByStatus(payment, selectedStatus)
    )
    .filter(payment =>
      searchedPayment === '' || !searchedPayment
        ? payment
        : filterBySearch(payment, searchedPayment)
    )
