import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import StyledButton from 'src/components/buttons/styledButton'

const WebHookItem = ({ classes, handleDeleteWebHookClick, id, title, url }) => (
  <>
    <Grid
      container
      direction='row'
      className={classes.container}
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid item xs={3}>
        <Typography variant='button' className={classes.fontSize}>
          <b>{title && title.toUpperCase()}</b> ( {id} )
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography className={classes.fontSize}>{url}</Typography>
      </Grid>

      <Grid
        item
        container
        direction='row'
        xs={4}
        alignItems='flex-start'
        alignContent='center'
        justifyContent='flex-end'
      >
        <Grid item>
          <StyledButton
            onClick={handleDeleteWebHookClick({ title, id })}
            colorType={'red'}
            data-testid='deleteAddWebhook'
          >
            Delete
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <div className={classes.headerDivider} />
    </Grid>
  </>
)
WebHookItem.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDeleteWebHookClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

const styles = theme => ({
  container: {
    padding: '25px 35px'
  },
  fontSize: {
    fontSize: 14
  },
  headerDivider: {
    height: '1px',
    backgroundColor: theme.palette.lights.dividerGrey
  }
})

export default withStyles(styles)(WebHookItem)
