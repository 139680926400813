import React from 'react'
import { Grid } from '@material-ui/core'
import Divider from 'src/components/divider'
import PropTypes from 'prop-types'
import AddEditButton from 'src/components/buttons/addEditButton'
import HeaderLabel from 'src/components/headers/headerLabel'

export const StaticHeader = ({ classes, onAddClick }) => (
  <Grid
    container
    direction='row'
    justifyContent='flex-end'
    spacing={2}
    className={classes.header}
  >
    <Grid item>
      <AddEditButton onClick={onAddClick}>+ Add Payment</AddEditButton>
    </Grid>
  </Grid>
)

StaticHeader.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export const Header = ({ classes }) => (
  <>
    <Grid container className={classes.whiteCardHeader}>
      <Grid item xs={4}>
        <HeaderLabel align='left'>NAME</HeaderLabel>
      </Grid>
      <Grid item xs={2}>
        <HeaderLabel>
          <b>AMOUNT</b>
        </HeaderLabel>
      </Grid>
      <Grid item xs={2}>
        <HeaderLabel>STATUS</HeaderLabel>
      </Grid>
      <Grid item xs={4}>
        <HeaderLabel>ACTIONS</HeaderLabel>
      </Grid>
    </Grid>
    <Grid item>
      <Divider />
    </Grid>
  </>
)

Header.propTypes = {
  classes: PropTypes.object.isRequired
}
