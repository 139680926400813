import React, { useState, useEffect } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import SelectKey from 'src/pages/manageAPI/selectKey'
import RunLiveTest from 'src/pages/manageAPI/runLiveTest'
import PropTypes from 'prop-types'
import apiRedux from 'src/redux/api'
import * as R from 'ramda'
import { connect } from 'react-redux'
import resourcesRedux from 'src/redux/resources'
import { actions } from 'src/redux'
import { currenciesPropTypes } from 'src/pages/transact/proptypes'

export const TestLiveOnlinePayments = ({
  classes,
  initialApiKeyId,
  onlinePaymentApiKeys,
  currencies,
  requestMerchantInfo
}) => {
  useEffect(() => {
    requestMerchantInfo()
    return () => {}
  }, [])
  const initialApiKey = onlinePaymentApiKeys.find(
    apiKey => apiKey.id === initialApiKeyId
  )
  const [apiKeySelected, setApiKeySelected] = useState(initialApiKey)
  return (
    <Grid
      container
      direction='column'
      className={classes.container}
      justifyContent='space-between'
    >
      <Grid item>
        <Typography variant='body1'>
          Select a key, Copy your code snippet and Use it in your website to
          activate mMoney Online Payments.
        </Typography>
        <SelectKey
          apiKeySelected={apiKeySelected}
          setApiKeySelected={setApiKeySelected}
          apiKeyList={onlinePaymentApiKeys}
        />
        <Typography variant='body1' className={classes.textRunTest}>
          Run Live Test Below:
        </Typography>
        <RunLiveTest apiKeySelected={apiKeySelected} currencies={currencies} />
      </Grid>
    </Grid>
  )
}

TestLiveOnlinePayments.propTypes = {
  classes: PropTypes.object.isRequired,
  requestMerchantInfo: PropTypes.func.isRequired,
  initialApiKeyId: PropTypes.number,
  currencies: currenciesPropTypes,
  onlinePaymentApiKeys: PropTypes.arrayOf(
    PropTypes.shape({
      apiKey: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      receiveFunds: PropTypes.bool.isRequired,
      sendRefunds: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired
    })
  )
}

const styles = theme => ({
  textRunTest: {
    padding: '15px 0px'
  }
})

const mapStateToProps = state => ({
  onlinePaymentApiKeys: apiRedux.selectors.onlinePaymentApiKeys(state),
  currencies: resourcesRedux.selectors.currencies(state)
})

const mapDispatchToProps = dispatch => ({
  requestMerchantInfo: () => dispatch(actions.resources.getMerchantInfo())
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TestLiveOnlinePayments)
