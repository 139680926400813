import PropTypes from 'prop-types'
import React from 'react'
import navigate from 'src/tools/navigate-wrapper'

import { Grid, withStyles } from '@material-ui/core'

const EntryFooter = props => (
  <Grid
    container
    direction='row'
    justifyContent='center'
    alignItems='center'
    className={props.classes.container}
  >
    {props.pathname.includes('signup/setup') ? (
      <Grid item>
        Already have an account?{' '}
        <button
          className={props.classes.linkButton}
          onClick={() => navigate('/')}
          type='button'
        >
          Just sign in here
        </button>
      </Grid>
    ) : (
      <Grid item>
        Don't have an mMoney account?{' '}
        <button
          className={props.classes.linkButton}
          onClick={() => navigate('/signup/setup')}
          type='button'
        >
          Sign up
        </button>{' '}
        today.
      </Grid>
    )}
  </Grid>
)

EntryFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired
}

const styles = theme => ({
  container: {
    color: 'white',
    height: 96,
    fontSize: '0.9rem'
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
    padding: 0,
    color: '#86b4e5',
    fontSize: 'inherit'
  }
})

export default withStyles(styles)(EntryFooter)
