/**
 * Functions making external calls to server to perform account management
 * */

import { postJSON, getJSON } from 'src/services/lib/http'

export const checkEmailAvailability = email =>
  postJSON('/merchant/register/email-available', { email })

export const signup = ({
  email,
  password,
  legalBusinessName,
  ownerFirstName,
  ownerLastName,
  businessCategory,
  referralCode,
  currencyCode
}) =>
  postJSON('/merchant/register', {
    email,
    password,
    name: legalBusinessName,
    first_name: ownerFirstName,
    last_name: ownerLastName,
    category_id: businessCategory,
    ref_code: referralCode,
    currency: currencyCode
  })

export const forgotPassword = email =>
  postJSON('/merchant/forgot-password', { email })

export const resetPassword = (resetToken, newPassword) =>
  postJSON('/merchant/reset-password', { token: resetToken, newPassword })

export const getCategories = () => getJSON('/merchant/categories', {}, null)
