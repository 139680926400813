import * as complianceServices from 'src/services/compliance'
import { getAuthToken } from 'src/tools/token'
import { mapBasicInfo, verificationPayload } from 'src/redux/compliance/helpers'
import { isOfficersRequired } from 'src/pages/verifications/helpers'
import complianceRedux from 'src/redux/compliance'
import notificationRedux from 'src/redux/notification'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import * as R from 'ramda'

const { actions, types, selectors } = complianceRedux

export function * documentVerificationSaga ({ companyDocuments, effect }) {
  const { documents, executiveDocuments } = companyDocuments

  try {
    const token = yield select(getAuthToken)
    const hasVerificationReference = yield select(
      selectors.hasVerificationReference
    )

    const { getReferenceSaga } = yield call(
      getReferenceFetcher,
      hasVerificationReference
    )

    const countryCode = yield select(selectors.countryCode)
    const reference = yield call(getReferenceSaga, countryCode)

    const payload = yield call(verificationPayload, reference, documents)

    yield call(complianceServices.uploadUserDocumentation, payload, token)

    const basicInfo = yield select(selectors.basicInfo)
    const hasOfficers = yield call(isOfficersRequired, basicInfo)

    if (hasOfficers) {
      yield call(
        complianceServices.uploadExecutiveDocumentation,
        { applications: executiveDocuments },
        token
      )
    }

    yield put(
      notificationRedux.actions.notificationShow({
        message: 'Success. You will be informed further via email',
        type: 'success'
      })
    )

    if (effect) yield call(effect)
  } catch (e) {
    yield put(
      notificationRedux.actions.notificationShow({
        message: e.message,
        type: 'error'
      })
    )

    if (effect) yield call(effect)
  }
}

export const getReferenceFetcher = storeHasReference =>
  storeHasReference
    ? { getReferenceSaga: getReferenceFromStoreSaga }
    : { getReferenceSaga: getReferenceFromServiceSaga }

export function * getReferenceFromStoreSaga () {
  try {
    const reference = yield select(selectors.verificationReference)
    return reference
  } catch (e) {
    return undefined
  }
}

export function * getReferenceFromServiceSaga (countryCode) {
  try {
    const token = yield select(getAuthToken)
    const { data } = yield call(
      complianceServices.prepareDocumentVerification,
      countryCode.toUpperCase(),
      token
    )

    return R.pathOr(undefined, ['pending', 'ref'], data)
  } catch (e) {
    return undefined
  }
}
export function * basicVerificationSaga ({ basicInfo, effect = undefined }) {
  try {
    const token = yield select(getAuthToken)

    yield call(complianceServices.basicVerification, token, basicInfo)

    yield put(
      notificationRedux.actions.notificationShow({
        message: 'Verification successful',
        type: 'success'
      })
    )

    if (effect) yield call(effect)
  } catch (e) {
    yield put(
      notificationRedux.actions.notificationShow({
        message: `Verification failed;${e.message}`,
        type: 'error'
      })
    )
    if (effect) yield call(effect)
  }
}

export function * getBasicInfoSaga () {
  try {
    const token = yield select(getAuthToken)

    const { data } = yield call(complianceServices.getBasicInfo, token)
    const basicInfo = yield call(mapBasicInfo, data)

    yield put(actions.getBasicInfoSuccess(basicInfo))
  } catch (e) {
    yield put(
      notificationRedux.actions.notificationShow({
        message: 'Failed to fetch basic information',
        type: 'error'
      })
    )
  }
}

export function * getVerificationDataSaga () {
  try {
    const token = yield select(getAuthToken)

    const { data } = yield call(complianceServices.getVerificationData, token)
    const { count, limits, pending, verified } = data

    yield put(
      actions.getVerificationDataSuccess(count, limits, pending, verified)
    )
  } catch (e) {
    yield put(
      notificationRedux.actions.notificationShow({
        message: 'Failed to fetch verification data',
        type: 'error'
      })
    )
  }
}

export default function * root () {
  yield takeLatest(types.GET_BASIC_INFO, getBasicInfoSaga)
  yield takeLatest(types.GET_VERIFICATION_DATA, getVerificationDataSaga)
  yield takeLatest(types.DOCUMENT_VERIFICATION, documentVerificationSaga)
  yield takeLatest(types.BASIC_VERIFICATION, basicVerificationSaga)
}
