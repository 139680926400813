import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper
} from '@material-ui/core'
import TablePaginationActions from 'src/pages/reports/tablePaginationActions'
import CancelRefund from 'src/pages/reports/cancelRefund'
import ReportConfirmDialog from 'src/pages/reports/reportConfirmDialog'
import reportsRedux from 'src/redux/reports'
import * as R from 'ramda'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import { format, parseISO } from 'date-fns'
import AddEditButton from 'src/components/buttons/addEditButton'

const headRows = [
  { id: 'date', label: 'DATE CREATED', numeric: false },
  {
    id: 'invoiceId',
    label: 'INVOICE #',
    numeric: true
  },
  {
    id: 'checkoutId',
    label: 'CHECKOUT ID',
    numeric: false
  },
  { id: 'type', label: 'TYPE', numeric: false },
  {
    id: 'userFullname',
    label: 'EMPLOYEE NAME',
    numeric: false
  },
  { id: 'amount', label: 'AMOUNT', numeric: true },
  { id: 'paid', label: 'PAID', numeric: true },
  { id: 'fee', label: 'FEE', numeric: true },
  {
    id: 'cancel',
    label: 'REFUND/CANCEL',
    numeric: false
  },
  {
    id: 'dateUpdated',
    label: 'DATE UPDATED/PAID',
    numeric: false
  },
  {
    id: 'address',
    label: 'TRANSACTION ADDRESS',
    numeric: false
  }
]

export const ReportsTable = ({
  classes,
  transactions,
  runReport,
  downloadReport,
  requestInvoiceCancel,
  requestInvoiceRefund,
  total,
  pageNumber,
  rowsPerPage
}) => {
  const [onCancelOpen, setCancelOpen] = useState(false)
  const [onRefundOpen, setRefundOpen] = useState(false)
  const [itemId, setItemId] = useState(undefined)

  const handleChangePage = (_, page) => {
    runReport({ page })
  }

  const handleChangeRowsPerPage = event => {
    runReport({ rowsPerPage: event.target.value })
  }

  const handleOnCancelClick = itemId => () => {
    setItemId(itemId)
    setCancelOpen(true)
  }

  const handleCancelOnCancelClick = () => {
    setItemId(undefined)
    setCancelOpen(false)
  }

  const handleOnRefundClick = itemId => () => {
    setItemId(itemId)
    setRefundOpen(true)
  }

  const handleCancelOnRefundClick = () => {
    setItemId(undefined)
    setRefundOpen(false)
  }

  return (
    <Grid className={classes.root}>
      <AddEditButton onClick={downloadReport} data-testid='downloadReport'>
        Download
      </AddEditButton>
      <Paper className={classes.paper}>
        <Grid container className={classes.table}>
          <Table xs={12}>
            <TableHead>
              <TableRow>
                {headRows.map(row => (
                  <TableCell
                    key={row.id}
                    align={row.numeric ? 'right' : 'left'}
                  >
                    <b>{row.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map(item => (
                <TableRow key={item.invoiceId}>
                  <TableCell component='th' scope='row'>
                    {format(parseISO(item.date), 'MMM do yyyy, h:mm:ss a')}
                  </TableCell>
                  <TableCell align='right'>{item.invoiceId}</TableCell>
                  <TableCell align='left'>{item.merchantOrderId}</TableCell>
                  <TableCell align='left'>{item.type}</TableCell>
                  <TableCell align='left'>{item.userFullname}</TableCell>
                  <TableCell align='right'>{item.amount}</TableCell>
                  <TableCell align='right'>{item.paid}</TableCell>
                  <TableCell align='right'>{item.fee}</TableCell>
                  <TableCell align='center'>
                    <CancelRefund
                      {...item}
                      onCancel={handleOnCancelClick(item.invoiceId)}
                      onRefund={handleOnRefundClick(item.invoiceId)}
                    />
                  </TableCell>
                  <TableCell align='left'>
                    {' '}
                    {format(
                      parseISO(item.dateUpdated),
                      'MMM do yyyy, h:mm:ss a'
                    )}
                  </TableCell>
                  <TableCell align='left'>{item.address}</TableCell>
                </TableRow>
              ))}
              <ReportConfirmDialog
                open={onCancelOpen}
                onCancel={handleCancelOnCancelClick}
                onConfirm={requestInvoiceCancel}
                id={itemId}
              />
              <ReportConfirmDialog
                open={onRefundOpen}
                onCancel={handleCancelOnRefundClick}
                id={itemId}
                onConfirm={requestInvoiceRefund}
              />
            </TableBody>
          </Table>
        </Grid>
        <Grid container justifyContent='flex-end'>
          <TablePagination
            component='div'
            rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
            colSpan={3}
            count={total}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            SelectProps={{
              native: true
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Grid>
      </Paper>
    </Grid>
  )
}

ReportsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      actionLabel: PropTypes.string.isRequired,
      actionType: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      buttonDisabled: PropTypes.bool.isRequired,
      currency: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      dateUpdated: PropTypes.string.isRequired,
      fee: PropTypes.string.isRequired,
      invoiceId: PropTypes.number.isRequired,
      isCancel: PropTypes.bool.isRequired,
      isOnlinePayment: PropTypes.bool.isRequired,
      merchantOrderId: PropTypes.number,
      netAmount: PropTypes.string.isRequired,
      note: PropTypes.string,
      paid: PropTypes.string.isRequired,
      refundAddress: PropTypes.string,
      refundId: PropTypes.number,
      status: PropTypes.string.isRequired,
      transactionFee: PropTypes.string.isRequired,
      transactionType: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      userFullname: PropTypes.string.isRequired
    })
  ),
  runReport: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  requestInvoiceCancel: PropTypes.func.isRequired,
  requestInvoiceRefund: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflow: 'hidden'
  },
  table: {
    overflow: 'auto'
  }
})

const mapStateToProps = state => ({
  transactions: reportsRedux.selectors.transactions(state),
  rowsPerPage: reportsRedux.selectors.rowsPerPage(state),
  pageNumber: reportsRedux.selectors.pageNumber(state),
  total: reportsRedux.selectors.total(state)
})

const mapDispatchToProps = dispatch => ({
  runReport: payload => dispatch(actions.reports.getReportPage(payload)),
  downloadReport: () => dispatch(actions.reports.downloadReport()),
  requestInvoiceCancel: (password, id, onFinish) =>
    dispatch(actions.reports.requestInvoiceCancel(password, id, onFinish)),
  requestInvoiceRefund: (password, id, onFinish) =>
    dispatch(actions.reports.requestInvoiceRefund(password, id, onFinish))
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReportsTable)
