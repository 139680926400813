import {
  fmtErrors,
  mappingEmployees,
  mapRoles,
  mapPermissions,
  mapCompany
} from 'src/redux/resources/helpers'
import helpers from 'src/redux/resources'
import { put, call, takeLatest, select } from 'redux-saga/effects'
import { actions } from 'src/redux'
import { getAuthToken } from 'src/tools/token'
import {
  addEmployeesData,
  addRoleData,
  deleteEmployeeData,
  editRoleData,
  getEmployeesData,
  editEmployeePermissions,
  editEmployeesData,
  getMerchantInfo,
  getRolesData
} from 'src/services/merchant/resources'

export function * getEmployeesSaga () {
  try {
    const token = yield select(getAuthToken)
    const { employees } = yield call(getEmployeesData, token)
    const { permissions } = yield call(getRolesData, token)
    const relevantEmployeesData = yield call(mappingEmployees, {
      employees,
      permissions
    })
    yield put(actions.resources.getEmployeesSuccess(relevantEmployeesData))
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'fail'
      })
    )
  }
}

export function * addEmployeeSaga ({ user, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    const response = yield call(addEmployeesData, { user, token })
    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'Employee added successfully',
          type: 'success'
        })
      )
      yield put(actions.resources.addEmployeeSuccess())
      yield call(getEmployeesSaga)
      yield call(onFinish)
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * editEmployeeSaga ({ user, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    const editPermissionsResponse = yield call(editEmployeePermissions, {
      userId: user.id,
      filteredRoles: user.filteredRoles,
      token
    })
    const editEmployeeResponse = yield call(editEmployeesData, { user, token })
    yield call(getEmployeesSaga)
    if (editEmployeeResponse.success && editPermissionsResponse.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'Employee edited successfully',
          type: 'success'
        })
      )
      yield put(actions.resources.addEmployeeSuccess())
      yield call(onFinish)
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(
            editEmployeeResponse.errors || editPermissionsResponse.errors
          ),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * deleteEmployeeSaga ({ id, onCancel }) {
  try {
    const token = yield select(getAuthToken)
    const response = yield call(deleteEmployeeData, { id, token })
    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'Employee deleted successfully',
          type: 'success'
        })
      )
      yield put(actions.resources.deleteEmployeeSuccess())
      yield call(getEmployeesSaga)
      yield call(onCancel)
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * getRolesSaga () {
  try {
    const token = yield select(getAuthToken)
    const { permissions } = yield call(getRolesData, token)
    const mapped = yield call(mapRoles, permissions)

    yield put(actions.resources.getRolesSuccess(mapped))
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * addRoleSaga ({ role, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    yield call(addRoleData, { token, role })
    yield call(getRolesSaga)
    yield put(
      actions.notification.notificationShow({
        message: 'A role added successfully!',
        type: 'success'
      })
    )
    yield put(actions.resources.addRoleSuccess())
    yield call(onFinish)
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * editRoleSaga ({ role, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    yield call(editRoleData, { token, role })
    yield call(getEmployeesSaga)
    yield put(
      actions.notification.notificationShow({
        message: 'A role edited successfully!',
        type: 'success'
      })
    )
    yield put(actions.resources.editRoleSuccess())
    yield call(onFinish)
    yield call(getRolesSaga)
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * getMerchantInfoSaga () {
  try {
    const token = yield select(getAuthToken)
    const {
      // eslint-disable-next-line
      first_name,
      // eslint-disable-next-line
      last_name,
      email,
      company,
      permissions,
      id
    } = yield call(getMerchantInfo, token)
    const userInfo = {
      // eslint-disable-next-line
      firstName: first_name,
      // eslint-disable-next-line
      lastName: last_name,
      email,
      userId: id
    }
    const mappedPermissions = yield call(mapPermissions, { permissions })
    const mappedCompany = yield call(mapCompany, company)
    yield put(
      actions.resources.getMerchantInfoSuccess(
        mappedPermissions,
        permissions,
        mappedCompany,
        userInfo
      )
    )
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export default function * root () {
  yield takeLatest(helpers.types.GET_EMPLOYEES, getEmployeesSaga)
  yield takeLatest(helpers.types.ADD_EMPLOYEE, addEmployeeSaga)
  yield takeLatest(helpers.types.EDIT_EMPLOYEE, editEmployeeSaga)
  yield takeLatest(helpers.types.DELETE_EMPLOYEE, deleteEmployeeSaga)
  yield takeLatest(helpers.types.GET_ROLES, getRolesSaga)
  yield takeLatest(helpers.types.ADD_ROLE, addRoleSaga)
  yield takeLatest(helpers.types.EDIT_ROLE, editRoleSaga)
  yield takeLatest(helpers.types.GET_MERCHANT_INFO, getMerchantInfoSaga)
}
