import React, { useEffect } from 'react'
import { Grid, useMediaQuery, withStyles } from '@material-ui/core'

import FormItem from 'src/components/forms/form-item'
import SelectItem from 'src/components/forms/select-item'
import * as Yup from 'yup'

import usersRedux from 'src/redux/users'
import * as R from 'ramda'
import { actions } from 'src/redux'
import { connect } from 'react-redux'

import {
  getFormStyles,
  selectSignupStyles
} from 'src/components/forms/verification/common'
import { useTheme } from '@material-ui/core/styles'

const FIRST_NAME_LABEL = 'Owner First Name'
const LAST_NAME_LABEL = 'Owner Last Name'
const ACTIVITY_LABEL = 'Estimated Account Activity'

export const formValues = {
  ownerFirstName: '',
  ownerLastName: '',
  estimatedAccountActivity: ''
}

export const validationRules = {
  ownerFirstName: Yup.string()
    .trim()
    .required('Owner first name cannot be blank'),
  ownerLastName: Yup.string()
    .trim()
    .required('Owner last name cannot be blank'),
  estimatedAccountActivity: Yup.string().required(
    'Please select an estimated account activity'
  )
}

const OwnerInformation = ({
  classes,
  isSignup,
  merchantBandOptions,
  getMerchantBands,
  formikProps
}) => {
  useEffect(() => {
    getMerchantBands()
  }, [])

  const theme = useTheme()
  const noSpacing = useMediaQuery(theme.breakpoints.down('xs')) || isSignup

  const borderBottomField = `${classes.field} ${classes.borderBottom}`

  return (
    <Grid container direction='column'>
      <Grid item container spacing={noSpacing ? 0 : 3}>
        <Grid
          item
          sm={isSignup ? 12 : 6}
          xs={12}
          className={isSignup ? borderBottomField : ''}
        >
          <FormItem
            item={FIRST_NAME_LABEL}
            formik={formikProps}
            variant={isSignup ? 'standard' : 'outlined'}
            InputProps={{
              disableUnderline: isSignup
            }}
          />
        </Grid>
        <Grid
          item
          sm={isSignup ? 12 : 6}
          xs={12}
          className={isSignup ? borderBottomField : ''}
        >
          <FormItem
            item={LAST_NAME_LABEL}
            formik={formikProps}
            variant={isSignup ? 'standard' : 'outlined'}
            InputProps={{
              disableUnderline: isSignup
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs>
        <SelectItem
          item={ACTIVITY_LABEL}
          formikProps={formikProps}
          options={merchantBandOptions}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.value}
          placeholder={ACTIVITY_LABEL}
          customStyles={isSignup ? selectSignupStyles : {}}
          labelStyles={isSignup ? classes.label : ''}
        />
      </Grid>
    </Grid>
  )
}

const styles = theme => getFormStyles(theme)

const mapStateToProps = state => ({
  merchantBandOptions: usersRedux.selectors.merchantBandOptions(state)
})

const mapDispatchToProps = dispatch => ({
  getMerchantBands: () => dispatch(actions.users.getMerchantBands())
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(OwnerInformation)
