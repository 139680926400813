import * as R from 'ramda'
import { compareDesc, parseISO } from 'date-fns'

export const curatingTransactionData = transactions =>
  R.pipe(
    R.take(4),
    // eslint-disable-next-line
    R.map(({ user_fullname, amount, transaction_type, date }) => ({
      name: user_fullname,
      displayType: mappingTypes(transaction_type),
      amount,
      type: transaction_type,
      date
    }))
  )(transactions)

export const mergingTransactionData = (firstList, secondList) =>
  R.pipe(
    R.sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date))),
    R.take(4)
  )(
    R.concat(
      curatingTransactionData(firstList),
      curatingTransactionData(secondList)
    )
  )

export const mappingTypes = R.cond([
  [R.equals('invoice'), R.always('Received')],
  [R.equals('debit'), R.always('Sent')],
  [R.equals('withdraw'), R.always('Withdraw')]
])
