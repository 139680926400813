import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

const ConfirmationDialog = ({ action, open, onClose }) => (
  <Dialog
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    onClose={onClose}
    open={open}
  >
    <DialogTitle id='alert-dialog-title'>Verification Confirmation</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>
        Are you sure you have sumitted all of the required documents?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        color='primary'
        onClick={() => {
          action()
          onClose()
        }}
      >
        Yes
      </Button>
      <Button color='primary' onClick={onClose}>
        No
      </Button>
    </DialogActions>
  </Dialog>
)

ConfirmationDialog.propTypes = {
  action: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default ConfirmationDialog
