import helpers from 'src/redux/notification'
import { put, takeLatest, take, race, delay } from 'redux-saga/effects'
import { actions } from 'src/redux'

export function * showNotificationSaga ({ message: { type } }) {
  try {
    const delayMS = type === 'success' ? 3000 : 5000
    const { timeout, hide, show } = yield race({
      timeout: delay(delayMS),
      hide: take(helpers.types.NOTIFICATION_HIDE),
      show: take(helpers.types.NOTIFICATION_SHOW)
    })
    if (timeout) {
      yield put(actions.notification.notificationHide())
      return undefined
    } else if (hide || show) {
      return undefined
    }
  } catch (e) {
    // well, we cannot show another notification...
    return undefined
  }
}

export default function * root () {
  yield takeLatest(helpers.types.NOTIFICATION_SHOW, showNotificationSaga)
}
