import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { actions } from 'src/redux'
import usersRedux from 'src/redux/users'
import EntryLayout from 'src/components/layouts/entry-layout'
import { Button, Grid, Paper, withStyles } from '@material-ui/core'
import { Link } from '@reach/router'
import { useApolloClient } from '@apollo/react-hooks'

import TextInput from 'src/components/forms/entry/text-input'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikCheckbox from 'src/components/formik/FormikCheckbox'

const InitialFormValues = { email: '', password: '', persistLogin: false }

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email cannot be blank')
    .email('This email is invalid'),
  password: Yup.string()
    .trim()
    .required('Password cannot be blank')
})

const onFormSubmit = (login, apolloClient) => ({
  email,
  password,
  persistLogin
}) => {
  login(email, password, persistLogin ? 1 : 0)
  apolloClient.clearStore()
}

const SignIn = ({ actions, classes }) => {
  const client = useApolloClient()
  return (
    <EntryLayout>
      <Grid
        item
        container
        xs={12}
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Formik
          initialValues={InitialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={onFormSubmit(actions.usersLogin, client)}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit} className={classes.form}>
                <Grid container direction='column'>
                  <Paper>
                    <Grid item className={classes.bottomBorder}>
                      <TextInput
                        autoComplete='username'
                        errors={errors}
                        label='Email'
                        name='email'
                        onChange={handleChange}
                        placeholder='Email'
                        touched={touched}
                        value={values.email}
                      />
                    </Grid>
                    <Grid item>
                      <TextInput
                        autoComplete='current-password'
                        errors={errors}
                        label='Password'
                        name='password'
                        onChange={handleChange}
                        placeholder='Password'
                        touched={touched}
                        type='password'
                        value={values.password}
                      />
                    </Grid>
                  </Paper>
                  <Grid item container>
                    <Button
                      className={classes.signinBtn}
                      color='primary'
                      type='submit'
                      variant='contained'
                    >
                      Sign In
                    </Button>
                  </Grid>
                  <Grid className={classes.options} container item>
                    <Grid
                      alignItems='center'
                      className={classes.rememberMe}
                      container
                      item
                      xs={6}
                    >
                      <FormikCheckbox
                        name='persistLogin'
                        label='Remember Me'
                        inputProps={{ 'data-testid': 'persistLogin' }}
                        color='default'
                        style={{ color: 'white' }}
                        formik={{
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      justifyContent='flex-end'
                      className={classes.forgotLink}
                    >
                      <Link to='forgot'>Forgot password?</Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </EntryLayout>
  )
}

// TODO clean up styles for this component
const styles = theme => ({
  form: {
    width: '100%',
    maxWidth: 420,
    borderRadius: 8
  },
  signinBtn: {
    width: '100%',
    border: 0,
    transition: 'background .4s',
    borderRadius: 5,
    padding: theme.spacing(1, 0),
    marginTop: theme.spacing(3)
  },
  options: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  forgotLink: {
    '& a': {
      color: '#86b4e5',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '1.1rem'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  bottomBorder: {
    borderBottom: '1px solid #ecf3fb'
  },
  rememberMe: {
    color: theme.palette.lights.grey1,
    '& input': {
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  }
})

const mapStateToProps = state => ({
  hasLoginError: usersRedux.selectors.hasLoginError(state),
  loginError: usersRedux.selectors.loginError(state)
})

const mapDispatchToProps = dispatch => ({
  actions: {
    usersLogin: (email, password, persistLogin) =>
      R.pipe(
        actions.users.usersLogin,
        dispatch
      )(email, password, persistLogin)
  }
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SignIn)
