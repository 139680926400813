import React from 'react'
import { getFlagImg } from 'src/services/merchant/flags'
import { CardMedia, Grid, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const flagImage = ({ classes, flagClass, img, code }) => {
  const imgSource = getFlagImg(img || code)

  return (
    <Grid item className={classes.holder}>
      {imgSource && (
        <CardMedia className={flagClass || classes.flag} image={imgSource} />
      )}
    </Grid>
  )
}

flagImage.propTypes = {
  classes: PropTypes.object.isRequired,
  flagClass: PropTypes.string,
  img: PropTypes.string,
  code: PropTypes.string.isRequired
}

const styles = theme => ({
  flag: { height: 30, width: 40, borderRadius: 5 },
  holder: { padding: '0 8px 0 0' }
})

export default withStyles(styles)(flagImage)
