import React from 'react'
import { InputAdornment } from '@material-ui/core'

const startAdornment = (label, adornmentStyle) => ({
  startAdornment: (
    <InputAdornment className={adornmentStyle} position='start'>
      {label}
    </InputAdornment>
  )
})

export default startAdornment
