import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import navigate from 'src/tools/navigate-wrapper'

import { SetTouchFields } from 'src/pages/signup/helpers'
import { Button, Typography, Grid, Paper, withStyles } from '@material-ui/core'
import * as R from 'ramda'

import { FormFieldNames as SetupFormFieldNames } from 'src/pages/signup/account-setup'
import OwnerInfomationForm from 'src/components/forms/verification/owner-information'

export const FormFieldNames = [
  'ownerFirstName',
  'ownerLastName',
  'estimatedAccountActivity'
]

const OwnerInformation = ({ classes, validateForm, ...formikProps }) => {
  const { values, setFieldTouched } = formikProps
  useEffect(() => {
    R.pipe(
      R.pick,
      R.pickBy(fieldValue => fieldValue !== '' && fieldValue !== false),
      R.when(R.isEmpty, () => navigate('setup'))
    )(SetupFormFieldNames, values)
  }, [])

  const handleButtonClick = () => {
    validateForm().then(ownerErrors => {
      SetTouchFields(FormFieldNames, setFieldTouched)
      R.when(R.isEmpty, () => navigate('business-info'))(ownerErrors)
    })
  }

  return (
    <>
      <Grid container className={classes.container} direction='column'>
        <Grid item>
          <Typography
            variant='h4'
            align='center'
            gutterBottom
            className={classes.whiteText}
          >
            Basic Business Information
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='subtitle1'
            align='center'
            className={classes.whiteText}
          >
            Great. Your email address was confirmed.{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='subtitle1'
            align='center'
            className={classes.whiteText}
          >
            Tell us a bit about your business and you're good to go.
          </Typography>
        </Grid>

        <Grid item className={classes.formContainer}>
          <Paper>
            <OwnerInfomationForm isSignup formikProps={formikProps} />
          </Paper>
          <Grid container justifyContent='center'>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='button'
                className={classes.nextStepBtn}
                onClick={handleButtonClick}
              >
                Next Step
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const styles = theme => ({
  container: {
    flex: 1
  },
  formContainer: {
    marginTop: 24
  },
  paper: {
    borderRadius: 8
  },
  nextStepBtn: {
    width: 205,
    padding: '12px 18px',
    marginTop: 24
  },
  errorMessage: {
    padding: '0 32px 6px',
    color: '#d9534f'
  },
  bottomBorder: {
    borderBottom: '1px solid #ecf3fb'
  },
  whiteText: {
    color: 'white'
  }
})

OwnerInformation.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(OwnerInformation)
