import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DropzoneComponent from 'src/pages/verifications/document/dropzone'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import {
  ACCEPTED_FILES_EXTENSIONS,
  BACK_IMAGE_TYPE,
  ID_TYPES,
  ID_FRONT,
  ID_BACK,
  FRONT_IMAGE_TYPE
} from 'src/pages/verifications/helpers'
import * as R from 'ramda'

const addIdDocuments = (
  addDocuments,
  document,
  backDocument,
  handleClose,
  mode,
  setDocumentErrors
) => () => {
  const documentErrors = validateDocuments(backDocument, document, mode)

  if (documentErrors.front || documentErrors.back) {
    return setDocumentErrors(documentErrors)
  }

  const userDocuments = [
    [ID_FRONT, { file: document, documentType: FRONT_IMAGE_TYPE }]
  ]

  const updatedUserDocuments = R.ifElse(
    R.isNil,
    () => userDocuments,
    document =>
      R.append(
        [ID_BACK, { file: document, documentType: BACK_IMAGE_TYPE }],
        userDocuments
      )
  )(backDocument)

  addDocuments(updatedUserDocuments)
  handleClose()
}

const validateDocuments = (backDocument, document, mode) => {
  const errorMessage = 'A valid image is required'

  const isBackRequired = mode === 'id' || mode === 'license'

  const errors = R.pipe(
    R.assoc('back', isBackRequired && !backDocument ? errorMessage : ''),
    R.assoc('front', !document ? errorMessage : '')
  )({})

  return errors
}

export const AddIdModal = ({
  classes,
  handleAddDocuments,
  handleClose,
  hasBase64,
  open
}) => {
  const [document, setDocument] = useState(undefined)
  const [backDocument, setBackDocument] = useState(undefined)
  const [documentErrors, setDocumentErrors] = useState({ back: '', front: '' })
  const [mode, setMode] = useState('passport')

  const artefactCleanup = () => {
    if (document && R.hasPath(['file', 'preview'], document)) {
      URL.revokeObjectURL(document.file.preview)
    }

    if (backDocument && R.hasPath(['file', 'preview'], backDocument)) {
      URL.revokeObjectURL(backDocument.file.preview)
    }
  }

  const onClose = () => {
    artefactCleanup()
    setDocument(undefined)
    setBackDocument(undefined)
    setDocumentErrors({ back: '', front: '' })
    setMode('passport')
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      PaperProps={{ className: classes.container }}
    >
      <DialogTitle>Add Identification Documents</DialogTitle>

      <DialogContent className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={mode === 'passport' ? 12 : 6}>
            <DropzoneComponent
              acceptedFileExtensions={ACCEPTED_FILES_EXTENSIONS}
              document={document}
              errorMessage={documentErrors.front}
              handleAddDocument={setDocument}
              handleRemoveDocument={() => setDocument(undefined)}
              hasBase64={hasBase64}
              isImageOnly
              promptText={
                mode === 'passport'
                  ? 'Click to upload image of passport.'
                  : `Click to upload front image of ${mode}.`
              }
            />
          </Grid>

          {(mode === 'id' || mode === 'license') && (
            <Grid item xs={12} sm={6}>
              <DropzoneComponent
                acceptedFileExtensions={ACCEPTED_FILES_EXTENSIONS}
                document={backDocument}
                errorMessage={documentErrors.back}
                handleAddDocument={setBackDocument}
                handleRemoveDocument={() => setBackDocument(undefined)}
                hasBase64={hasBase64}
                isImageOnly
                promptText={`Click to upload back image of ${mode}.`}
              />
            </Grid>
          )}
        </Grid>
        <Typography variant='caption'>SELECT THE TYPE OF DOCUMENT</Typography>
        <ReactSelect
          aria-label='document-type'
          name='documentType'
          id='documentType'
          styles={{
            control: provided => ({ ...provided, minHeight: 55 })
          }}
          defaultValue={ID_TYPES[0]}
          onChange={value => setMode(value.value)}
          options={ID_TYPES}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.value}
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid='add-button'
          color='primary'
          onClick={addIdDocuments(
            handleAddDocuments,
            document,
            backDocument,
            onClose,
            mode,
            setDocumentErrors
          )}
        >
          Add
        </Button>
        <Button color='primary' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = theme => ({
  container: {
    overflow: 'visible'
  },
  content: {
    width: 520,
    paddingTop: '0px !important',
    overflow: 'visible'
  },
  dropzone: {
    '&:focus': {
      outline: 'none'
    },
    border: '2px dashed #c7c7c7',
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3)
  }
})

AddIdModal.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleAddDocuments: PropTypes.func.isRequired,
  open: PropTypes.bool
}
export default withStyles(styles)(AddIdModal)
