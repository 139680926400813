import { compareDesc, parseISO } from 'date-fns'

export const mapLocations = locations =>
  locations
    .sort((a, b) => compareDesc(parseISO(a.updated_at), parseISO(b.updated_at)))
    .map(location => ({
      active: location.active,
      address: location.address,
      companyId: location.company_id,
      id: location.id,
      latitude: location.latitude,
      longitude: location.longitude,
      phone: location.phone
    }))

export const formUpdatePayload = ({
  countryCode,
  phoneNumber,
  email,
  defaultCurrency
}) => ({
  number: `${countryCode.callingCode || ''}${phoneNumber}`,
  contact_email: email,
  default_currency: defaultCurrency
})
