import React, { useEffect } from 'react'
import {
  Grid,
  Paper,
  CircularProgress,
  Typography,
  withStyles
} from '@material-ui/core'
import { actions } from 'src/redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as R from 'ramda'
import EntryLayout from 'src/components/layouts/entry-layout'

const ActivateAccount = ({ classes, activationToken, activateAccount }) => {
  useEffect(() => {
    window.setTimeout(() => activateAccount(activationToken), 2000)
  }, [])

  return (
    <EntryLayout>
      <Grid container justifyContent='center'>
        <Grid item>
          <Paper elevation={1}>
            <Grid container direction='column' className={classes.content}>
              <Grid
                container
                item
                className={classes.header}
                justifyContent='center'
              >
                <Typography className={classes.headerText} variant='h6'>
                  Activating Account...
                </Typography>
              </Grid>
              <Grid
                className={classes.spinner}
                container
                item
                justifyContent='center'
                alignItems='center'
              >
                <CircularProgress />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </EntryLayout>
  )
}

const mapDispatchToProps = dispatch => ({
  activateAccount: activationToken =>
    dispatch(actions.users.activateAccount(activationToken))
})

const styles = theme => ({
  content: {
    width: 400
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: '12px 0'
  },
  headerText: {
    color: theme.palette.lights.white
  },
  spinner: {
    height: 100
  }
})

ActivateAccount.propTypes = {
  classes: PropTypes.object,
  activationToken: PropTypes.string.isRequired,
  activateAccount: PropTypes.func.isRequired
}

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(ActivateAccount)
