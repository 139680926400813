import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Grid,
  Snackbar,
  SnackbarContent,
  Typography,
  withStyles,
  Slide,
  useMediaQuery
} from '@material-ui/core'
import * as R from 'ramda'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import notificationRedux from 'src/redux/notification'
import { Location } from '@reach/router'
import classNames from 'classnames'
import MessageComponent from 'src/components/notification/MessageComponent'
import { useTheme } from '@material-ui/core/styles'

const slide = props => <Slide {...props} direction='left' />

export const SnackBarNotification = ({
  open,
  type,
  message,
  onCancel,
  buttonLabel,
  labelColor,
  classes
}) => {
  const theme = useTheme()
  const drawerIsPermanent = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <Location>
      {({ location }) => (
        <Grid container className={classes.container}>
          <Grid item>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={open}
              className={
                location.pathname.includes('dashboard') && drawerIsPermanent
                  ? classes.snack
                  : classes.fullSnack
              }
              TransitionComponent={slide}
            >
              <SnackbarContent
                message={<MessageComponent type={type} message={message} />}
                className={classNames(
                  type === 'success' ? classes.success : classes.fail,
                  classes.content
                )}
                action={
                  <Button
                    onClick={onCancel}
                    className={classes.button}
                    variant='outlined'
                  >
                    <Typography
                      color={labelColor}
                      component='span'
                      variant='body1'
                      className={classes.button}
                    >
                      {buttonLabel || 'Close'}
                    </Typography>
                  </Button>
                }
              />
            </Snackbar>
          </Grid>
        </Grid>
      )}
    </Location>
  )
}

const styles = theme => ({
  snack: {
    paddingLeft: 220,
    minWidth: '100%'
  },
  fullSnack: {
    minWidth: '100%'
  },
  success: {
    backgroundColor: theme.palette.secondary.main
  },
  fail: {
    backgroundColor: '#d9534f'
  },
  content: {
    minHeight: '80px',
    minWidth: '100%',
    fontSize: 20,
    fontWeight: 'fontWeightMedium'
  },
  button: {
    color: theme.palette.lights.white,
    borderRadius: 50,
    borderColor: theme.palette.lights.white,
    minWidth: 85,
    fontWeight: 'fontWeightMedium',
    fontSize: 20
  }
})

SnackBarNotification.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  labelColor: PropTypes.string,
  classes: PropTypes.object
}

SnackBarNotification.defaultProps = {
  open: true,
  type: 'fail'
}

export const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(actions.notification.notificationHide())
})

export const mapStateToProps = state => ({
  open: notificationRedux.selectors.openNotificationState(state),
  type: notificationRedux.selectors.notificationType(state),
  message: notificationRedux.selectors.notificationMessage(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SnackBarNotification)
