import React from 'react'
import { Typography } from '@material-ui/core'

const HeaderLabel = ({ children, ...props }) => (
  <Typography variant='body2' align='center' {...props}>
    <b>{children}</b>
  </Typography>
)

export default HeaderLabel
