import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'src/components/page'
import { Grid, withStyles, AppBar, Tabs } from '@material-ui/core'
import Header from 'src/components/headers/dashboard-header'

const TabContainer = ({ classes, children }) => {
  return <Grid className={classes.tabContainer}>{children}</Grid>
}

TabContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

const TabsComponent = ({ classes, title, tabsLabels, renderTabs }) => {
  const [tab, setTab] = useState(0)

  const handleTabChange = (event, value) => {
    setTab(value)
  }

  return (
    <Grid container>
      <Grid item className={classes.header}>
        <Header title={title} />
      </Grid>
      <AppBar position='static' className={classes.tabs}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          classes={{ indicator: classes.indicator }}
          variant='scrollable'
          scrollButtons='auto'
        >
          {tabsLabels}
        </Tabs>
      </AppBar>
      <Page showBack withHeader={false}>
        <TabContainer classes={classes}>{renderTabs(tab, setTab)}</TabContainer>
      </Page>
    </Grid>
  )
}

TabsComponent.propTypes = {
  title: PropTypes.string.isRequired,
  tabsLabels: PropTypes.arrayOf(PropTypes.element).isRequired,
  renderTabs: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  header: {
    flex: 1,
    backgroundColor: theme.palette.lights.lightGrey
  },
  tabs: {
    backgroundColor: 'inherit',
    color: theme.palette.darks.black
  },
  indicator: {
    backgroundColor: theme.palette.lights.grey3
  },
  tabContainer: {
    flex: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
})

export default withStyles(styles)(TabsComponent)
