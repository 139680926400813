import { getJSON, postJSON } from 'src/services/lib/http'
import { fetchJSONDefaultError } from '../lib/fetch'
import { normalizeAmountToBackend } from 'src/services/merchant/helpers'

export const enableTellerData = ({ token }) =>
  postJSON(
    '/merchant/company',
    {
      teller: true
    },
    token,
    fetchJSONDefaultError
  )

export const updateCompanyInformationData = ({ companyInfo, token }) =>
  postJSON('/merchant/company', companyInfo, token, fetchJSONDefaultError)

export const updateCurrencyInformationData = ({ currency, token }) =>
  postJSON(
    '/merchant/currencies/' + currency.id,
    {
      active: currency.active,
      max_deposit: normalizeAmountToBackend(currency.maxCashInAmount),
      max_withdraw: normalizeAmountToBackend(currency.maxCashOutAmount)
    },
    token,
    fetchJSONDefaultError
  )

export const addLocationData = ({ location, token }) =>
  postJSON(
    '/merchant/locations/',
    {
      active: location.active,
      address: location.address,
      phone: location.phone,
      latitude: location.coordinates.lat,
      longitude: location.coordinates.lng
    },
    token
  )

export const getLocationsData = token =>
  getJSON('/merchant/locations/', {}, token)

export const editLocationData = ({ location, token }) =>
  postJSON(
    '/merchant/locations/' + location.id,
    {
      active: location.active,
      address: location.address,
      phone: location.phone,
      latitude: location.coordinates.lat,
      longitude: location.coordinates.lng
    },
    token
  )
