import React, { useState } from 'react'
import { Grid, Card, withStyles, useMediaQuery } from '@material-ui/core'
import PropTypes from 'prop-types'
import Page from 'src/components/page'
import { actions } from 'src/redux'
import * as R from 'ramda'
import connect from 'react-redux/es/connect/connect'
import { Header, StaticHeader } from 'src/pages/staticPayments/manageComponents'
import SearchSelect, {
  allStatusesItem
} from 'src/pages/staticPayments/searchSelect'
import { PaymentsList } from 'src/pages/staticPayments/paymentsList'
import { filterPayments } from 'src/pages/staticPayments/helpers'
import Divider from 'src/components/divider'
import AddEditStaticPayment from 'src/pages/staticPayments/addEditStaticPayment'
import ViewStaticPayment from 'src/pages/staticPayments/viewStaticPayment'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Loading from 'src/components/loading'
import { useTheme } from '@material-ui/core/styles'

export const GET_PAYMENTS = gql`
  query {
    staticPayments {
      name
      active
      amount
      id
    }
  }
`

const staticPayment = ({
  classes,
  permissions,
  currentPerms,
  showNotification,
  ...props
}) => {
  const [addPaymentOpen, setAddPaymentOpen] = useState(false)
  const [editPaymentOpen, setEditPaymentOpen] = useState(false)
  const [viewPaymentOpen, setViewPaymentOpen] = useState(false)

  const [selectedPayment, setSelectedPayment] = useState(undefined)

  const [selectedStatus, setSelectedStatus] = useState(allStatusesItem)
  const [searchedPayment, setSearchedPayment] = useState('')

  const theme = useTheme()
  const showHeader = useMediaQuery(theme.breakpoints.up('sm'))

  const handleAddPaymentClick = () => {
    setSelectedPayment(undefined)
    setAddPaymentOpen(true)
  }

  const handleEditPaymentClick = payment => () => {
    setSelectedPayment(payment)
    setEditPaymentOpen(true)
  }

  const handleViewPaymentClick = payment => () => {
    setSelectedPayment(payment)
    setViewPaymentOpen(true)
  }

  const handlePaymentDialogCancel = () => {
    setAddPaymentOpen(false)
    setEditPaymentOpen(false)
    setViewPaymentOpen(false)
    setSelectedPayment(undefined)
  }

  const { data, error, loading } = useQuery(GET_PAYMENTS)

  if (loading) return <Loading />
  if (error) return 'Error'

  const divide = value => value / 100

  const staticPayments = data.staticPayments.map(staticP => ({
    ...staticP,
    amount: divide(staticP.amount)
  }))

  return (
    <Page showBack title='Static Payment'>
      <Grid container direction='column' className={classes.container}>
        <StaticHeader classes={classes} onAddClick={handleAddPaymentClick} />
        <Card direction='column' className={classes.card}>
          <SearchSelect
            selectedStatus={selectedStatus}
            searchedQuery={searchedPayment}
            setSelectedStatus={setSelectedStatus}
            setSearchedQuery={setSearchedPayment}
          />
          <AddEditStaticPayment
            open={addPaymentOpen || editPaymentOpen}
            onCancel={handlePaymentDialogCancel}
            payment={selectedPayment}
            editing={selectedPayment && selectedPayment.name}
            showNotification={showNotification}
          />
          <ViewStaticPayment
            open={viewPaymentOpen}
            onCancel={handlePaymentDialogCancel}
            paymentID={selectedPayment && selectedPayment.id}
          />

          <Divider style={classes.divider} />
          {showHeader && <Header classes={classes} />}
          <PaymentsList
            payments={filterPayments(
              staticPayments,
              selectedStatus,
              searchedPayment
            )}
            onEditClick={handleEditPaymentClick}
            onViewClick={handleViewPaymentClick}
          />
        </Card>
      </Grid>
    </Page>
  )
}

staticPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      filteredRoles: PropTypes.array,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.string
    })
  )
}

const styles = theme => ({
  container: {
    padding: theme.spacing(0, 1),
    flexWrap: 'nowrap'
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  header: {
    padding: theme.spacing(2)
  },
  divider: {
    height: '3px',
    backgroundColor: theme.palette.lights.dividerGrey
  },
  whiteCardHeader: {
    minHeight: '50px',
    padding: theme.spacing(3)
  },
  editButton: {
    backgroundColor: theme.palette.darks.medium,
    color: theme.palette.lights.white,
    fontWeight: 400
  },
  buttonDelete: {
    backgroundColor: theme.palette.buttons.red,
    color: theme.palette.lights.white,
    fontWeight: 400
  },
  viewButton: {
    backgroundColor: theme.palette.buttons.green,
    color: theme.palette.lights.white,
    fontWeight: 400
  },
  active: {
    color: theme.palette.buttons.green
  },
  inactive: {
    color: theme.palette.buttons.red
  }
})

const mapDispatchToProps = dispatch => ({
  requestStaticPayments: () => dispatch(actions.payments.getStaticPayments()),
  showNotification: (message, type) =>
    dispatch(
      actions.notification.notificationShow({
        message,
        type
      })
    )
})

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(staticPayment)
