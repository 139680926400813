import React from 'react'
import PropTypes from 'prop-types'

import EntryHeader from 'src/components/headers/entry-header'
import EntryFooter from 'src/components/footers/entry-footer'

import { Location } from '@reach/router'
import { Grid, withStyles } from '@material-ui/core'

const EntryLayout = props => (
  <Location>
    {({ location }) => (
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        className={props.classes.container}
      >
        <Grid item>
          <EntryHeader pathname={location.pathname} />
        </Grid>
        <Grid container item justifyContent='center' alignContent='center'>
          {props.children}
        </Grid>
        <Grid item>
          <EntryFooter pathname={location.pathname} />
        </Grid>
      </Grid>
    )}
  </Location>
)

EntryLayout.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  container: {
    background: 'linear-gradient(-180deg,#194896,#241364)',
    minHeight: '100vh',
    width: '100vw',
    [theme.breakpoints.down('xs')]: {
      padding: 8
    },
    [theme.breakpoints.up('sm')]: {
      padding: 0
    }
  }
})

export default withStyles(styles)(EntryLayout)
