import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import { withStyles } from '@material-ui/core'

const Select = ({ name, styles, customStyles, formikProps, ...props }) => {
  return (
    <ReactSelect
      {...formikProps}
      name={name}
      id={name}
      styles={{
        control: provided => ({ ...provided, minHeight: 51 }),
        ...customStyles
      }}
      value={formikProps && formikProps.values[name]}
      onChange={value => formikProps && formikProps.setFieldValue(name, value)}
      {...props}
    />
  )
}

const styles = theme => ({})

Select.propTypes = {
  name: PropTypes.string.isRequired,
  styles: PropTypes.object,
  customStyles: PropTypes.object,
  formikProps: PropTypes.object
}

export default withStyles(styles)(Select)
