const localStorage = window.localStorage
const sessionStorage = window.sessionStorage

export const storeAuthToken = token =>
  getStorageType().setItem('MM-token', token)

export const getAuthToken = () => getStorageType().getItem('MM-token')

export const clearAuthToken = () => getStorageType().removeItem('MM-token')

const getStorageType = () =>
  parseInt(localStorage.getItem('persist_login'), 10)
    ? localStorage
    : sessionStorage

// Auxiliary Functions
export const storePersistLogin = persistLogin =>
  localStorage.setItem('persist_login', persistLogin)

export const clearPersistLogin = () => localStorage.removeItem('persist_login')
