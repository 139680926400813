import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Paper, withStyles } from '@material-ui/core'
import { Formik, Form } from 'formik'
import Page from 'src/components/page'
import OwnerInfomationForm, {
  formValues as ownerFormValues,
  validationRules as ownerValidationRules
} from 'src/components/forms/verification/owner-information'
import BusinessInfomationForm, {
  formValues as businessFormValues,
  validationRules as businessValidationRules
} from 'src/components/forms/verification/business-information'
import * as R from 'ramda'
import * as Yup from 'yup'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import complianceRedux from 'src/redux/compliance'
import userRedux from 'src/redux/users'
import { transformBasicInfo } from 'src/pages/verifications/helpers'
import AddEditButton from 'src/components/buttons/addEditButton'
import theme from 'src/theme'

const initialFormValues = {
  ...ownerFormValues,
  ...businessFormValues
}

const validationSchema = Yup.object().shape({
  ...ownerValidationRules,
  ...businessValidationRules
})

const onFormSubmit = (action, email, setIsProcessing) => (values, _actions) => {
  const stopProcessing = () => setIsProcessing(false)
  const basicInfo = transformBasicInfo({ ...values, email })
  setIsProcessing(true)
  action(basicInfo, stopProcessing)
}

const BasicVerification = ({
  basicInfo,
  basicVerification,
  classes,
  getBasicInfo
}) => {
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    getBasicInfo()
  }, [])

  const { email } = basicInfo

  return (
    <Page showBack title='Basic Verification'>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={8} className={classes.formContainer}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit(basicVerification, email, setIsProcessing)}
            >
              {({ handleSubmit, ...formikProps }) => (
                <Form onSubmit={handleSubmit}>
                  <OwnerInfomationForm
                    formikProps={formikProps}
                    {...basicInfo}
                  />
                  <BusinessInfomationForm
                    formikProps={formikProps}
                    {...basicInfo}
                  />
                  <Grid container justifyContent='center'>
                    <AddEditButton
                      type='submit'
                      style={{ marginTop: theme.spacing(3), minWidth: 100 }}
                    >
                      {isProcessing ? (
                        <CircularProgress
                          className={classes.progress}
                          size={22}
                        />
                      ) : (
                        'Submit'
                      )}
                    </AddEditButton>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  )
}

const styles = theme => ({
  formContainer: {
    maxWidth: 640,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(5)
  },
  progress: {
    color: theme.palette.lights.white
  }
})

const mapStateToProps = state => ({
  basicInfo: complianceRedux.selectors.basicInfo(state),
  email: userRedux.selectors.userEmail(state)
})

const mapDispatchToProps = dispatch => ({
  basicVerification: (basicInfo, effect) =>
    dispatch(actions.compliance.basicVerification(basicInfo, effect)),
  getBasicInfo: () => dispatch(actions.compliance.getBasicInfo())
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BasicVerification)
