import React from 'react'
import { Grid, Typography, RootRef } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DocumentPreview from 'src/components/verification/document-preview'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { join, pipe } from 'ramda'
import { convertToBase64Promise } from 'src/pages/verifications/helpers'
import { actions } from 'src/redux'
import { connect } from 'react-redux'

const DEFAULT_TEXT = 'Drag and drop some files here, or click to select files'
const ACCEPT_ALL =
  'image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
const ACCEPT_IMAGES_ONLY = 'image/*'

const handleDrop = (
  handleAddDocument,
  hasBase64,
  notificationShow
) => async acceptedFiles => {
  try {
    const tempFile = acceptedFiles[0]

    if (!tempFile) return

    const base64 = hasBase64
      ? await convertToBase64Promise(tempFile)
      : undefined

    const file = Object.assign(tempFile, {
      preview: URL.createObjectURL(tempFile),
      base64
    })

    handleAddDocument(file)
  } catch (e) {
    notificationShow('Failed to upload image', 'error')
  }
}

const Dropzone = ({
  acceptedFileExtensions,
  hasBase64,
  classes,
  document,
  errorMessage,
  handleAddDocument,
  handleRemoveDocument,
  isImageOnly,
  notificationShow,
  promptText
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: isImageOnly ? ACCEPT_IMAGES_ONLY : ACCEPT_ALL,
    disabled: !!document,
    maxSize: 3145728,
    multiple: false,
    onDrop: handleDrop(handleAddDocument, hasBase64, notificationShow)
  })

  const { ref, ...rootProps } = getRootProps({
    className: 'dropzone'
  })

  return (
    <>
      <RootRef rootRef={ref}>
        <Grid {...rootProps} className={classes.dropzone} item xs>
          <input {...getInputProps()} name='document' />
          {!document ? (
            <>
              <Typography align='center' variant='subtitle1'>
                {promptText || DEFAULT_TEXT}
              </Typography>
              <Typography align='center' variant='subtitle2'>
                {`(Accepted files: ${join(',', acceptedFileExtensions)})`}
              </Typography>
              <Typography align='center' variant='subtitle2'>
                {'Max size: 3MB'}
              </Typography>
            </>
          ) : (
            <Grid container justifyContent='center'>
              <DocumentPreview
                file={document}
                handleRemove={handleRemoveDocument}
              />
            </Grid>
          )}
        </Grid>
      </RootRef>
      <Typography variant='caption' className={classes.errorMessage}>
        {errorMessage}
      </Typography>
    </>
  )
}

const styles = theme => ({
  dropzone: {
    '&:focus': {
      outline: 'none'
    },
    border: '2px dashed #c7c7c7',
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(0.5)
  },
  errorMessage: {
    color: '#f44336',
    marginBottom: theme.spacing(2)
  }
})

const mapDispatchToProps = dispatch => ({
  notificationShow: (message, type) =>
    dispatch(
      actions.notification.notificationShow({
        message,
        type
      })
    )
})

export default pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(Dropzone)

Dropzone.propTypes = {
  acceptedFileExtensions: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object.isRequired,
  document: PropTypes.object,
  errorMessage: PropTypes.string,
  handleAddDocument: PropTypes.func.isRequired,
  handleRemoveDocument: PropTypes.func.isRequired,
  hasBase64: PropTypes.bool,
  isImageOnly: PropTypes.bool,
  onErrorAction: PropTypes.func,
  prompText: PropTypes.string
}

Dropzone.defaultProps = {
  acceptedFileExtensions: ['*.jpeg', '*.png', '*.pdf', ' *.doc', '*.docx'],
  errorMessage: '',
  hasBase64: false,
  isImageOnly: false
}
