import React, { useEffect, useState } from 'react'
import { Grid, Card, withStyles, useMediaQuery } from '@material-ui/core'
import PropTypes from 'prop-types'
import Page from 'src/components/page'
import { actions } from 'src/redux'
import * as R from 'ramda'
import { connect } from 'react-redux'
import resourcesRedux from 'src/redux/resources'
import {
  Header,
  ManageResourcesHeader
} from 'src/pages/manageMembers/manageResourcesComponents'
import SearchSelect, {
  allRolesItem,
  allStatusesItem
} from 'src/pages/manageMembers/searchSelect'
import { ResourceList } from 'src/pages/manageMembers/resourceList'
import AddEditEmployee from 'src/pages/manageMembers/addEditEmployee'
import AddRole from 'src/pages/manageMembers/addRole'
import EditRole from 'src/pages/manageMembers/editRole'
import ConfirmDialog from 'src/pages/manageAPI/confirmDialog'
import Divider from 'src/components/divider'
import { filterEmployees } from 'src/pages/manageMembers/helpers'
import {
  filteredRoleProptype,
  roleProptype
} from 'src/pages/manageMembers/proptypes'
import { useTheme } from '@material-ui/core/styles'

export const ManageMembers = ({
  classes,
  requestEmployees,
  requestRoles,
  requestMerchantInfo,
  roles,
  availablePermissions,
  addEmployee,
  editEmployee,
  addRole,
  editRole,
  employees,
  deleteEmployee
}) => {
  useEffect(() => {
    requestEmployees()
    requestRoles()
    requestMerchantInfo()
    return () => {}
  }, [])
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false)
  const [addRoleOpen, setAddRoleOpen] = useState(false)
  const [editRoleOpen, setEditRoleOpen] = useState(false)
  const [deleteEmployeeOpen, setDeleteEmployeeOpen] = useState(false)
  const [employee, setEmployee] = useState(undefined)

  const [selectedRole, setSelectedRole] = useState(allRolesItem)
  const [selectedStatus, setSelectedStatus] = useState(allStatusesItem)
  const [searchedEmployee, setSearchedEmployee] = useState('')

  const handleAddEmployeeClick = () => {
    setEmployee(undefined)
    setAddEmployeeOpen(true)
  }

  const onEditEmployeeClick = employee => () => {
    setEmployee(employee)
    setAddEmployeeOpen(!addEmployeeOpen)
  }

  const handleAddEditEmployeeCancel = () => {
    setAddEmployeeOpen(false)
  }

  const handleAddRoleClick = () => {
    setAddRoleOpen(true)
  }

  const handleEditRoleClick = () => {
    setEditRoleOpen(true)
  }

  const handleAddEditRoleCancel = () => {
    setAddRoleOpen(false)
    setEditRoleOpen(false)
  }

  const handleDeleteEmployeeClick = employee => () => {
    setEmployee(employee)
    setDeleteEmployeeOpen(true)
  }

  const handleDeleteEmployeeCancel = () => {
    setDeleteEmployeeOpen(false)
  }

  const onDelete = () => {
    deleteEmployee(employee.id, handleDeleteEmployeeCancel)
  }

  return (
    <Page showBack title='Manage Resources'>
      <>
        <Grid container direction='column' className={classes.container}>
          <ManageResourcesHeader
            classes={classes}
            onAddRoleClick={handleAddRoleClick}
            onEditRoleClick={handleEditRoleClick}
            onAddEmployeeClick={handleAddEmployeeClick}
          />
          <Card direction='column' className={classes.card}>
            <SearchSelect
              availableRoles={roles}
              selectedRole={selectedRole}
              selectedStatus={selectedStatus}
              searchedQuery={searchedEmployee}
              setSelectedRole={setSelectedRole}
              setSelectedStatus={setSelectedStatus}
              setSearchedQuery={setSearchedEmployee}
            />
            <Divider style={classes.divider} />
            <WhiteCard
              classes={classes}
              employees={filterEmployees(
                employees,
                selectedRole,
                selectedStatus,
                searchedEmployee
              )}
              onEditClick={onEditEmployeeClick}
              handleDeleteEmployeeClick={handleDeleteEmployeeClick}
            />
          </Card>
        </Grid>
        <AddEditEmployee
          open={addEmployeeOpen}
          onCancel={handleAddEditEmployeeCancel}
          availableRoles={roles.map(role => ({
            value: role.value,
            label: role.label
          }))}
          employee={employee}
          editing={employee && !!employee.email}
          onCreateUser={addEmployee}
          onEditUser={editEmployee}
        />
        <AddRole
          open={addRoleOpen}
          onCancel={handleAddEditRoleCancel}
          onAddRole={addRole}
          availablePerms={availablePermissions}
        />
        <EditRole
          open={editRoleOpen}
          onCancel={handleAddEditRoleCancel}
          onEditRole={editRole}
          availablePerms={availablePermissions}
          roles={roles}
        />
        <ConfirmDialog
          open={deleteEmployeeOpen}
          onCancel={handleDeleteEmployeeCancel}
          onConfirm={onDelete}
        >
          Are You sure you wish to delete
          <br />
          <b>{employee && employee.email}</b> of{' '}
          <b>{employee && employee.id} ID</b>?
        </ConfirmDialog>
      </>
    </Page>
  )
}

ManageMembers.propTypes = {
  classes: PropTypes.object.isRequired,
  requestEmployees: PropTypes.func.isRequired,
  requestRoles: PropTypes.func.isRequired,
  requestMerchantInfo: PropTypes.func.isRequired,
  addEmployee: PropTypes.func.isRequired,
  editEmployee: PropTypes.func.isRequired,
  addRole: PropTypes.func.isRequired,
  editRole: PropTypes.func.isRequired,
  deleteEmployee: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(roleProptype),
  availablePermissions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.number,
      verified: PropTypes.bool,
      filteredRoles: PropTypes.arrayOf(filteredRoleProptype)
    })
  )
}

const WhiteCard = ({
  classes,
  employees,
  onEditClick,
  handleDeleteEmployeeClick
}) => {
  const theme = useTheme()
  const showHeader = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <>
      {showHeader && <Header classes={classes} />}
      <ResourceList
        classes={classes}
        employees={employees}
        onEditClick={onEditClick}
        handleDeleteEmployeeClick={handleDeleteEmployeeClick}
      />
    </>
  )
}

WhiteCard.propTypes = {
  classes: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  handleDeleteEmployeeClick: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      id: PropTypes.number,
      verified: PropTypes.bool,
      filteredRoles: PropTypes.arrayOf(filteredRoleProptype)
    })
  )
}

const styles = theme => ({
  container: {
    padding: theme.spacing(0, 1)
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  header: {
    padding: theme.spacing(2)
  },
  divider: {
    height: '3px',
    backgroundColor: theme.palette.lights.dividerGrey
  },
  whiteCardHeader: {
    minHeight: '50px',
    padding: theme.spacing(3)
  },
  editButton: {
    backgroundColor: theme.palette.darks.medium,
    color: theme.palette.lights.white,
    fontWeight: 400
  },
  buttonDelete: {
    backgroundColor: theme.palette.buttons.red,
    color: theme.palette.lights.white,
    fontWeight: 400
  }
})

const mapDispatchToProps = dispatch => ({
  requestEmployees: () => dispatch(actions.resources.getEmployees()),
  requestRoles: () => dispatch(actions.resources.getRoles()),
  requestMerchantInfo: () => dispatch(actions.resources.getMerchantInfo()),
  addEmployee: (user, onFinish) =>
    dispatch(actions.resources.addEmployee(user, onFinish)),
  editEmployee: (user, onFinish) =>
    dispatch(actions.resources.editEmployee(user, onFinish)),
  addRole: (role, onFinish) =>
    dispatch(actions.resources.addRole(role, onFinish)),
  editRole: (role, onFinish) =>
    dispatch(actions.resources.editRole(role, onFinish)),
  deleteEmployee: (id, onCancel) =>
    dispatch(actions.resources.deleteEmployee(id, onCancel))
})

const mapStateToProps = state => ({
  employees: resourcesRedux.selectors.employees(state),
  roles: resourcesRedux.selectors.roles(state),
  availablePermissions: resourcesRedux.selectors.availablePermissions(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ManageMembers)
