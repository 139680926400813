import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Grid, Typography, Button, IconButton } from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import MenuIcon from '@material-ui/icons/Menu'

import { connect } from 'react-redux'
import * as R from 'ramda'
import resourcesRedux from 'src/redux/resources'
import { actions } from 'src/redux'
import numbro from 'numbro/dist/numbro'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'

export const totalPending = R.curry((pending, type) =>
  R.pipe(
    R.defaultTo([]),
    R.filter(R.propEq('type', type)),
    R.map(R.prop('amount_float')),
    R.sum()
  )(pending)
)

export const DashboardHeader = ({
  classes,
  title,
  availableFloat,
  pending,
  requestMerchantInfo,
  toggleMenuButton
}) => {
  useEffect(() => {
    requestMerchantInfo()
    return () => {}
  }, [])
  const [sums, setSums] = useState({
    pendingWithdraw: 0,
    pendingDeposit: 0,
    pendingSends: 0
  })
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    const getTotal = totalPending(pending)
    setSums({
      pendingWithdraw: getTotal('withdraw'),
      pendingDeposit: getTotal('deposit'),
      pendingSends: getTotal('send')
    })
  }, [pending])

  const { pendingWithdraw, pendingDeposit, pendingSends } = sums

  function handleClick (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      wrap='nowrap'
      className={classes.header}
    >
      <IconButton
        color='inherit'
        aria-label='open drawer'
        edge='start'
        onClick={toggleMenuButton}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Grid item xs={7}>
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <Grid
        xs={5}
        item
        container
        direction='column'
        alignContent='flex-end'
        justifyContent='flex-end'
        className={classes.balance}
      >
        <Grid item>
          <Typography className={classes.balanceTitle}>
            AVAILABLE BALANCE:
          </Typography>
        </Grid>

        <Grid item>
          <Button
            onClick={handleClick}
            variant='text'
            className={classes.button}
          >
            {numbro(availableFloat).formatCurrency('0,0.00')}
            <KeyboardArrowDown />
          </Button>

          <Menu
            id='customized-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <MenuItem className={classes.menuItem}>
              <ListItemText
                primary='Pending Withdrawals:  '
                secondary={numbro(pendingWithdraw).formatCurrency('0,0.00')}
              />
            </MenuItem>
            <MenuItem className={classes.menuItem}>
              <ListItemText
                primary='Pending Deposits: '
                secondary={numbro(pendingDeposit).formatCurrency('0,0.00')}
              />
            </MenuItem>
            <MenuItem className={classes.menuItem}>
              <ListItemText
                primary='Pending Sends:  '
                secondary={numbro(pendingSends).formatCurrency('0,0.00')}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  )
}

DashboardHeader.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object,
  availableFloat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  requestMerchantInfo: PropTypes.func.isRequired,
  toggleMenuButton: PropTypes.func,
  pending: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      amount_float: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      transaction_id: PropTypes.number,
      type: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    })
  )
}

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.lights.lightGrey,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1)
    }
  },
  title: {
    fontSize: 22,
    fontWeight: 600
  },
  balance: {
    textAlign: 'right'
  },
  balanceAmount: {
    fontSize: 22,
    fontWeight: 600,
    color: 'black'
  },
  balanceTitle: {
    fontWeight: 700,
    letterSpacing: 1,
    fontSize: 10,
    paddingRight: '8px'
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  button: {
    fontSize: 28,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22
    }
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.lights.grey
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-primary &.MuiListItemText-secondary': {
      color: theme.palette.text.dark
    }
  }
})

const mapStateToProps = state => ({
  availableFloat: resourcesRedux.selectors.availableFloat(state),
  pending: resourcesRedux.selectors.pending(state)
})

const mapDispatchToProps = dispatch => ({
  requestMerchantInfo: () => dispatch(actions.resources.getMerchantInfo()),
  toggleMenuButton: () => dispatch(actions.resources.toggleMenuButton())
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DashboardHeader)
