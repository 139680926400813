import React, { useState, useEffect } from 'react'
import EntryLayout from 'src/components/layouts/entry-layout'
import PropTypes from 'prop-types'

import AccountSetup from 'src/pages/signup/account-setup'
import BusinessInformation from 'src/pages/signup/business-information'
import OwnerInformation from 'src/pages/signup/owner-information'

import { actions } from 'src/redux'
import * as R from 'ramda'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Formik, Form } from 'formik'
import { ValidationSchemas, prepareInfo } from 'src/pages/signup/helpers'

import Stepper from 'src/components/stepper'

import { formValues as businessFormValues } from 'src/components/forms/verification/business-information'

import { formValues as ownerFormValues } from 'src/components/forms/verification/owner-information'

import { withStyles, Grid } from '@material-ui/core'

const initialFormValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
  legalAcceptance: false,
  referralCode: '',
  ...businessFormValues,
  ...ownerFormValues
}

const STEP_COMPONENTS = {
  setup: { component: AccountSetup, tag: 'Account Setup' },
  'owner-info': { component: OwnerInformation, tag: 'Owner Information' },
  'business-info': {
    component: BusinessInformation,
    tag: 'Business Information'
  },
  'verify-email': { component: <div />, tag: 'Verify Email' }
}

const renderCurrentStep = (step, formikProps) => {
  const Component = STEP_COMPONENTS[step]['component']
  const formik = step !== 'verify-email' && formikProps
  return <Component {...formik} />
}

// transform STEP_COMPONENTS into an array of tags
const getStepTags = () => R.map(step => step.tag, R.values(STEP_COMPONENTS))

// get the index of a tag from the list of step tags generated from getStepTags, for the current step
const getStepTag = step =>
  R.findIndex(R.equals(STEP_COMPONENTS[step]['tag']))(getStepTags())

export const Signup = ({ classes, signup, step }) => {
  const onFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false) // TODO; put as callback
    const params = prepareInfo(values)
    signup(params)
  }

  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    const stepIndex = getStepTag(step)
    setActiveStep(stepIndex)
  }, [step])

  return (
    <EntryLayout>
      <Grid
        container
        className={classes.container}
        alignItems='center'
        justifyContent='center'
        direction='column'
      >
        <Grid item>
          <Stepper
            className={classes.stepper}
            activeStep={activeStep}
            steps={getStepTags()}
            stepLabelProps={{
              classes: {
                label: classes.whiteText
              }
            }}
          />
        </Grid>
        <Grid item className={classes.content}>
          <Formik
            initialValues={initialFormValues}
            validationSchema={ValidationSchemas[step]}
            onSubmit={onFormSubmit}
          >
            {({ handleSubmit, ...formikProps }) => (
              <Form onSubmit={handleSubmit}>
                {renderCurrentStep(step, formikProps)}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </EntryLayout>
  )
}
const styles = theme => ({
  container: {
    flex: 1
  },
  content: {
    maxWidth: 500
  },
  stepper: {
    backgroundColor: 'transparent'
  },
  whiteText: {
    color: 'white !important'
  }
})

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
  signup: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired
}

const actionCreators = {
  signup: actions.users.usersSignup
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(actionCreators, dispatch)

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(Signup)
