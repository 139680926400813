import { applyMiddleware, createStore, combineReducers } from 'redux'
import promiseMiddleware from 'redux-promise'
import usersRedux from 'src/redux/users'
import notificationRedux from 'src/redux/notification'
import resourcesRedux from 'src/redux/resources'
import transactionsRedux from 'src/redux/transactions'
import apiRedux from 'src/redux/api'
import settingsRedux from 'src/redux/settings'
import complianceRedux from 'src/redux/compliance'
import reportsRedux from 'src/redux/reports'
import { combineModules } from 'src/redux/helpers'
import createSagaMiddleware from 'redux-saga'
import resourcesSaga from 'src/redux/resources/sagas'
import transactionsSaga from 'src/redux/transactions/sagas'
import chartRedux from 'src/redux/chart'
import chartSaga from 'src/redux/chart/sagas'
import settingsSaga from 'src/redux/settings/sagas'
import apiSaga from 'src/redux/api/sagas'
import usersSaga from 'src/redux/users/sagas'
import complianceSaga from 'src/redux/compliance/sagas'
import reportsSaga from 'src/redux/reports/sagas'
import notificationSaga from 'src/redux/notification/sagas'

const sagas = createSagaMiddleware()
const middleware = [promiseMiddleware, sagas]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(require(`redux-logger`).logger)
}

const { actions, reducers } = combineModules([
  usersRedux,
  notificationRedux,
  resourcesRedux,
  transactionsRedux,
  chartRedux,
  apiRedux,
  settingsRedux,
  complianceRedux,
  reportsRedux
])

const initialState = {}

const store = createStore(
  combineReducers(reducers),
  initialState,
  applyMiddleware(...middleware)
)

sagas.run(resourcesSaga)
sagas.run(transactionsSaga)
sagas.run(chartSaga)
sagas.run(apiSaga)
sagas.run(settingsSaga)
sagas.run(usersSaga)
sagas.run(complianceSaga)
sagas.run(reportsSaga)
sagas.run(notificationSaga)

export { actions, reducers, store }
