export const isDisabled = transaction =>
  (transaction.type &&
    transaction.type.includes('Teller') &&
    transaction.paid > 0) ||
  !!transaction.refundId ||
  transaction.status === 'canceled'

export const getActionType = (transaction, isDisabled) => {
  if (isDisabled) {
    return 'disabled'
  } else if (
    transaction.type &&
    transaction.type.includes('Merchant') &&
    transaction.address
  ) {
    return 'enabled'
  } else {
    return 'none'
  }
}

export const getActionLabel = transaction => {
  if (parseFloat(transaction.paid) === 0) {
    if (transaction.status !== 'canceled') {
      return 'Cancel'
    } else {
      return 'Canceled'
    }
  } else if (transaction.refundId) {
    return 'Refunded'
  } else if (transaction.type && transaction.type.includes('Merchant')) {
    return 'Refund'
  } else {
    return ''
  }
}

export const isCancel = transaction => parseFloat(transaction.paid) === 0

export const shouldBeAbleToCancelRefund = ({
  sendRefundPermission,
  typeOfActivity,
  transaction
}) =>
  typeOfActivity === 'Merchant' ||
  (typeOfActivity === 'All' && sendRefundPermission) ||
  !(
    transaction.type &&
    transaction.type.includes('Merchant') &&
    transaction.transactionType === 'debit'
  )

const addCancelRefundData = transaction => ({
  ...transaction,
  actionLabel: getActionLabel(transaction),
  buttonDisabled: isDisabled(transaction),
  actionType: getActionType(transaction, isDisabled(transaction)),
  isCancel: isCancel(transaction)
})

export const mapReportsResults = ({
  transactions,
  sendRefundPermission,
  typeOfActivity
}) =>
  transactions
    .map(transaction => ({
      address: transaction.address,
      amount: transaction.amount,
      currency: transaction.currency,
      date: transaction.date,
      dateUpdated: transaction.date_updated,
      fee: transaction.fee,
      invoiceId: transaction.id,
      isOnlinePayment: transaction.is_online_payment,
      merchantOrderId: transaction.merchant_order_id,
      netAmount: transaction.net_amount,
      note: transaction.note,
      paid: transaction.paid,
      refundAddress: transaction.refund_address,
      refundId: transaction.refund_id,
      status: transaction.status,
      transactionFee: transaction.transaction_fee,
      transactionType: transaction.transaction_type,
      type: transaction.type,
      userFullname: transaction.user_fullname
    }))
    .map(transaction =>
      shouldBeAbleToCancelRefund({
        sendRefundPermission,
        typeOfActivity,
        transaction
      })
        ? addCancelRefundData(transaction)
        : transaction
    )
