import React from 'react'
import { Formik } from 'formik'
import {
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@material-ui/core'
import FormikTextField from 'src/components/formik/FormikTextField'
import * as Yup from 'yup'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import { currenciesPropTypes } from 'src/pages/transact/proptypes'
import StyledButton from 'src/components/buttons/styledButton'

const initialFormValues = ({
  active = true,
  maxCashInAmount,
  maxCashOutAmount
}) => ({
  active,
  maxCashInAmount,
  maxCashOutAmount
})

const validationSchema = Yup.object().shape({
  maxCashInAmount: Yup.number()
    .positive('Must be greater than 0')
    .required('Cash amount cannot be blank'),
  maxCashOutAmount: Yup.number()
    .positive('Must be greater than 0')
    .required('Cash amount cannot be blank')
})

const handleSubmit = (onConfirm, onCancel, currency) => (values, actions) => {
  const onFinish = () => {
    onCancel()
    actions.setSubmitting(false)
  }
  onConfirm({ id: currency.id, ...values }, onFinish)
}

export const EditCurrencyInformationDialog = ({
  open,
  onCancel,
  onConfirm,
  classes,
  currency
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        Edit BBD Teller Limits
      </DialogTitle>
      <Grid>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialFormValues({
            active: currency.active,
            maxCashInAmount: currency.maxDepositFloat,
            maxCashOutAmount: currency.maxWithdrawFloat
          })}
          isInitialValid={validationSchema.isValidSync(
            initialFormValues({
              active: currency.active,
              maxCashInAmount: currency.maxDepositFloat,
              maxCashOutAmount: currency.maxWithdrawFloat
            })
          )}
          onSubmit={handleSubmit(onConfirm, onCancel, currency)}
        >
          {({ handleSubmit, ...formikProps }) => (
            <form onSubmit={handleSubmit}>
              <Grid container item direction='column'>
                <DialogContent>
                  <Grid
                    container
                    direction='column'
                    spacing={1}
                    className={classes.containers}
                  >
                    <Grid item container direction='row' alignItems='center'>
                      <Switch
                        name='active'
                        checked={formikProps.values['active']}
                        onChange={formikProps.handleChange}
                        value={formikProps.values['active']}
                        className={classes.switch}
                        inputProps={{
                          'data-testid': 'active'
                        }}
                      />
                      <Typography align='center' variant='caption'>
                        ACTIVE
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant='body1'>
                        Max Cash-In Amount
                      </Typography>
                      <FormikTextField
                        name='maxCashInAmount'
                        type='number'
                        variant='outlined'
                        placeholder='Max Cash-In Amount'
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant='body1'>
                        Max Cash-Out Amount
                      </Typography>
                      <FormikTextField
                        name='maxCashOutAmount'
                        type='number'
                        variant='outlined'
                        placeholder='Max Cash-Out Amount'
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsConfirm}>
                  <Grid
                    container
                    direction='row'
                    spacing={1}
                    justifyContent='flex-end'
                  >
                    <Grid item>
                      <StyledButton colorType={'red'} onClick={onCancel}>
                        Cancel
                      </StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledButton colorType={'green'} type='submit'>
                        Save
                      </StyledButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  )
}

EditCurrencyInformationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currencies: currenciesPropTypes
}

const styles = theme => ({
  card: {
    flex: 1,
    padding: theme.spacing(1)
  },
  title: {
    paddingBottom: 0
  },
  containers: {
    padding: theme.spacing(1)
  },
  switch: {
    color: theme.palette.buttons.green
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  }
})

export default R.pipe(withStyles(styles))(EditCurrencyInformationDialog)
