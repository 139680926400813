import React, { useEffect, useState } from 'react'
import { Grid, Card, Typography, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import WebHookItem from 'src/pages/manageAPI/webHookItem'
import AddWebHook from 'src/pages/manageAPI/addWebHookDialog'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { actions } from 'src/redux'
import apiRedux from 'src/redux/api'
import ConfirmDialog from 'src/pages/manageAPI/confirmDialog'
import Divider from 'src/components/divider'
import EmptyListComponent from 'src/pages/manageAPI/emptyListComponent'
import AddEditButton from 'src/components/buttons/addEditButton'

export const WebHooks = ({
  classes,
  addWebHook,
  requestGetWebHooks,
  webHooksList,
  onDeleteWebHook
}) => {
  useEffect(() => {
    requestGetWebHooks()
    return () => {}
  }, [])

  const [addAddWebHookOpen, setAddWebHookOpen] = useState(false)

  const handleAddWebHookClick = () => {
    setAddWebHookOpen(true)
  }

  const handleCancelWebHookClick = () => {
    setAddWebHookOpen(false)
  }

  const [deleteWebHookOpen, setDeleteWebHookOpen] = useState(false)

  const [deleteWebHookState, setDeleteWebHookState] = useState({
    title: '',
    id: ''
  })

  const handleDeleteWebHookClick = webhook => () => {
    setDeleteWebHookOpen(true)
    setDeleteWebHookState({ title: webhook.title, id: webhook.id })
  }
  const handleCancelDeleteWebHookClick = () => {
    setDeleteWebHookOpen(false)
  }

  const onDelete = () => {
    onDeleteWebHook(deleteWebHookState.id, handleCancelDeleteWebHookClick)
  }

  return (
    <Grid container direction='column'>
      <Grid container>
        <Typography>
          View information about webhooks and when they are called by the mMoney
          Merchant Service.
        </Typography>
      </Grid>
      <Grid container justifyContent='flex-end' className={classes.buttons}>
        <AddEditButton
          onClick={handleAddWebHookClick}
          data-testid='openAddWebhook'
        >
          <AddIcon /> Add Webhook
        </AddEditButton>
      </Grid>
      <Card direction='column' className={classes.card}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          className={classes.header}
        >
          <Grid item xs={3}>
            <Typography variant='button'>TITLE</Typography>
          </Grid>
          <Grid item>
            <Typography variant='button'>URL</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        {webHooksList && webHooksList.length ? (
          <Grid>
            {webHooksList.map(webhook => (
              <WebHookItem
                {...webhook}
                key={webhook.id}
                handleDeleteWebHookClick={handleDeleteWebHookClick}
              />
            ))}
          </Grid>
        ) : (
          <EmptyListComponent message={'No items in the list'} />
        )}
        <AddWebHook
          open={addAddWebHookOpen}
          onCancel={handleCancelWebHookClick}
          onAddWebHook={addWebHook}
        />
        <ConfirmDialog
          open={deleteWebHookOpen}
          onCancel={handleCancelDeleteWebHookClick}
          onConfirm={onDelete}
        >
          Are You sure you wish to delete Webhook
          <b> {deleteWebHookState.title}</b>?
        </ConfirmDialog>
      </Card>
    </Grid>
  )
}

WebHooks.propTypes = {
  classes: PropTypes.object.isRequired,
  addWebHook: PropTypes.func.isRequired,
  onDeleteWebHook: PropTypes.func.isRequired,
  requestGetWebHooks: PropTypes.func.isRequired,
  webHooksList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      inserted_at: PropTypes.string.isRequired
    })
  )
}

const styles = theme => ({
  card: {
    flex: 1,
    borderRadius: 0
  },
  header: {
    padding: '20px 35px'
  },
  buttons: {
    padding: theme.spacing(2)
  }
})

const mapDispatchToProps = dispatch => ({
  addWebHook: (webhook, onFinish) =>
    dispatch(actions.api.addWebHook(webhook, onFinish)),
  requestGetWebHooks: () => dispatch(actions.api.getWebHooks()),
  onDeleteWebHook: (id, onCancel) =>
    dispatch(actions.api.deleteWebHook(id, onCancel))
})

const mapStateToProps = state => ({
  webHooksList: apiRedux.selectors.webHooksList(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WebHooks)
