import PropTypes from 'prop-types'
import React from 'react'
import { Grid, withStyles, Typography } from '@material-ui/core'
import Logo from 'src/assets/images/logo.png'
import navigate from 'src/tools/navigate-wrapper'
import StyledButton from 'src/components/buttons/styledButton'

export const EntryHeader = props => {
  const isSignup = props.pathname.includes('signup')
  const navigatePath = isSignup ? '/' : '/signup/setup'

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      className={props.classes.container}
    >
      <Grid item container className={props.classes.left} xs={12} sm={6}>
        <Grid item>
          <img src={Logo} className={props.classes.logo} alt='mMoney' />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        alignItems='center'
        className={props.classes.right}
      >
        <Typography>
          {isSignup ? 'Already have an account?' : "Don't have an account?"}
        </Typography>
        <StyledButton
          colorType={isSignup ? 'blue' : 'green'}
          onClick={() => navigate(navigatePath)}
          style={{
            fontSize: '0.85rem',
            fontWeight: 'bold',
            marginLeft: 6
          }}
        >
          {isSignup ? 'Sign In' : 'Sign Up'}
        </StyledButton>
      </Grid>
    </Grid>
  )
}

EntryHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired
}

const styles = theme => ({
  container: {
    minHeight: 72,
    width: '100%',
    padding: '0 50px',
    marginBottom: theme.spacing(1)
  },
  logo: {
    width: 250
  },
  left: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  right: {
    color: 'white',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  headerText: {
    whiteSpace: 'nowrap',
    color: 'white',
    fontSize: '1rem'
  }
})

export default withStyles(styles)(EntryHeader)
