import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup
} from '@material-ui/core'
import FormikPropTypes from './FormikPropTypes'

export const FormikCheckbox = ({
  name,
  label,
  formik,
  formControlProps,
  formGroupProps,
  ...others
}) => (
  <FormControl {...formControlProps}>
    <FormGroup {...formGroupProps}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            name={name}
            checked={formik.values[name]}
            onChange={formik.handleChange}
            {...others}
          />
        }
      />
    </FormGroup>
    {formik.touched[name] && formik.errors[name] && (
      <FormHelperText>formik.errors[name]</FormHelperText>
    )}
  </FormControl>
)

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  formik: FormikPropTypes
}

export default connect(FormikCheckbox)
