export const selectSignupStyles = {
  control: provided => ({
    ...provided,
    minHeight: 40,
    boxShadow: 'none',
    border: 'none',
    padding: '0 12px 8px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  })
}

export const FIELD_PADDING = 12
export const getFormStyles = theme => {
  if (!theme) return {}

  return {
    borderBottom: {
      borderBottom: `1px solid ${theme.palette.lights.grey4}`
    },
    borderRight: {
      borderRight: `1px solid ${theme.palette.lights.grey4}`
    },
    field: {
      padding: `${FIELD_PADDING}px ${theme.spacing(3)}px`
    },
    label: {
      fontWeight: 600,
      marginLeft: FIELD_PADDING + 10,
      marginTop: theme.spacing(1)
    },
    gridRow: {
      marginBottom: theme.spacing(0.5)
    }
  }
}
