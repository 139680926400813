import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import { validateReduxHandlers } from 'src/redux/helpers'
import { mergeCurrencies } from 'src/redux/resources/helpers'
import { createSelector } from 'reselect'
import usersRedux from 'src/redux/users'

const { types: UsersTypes } = usersRedux

const STATE_KEY = 'resources'
const initialState = {
  employees: [],
  roles: [],
  availablePermissions: [],
  company: {
    currencies: []
  },
  userInfo: {},
  drawerVisible: false
}

const currenciesSelector = createSelector(
  R.path([STATE_KEY, 'company', 'currencies']),
  R.identity
)

const selectors = {
  currencies: currenciesSelector,
  currenciesForSelect: createSelector(
    R.path([STATE_KEY, 'company', 'currencies']),
    R.map(({ name, code }) => ({ name, code }))
  ),
  employees: createSelector(
    R.path([STATE_KEY, 'employees']),
    R.identity
  ),
  clerks: createSelector(
    R.path([STATE_KEY, 'employees']),
    R.map(({ email, id }) => ({ email, id }))
  ),
  roles: createSelector(
    R.path([STATE_KEY, 'roles']),
    R.identity
  ),
  availablePermissions: createSelector(
    R.path([STATE_KEY, 'availablePermissions']),
    R.identity
  ),
  permissions: createSelector(
    R.path([STATE_KEY, 'permissions']),
    R.identity
  ),
  company: createSelector(
    R.path([STATE_KEY, 'company']),
    R.identity
  ),
  availableFloat: createSelector(
    currenciesSelector,
    currencies =>
      currencies && currencies.length && currencies[0].availableFloat
  ),
  pending: createSelector(
    R.pipe(
      R.pathOr([], [STATE_KEY, 'company', 'currencies']),
      R.head,
      R.propOr(undefined, 'pending')
    ),
    R.identity
  ),
  userInfo: createSelector(
    R.path([STATE_KEY, 'userInfo']),
    R.identity
  ),
  isTellerEnabled: createSelector(
    R.path([STATE_KEY, 'company', 'isTeller']),
    R.identity
  ),
  companyName: createSelector(
    R.path([STATE_KEY, 'company', 'name']),
    R.identity
  ),
  companyId: createSelector(
    R.path([STATE_KEY, 'company', 'id']),
    R.identity
  ),
  currentCompanyInformation: createSelector(
    R.path([STATE_KEY, 'company']),
    R.pick(['defaultCurrency', 'contactEmail', 'phoneNumber'])
  ),
  drawerVisible: createSelector(
    R.path([STATE_KEY, 'drawerVisible']),
    R.identity
  )
}

const getEmployees = (state, _args) => state

const getEmployeesSuccess = (state, { employees }) =>
  R.merge(state, {
    employees: employees
  })

const getEmployeesFail = (state, _args) => state

const getRoles = (state, _args) => state

const getRolesSuccess = (state, { mapped }) =>
  R.merge(state, {
    roles: mapped
  })

const getRolesFail = (state, _args) => state

const addRole = (state, _args) => state

const addRoleSuccess = (state, _args) => state

const addRoleFail = (state, _args) => state

const editRole = (state, _args) => state

const editRoleSuccess = (state, _args) => state

const editRoleFail = (state, _args) => state

const getMerchantInfoFail = (state, _args) => state

const getMerchantInfo = (state, _args) => state

const getMerchantInfoSuccess = (
  state,
  { mappedPermissions, permissions, mappedCompany, userInfo }
) =>
  R.merge(state, {
    availablePermissions: mappedPermissions,
    permissions,
    company: mappedCompany,
    userInfo
  })

const addEmployee = (state, _args) => state

const addEmployeeSuccess = (state, _args) => state

const addEmployeeFail = (state, _args) => state

const editEmployee = (state, _args) => state

const editEmployeeSuccess = (state, _args) => state

const editEmployeeFail = (state, _args) => state

const deleteEmployee = (state, _args) => state

const deleteEmployeeSuccess = (state, _args) => state

const deleteEmployeeFail = (state, _args) => state

const toggleMenuButton = (state, _args) =>
  R.merge(state, {
    drawerVisible: !state.drawerVisible
  })

const balanceUpdate = (state, { currencies }) =>
  R.evolve({
    company: R.merge(R.__, {
      currencies: mergeCurrencies(state.company.currencies, currencies)
    })
  })(state)

const currencyChange = balanceUpdate

const pendingUpdate = (state, { pending }) =>
  R.evolve({
    company: R.merge(R.__, {
      currencies: mergeCurrencies(
        state.company.currencies,
        [{ pending, code: 'BBD' }] // There's no info what code has the pendings
      )
    })
  })(state)

const { Types, Creators } = createActions({
  getEmployees: [],
  getEmployeesSuccess: ['employees'],
  getEmployeesFail: ['error'],
  addEmployee: ['user', 'onFinish'],
  addEmployeeSuccess: [],
  addEmployeeFail: ['error'],
  editEmployee: ['user', 'onFinish'],
  editEmployeeSuccess: ['employees'],
  editEmployeeFail: ['error'],
  deleteEmployee: ['id', 'onCancel'],
  deleteEmployeeSuccess: [],
  deleteEmployeeFail: ['error'],
  toggleMenuButton: [],
  getRoles: [],
  getRolesSuccess: ['mapped'],
  getRolesFail: ['error'],
  addRole: ['role', 'onFinish'],
  addRoleSuccess: ['permissions'],
  addRoleFail: ['error'],
  editRole: ['role', 'onFinish'],
  editRoleSuccess: ['permissions'],
  editRoleFail: ['error'],
  getMerchantInfo: [],
  getMerchantInfoSuccess: [
    'mappedPermissions',
    'permissions',
    'mappedCompany',
    'userInfo'
  ],
  getMerchantInfoFail: ['error'],
  balanceUpdate: ['currencies'],
  pendingUpdate: ['pending'],
  currencyChange: ['currencies']
})

const logout = () => initialState

const handlers = {
  [Types.GET_EMPLOYEES]: getEmployees,
  [Types.GET_EMPLOYEES_SUCCESS]: getEmployeesSuccess,
  [Types.GET_EMPLOYEES_FAIL]: getEmployeesFail,
  [Types.ADD_EMPLOYEE]: addEmployee,
  [Types.ADD_EMPLOYEE_SUCCESS]: addEmployeeSuccess,
  [Types.ADD_EMPLOYEE_FAIL]: addEmployeeFail,
  [Types.EDIT_EMPLOYEE]: editEmployee,
  [Types.EDIT_EMPLOYEE_SUCCESS]: editEmployeeSuccess,
  [Types.EDIT_EMPLOYEE_FAIL]: editEmployeeFail,
  [Types.DELETE_EMPLOYEE]: deleteEmployee,
  [Types.DELETE_EMPLOYEE_SUCCESS]: deleteEmployeeSuccess,
  [Types.DELETE_EMPLOYEE_FAIL]: deleteEmployeeFail,
  [Types.TOGGLE_MENU_BUTTON]: toggleMenuButton,
  [Types.GET_ROLES]: getRoles,
  [Types.GET_ROLES_SUCCESS]: getRolesSuccess,
  [Types.GET_ROLES_FAIL]: getRolesFail,
  [Types.ADD_ROLE]: addRole,
  [Types.ADD_ROLE_SUCCESS]: addRoleSuccess,
  [Types.ADD_ROLE_FAIL]: addRoleFail,
  [Types.EDIT_ROLE]: editRole,
  [Types.EDIT_ROLE_SUCCESS]: editRoleSuccess,
  [Types.EDIT_ROLE_FAIL]: editRoleFail,
  [Types.GET_MERCHANT_INFO]: getMerchantInfo,
  [Types.GET_MERCHANT_INFO_SUCCESS]: getMerchantInfoSuccess,
  [Types.GET_MERCHANT_INFO_FAIL]: getMerchantInfoFail,
  [Types.BALANCE_UPDATE]: balanceUpdate,
  [Types.PENDING_UPDATE]: pendingUpdate,
  [Types.CURRENCY_CHANGE]: currencyChange,
  [UsersTypes.USERS_LOGOUT]: logout
}

validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
