import React from 'react'
import Page from 'src/components/page'
import { Grid, Typography, withStyles } from '@material-ui/core'
import managePic from 'src/assets/images/getting-started/manage-members.png'
import addDeleteMemberPic from 'src/assets/images/getting-started/add-delete-member.png'
import createMemberPic from 'src/assets/images/getting-started/create-member.png'
import editUsersRolePic from 'src/assets/images/getting-started/edit-users-role.png'
import editRoleHeaderPic from 'src/assets/images/getting-started/edit-role-header.png'
import editRolePermissionsPic from 'src/assets/images/getting-started/edit-role-permissions.png'
import editRolePic from 'src/assets/images/getting-started/edit-role.png'
import sidebarPic from 'src/assets/images/getting-started/sidebar.png'
import reportToGeneratePic from 'src/assets/images/getting-started/report-to-generate.png'
import generatedReportPic from 'src/assets/images/getting-started/generated-report.png'
import viewTransactHistoryPic from 'src/assets/images/getting-started/view-transact-history.png'
import dashboardPic from 'src/assets/images/getting-started/dashboard.png'
import createInvoicePic from 'src/assets/images/getting-started/create-invoice.png'
import appMenuPic from 'src/assets/images/getting-started/app-menu.png'
import QRcodePic from 'src/assets/images/getting-started/QR-code.png'
import listOfTransactionsPic from 'src/assets/images/getting-started/list-of-transactions.png'
import refundPic from 'src/assets/images/getting-started/refund.png'
import digitalDollarsMenuPic from 'src/assets/images/getting-started/digital-dollars-menu.png'
import digitalDollarsValuePic from 'src/assets/images/getting-started/digital-dollars-value.png'
import scanQRcodePic from 'src/assets/images/getting-started/scan-QR-code.png'
import { styled } from '@material-ui/styles'

const H2 = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4, 0)
}))

const H3 = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(3, 0)
}))

const H5 = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 0)
}))

const GettingStarted = ({ classes }) => {
  return (
    <Page title='Getting Started'>
      <Grid container className={classes.container}>
        <H2 variant='h2'>Management Panel</H2>
        <Grid container item direction='column'>
          <H3 variant='h3'>Creating a Clerk</H3>
          <H5 variant='h5'>Step 1</H5>
          <Typography paragraph>
            After logging into your merchant account at mMoney's Merchant
            Website, navigate to the "Manage Members" screen by clicking on the
            “Manage Members” area of the navigation bar at the side of your
            page.
          </Typography>
          <Grid>
            <img
              alt={'Manage member screen'}
              src={managePic}
              className={classes.image}
            />
          </Grid>
          <H5 variant='h5'>Step 2</H5>
          <Typography paragraph>
            At the Manage Members page you are presented with the current
            members of your business and a few options. Now click on the “+ Add
            Employee” button to add a new Member.
          </Typography>
          <Grid>
            <img
              alt={'Edit member screen'}
              src={addDeleteMemberPic}
              className={classes.image}
            />
          </Grid>

          <H5 variant='h5'>Step 3</H5>
          <Typography paragraph>
            Simply, enter the member’s email address, name and assign a role
            (Admin, Clerk or Teller) to your new member, finishing by clicking
            on the blue “Create User” button at the bottom of the dialogue box.
            Your new member will receive an email at the email address provided
            containing directions on completing their signup process.
          </Typography>

          <Grid>
            <img
              alt={'Create member screen'}
              src={createMemberPic}
              className={classes.image}
            />
          </Grid>

          <p className='tip'>
            <Typography variant='button'>
              <span>Pro Tip: </span>
            </Typography>
            Here you can click to select more than one role for your member.
            Select as many as your need!
          </p>
        </Grid>

        <div>
          <H3 variant='h3'>Editing a Member's Role </H3>

          <Typography paragraph>
            Follow step one from the “Creating a Clerk” section above and make
            sure that you are in the “Manage Members” area of your account.
          </Typography>
          <H5 variant='h5'>Step 1</H5>
          <Typography paragraph>
            You may now edit a specific member’s permissions by clicking on the
            “Edit” button next to the user you want to edit.
          </Typography>

          <Grid>
            <img
              alt={'Edit member screen'}
              src={addDeleteMemberPic}
              className={classes.image}
            />
          </Grid>

          <H5 variant='h5'>Step 2</H5>
          <Typography paragraph>
            Once you have selected to edit a User’s role you will see the popup
            as below. Here you can deactivate the User by clicking on the toggle
            labeled “Active”, change the User’s information or edit their
            role(s). Once you have finished click "Edit" at the bottom of the
            popup.
          </Typography>

          <Grid>
            <img
              alt={'Edit users role screen'}
              src={editUsersRolePic}
              className={classes.image}
            />
          </Grid>
        </div>

        <div>
          <H3 variant='h3'>Editing a Role's Permissions </H3>

          <Typography paragraph>
            Continuing from the “Manage Members” area
          </Typography>

          <H5 variant='h5'>Step 1</H5>
          <Typography paragraph>
            Now click on the “+ Add/Edit Role” button.
          </Typography>

          <Grid>
            <img
              alt={'Edit role header screen'}
              src={editRoleHeaderPic}
              className={classes.image}
            />
          </Grid>

          <H5 variant='h5'>Step 2</H5>
          <Typography paragraph>
            Select the role that you want to edit by clicking on the drop-down
            box here and clicking on the Role you want to edit.
          </Typography>

          <Grid>
            <img
              alt={'Edit role permissions screen'}
              src={editRolePermissionsPic}
              className={classes.image}
            />
          </Grid>

          <Grid>
            <img
              alt={'Edit role screen'}
              src={editRolePic}
              className={classes.image}
            />
          </Grid>

          <H5 variant='h5'>Step 3</H5>
          <Typography paragraph>
            Now you can click on the permissions, adding or removing as many as
            you would like to the Role that you selected earlier.
          </Typography>
          <H5 variant='h5'>Step 4</H5>
          <Typography paragraph>
            Finish up your changes by clicking the “Edit Role” button.
          </Typography>
        </div>

        <div>
          <H3 variant='h3'>Generating Reports </H3>

          <H5 variant='h5'>Step 1</H5>
          <Typography paragraph>
            Assuming that you are already logged into your acccount at mMoney's
            Merchant Website navigate to the Reports screen by clicking on the
            “Reports” area of the Nav Bar at the top of your page
          </Typography>

          <Grid>
            <img
              alt={'Sidebar screen'}
              src={sidebarPic}
              className={classes.narrowImage}
            />
          </Grid>

          <H5 variant='h5'>Step 2</H5>
          <Typography paragraph>
            Now that you are in the Reports area of your Merchant account you
            will be greeted with the selection boxes as shown below. This is
            where you specify what your report should show you. <br />
            Click the green “Run Report” button at the bottom of the selection
            area when you are ready to see your report, if you leave your
            selection boxes blank a generalized report showing all transaction
            history will be generated.
          </Typography>

          <Grid>
            <img
              alt={'Reports screen'}
              src={reportToGeneratePic}
              className={classes.image}
            />
          </Grid>

          <H5 variant='h5'>Step 3</H5>
          <Typography paragraph>
            Now that you have generated a report you can browse your results,
            navigating via the “Next” and “Previous” buttons at the bottom of
            your report.
          </Typography>

          <Grid>
            <img
              alt={'Generated report screen'}
              src={generatedReportPic}
              className={classes.image}
            />
          </Grid>

          <p className='tip'>
            <Typography variant='button'>
              <span>Pro Tip: </span>
            </Typography>
            You can show a more results per page by changing the number of rows
            displayed here. If you want to download your report click on the
            blue “Download” at the top-left of your report.
          </p>
        </div>

        <div>
          <H3 variant='h3'>View Transaction History </H3>

          <H5 variant='h5'>Step 1</H5>
          <Typography paragraph>
            After logging into your Merchant account on mMoney's Merchant
            Website scroll down your page to find a chart representing your
            sales for a specific time frame which can be change at the top-right
            of your graph.
          </Typography>

          <Grid>
            <img
              alt={'Dashboard screen'}
              src={dashboardPic}
              className={classes.image}
            />
          </Grid>

          <H5 variant='h5'>Step 2</H5>
          <Typography paragraph>
            You can see a break-down of your transactions and sort them by
            Merchant or Teller by clicking on the title. This section shows your
            time, value and who performed it.
          </Typography>
        </div>
        <Grid>
          <img
            alt={'Transact screen'}
            src={viewTransactHistoryPic}
            className={classes.image}
          />
        </Grid>

        <H2 variant='h2'>Merchant / Teller App</H2>
        <div>
          <H3 variant='h3'>Menu </H3>

          <Typography paragraph>
            After you have logged into mMoney’s Merchant Teller App you will be
            greeted with a screen similar to this image. From here you can
            navigate to your menu by touching on the three bars that are located
            top-left of your screen.
          </Typography>

          <Grid>
            <img
              alt={'App create invoice screen'}
              src={createInvoicePic}
              className={classes.narrowImage}
            />
          </Grid>

          <Typography paragraph>
            Once you have opened your menu (as described above) you can navigate
            to your Teller Mode, Merchant Mode, Administration panel or
            Settings. You can Also see your available funds, the current member
            logged in, your business’ name and the option to logout.
          </Typography>

          <Grid>
            <img
              alt={'App menu screen'}
              src={appMenuPic}
              className={classes.image}
            />
          </Grid>
        </div>

        <div>
          <H3 variant='h3'>Creating Invoices for Payment </H3>

          <Typography paragraph>
            After you log into your Merchant Teller App with your
            Merchant/Member’s account you are automatically in Merchant Mode.
            Use this in a similar fashion to how you would use a Cash Register.
          </Typography>

          <H5 variant='h5'>Step 1</H5>
          <Typography paragraph>
            Tap the numbers on the screen to enter the value of your goods sold
            then tap “Create Invoice”.
          </Typography>

          <Grid>
            <img
              alt={'App create invoice screen'}
              src={createInvoicePic}
              className={classes.narrowImage}
            />
          </Grid>

          <H5 variant='h5'>Step 2</H5>
          <Typography paragraph>
            A QR code will be generated containing your address information and
            the value of your invoice. Get your customer to scan the QR code
            with their mMoney User Wallet, once they complete the payment the
            invoice screen will automatically close and the transaction will be
            completed.
          </Typography>

          <Grid>
            <img
              alt={'QR code screen'}
              src={QRcodePic}
              className={classes.narrowImage}
            />
          </Grid>
        </div>

        <div>
          <H3 variant='h3'>Refunding </H3>

          <Typography paragraph>
            You may only refund transactions performed in Merchant Mode of the
            Merchant Teller App. To issue a refund you must first be in Merchant
            Mode (as displayed. Steps on how to navigate to this portion of the
            App are in The Menu section of the document).
          </Typography>

          <H5 variant='h5'>Step 1</H5>
          <Typography paragraph>
            Click on the clock symbol in the top-right corner to display today’s
            transactions.
          </Typography>

          <Grid>
            <img
              alt={'App create invoice screen'}
              src={createInvoicePic}
              className={classes.narrowImage}
            />
          </Grid>

          <H5 variant='h5'>Step 2</H5>
          <Typography paragraph>
            From the list of transactions find the specific one that you would
            like to issue a refund to. Click on this transaction and its
            information will be brought to the screen.
          </Typography>

          <Grid>
            <img
              alt={'List of transactions screen'}
              src={listOfTransactionsPic}
              className={classes.image}
            />
          </Grid>

          <H5 variant='h5'>Step 3</H5>
          <Typography paragraph>
            Touch the blue “Refund” button show here to finalize your refund.
          </Typography>

          <Grid>
            <img
              alt={'Refund screen'}
              src={refundPic}
              className={classes.narrowImage}
            />
          </Grid>

          <p>
            <Typography variant='button'>
              <span>Pro Tip: </span>
            </Typography>{' '}
            Any Merchant transactions refunded are also visible in the reports
            generated for your Merchant account (as shown in MTMP – Generating
            Reports section of this document). <br />
            If a transaction is underpaid and you wish to receive full payment
            you must first refund the underpaid transaction and issue a new
            invoice with the original amount.
          </p>
        </div>

        <div>
          <H3 variant='h3'>Sending Digital Dollars </H3>

          <Typography paragraph>
            To sell a customer Digital Dollars in Teller Mode you must have
            Digital Dollars available on your Merchant account. Eg: This balance
            of Digital Dollars is the balance of the “BBD” shown in the menu of
            your app. Now you can receive fiat BBD from customers and send them
            Digital Dollars in return.
          </Typography>
          <a
            className='img-link mobile'
            target='_blank'
            href='/src/assets/images/getting-started/Screenshot_20171207_122445.png' // digital-dollars-menu.png
          >
            <img
              alt=''
              src='/src/assets/images/getting-started/Screenshot_20171207_122445.png'
            />
          </a>

          <Grid>
            <img
              alt={'Digital dollars menu screen'}
              src={digitalDollarsMenuPic}
              className={classes.image}
            />
          </Grid>

          <H5 variant='h5'>Step 1</H5>
          <Typography paragraph>
            Navigate to Teller Mode in mMoney’s Merchant Teller App.
          </Typography>

          <H5 variant='h5'>Step 2</H5>
          <Typography paragraph>
            Tap the numbers on the screen (similarly to how a cash register
            works) to generate the value of Digital Dollars your customer wants
            to purchase.
          </Typography>

          <Grid>
            <img
              alt={'Digital dollars value screen'}
              src={digitalDollarsValuePic}
              className={classes.narrowImage}
            />
          </Grid>

          <H5 variant='h5'>Step 3</H5>
          <Typography paragraph>
            Touch the “Scan Address” button at the bottom of your screen and
            scan your customers address as shown here.
          </Typography>
          <a
            className='img-link mobile'
            target='_blank'
            href='/src/assets/images/getting-started/Screenshot_2017-07-18-20-00-35.png' // scan-QR-code.png
          >
            <img
              alt=''
              src='/src/assets/images/getting-started/Screenshot_2017-07-18-20-00-35.png'
            />
          </a>

          <Grid>
            <img
              alt={'Scan QR code screen'}
              src={scanQRcodePic}
              className={classes.narrowImage}
            />
          </Grid>

          <p className='tip'>
            <Typography variant='button'>
              <span>Pro Tip: </span>
            </Typography>{' '}
            You can view Teller transactions by tapping the clock symbol in the
            top-right corner of your screen while in Teller Mode of the Merchant
            Teller App. <br />
            Teller transactions are also visible in the reports generated by
            MTMP – Generating Reports (as shown above)
          </p>
        </div>
      </Grid>
    </Page>
  )
}

const styles = theme => ({
  container: {
    padding: theme.spacing(1, 1)
  },
  image: {
    maxWidth: '100%',
    display: 'block',
    margin: '25px auto 25px',
    boxShadow: '0 10px 45px -5px rgba(0, 0, 0, 0.2)'
  },
  narrowImage: {
    maxWidth: '100%',
    maxHeight: 600,
    display: 'block',
    margin: '25px auto 25px',
    boxShadow: '0 10px 45px -5px rgba(0, 0, 0, 0.2)'
  }
})
export default withStyles(styles)(GettingStarted)
