import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Gmaps, Marker } from 'react-gmaps'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const mapParams = {
  v: '3.exp',
  key: 'AIzaSyAV4kKPFEpW2EFQ657AHQsMrrsdGZMrjos'
}

const GoogleMap = ({ name, values, onChange }) => {
  const [cooridinates, setCooridinates] = useState({
    lat: values.lat,
    lng: values.lng
  })
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const onCoordinateChange = event => {
    const { latLng } = event
    setCooridinates({ lat: latLng.lat(), lng: latLng.lng() })
    onChange({ lat: latLng.lat(), lng: latLng.lng() })
  }

  const lat =
    (cooridinates && cooridinates.lat) || values.lat || 13.19014325353962
  const lng =
    (cooridinates && cooridinates.lng) || values.lng || -59.57795917892042

  return (
    <Gmaps
      name={name}
      id={name}
      lat={lat}
      lng={lng}
      height={isSmallScreen ? 220 : 250}
      loadingMessage={'Loading'}
      params={mapParams}
      onMapCreated={map =>
        map.setOptions({
          disableDefaultUI: false,
          scrollwheel: false
        })
      }
      style={{ minWidth: '100%' }}
      onClick={onCoordinateChange}
      zoom={13}
    >
      <Marker lat={lat} lng={lng} draggable onDragEnd={onCoordinateChange} />
    </Gmaps>
  )
}

GoogleMap.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  })
}

export default GoogleMap
