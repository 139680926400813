import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { Done, PriorityHigh } from '@material-ui/icons'
import * as R from 'ramda'

export const MessageComponent = ({ classes, type, message }) => (
  <Grid item container direction='row'>
    <Grid item container alignItems='center'>
      <Grid item>{type === 'success' ? <Done /> : <PriorityHigh />}</Grid>
      <Typography className={classes.typographyBoldStyle} variant='h6'>
        {type === 'success' ? 'Success!' : 'Failed...'}
      </Typography>
      <Typography className={classes.typographyStyle} variant='h6'>
        {message ||
          (type === 'success'
            ? 'Successfully finished the action!'
            : 'There has been a network error')}
      </Typography>
    </Grid>
  </Grid>
)

const styles = theme => ({
  avatar: {
    margin: theme.spacing(1)
  },
  typographyStyle: {
    color: theme.palette.lights.white,
    padding: theme.spacing(1)
  },
  typographyBoldStyle: {
    color: theme.palette.lights.white,
    padding: theme.spacing(1),
    fontWeight: 600
  }
})

export default R.pipe(withStyles(styles))(MessageComponent)
