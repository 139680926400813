import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Grid
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Page from 'src/components/page'
import DocumentRequirements from 'src/pages/verifications/document/requirements'
import DocumentUpload from 'src/pages/verifications/document/document-upload'
import ExecutiveDocuments from 'src/pages/verifications/document/executive-documents'
import {
  isOfficersRequired,
  transformExecutiveDocuments,
  transformDocuments
} from 'src/pages/verifications/helpers'

import * as R from 'ramda'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import ConfirmDialog from 'src/pages/verifications/document/confirmation-dialog'
import complianceRedux from 'src/redux/compliance'
import AddEditButton from 'src/components/buttons/addEditButton'
import theme from 'src/theme'

const verifyCompany = (
  action,
  companyDocuments,
  executiveDocuments,
  setIsProcessing,
  setOpenConfirmation
) => () => {
  const stopProcessing = () => setIsProcessing(false)
  const transformedCompanyDocuments = transformDocuments(companyDocuments)
  const transformedExecutiveDocuments = transformExecutiveDocuments(
    executiveDocuments
  )
  const payload = {
    documents: transformedCompanyDocuments,
    executiveDocuments: transformedExecutiveDocuments
  }

  action(payload, stopProcessing)
  setIsProcessing(true)
  setOpenConfirmation(false)
}

// TODO - update this validation to depend on business type from basicInfo
// const validateVerification = (companyDocuments, executiveInfo) =>
//   R.isEmpty(companyDocuments) && R.isEmpty(executiveInfo)

export const VerifyYourBusiness = ({
  basicInfo,
  classes,
  documentVerification,
  getBasicInfo,
  getVerificationData
}) => {
  useEffect(() => {
    getBasicInfo()
    getVerificationData()
  }, [])

  const [expanded, setExpanded] = useState('requirements')
  const [companyDocuments, setCompanyDocuments] = useState([])
  const [executivesInfo, setExecutivesInfo] = useState([])
  const [openConfirmation, setOpenConformation] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleChange = panel => (_event, expanded) => {
    setExpanded(expanded ? panel : false)
  }

  const removeCompanyDocument = documentIndex => {
    const newDocuments = R.remove(documentIndex, 1, companyDocuments)
    setCompanyDocuments(newDocuments)
  }

  const addCompanyDocument = document =>
    setCompanyDocuments(R.append(document, companyDocuments))

  const addExecutiveInfo = executiveInfo =>
    setExecutivesInfo(R.append(executiveInfo, executivesInfo))

  const updateExecutiveInfo = executiveInfo =>
    R.pipe(
      R.findIndex(R.propEq('id', executiveInfo.id)),
      R.update(R.__, executiveInfo, executivesInfo),
      updatedInfo => setExecutivesInfo(updatedInfo)
    )(executivesInfo)

  const removeExecutive = recordID =>
    R.pipe(
      R.findIndex(R.propEq('id', recordID)),
      R.remove(R.__, 1, executivesInfo),
      updatedInfo => setExecutivesInfo(updatedInfo)
    )(executivesInfo)

  const disableOfficers = !isOfficersRequired(basicInfo)
  return (
    <Page showBack title='Verify Your Business'>
      <>
        <Grid
          direction='column'
          alignItems='center'
          container
          className={classes.container}
        >
          <Grid item xs={12} sm={8}>
            <ExpansionPanel
              expanded={expanded === 'requirements'}
              onChange={handleChange('requirements')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Requirements
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  Required documents for successful verification
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container direction='column' alignItems='center'>
                  <DocumentRequirements
                    handleGetStarted={() => setExpanded('company')}
                  />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'company'}
              onChange={handleChange('company')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Company Documents
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  Required documents for successful verification
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container>
                  <DocumentUpload
                    documents={companyDocuments}
                    handleAddDocument={addCompanyDocument}
                    removeDocument={removeCompanyDocument}
                  />
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'executives'}
              disabled={disableOfficers}
              onChange={handleChange('executives')}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  Executive Documents
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  Executives, Directors, Managers, Supervisors
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ExecutiveDocuments
                  addExecutiveInfo={addExecutiveInfo}
                  executives={executivesInfo}
                  updateExecutiveInfo={updateExecutiveInfo}
                  removeExecutive={removeExecutive}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid container item justifyContent='center' xs={12} sm={8}>
            <AddEditButton
              style={{ marginTop: theme.spacing(3), minWidth: 100 }}
              onClick={() => setOpenConformation(true)}
            >
              {isProcessing ? (
                <CircularProgress className={classes.progress} size={22} />
              ) : (
                'Verify'
              )}
            </AddEditButton>
          </Grid>
        </Grid>
        <ConfirmDialog
          action={verifyCompany(
            documentVerification,
            companyDocuments,
            executivesInfo,
            setIsProcessing,
            setOpenConformation
          )}
          onClose={() => setOpenConformation(false)}
          open={openConfirmation}
        />
      </>
    </Page>
  )
}

const styles = theme => ({
  container: {
    padding: theme.spacing(3)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  verifyButton: {
    marginTop: theme.spacing(4),
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
  },
  progress: {
    color: theme.palette.lights.white
  }
})

const mapStateToProps = state => ({
  basicInfo: complianceRedux.selectors.basicInfo(state)
})

const mapDispatchToProps = dispatch => ({
  getBasicInfo: () => dispatch(actions.compliance.getBasicInfo()),
  getVerificationData: () => dispatch(actions.compliance.getVerificationData()),
  documentVerification: (companyDocuments, effect) =>
    dispatch(actions.compliance.documentVerification(companyDocuments, effect))
})

VerifyYourBusiness.propTypes = {
  classes: PropTypes.object,
  documentVerification: PropTypes.func
}
export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(VerifyYourBusiness)
