import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import LabelComponent from 'src/components/labelComponent'
import { getDisplaySnippet } from 'src/services/merchant/snippet'
import Divider from 'src/components/divider'
import CopyToClipboard from 'src/components/copyToClipboard'
import StyledButton from 'src/components/buttons/styledButton'
import { bpMaxSM } from 'src/tools/screenSize'

const ApiKeyItem = ({
  classes,
  title,
  id,
  apiKey,
  receiveFunds,
  sendRefunds,
  handleDeleteKeyClick,
  onTestApiClick
}) => {
  return (
    <>
      <Grid
        container
        item
        className={classes.container}
        justifyContent='space-between'
      >
        <Grid item xs={12} md={3}>
          <Typography variant='button' className={classes.fontSize}>
            {title} <b>({id})</b>
          </Typography>
        </Grid>
        <Grid item container xs={12} md={5}>
          <Typography className={classes.apiKey}>{apiKey}</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={4}
          spacing={1}
          alignContent='center'
          justifyContent='flex-end'
        >
          <Grid item>
            <CopyToClipboard
              toCopy={receiveFunds ? getDisplaySnippet({ apiKey }) : apiKey}
              beforeCopyLabel={receiveFunds ? 'Copy Code Snippet' : 'Copy Key'}
            />
          </Grid>
          {receiveFunds && (
            <Grid item>
              <StyledButton
                className={classes.buttonTest}
                onClick={() => onTestApiClick({ id })}
                colorType={'green'}
              >
                Test Live Online
              </StyledButton>
            </Grid>
          )}
          <Grid item>
            <StyledButton
              colorType={'red'}
              className={classes.buttonDelete}
              onClick={handleDeleteKeyClick({ title, id })}
            >
              Delete
            </StyledButton>
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='flex-start'>
          {(receiveFunds || sendRefunds) && (
            <Grid item>
              <LabelComponent
                label={receiveFunds ? 'Receive Funds' : 'Send Refunds'}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
    </>
  )
}

ApiKeyItem.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  apiKey: PropTypes.string.isRequired,
  receiveFunds: PropTypes.bool,
  sendRefunds: PropTypes.bool,
  handleDeleteKeyClick: PropTypes.func.isRequired,
  onTestApiClick: PropTypes.func.isRequired
}

ApiKeyItem.defaultProps = {
  receiveFunds: false,
  sendRefunds: false
}

const styles = theme => ({
  container: {
    padding: theme.spacing(3, 4),
    minHeight: 168,
    overflow: 'hidden',
    flexDirection: 'row',
    [`${bpMaxSM}`]: {
      padding: theme.spacing(2, 3),
      flexDirection: 'column'
    }
  },
  fontSize: {
    fontSize: 14
  },
  apiKey: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word'
  },
  buttonTest: {
    backgroundColor: theme.palette.buttons.green
  },
  buttonDelete: {
    backgroundColor: theme.palette.buttons.red
  }
})

export default withStyles(styles)(ApiKeyItem)
