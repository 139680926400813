import React from 'react'
import { Link } from '@reach/router'

const NavLink = props => (
  <Link
    getProps={({ isPartiallyCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return isPartiallyCurrent
        ? { className: `${props.className} active` }
        : null
    }}
    {...props}
  />
)

export default NavLink
