import React from 'react'
import {
  CircularProgress,
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import * as R from 'ramda'
import QRCode from 'qrcode.react'

import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import numbro from 'numbro/dist/numbro'
import StyledButton from 'src/components/buttons/styledButton'

const GET_STATIC_PAYMENT = gql`
  query RootQueryType($id: Int!) {
    staticPayment(id: $id) {
      active
      name
      amount
      qrStr
      id
    }
  }
`

const ViewStaticPayment = ({ open, onCancel, classes, paymentID }) => {
  if (!open) {
    return <span />
  }

  const { data, error, loading } = useQuery(GET_STATIC_PAYMENT, {
    variables: { id: paymentID }
  })

  if (loading) {
    return <CircularProgress size={80} thickness={5} />
  }

  if (error) {
    return <span />
  }

  const { staticPayment } = data

  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <Grid container direction='column' alignItems='center'>
        <DialogTitle id='form-dialog-title'>
          <Typography className={classes.title} variant='h5'>
            {staticPayment.name.toString()}
          </Typography>
        </DialogTitle>
      </Grid>
      <DialogContent>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <QRCode value={staticPayment.qrStr || ''} size={225} />
          </Grid>
          <Grid item>
            <Typography className={classes.amount} variant='h6'>
              {numbro(staticPayment.amount / 100).formatCurrency('0,0.00')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction='column'
          alignItems='center'
          className={classes.modalActions}
        >
          <Grid item>
            <StyledButton onClick={onCancel} size='large' colorType={'red'}>
              Close
            </StyledButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const styles = theme => ({
  card: {
    flex: 1,
    padding: theme.spacing(1)
  },
  title: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.buttons.green,
    fontWeight: 'bold'
  },
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  },
  switch: {
    color: theme.palette.buttons.green
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  },
  amount: {
    paddingTop: theme.spacing(1),
    color: theme.palette.buttons.green,
    fontWeight: 'bold'
  },
  modalActions: {
    paddingBottom: theme.spacing(3)
  }
})

export default R.pipe(withStyles(styles))(ViewStaticPayment)
