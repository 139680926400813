export const mapBasicInfo = (basicInfo = {}) => ({
  email: basicInfo.email,
  firstName: basicInfo.afn,
  lastName: basicInfo.aln,
  country: basicInfo.aco,
  legalBusinessName: basicInfo.amn,
  businessPhoneNumber: basicInfo.aph,
  businessAddress: basicInfo.asn,
  parish: basicInfo.ast,
  businessType: basicInfo.businesstype,
  businessTradingName: basicInfo.dba,
  estimatedAccountActivity: basicInfo.limitBandId,
  dailyLimit: basicInfo.dailyLimit,
  monthlyLimit: basicInfo.monthlyLimit
})

export const verificationPayload = (reference, userDocuments) => {
  const payload = userDocuments
  payload.append('ref', reference)

  return payload
}
