import { createTheme } from '@material-ui/core'

export default createTheme({
  palette: {
    primary: {
      main: '#3288df',
      darker: '#154f9a',
      dark: '#262361'
    },
    secondary: {
      main: '#24b89c',
      lighter: '#4ec2c0',
      light: '#9fdbe9'
    },
    lights: {
      white: '#ffffff',
      light: '#F6F6F6',
      lightGrey: '#f7f8f8',
      grey: '#eeeff0',
      grey1: '#ECECEC',
      grey2: '#AAAAAA',
      grey3: '#9BA2AE',
      grey4: '#ECF3FB',
      dividerGrey: '#d9d9d9'
    },
    darks: {
      deep: '#072744',
      dark: '#1d3464',
      medium: '#135DA7',
      light: '#02588e',
      black: '#000000'
    },
    text: {
      secondary: 'black',
      dark: '#515c6b',
      inactive: '#bfbfbf'
    },
    buttons: {
      green: '#47A447',
      lightGreen: '#A4D2A4',
      red: '#d9534f',
      blue: '#135DA7',
      grey: '#9BA2AE',
      white: '#ffffff'
    },
    hovers: {
      green: '#19806D',
      red: '#990136',
      blue: '#262460'
    }
  },
  typography: {
    fontFamily: [
      '"Source Sans Pro"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    htmlFontSize: 14.5,
    label: {
      fontWeight: 600
    },
    button: {
      fontWeight: 'bold',
      fontSize: '1rem',
      textTransform: 'capitalize'
    },
    letterSpacing: '0.1px'
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: 'visible'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '16.5px 14px'
      }
    }
  }
})
