import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import { validateReduxHandlers } from 'src/redux/helpers'
import { createSelector } from 'reselect'
import usersRedux from 'src/redux/users'

const { types: UsersTypes } = usersRedux

const STATE_KEY = 'settings'

const initialState = {
  locations: []
}

const selectors = {
  locations: createSelector(
    R.path([STATE_KEY, 'locations']),
    R.identity
  )
}

const enableTeller = (state, _args) => state

const enableTellerSuccess = (state, _args) => state

const enableTellerFail = (state, _args) => state

const updateCompanyInformation = (state, _args) => state

const updateCompanyInformationSuccess = (state, _args) => state

const updateCompanyInformationFail = (state, _args) => state

const updateCurrencyInformation = (state, _args) => state

const updateCurrencyInformationSuccess = (state, _args) => state

const updateCurrencyInformationFail = (state, _args) => state

const addLocation = (state, _args) => state

const addLocationSuccess = (state, _args) => state

const addLocationFail = (state, _args) => state

const getLocations = (state, _args) => state

export const getLocationsSuccess = (state, { mappedLocations }) =>
  R.merge(state, {
    locations: mappedLocations
  })

const getLocationsFail = (state, _args) => state

const editLocation = (state, _args) => state

const editLocationSuccess = (state, _args) => state

const editLocationFail = (state, _args) => state

const { Types, Creators } = createActions({
  enableTeller: ['onFinish'],
  enableTellerSuccess: [''],
  enableTellerFail: ['error'],
  updateCompanyInformation: ['values'],
  updateCompanyInformationSuccess: [''],
  updateCompanyInformationFail: ['error'],
  updateCurrencyInformation: ['currency', 'onFinish'],
  updateCurrencyInformationSuccess: [''],
  updateCurrencyInformationFail: ['error'],
  addLocation: ['location', 'onFinish'],
  addLocationSuccess: [''],
  addLocationFail: ['error'],
  getLocations: [],
  getLocationsSuccess: ['mappedLocations'],
  getLocationsFail: ['error'],
  editLocation: ['location', 'onFinish'],
  editLocationSuccess: [''],
  editLocationFail: ['error']
})

const logout = () => initialState

const handlers = {
  [Types.ENABLE_TELLER]: enableTeller,
  [Types.ENABLE_TELLER_SUCCESS]: enableTellerSuccess,
  [Types.ENABLE_TELLER_FAIL]: enableTellerFail,
  [Types.UPDATE_COMPANY_INFORMATION]: updateCompanyInformation,
  [Types.UPDATE_COMPANY_INFORMATION_SUCCESS]: updateCompanyInformationSuccess,
  [Types.UPDATE_COMPANY_INFORMATION_FAIL]: updateCompanyInformationFail,
  [Types.UPDATE_CURRENCY_INFORMATION]: updateCurrencyInformation,
  [Types.UPDATE_CURRENCY_INFORMATION_SUCCESS]: updateCurrencyInformationSuccess,
  [Types.UPDATE_CURRENCY_INFORMATION_FAIL]: updateCurrencyInformationFail,
  [Types.ADD_LOCATION]: addLocation,
  [Types.ADD_LOCATION_SUCCESS]: addLocationSuccess,
  [Types.ADD_LOCATION_FAIL]: addLocationFail,
  [Types.GET_LOCATIONS]: getLocations,
  [Types.GET_LOCATIONS_SUCCESS]: getLocationsSuccess,
  [Types.GET_LOCATIONS_FAIL]: getLocationsFail,
  [Types.EDIT_LOCATION]: editLocation,
  [Types.EDIT_LOCATION_SUCCESS]: editLocationSuccess,
  [Types.EDIT_LOCATION_FAIL]: editLocationFail,
  [UsersTypes.USERS_LOGOUT]: logout
}

validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
