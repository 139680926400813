import { getJSON, postJSON } from 'src/services/lib/http'
import { normalizeAmountToBackend } from 'src/services/merchant/helpers'
import { fetchNonJSON } from 'src/services/lib/fetch'
import { format } from 'date-fns'
import { defaultFormat } from 'src/redux/chart/sagas'

export const sendFund = ({ address, amount, note, guid }, token) =>
  postJSON(
    '/merchant/debits',
    {
      address,
      amount: normalizeAmountToBackend(amount),
      note,
      guid,
      currency: 'BBD'
    },
    token
  )

export const requestPayment = ({
  amount,
  token,
  type,
  comment,
  customerEmail
}) =>
  postJSON(
    '/merchant/invoices',
    {
      price: normalizeAmountToBackend(amount),
      currency: 'BBD',
      type: type,
      comment: comment,
      customer_email: customerEmail
    },
    token
  )

export const withdraw = ({ amount, reference, method, guid }, token) =>
  postJSON(
    '/merchant/withdraw',
    {
      amount: normalizeAmountToBackend(amount),
      currency: 'BBD',
      reference,
      method,
      guid
    },
    token
  )

export const deposit = ({ amount, method, guid }, token) =>
  postJSON(
    '/merchant/deposit',
    {
      amount: normalizeAmountToBackend(amount),
      currency: 'BBD',
      method,
      guid
    },
    token
  )

export const cancelInvoice = ({ id, token }) =>
  postJSON(
    '/merchant/invoices/' + id,
    {
      status: 'canceled'
    },
    token
  )

export const refundInvoice = ({ id, token, guid }) =>
  postJSON(
    '/merchant/refunds',
    {
      invoiceId: id,
      guid
    },
    token
  )

export const getTransactions = ({ token, type }) =>
  getJSON('/merchant/transactions', { transaction_type: type }, token)

export const getWithdrawTransactions = ({ token, type }) =>
  getJSON('/merchant/transactions', { type }, token)

export const getInvoicesMerchant = ({ params, token }) =>
  getJSON('/merchant/invoices', params, token)

export const getInvoicesTeller = ({ params, token }) =>
  getJSON('/merchant/debits', params, token)

export const downloadCvvReport = ({ params, token }) =>
  getJSON(
    '/merchant/transactions.csv',
    generateReportPayload(params),
    token,
    fetchNonJSON
  )

export const getTransactionsForReports = ({ token, params }) =>
  getJSON('/merchant/transactions', generateReportPayload(params), token)

const generateReportPayload = params => ({
  start: params.startDate && format(params.startDate, defaultFormat),
  stop: params.endDate && format(params.endDate, defaultFormat),
  minamt: params.minimumAmount && params.minimumAmount * 100000000,
  maxamt: params.maximumAmount && params.maximumAmount * 100000000,
  employee: params.clerk.id,
  currency: params.currency.code,
  address: params.address,
  merchant_order_id: params.merchantOrderId,
  status: params.status.value,
  page: params.page + 1,
  limit: params.pageSize,
  transaction_type:
    params.typeOfTransaction.value === 'all'
      ? ''
      : params.typeOfTransaction.value,
  type:
    params.typeOfActivity.value === 'all' ? '' : params.typeOfActivity.value,
  id: params.invoiceNumber
})
