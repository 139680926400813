import React from 'react'
import { Grid, Paper, withStyles } from '@material-ui/core'
import { Formik, Form } from 'formik'
import EntryLayout from 'src/components/layouts/entry-layout'
import TextInput from 'src/components/forms/entry/text-input'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from 'src/redux'
import * as R from 'ramda'
import StyledButton from 'src/components/buttons/styledButton'

const InitialFormValues = { email: '' }
const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email cannot be blank')
    .email('This email is invalid')
})
const ForgotPassword = ({ classes, forgotPassword, navigate, width }) => {
  const onFormSubmit = ({ email }, _actions) => forgotPassword(email)
  return (
    <EntryLayout>
      <Grid container alignItems='center' justifyContent='center'>
        <Formik
          initialValues={InitialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={onFormSubmit}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit} className={classes.form}>
              <Grid container direction='column'>
                <Grid item xs>
                  <Paper>
                    <TextInput
                      placeholder='Email'
                      label='Email Address'
                      name='email'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Paper>
                </Grid>
                <Grid
                  item
                  container
                  xs
                  justifyContent='space-between'
                  className={classes.buttonsContainer}
                >
                  <Grid item xs={12} sm={4}>
                    <StyledButton
                      colorType='red'
                      fullWidth
                      type='button'
                      onClick={() => navigate('..')}
                      style={{ minWidth: 150 }}
                    >
                      Cancel
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StyledButton
                      colorType='blue'
                      fullWidth
                      style={{ minWidth: 150 }}
                      type='submit'
                    >
                      Reset Password
                    </StyledButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </EntryLayout>
  )
}

const styles = theme => ({
  form: {
    maxWidth: 500,
    width: '100%'
  },
  buttonsContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      '& div:first-child': {
        marginBottom: theme.spacing(1)
      }
    }
  }
})

const actionCreators = {
  forgotPassword: actions.users.usersForgotPassword
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(actionCreators, dispatch)

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(ForgotPassword)
