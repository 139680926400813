import helpers from 'src/redux/settings'
import { put, call, takeLatest, select } from 'redux-saga/effects'
import { actions } from 'src/redux'
import { getAuthToken } from 'src/tools/token'
import { fmtErrors } from 'src/redux/resources/helpers'
import {
  enableTellerData,
  updateCompanyInformationData,
  updateCurrencyInformationData,
  addLocationData,
  getLocationsData,
  editLocationData
} from 'src/services/merchant/settings'
import { getMerchantInfoSaga } from 'src/redux/resources/sagas'
import { formUpdatePayload, mapLocations } from 'src/redux/settings/helpers'

export function * enableTellerSaga ({ onFinish }) {
  try {
    const token = yield select(getAuthToken)
    const response = yield call(enableTellerData, { token })
    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'Teller enabled successfully',
          type: 'success'
        })
      )
      yield put(actions.settings.enableTellerSuccess())
      yield call(getMerchantInfoSaga)
      yield call(onFinish)
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * updateCompanyInformationSaga ({ values }) {
  try {
    const token = yield select(getAuthToken)
    const formedPayload = yield call(formUpdatePayload, values)
    const response = yield call(updateCompanyInformationData, {
      companyInfo: formedPayload,
      token
    })

    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'Company Information updated',
          type: 'success'
        })
      )
      yield call(getMerchantInfoSaga)
      yield put(actions.settings.updateCompanyInformationSuccess())
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * updateCurrencyInformationSaga ({ currency, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    const response = yield call(updateCurrencyInformationData, {
      currency,
      token
    })
    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'Currency Information updated',
          type: 'success'
        })
      )
      yield call(getMerchantInfoSaga)
      yield call(onFinish)
      yield put(actions.settings.updateCurrencyInformationSuccess())
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * addLocationSaga ({ location, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    yield call(addLocationData, {
      location,
      token
    })

    yield put(
      actions.notification.notificationShow({
        message: 'Location added successfully',
        type: 'success'
      })
    )
    yield put(actions.settings.addLocationSuccess())
    yield call(getLocationsSaga)
    yield call(onFinish)
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * getLocationsSaga () {
  try {
    const token = yield select(getAuthToken)
    const { locations } = yield call(getLocationsData, token)
    const mappedLocations = yield call(mapLocations, locations)

    yield put(actions.settings.getLocationsSuccess(mappedLocations))
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * editLocationSaga ({ location, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    yield call(editLocationData, {
      location,
      token
    })
    yield put(
      actions.notification.notificationShow({
        message: 'Location edited successfully',
        type: 'success'
      })
    )
    yield put(actions.settings.editLocationSuccess())
    yield call(getLocationsSaga)
    yield call(onFinish)
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export default function * root () {
  yield takeLatest(helpers.types.ENABLE_TELLER, enableTellerSaga)
  yield takeLatest(
    helpers.types.UPDATE_COMPANY_INFORMATION,
    updateCompanyInformationSaga
  )
  yield takeLatest(
    helpers.types.UPDATE_CURRENCY_INFORMATION,
    updateCurrencyInformationSaga
  )
  yield takeLatest(helpers.types.ADD_LOCATION, addLocationSaga)
  yield takeLatest(helpers.types.GET_LOCATIONS, getLocationsSaga)
  yield takeLatest(helpers.types.EDIT_LOCATION, editLocationSaga)
}
