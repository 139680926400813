import React from 'react'
import { Router, Redirect } from '@reach/router'
import SignIn from 'src/pages/sign-in'
import NotFound from 'src/pages/not-found'
import Overview from 'src/pages/overview'
import Reports from 'src/pages/reports'
import Transact from 'src/pages/transact/transact'
import ManageMembers from 'src/pages/manageMembers/manage-members'
import StaticPayments from 'src/pages/staticPayments/static-payments'
import ResponsiveDrawer from 'src/components/responsive-drawer'
import SignUp from 'src/pages/signup'
import PrivacyPolicy from 'src/pages/signup/privacy-policy'
import TermsAndConditions from 'src/pages/signup/terms-and-conditions'
import Verifications from 'src/pages/verifications/verifications'
import VerifyBusiness from 'src/pages/verifications/document/verify-your-business'
import VerifyBasic from 'src/pages/verifications/basic/verify'
import General from 'src/pages/settings'
import ManageApi from 'src/pages/manage-api'
import ForgotPassword from 'src/pages/forgot-password'
import ResetPassword from 'src/pages/reset-password'
import Dashboard from 'src/pages/dashboard'
import ActivateAccount from 'src/pages/activate-account'
import { BasePath } from 'src/tools/navigate-wrapper'
import GettingStarted from 'src/pages/getting-started'
// import MerchantFeatures from 'src/pages/merchant-features'
// eslint-disable-next-line
import { getSnippet_paymentScript } from 'src/services/merchant/snippet'
import { withStyles } from '@material-ui/core'
import EntryNotFound from 'src/pages/entry-not-found'
// import flags from 'src/tools/featureFlags'

const routes = ({ classes }) => (
  <div className={classes.container}>
    {getSnippet_paymentScript('', true)}
    <Router primary={false} basepath={BasePath}>
      <ResponsiveDrawer path='dashboard/*' />
    </Router>

    <Router className={classes.paths} path='/' basepath={BasePath}>
      <Redirect from='signup' to='/signup/setup' />
      <SignIn path='/' />
      <ForgotPassword path='forgot' />
      <ResetPassword path='reset/:resetToken' />
      <SignUp path='signup/:step' />
      <PrivacyPolicy path='privacy-policy' />
      <TermsAndConditions path='terms-and-conditions' />
      <ActivateAccount path='confirm/:activationToken' />
      <Dashboard path='dashboard'>
        <Overview path='overview' />
        <Reports path='reports' />
        <Transact path='transact' />
        <ManageMembers path='manage-members' />
        <StaticPayments path='static' />
        <Verifications path='verifications' />
        <VerifyBusiness path='verify-business' />
        <VerifyBasic path='verify-basic' />
        <GettingStarted path='getting-started' />
        <General path='settings' />
        <ManageApi path='manage-api' />
        {/* {flags.showTiers && <MerchantFeatures path='merchant-features' />} */}
        <NotFound default />
      </Dashboard>
      <EntryNotFound default />
    </Router>
  </div>
)

const styles = theme => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    width: '100vw'
  },
  paths: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden'
  },
  sidebar: {
    width: 220
  }
})

export default withStyles(styles)(routes)
