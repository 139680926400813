import { postJSON, getJSON } from 'src/services/lib/http'

export const login = (email, password) =>
  postJSON('/merchant/login', { email, password }, null)

export const resendActivationEmail = (email, token) =>
  postJSON('/merchant/resend-verification', { email }, token)

export const activateAccount = activationToken =>
  getJSON(`/merchant/verify/${activationToken}`)
