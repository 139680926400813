import React, { useEffect } from 'react'
import { Card, withStyles } from '@material-ui/core'
import Page from 'src/components/page'
import ReportForm from 'src/pages/reports/reportForm'
import ReportTable from 'src/pages/reports/reportTable'
import reportsRedux from 'src/redux/reports'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { actions } from 'src/redux'
import EmptyListComponent from 'src/pages/manageAPI/emptyListComponent'

const results = (reportNeverRun, resultsExist) => {
  if (!reportNeverRun) {
    if (resultsExist) {
      return <ReportTable />
    } else {
      return <EmptyListComponent message={'No Results Found'} />
    }
  } else {
    return null
  }
}

const reports = ({ classes, clearReport, reportNeverRun, resultsExist }) => {
  useEffect(() => {
    clearReport()
  }, [])

  return (
    <Page title='Reports'>
      <Card direction='column' className={classes.card}>
        <ReportForm />
      </Card>
      {results(reportNeverRun, resultsExist)}
    </Page>
  )
}

const styles = theme => ({
  card: {
    flex: 1,
    padding: theme.spacing(1)
  }
})

const mapStateToProps = state => ({
  reportNeverRun: reportsRedux.selectors.reportNeverRun(state),
  resultsExist: reportsRedux.selectors.resultsExist(state)
})

const mapDispatchToProps = dispatch => ({
  clearReport: () => dispatch(actions.reports.clearReport())
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(reports)
