import { getJSON, postJSON, postFormData } from 'src/services/lib/http'

export const getMerchantBands = () =>
  getJSON('/compliance/merchant/bands', {}, null)

export const basicVerification = (
  token,
  {
    email,
    ownerFirstName,
    ownerLastName,
    estimatedAccountActivity,
    businessTradingName,
    businessAddress,
    businessPhoneNumber,
    businessType,
    country,
    legalBusinessName,
    parish
  }
) =>
  postJSON(
    '/compliance/merchant/kyclevel1',
    {
      ac: ' ',
      aco: country,
      afn: ownerFirstName,
      aln: ownerLastName,
      amn: legalBusinessName,
      aph: businessPhoneNumber,
      asn: businessAddress,
      ast: parish,
      ataxid: ' ',
      az: ' ',
      businesstype: businessType,
      dba: businessTradingName,
      email,
      limitBandId: estimatedAccountActivity,
      pbc: '.',
      title: ' ',
      website: ' '
    },
    token
  )

export const prepareDocumentVerification = (countryCode, token) =>
  postJSON('/compliance/merchant/kyclevel2', { country: countryCode }, token)

export const uploadUserDocumentation = (payload, token) =>
  postFormData('/compliance/merchant/docs', payload, token)

export const getVerificationData = token =>
  getJSON('/compliance/merchant/verification', {}, token)

export const getBasicInfo = token =>
  getJSON('/compliance/merchant/info', {}, token)

export const uploadExecutiveDocumentation = (payload, token) =>
  postJSON('/compliance/merchant/verification/ancillaries', payload, token)
