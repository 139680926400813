import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import { validateReduxHandlers } from 'src/redux/helpers'
import { createSelector } from 'reselect'

const STATE_KEY = 'notification'
// This store is not cleaned on logout. Don't left sensitive information in here.
const initialState = {
  open: false,
  message: undefined,
  type: 'success'
}

const selectors = {
  openNotificationState: createSelector(
    R.path([STATE_KEY, 'open']),
    R.identity
  ),
  notificationMessage: createSelector(
    R.path([STATE_KEY, 'message']),
    R.identity
  ),
  notificationType: createSelector(
    R.path([STATE_KEY, 'type']),
    R.identity
  )
}

const openToFalse = state =>
  R.merge(state, {
    open: false,
    message: undefined
  })

const openToTrue = (state, { message: { message, type } }) =>
  R.merge(state, {
    open: true,
    type: type,
    message: message
  })

const { Types, Creators } = createActions({
  notificationHide: ['hideMessage'],
  notificationShow: ['message', 'type']
})

const handlers = {
  [Types.NOTIFICATION_HIDE]: openToFalse,
  [Types.NOTIFICATION_SHOW]: openToTrue
}

validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
