import React from 'react'

const useDocumentTitle = title => {
  const defaultTitle = 'mMoney Merchant'
  const currentTitle = title ? `${title} - ${defaultTitle}` : defaultTitle

  React.useEffect(() => {
    document.title = currentTitle

    return () => (document.title = defaultTitle)
  }, [title])
}

export default useDocumentTitle
