import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Divider from 'src/components/divider'
import PropTypes from 'prop-types'
import AddEditButton from 'src/components/buttons/addEditButton'

export const ManageResourcesHeader = ({
  classes,
  onAddEmployeeClick,
  onAddRoleClick,
  onEditRoleClick
}) => (
  <Grid
    container
    direction='row'
    justifyContent='flex-end'
    spacing={2}
    className={classes.header}
  >
    <Grid item>
      <AddEditButton onClick={onAddEmployeeClick}>+ Add Employee</AddEditButton>
    </Grid>
    <Grid item>
      <AddEditButton onClick={onAddRoleClick}>+ Add role</AddEditButton>
    </Grid>
    <Grid item>
      <AddEditButton onClick={onEditRoleClick}>+ Edit role</AddEditButton>
    </Grid>
  </Grid>
)

ManageResourcesHeader.propTypes = {
  onAddEmployeeClick: PropTypes.func.isRequired,
  onAddRoleClick: PropTypes.func.isRequired,
  onEditRoleClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export const Header = ({ classes }) => (
  <>
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      className={classes.whiteCardHeader}
    >
      <Grid item xs={4}>
        <Typography variant='button'>NAME</Typography>
      </Grid>
      <Grid item>
        <Typography variant='button'>ROLES</Typography>
      </Grid>
    </Grid>
    <Grid item>
      <Divider />
    </Grid>
  </>
)

Header.propTypes = {
  classes: PropTypes.object.isRequired
}
