import PropTypes from 'prop-types'

const FormikPropTypes = PropTypes.shape({
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
})

export default FormikPropTypes
