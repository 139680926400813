import PropTypes from 'prop-types'

export const currenciesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    active: PropTypes.bool.isRequired,
    available: PropTypes.number.isRequired,
    availableFloat: PropTypes.string,
    code: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    maxDeposit: PropTypes.number.isRequired,
    maxDepositFloat: PropTypes.string.isRequired,
    maxWithdraw: PropTypes.number.isRequired,
    maxWithdrawFloat: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pending: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        amount_float: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        transaction_id: PropTypes.number,
        type: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired
      })
    ),
    prefix: PropTypes.string.isRequired,
    refundFee: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    totalFloat: PropTypes.string.isRequired
  })
)

export const currenciesForSelectPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })
).isRequired

export const transactionItemType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired
})

export const transactionListType = PropTypes.arrayOf(transactionItemType)
