import React from 'react'
import { Formik } from 'formik'
import { number, object, string } from 'yup'
import { Grid, withStyles } from '@material-ui/core'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { actions } from 'src/redux'
import FormikTextField from 'src/components/formik/FormikTextField'
import startAdornment from 'src/components/inputFields/startAdornment'
import PropTypes from 'prop-types'
import Label from 'src/components/forms/label'
import StyledButton from 'src/components/buttons/styledButton'

const validationSchema = object().shape({
  contact: string().required('Contact details are required'),
  amount: number()
    .moreThan(0, 'Must be greater than 0')
    .required('Amount is required')
})

const initialValues = { contact: '', amount: '', note: '' }

const handleSubmit = (onSend, onCancel) => (values, actions) => {
  const onFinish = () => {
    actions.setSubmitting(false)
  }

  onSend(
    {
      address: values.contact,
      amount: values.amount,
      note: values.note
    },
    onFinish,
    onCancel
  )
}

export const SendForm = ({ onConfirmSend, onCancel, classes }) => (
  <Grid data-testid='sendForm'>
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      onSubmit={handleSubmit(onConfirmSend, onCancel)}
    >
      {({ handleSubmit, isSubmitting, ...formikProps }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction='column' spacing={1}>
            <Grid item>
              <Label>CONTACT</Label>
              <FormikTextField
                name='contact'
                type='text'
                placeholder='Enter wallet address'
                variant='outlined'
                fullWidth
                formik={formikProps}
              />
            </Grid>
            <Grid item>
              <Label>AMOUNT</Label>
              <FormikTextField
                name='amount'
                type='number'
                placeholder='0.00'
                variant='outlined'
                fullWidth
                step={0.01}
                InputProps={{ ...startAdornment('$'), step: 0.01 }}
                formik={formikProps}
              />
            </Grid>
            <Grid item>
              <Label>NOTE</Label>
              <FormikTextField
                name='note'
                placeholder='Enter optional note'
                type='text'
                variant='outlined'
                fullWidth
                formik={formikProps}
              />
            </Grid>
            <Grid
              container
              direction='row'
              spacing={1}
              justifyContent='flex-end'
              className={classes.buttons}
            >
              <Grid item>
                <StyledButton onClick={onCancel} colorType={'red'}>
                  CANCEL
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  type='submit'
                  disabled={isSubmitting}
                  colorType={'green'}
                  data-testid='submitSendForm'
                >
                  CONFIRM
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  </Grid>
)

SendForm.propTypes = {
  onConfirmSend: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  buttons: {
    padding: theme.spacing(1)
  },
  inputDescription: {
    fontWeight: 600,
    fontSize: '0.75rem'
  }
})

const mapDispatchToProps = dispatch => ({
  onConfirmSend: (payload, onFinish, onCancel) =>
    dispatch(
      actions.transactions.transactionsSendFund(payload, onFinish, onCancel)
    )
})

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(SendForm)
