import React, { useState } from 'react'

import {
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  Typography,
  withStyles
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddEditButton from 'src/components/buttons/addEditButton'
import navigate from 'src/tools/navigate-wrapper'

const handleChange = (panel, setExpanded) => (_event, isExpanded) =>
  setExpanded(isExpanded ? panel : false)

const VerificationXS = ({
  adnotationList,
  classes,
  statementsList,
  stepOneList,
  stepTwoList
}) => {
  const [expanded, setExpanded] = useState('one')

  return (
    <>
      <ExpansionPanel
        expanded={expanded === 'one'}
        onChange={handleChange('one', setExpanded)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1bh-content"
          // id="panel1bh-header"
        >
          Basic Verification - Enter your personal details
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {/* <StepOne /> */}
          <Grid container direction='column'>
            <Grid item>
              <Typography>
                <strong>SEND LIMIT</strong>
              </Typography>
              <Typography paragraph>
                {'500 USD or equivalent per month'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>REQUIREMENTS</strong>
              </Typography>
              <ul className={classes.list}>
                {stepOneList.map(({ text }, index) => (
                  <li key={index}>
                    <Typography>{text}</Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid
              className={classes.buttonContainer}
              container
              item
              justifyContent='flex-end'
            >
              <AddEditButton onClick={() => navigate('verify-basic')}>
                <Typography>Edit</Typography>
                <Icon className={classes.rightIcon}>send</Icon>
              </AddEditButton>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === 'two'}
        onChange={handleChange('two', setExpanded)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1bh-content"
          // id="panel1bh-header"
        >
          Document Verification - Upload the required documents
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction='column'>
            <Grid item>
              <Typography>
                <strong>SEND LIMIT</strong>
              </Typography>
              <Typography paragraph>
                {'10,000 USD or equivalent per month'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>REQUIREMENTS</strong>
              </Typography>
              <ul className={classes.list}>
                {stepTwoList.map(({ text, adnotation }, index) => (
                  <li key={index}>
                    <Typography>
                      {text} <sup>{adnotation}</sup>
                    </Typography>
                  </li>
                ))}
              </ul>
              <Grid item className={classes.marginLeft20}>
                {statementsList.map((item, index) => (
                  <Grid item key={index}>
                    <Typography className={classes.content}>{item}</Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                {adnotationList.map(({ adnotation, text }, index) => (
                  <Typography component='div' key={index} variant='caption'>
                    <sup>{adnotation}</sup>
                    {text}
                  </Typography>
                ))}
              </Grid>
            </Grid>
            <Grid
              className={classes.buttonContainer}
              container
              item
              justifyContent='flex-end'
            >
              <AddEditButton onClick={() => navigate('verify-business')}>
                <Typography>Get Verified</Typography>
                <Icon className={classes.rightIcon}>send</Icon>
              </AddEditButton>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}

const styles = theme => ({
  buttonContainer: {
    marginTop: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  list: {
    paddingLeft: theme.spacing(2.5),
    marginBottom: theme.spacing(0.5)
  },
  marginLeft20: {
    marginLeft: 20
  }
})

export default withStyles(styles)(VerificationXS)
