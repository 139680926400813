import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import Page from 'src/components/page'

const notFound = ({ classes }) => (
  <Page showBack title='Page Not Found'>
    <Grid
      container
      justifyContent='center'
      direction='column'
      className={classes.container}
    >
      <Grid item className={classes.message}>
        404: Page Not Found
      </Grid>
    </Grid>
  </Page>
)

const styles = theme => ({
  message: {
    color: theme.palette.primary.main,
    fontSize: 35,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: theme.spacing(10)
  },
  container: {
    minHeight: '350px'
  }
})

export default withStyles(styles)(notFound)
