import * as Yup from 'yup'
import * as R from 'ramda'
import { validationRules as businessValidationRules } from 'src/components/forms/verification/business-information'

import { validationRules as ownerValidationRules } from 'src/components/forms/verification/owner-information'

export const passwordValidationSchema = Yup.string()
  .trim()
  .required('Password cannot be blank')
  .min(6, 'A password must be at least 6 characters in length')
  .matches(/[A-Z]/, 'A password must contain at least one uppercase letter')
  .matches(/[0-9]/, 'A password must contain at least one number')
  .matches(
    /[-+_!@#$%^&*.,?]/,
    'A password must contain at least one special character \u000A (- + _ ! @ # $ % ^ & * . , ?)'
  )

export const ValidationSchemas = {
  setup: Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Email cannot be blank')
      .email('This email is invalid'),
    password: passwordValidationSchema,
    passwordConfirmation: Yup.string()
      .trim()
      .required('Confirmation cannot be blank')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    legalAcceptance: Yup.boolean().oneOf(
      [true],
      'Must Accept Terms and Conditions'
    )
  }),
  'owner-info': Yup.object().shape({
    ...ownerValidationRules
  }),
  'business-info': Yup.object().shape({
    ...businessValidationRules
  })
}

export const SetTouchFields = (fields, setFieldTouched) =>
  R.forEach(field => setFieldTouched(field, true), fields)

export const prepareInfo = accountInfo => ({
  ...accountInfo,
  estimatedAccountActivity: accountInfo.estimatedAccountActivity.value,
  businessCategory: accountInfo.businessCategory.value,
  businessType: accountInfo.businessType.value,
  country: accountInfo.country.code,
  parish: accountInfo.parish.value
})
