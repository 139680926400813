import React from 'react'
import { Button, Grid, withStyles } from '@material-ui/core'
import { Link } from '@reach/router'

const notFound = ({ classes }) => (
  <Grid
    container
    alignItems='center'
    justifyContent='center'
    direction='column'
  >
    <Grid item className={classes.message}>
      404: Page Not Found
    </Grid>
    <Grid item>
      <Button fullWidth style={{ minWidth: 150 }} type='submit'>
        <Link to='/'>Return to Login</Link>
      </Button>
    </Grid>
  </Grid>
)

const styles = theme => ({
  message: {
    color: theme.palette.primary.main,
    fontSize: 35,
    fontWeight: 'bold'
  }
})

export default withStyles(styles)(notFound)
