import React from 'react'
import { Card, Grid, Typography, withStyles } from '@material-ui/core'
import { Link } from '@reach/router'

import androidImage from '../assets/images/download-android.png'
import iosImage from '../assets/images/download-ios.png'

const footer = ({ classes }) => {
  return (
    <Card className={classes.footer}>
      <Grid container direction='column' className={classes.info}>
        <Grid item>
          <Typography className={classes.infoTitle}>Need Help?</Typography>
        </Grid>
        <Grid item className={classes.infoText}>
          <Typography>
            Try running through our{' '}
            <Link to='/dashboard/getting-started'>Getting Started</Link>{' '}
            tutorials or{' '}
            <a title='support@mmoneybb.com' href='mailto:support@mmoneybb.com'>
              Contact Support
            </a>
            .
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction='column' className={classes.info}>
        <Grid item className={classes.infoTitle}>
          <Typography>Download the app</Typography>
          <Grid item className={classes.infoText}>
            <Typography>Access your account on the go</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction='row' className={classes.info}>
        <Grid item>
          <a
            href='https://play.google.com/store/apps/details?id=com.mmoney.merchant'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              className={classes.badge}
              src={androidImage}
              alt='Google Play Store'
            />
          </a>
        </Grid>
        <Grid item>
          <a
            href='https://itunes.apple.com/us/app/mmoney-merchant-by-bitt/id1321157658?ls=1&mt=8'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              className={classes.badge}
              src={iosImage}
              alt='iTunes App Store'
            />
          </a>
        </Grid>
      </Grid>
    </Card>
  )
}

const styles = theme => ({
  footer: {
    flex: 1,
    padding: 15
  },
  info: {
    paddingBottom: 8
  },
  infoTitle: {
    fontWeight: 'bold'
  },
  infoText: {
    color: '#969696',
    '> a': {
      color: '#3288df'
    }
  },
  badge: {
    height: 40,
    marginRight: 8
  }
})

export default withStyles(styles)(footer)
