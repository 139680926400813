import React, { useEffect, useState } from 'react'
import {
  Grid,
  Card,
  Typography,
  List,
  withStyles,
  useMediaQuery
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ApiKeyItem from 'src/pages/manageAPI/apiKeyItem'
import EmptyListComponent from 'src/pages/manageAPI/emptyListComponent'
import PropTypes from 'prop-types'
import AddKey from 'src/pages/manageAPI/addKeyDialog'
import ConfirmDialog from 'src/pages/manageAPI/confirmDialog'
import { actions } from 'src/redux'
import * as R from 'ramda'
import { connect } from 'react-redux'
import apiRedux from 'src/redux/api'
import Divider from 'src/components/divider'
import AddEditButton from 'src/components/buttons/addEditButton'
import { useTheme } from '@material-ui/core/styles'

export const GenerateKeys = ({
  classes,
  requestAddApiKey,
  requestGetApiKeys,
  apiKeyList,
  onDeleteKey,
  onTestApiClick
}) => {
  useEffect(() => {
    requestGetApiKeys()
    return () => {}
  }, [])

  const [addOnlinePaymentAPIKeyOpen, setAddOnlinePaymentAPIKeyOpen] = useState(
    false
  )
  const [addOtherAPIKeyOpen, setAddOtherAPIKeyOpen] = useState(false)
  const [deleteKeyOpen, setDeleteKeyOpen] = useState(false)
  const [deleteKeyState, setDeleteKeyState] = useState({ title: '', id: '' })
  const theme = useTheme()
  const showHeader = useMediaQuery(theme.breakpoints.up('sm'))

  const handleAddOnlinePaymentClick = () => {
    setAddOnlinePaymentAPIKeyOpen(true)
  }
  const handleCancelOnlinePaymentClick = () => {
    setAddOnlinePaymentAPIKeyOpen(false)
  }
  const handleAddOtherAPIKeyClick = () => {
    setAddOtherAPIKeyOpen(true)
  }
  const handleCancelOtherAPIKeyClick = () => {
    setAddOtherAPIKeyOpen(false)
  }
  const handleDeleteKeyClick = apiKey => () => {
    setDeleteKeyOpen(true)
    setDeleteKeyState({ title: apiKey.title, id: apiKey.id })
  }
  const handleCancelDeleteKeyClick = () => {
    setDeleteKeyOpen(false)
  }

  const onDelete = () => {
    onDeleteKey(deleteKeyState.id, handleCancelDeleteKeyClick)
  }

  return (
    <Grid container direction='column'>
      <Grid container>
        <Typography>
          Generate an API key to start your set up for online payments.
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent='flex-end'
        className={classes.buttons}
        spacing={2}
      >
        <Grid item>
          <AddEditButton onClick={handleAddOnlinePaymentClick}>
            <AddIcon /> Add API key for Online Payments
          </AddEditButton>
        </Grid>
        <Grid item>
          <AddEditButton onClick={handleAddOtherAPIKeyClick}>
            <AddIcon /> Add Other API key
          </AddEditButton>
        </Grid>
      </Grid>
      <Card direction='column' className={classes.card}>
        {showHeader && (
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            className={classes.header}
          >
            <Grid item xs={3}>
              <Typography variant='button'>TITLE</Typography>
            </Grid>
            <Grid item>
              <Typography variant='button'>KEY</Typography>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Divider />
        </Grid>
        <Grid>
          {apiKeyList && apiKeyList.length ? (
            <List>
              {apiKeyList.map(apiKey => (
                <ApiKeyItem
                  {...apiKey}
                  key={apiKey.id}
                  handleDeleteKeyClick={handleDeleteKeyClick}
                  onTestApiClick={onTestApiClick}
                />
              ))}
            </List>
          ) : (
            <EmptyListComponent message={'No items in the list'} />
          )}
        </Grid>
        <AddKey
          open={addOnlinePaymentAPIKeyOpen}
          onCancel={handleCancelOnlinePaymentClick}
          onAddKey={requestAddApiKey}
          isOnlinePayment
        />
        <AddKey
          open={addOtherAPIKeyOpen}
          onCancel={handleCancelOtherAPIKeyClick}
          onAddKey={requestAddApiKey}
          isOnlinePayment={false}
        />
        <ConfirmDialog
          open={deleteKeyOpen}
          onCancel={handleCancelDeleteKeyClick}
          onConfirm={onDelete}
        >
          Are You sure you wish to delete API Key <br />
          <b>{deleteKeyState.title}</b>?
        </ConfirmDialog>
      </Card>
    </Grid>
  )
}

GenerateKeys.propTypes = {
  classes: PropTypes.object.isRequired,
  requestAddApiKey: PropTypes.func.isRequired,
  requestGetApiKeys: PropTypes.func.isRequired,
  apiKeyList: PropTypes.arrayOf(
    PropTypes.shape({
      apiKey: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      receiveFunds: PropTypes.bool,
      sendRefunds: PropTypes.bool,
      title: PropTypes.string.isRequired
    })
  ),
  onDeleteKey: PropTypes.func.isRequired,
  onTestApiClick: PropTypes.func.isRequired
}

GenerateKeys.defaultProps = {
  receiveFunds: false,
  sendRefunds: false
}

const styles = theme => ({
  card: {
    flex: 1,
    borderRadius: 0
  },
  header: {
    padding: theme.spacing(3, 4)
  },
  buttons: {
    padding: theme.spacing(2)
  }
})

const mapDispatchToProps = dispatch => ({
  requestAddApiKey: (values, onFinish) =>
    dispatch(actions.api.addApiKey(values, onFinish)),
  requestGetApiKeys: () => dispatch(actions.api.getApiKeys()),
  onDeleteKey: (id, onCancel) =>
    dispatch(actions.api.deleteApiKey(id, onCancel))
})

const mapStateToProps = state => ({
  apiKeyList: apiRedux.selectors.apiKeyList(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GenerateKeys)
