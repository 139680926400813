import React from 'react'
import { Button, Grid, Typography, withStyles } from '@material-ui/core'
import * as R from 'ramda'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import transactionsRedux from 'src/redux/transactions'
import FlagImage from 'src/components/FlagImage'

export const QRpayment = ({
  classes,
  qrCodeValue,
  resetInvoice,
  lastInvoice = {}
}) => (
  <Grid container direction='column' className={classes.containers}>
    <Grid container direction='row' justifyContent='space-around'>
      <Grid item>
        <Grid container item direction='column' alignItems='center' spacing={3}>
          <Grid item data-testid='flagImage'>
            <FlagImage code={lastInvoice.currency} flagClass={classes.flag} />
          </Grid>
          <Grid item>
            <Typography>
              <b>$ </b>
              {lastInvoice && lastInvoice.priceFloat} BBD
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Paid {lastInvoice && lastInvoice.paidFloat}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <QRCode value={qrCodeValue} size={200} data-testid='QRcode' />
      </Grid>
    </Grid>
    <Grid container justifyContent='center' className={classes.buttons}>
      <Grid item>
        <Button color='primary' variant='contained' onClick={resetInvoice}>
          OKAY
        </Button>
      </Grid>
    </Grid>
  </Grid>
)

QRpayment.propTypes = {
  qrCodeValue: PropTypes.string.isRequired,
  resetInvoice: PropTypes.func.isRequired,
  lastInvoice: PropTypes.object,
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  buttons: { paddingTop: theme.spacing(1) },
  containers: {
    padding: theme.spacing(3)
  },
  flag: { height: 50, width: 50 }
})

const mapDispatchToProps = dispatch => ({
  onConfirmRequest: payload =>
    dispatch(actions.transactions.transactionsRequestPayment(payload)),
  resetInvoice: () => dispatch(actions.transactions.transactionsResetInvoice())
})

const mapStateToProps = state => ({
  qrCodeValue: transactionsRedux.selectors.qrCodeValue(state),
  lastInvoice: transactionsRedux.selectors.lastInvoice(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QRpayment)
