import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogTitle
} from '@material-ui/core'
import RoleForm from 'src/pages/manageMembers/roleForm'
import * as R from 'ramda'
import Select from 'src/components/forms/entry/Select'
import PropTypes from 'prop-types'
import { roleProptype } from 'src/pages/manageMembers/proptypes'

export const EditRole = ({ open, classes, roles, onCancel, ...formProps }) => {
  const [role, setRole] = useState()

  useEffect(() => {
    roles && roles[0] && setRole(roles[0])
    return () => {}
  }, [roles])
  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        <b>Edit Role</b>
      </DialogTitle>
      <Grid item className={classes.selectHolder}>
        <Typography variant='caption' className={classes.titleHeader}>
          SELECT A SAVED ROLE TO START EDITING
        </Typography>
        <Select
          name='role'
          options={roles}
          placeholder='Role'
          value={role}
          onChange={value => setRole(value)}
        />
      </Grid>
      <RoleForm
        {...formProps}
        role={role}
        onCancel={() => {
          onCancel()
          setRole()
        }}
      />
    </Dialog>
  )
}

EditRole.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEditRole: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(roleProptype).isRequired
}

const styles = theme => ({
  title: {
    paddingBottom: 0
  },
  titleHeader: {
    fontWeight: 600
  },
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  },
  cancelButton: {
    backgroundColor: theme.palette.buttons.red,
    color: theme.palette.lights.white
  },
  editButton: {
    backgroundColor: theme.palette.buttons.green,
    color: theme.palette.lights.white
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  },
  container: {
    padding: '0 24px 0 24px'
  },
  selectHolder: {
    padding: '0 34px'
  },
  content: {
    paddingBottom: '40px'
  }
})

export default R.pipe(withStyles(styles))(EditRole)
