import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import { validateReduxHandlers } from 'src/redux/helpers'
import { createSelector } from 'reselect'
import usersRedux from 'src/redux/users'

const { types: UsersTypes } = usersRedux

const STATE_KEY = 'api'
const initialState = {
  apiKeyList: [],
  webHooksList: []
}

const selectors = {
  onlinePaymentApiKeys: createSelector(
    R.pipe(
      R.path([STATE_KEY, 'apiKeyList']),
      R.filter(R.propEq('receiveFunds', true))
    ),
    R.identity
  ),
  apiKeyList: createSelector(
    R.path([STATE_KEY, 'apiKeyList']),
    R.identity
  ),
  webHooksList: createSelector(
    R.path([STATE_KEY, 'webHooksList']),
    R.identity
  )
}

const addApiKey = (state, _args) => state

const addApiKeySuccess = (state, _args) => state

const addApiKeyFail = (state, _args) => state

const getApiKeys = (state, _args) => state

const getApiKeysSuccess = (state, { apiKeyList }) =>
  R.merge(state, {
    apiKeyList
  })

const getApiKeysFail = (state, _args) => state

const deleteApiKey = (state, _args) => state

const deleteApiKeySuccess = (state, _args) => state

const deleteApiKeyFail = (state, _args) => state

const addWebHook = (state, _args) => state

const addWebHookSuccess = (state, _args) => state

const addWebHookFail = (state, _args) => state

const getWebHooks = (state, _args) => state

const getWebHooksSuccess = (state, { webHooksList }) =>
  R.merge(state, {
    webHooksList
  })

const getWebHooksFail = (state, _args) => state

const deleteWebHook = (state, _args) => state

const deleteWebHookSuccess = (state, _args) => state

const deleteWebHookFail = (state, _args) => state

const connectWebsocket = (state, _args) => state

const { Types, Creators } = createActions({
  addApiKey: ['apiKey', 'onFinish'],
  addApiKeySuccess: [],
  addApiKeyFail: ['error'],
  getApiKeys: [],
  getApiKeysSuccess: ['apiKeyList'],
  getApiKeysFail: ['error'],
  deleteApiKey: ['id', 'onCancel'],
  deleteApiKeySuccess: [],
  deleteApiKeyFail: ['error'],
  addWebHook: ['webhook', 'onFinish'],
  addWebHookSuccess: [],
  addWebHookFail: ['error'],
  getWebHooks: [],
  getWebHooksSuccess: ['webHooksList'],
  getWebHooksFail: ['error'],
  deleteWebHook: ['id', 'onCancel'],
  deleteWebHookSuccess: [],
  deleteWebHookFail: ['error'],
  connectWebsocket: ['companyId', 'userId']
})

const logout = () => initialState

const handlers = {
  [Types.ADD_API_KEY]: addApiKey,
  [Types.ADD_API_KEY_SUCCESS]: addApiKeySuccess,
  [Types.ADD_API_KEY_FAIL]: addApiKeyFail,
  [Types.GET_API_KEYS]: getApiKeys,
  [Types.GET_API_KEYS_SUCCESS]: getApiKeysSuccess,
  [Types.GET_API_KEYS_FAIL]: getApiKeysFail,
  [Types.DELETE_API_KEY]: deleteApiKey,
  [Types.DELETE_API_KEY_SUCCESS]: deleteApiKeySuccess,
  [Types.DELETE_API_KEY_FAIL]: deleteApiKeyFail,
  [Types.ADD_WEB_HOOK]: addWebHook,
  [Types.ADD_WEB_HOOK_SUCCESS]: addWebHookSuccess,
  [Types.ADD_WEB_HOOK_FAIL]: addWebHookFail,
  [Types.GET_WEB_HOOKS]: getWebHooks,
  [Types.GET_WEB_HOOKS_SUCCESS]: getWebHooksSuccess,
  [Types.GET_WEB_HOOKS_FAIL]: getWebHooksFail,
  [Types.DELETE_WEB_HOOK]: deleteWebHook,
  [Types.DELETE_WEB_HOOK_SUCCESS]: deleteWebHookSuccess,
  [Types.DELETE_WEB_HOOK_FAIL]: deleteWebHookFail,
  [Types.CONNECT_WEBSOCKET]: connectWebsocket,
  [UsersTypes.USERS_LOGOUT]: logout
}

validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
