import { compareDesc, parseISO } from 'date-fns'

export const mapApiKeys = apiKeys =>
  apiKeys
    .sort((a, b) =>
      compareDesc(parseISO(a.inserted_at), parseISO(b.inserted_at))
    )
    .map(key => ({
      title: key.title,
      id: key.id,
      apiKey: key.key,
      receiveFunds: key.receive_funds,
      sendRefunds: key.send_refunds
    }))

export const mapWebhooks = webhooks =>
  webhooks
    .sort((a, b) =>
      compareDesc(parseISO(a.inserted_at), parseISO(b.inserted_at))
    )
    .map(key => ({
      url: key.url,
      id: key.id,
      title: key.title
    }))
