import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import {
  validateReduxHandlers,
  generateDefaultHandler
} from 'src/redux/helpers'
import { createSelector } from 'reselect'
import usersRedux from 'src/redux/users'

const { types: UsersTypes } = usersRedux

const STATE_KEY = 'compliance'

const initialState = {
  verification: {},
  basicInfo: {}
}

const verificationSelectors = {
  completeVerifications: createSelector(
    R.pathOr('', [STATE_KEY, 'verification', 'complete']),
    R.identity
  ),
  pendingVerifications: createSelector(
    R.pathOr('', [STATE_KEY, 'verification', 'pending']),
    R.identity
  )
}

const selectors = {
  ...verificationSelectors,
  hasVerificationReference: createSelector(
    verificationSelectors.completeVerifications,
    verificationSelectors.pendingVerifications,
    (complete, pending) =>
      complete.level === 2 || (pending.level === 2 && pending.ref !== '')
  ),
  verificationReference: createSelector(
    verificationSelectors.completeVerifications,
    verificationSelectors.pendingVerifications,
    (complete, pending) => {
      if (complete.level === 2) return complete.ref
      if (pending.level === 2 && pending.ref !== '') {
        return pending.ref
      }
      return undefined
    }
  ),
  countryCode: createSelector(
    R.pathOr('', [STATE_KEY, 'basicInfo', 'country']),
    R.identity
  ),
  basicInfo: createSelector(
    R.pathOr('', [STATE_KEY, 'basicInfo']),
    R.identity
  )
}

const { Types, Creators } = createActions({
  getBasicInfo: [],
  getBasicInfoSuccess: ['basicInfo'],
  getVerificationData: [],
  getVerificationDataSuccess: ['count', 'limits', 'pending', 'verified'],
  documentVerification: ['companyDocuments', 'effect'],
  basicVerification: ['basicInfo', 'effect']
})

const getBasicInfo = generateDefaultHandler()

const getBasicInfoSuccess = (state, { basicInfo }) =>
  R.assoc('basicInfo', basicInfo, state)

const getVerificationData = generateDefaultHandler()

const getVerificationDataSuccess = (
  state,
  { verified, pending, count, limits }
) =>
  R.evolve({
    verification: R.merge(R.__, {
      complete: verified,
      count,
      limits,
      pending
    })
  })(state)

const documentVerification = generateDefaultHandler()
const basicVerification = generateDefaultHandler()

const logout = () => initialState

const handlers = {
  [Types.GET_BASIC_INFO]: getBasicInfo,
  [Types.GET_BASIC_INFO_SUCCESS]: getBasicInfoSuccess,
  [Types.GET_VERIFICATION_DATA]: getVerificationData,
  [Types.GET_VERIFICATION_DATA_SUCCESS]: getVerificationDataSuccess,
  [Types.DOCUMENT_VERIFICATION]: documentVerification,
  [Types.BASIC_VERIFICATION]: basicVerification,
  [UsersTypes.USERS_LOGOUT]: logout
}

validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
