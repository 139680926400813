import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import Stepper from 'src/components/stepper'

const Steps = [
  'Account Setup',
  'Owner Information',
  'Business Information',
  'Verify Email'
]
const ActivationPrompt = ({ classes, email, resendEmail, token }) => (
  <Grid
    className={classes.container}
    container
    direction='column'
    alignItems='center'
  >
    <Grid item>
      <Stepper className={classes.stepper} activeStep={3} steps={Steps} />
    </Grid>
    <Grid className={classes.content} item>
      We sent an email to <strong data-testid='recipient-email'>{email}</strong>
      . Please click the link in the the email to complete your profile. Once
      your email is verified, you can start accepting payments.{' '}
      <button
        type='button'
        className={classes.linkButton}
        onClick={() => resendEmail(email, token)}
      >
        Resend Email
      </button>
    </Grid>
  </Grid>
)

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(10)
  },
  content: {
    backgroundColor: 'white',
    maxWidth: 480,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(6)
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
    padding: 0,
    color: '#3288df',
    fontSize: 'inherit'
  },
  stepper: {
    backgroundColor: 'transparent'
  }
})
export default withStyles(styles)(ActivationPrompt)
