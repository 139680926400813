import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import AddEditButton from 'src/components/buttons/addEditButton'
import PropTypes from 'prop-types'

const DocumentRequirements = ({ classes, handleGetStarted }) => (
  <>
    <Grid container direction='column' justifyContent='center'>
      <Grid item>
        <Typography variant='body2' gutterBottom className={classes.header}>
          To avoid delays in your verification process, please ensure uploaded
          documents are fully legible with the full company logo and date on the
          documents clearly seen.
        </Typography>
      </Grid>
      <Grid item className={classes.pleaseSelect}>
        <Typography>Please select the following to upload:</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.content}>
          <li>
            Business Name Registration Certificate/Certificate of Incorporation
          </li>
          <li>
            Valid photo IDs for owners (Passport, Driver's License, National ID)
          </li>
          <li>Proof of Address for owners (less than 3 months old)</li>
          <li>
            Valid photo IDs for directors (Passport, Driver's License, National
            ID)
          </li>
          <li>Proof of Address for directors (less than 3 months old)</li>
          <li>
            Form for Registration of Application
            <b>
              (Required by all business entities except incorporated companies.)
            </b>
          </li>
          <li>
            Notice of Directors <b>(Required by incorporated companies)</b>
          </li>
          <li>
            Notice of Address <b>(Required by incorporated companies)</b>
          </li>
          <li>Optional Documentation such as:</li>
        </Typography>
      </Grid>
    </Grid>
    <Grid container direction='column' className={classes.lastElements}>
      <Grid item>
        <Typography>
          - Financial statements (cash flow statement, income statement, balance
          sheet)
        </Typography>
        <Typography>- Bank statements (less than 3 months old)</Typography>
      </Grid>
    </Grid>
    <Grid container className={classes.getStarted} justifyContent='center'>
      <AddEditButton onClick={handleGetStarted}>Get Started</AddEditButton>
    </Grid>
  </>
)

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  lastElements: {
    marginLeft: 40
  },
  header: {
    padding: 15,
    fontWeight: 600
  },
  content: {
    marginLeft: 20,
    paddingTop: theme.spacing(1)
  },
  getStarted: {
    paddingTop: theme.spacing(2)
  },
  pleaseSelect: {
    marginLeft: 15
  }
})

DocumentRequirements.propTypes = {
  classes: PropTypes.object,
  handleGetStarted: PropTypes.func.isRequired
}

export default withStyles(styles)(DocumentRequirements)
