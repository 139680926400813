import { deleteJSON, getJSON, postJSON } from 'src/services/lib/http'
import { fetchJSONDefaultError } from '../lib/fetch'

export const getEmployeesData = token =>
  getJSON('/merchant/employees', {}, token)

export const getRolesData = token => getJSON('/merchant/permissions', {}, token)

export const addRoleData = ({ token, role }) =>
  postJSON('/merchant/permissions/', role, token)

export const editRoleData = ({ token, role = {} }) =>
  postJSON('/merchant/permissions/' + role.id, role, token)

export const getMerchantInfo = token => getJSON('/merchant/get-info', {}, token)

export const addEmployeesData = ({ user, token }) =>
  postJSON(
    '/merchant/employees',
    {
      active: user.active,
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      roles: user.filteredRoles.map(role => role.value)
    },
    token,
    fetchJSONDefaultError
  )

export const editEmployeesData = ({ user, token }) =>
  postJSON(
    '/merchant/employees/' + user.id,
    {
      active: user.active,
      first_name: user.firstName,
      last_name: user.lastName,
      roles: user.filteredRoles
    },
    token,
    fetchJSONDefaultError
  )

export const deleteEmployeeData = ({ id, token }) =>
  deleteJSON('/merchant/employees/' + id, null, token, fetchJSONDefaultError)

export const editEmployeePermissions = ({ userId, filteredRoles, token }) =>
  postJSON(
    '/merchant/employees/' + userId + '/permissions',
    {
      roles: filteredRoles.map(role => role.value)
    },
    token,
    fetchJSONDefaultError
  )
