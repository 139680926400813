import * as R from 'ramda'
import { fmtErrors } from 'src/redux/resources/helpers'

export const getErrorMessage = ({ statusText, errors, code }) => {
  switch (code) {
    case 400:
      return statusText

    case 401:
    case 422:
      return fmtErrors(errors)

    case 404:
      return "Opps, we're fixing up our servers. Please try again in a few minutes (404)"

    case 500:
      return 'Servers are busy, please try again in a few minutes (500)'
    case 511:
      return statusText
    case 0:
    case 524:
      return "Your request has timed out. Please ensure you're connected to the internet and try again"

    default:
      return 'An error occurred processing your request, please contact support if this issue persists. (default)'
  }
}

export const fetchJSON = (...fetchArgs) =>
  fetch(...fetchArgs)
    .then(rejectErrorResponses(fetchArgs))
    .then(response => response.json())

export const fetchJSONDefaultError = (...fetchArgs) =>
  fetch(...fetchArgs).then(response => response.json())

export const fetchNonJSON = (...fetchArgs) =>
  fetch(...fetchArgs).then(response => response.text())

const rejectErrorResponses = R.curry((fetchArgs, response) =>
  R.ifElse(R.prop('ok'), R.identity, errorFromResponse(fetchArgs))(response)
)

const errorFromResponse = R.curry((fetchArgs, response) =>
  response
    .json()
    .catch(() => response.text())
    .then(throwErrorResponse(fetchArgs, response))
)

const throwErrorResponse = R.curry((fetchArgs, response, body) => {
  const errorTxt = getErrorMessage({
    code: response.status,
    statusText: response.statusText,
    errors: body && body.errors
  })

  const serializedBody = R.when(R.is(Object), JSON.stringify)(body)
  const error = new Error(
    `[Network Error]: ${response.statusText} ${serializedBody}`
  )
  error.fetchArgs = fetchArgs
  error.response = response
  error.body = body
  error.errorTxt = errorTxt
  throw error
})
