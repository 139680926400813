import React from 'react'
import { Button, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

export const AddEditButton = ({ children, classes, onClick, ...props }) => (
  <Button
    {...props}
    variant='contained'
    color='primary'
    onClick={onClick}
    className={classes.button}
  >
    {children}
  </Button>
)

AddEditButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  onClick: PropTypes.func
}

const styles = theme => ({
  button: {
    borderRadius: 5
  }
})

export default withStyles(styles)(AddEditButton)
