import React from 'react'
import { Grid, Button, Paper, withStyles } from '@material-ui/core'
import { Formik, Form } from 'formik'
import EntryLayout from 'src/components/layouts/entry-layout'
import TextInput from 'src/components/forms/entry/text-input'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { passwordValidationSchema } from 'src/pages/signup/helpers'

const InitialFormValues = { newPassword: '', passwordConfirmation: '' }
const ValidationSchema = Yup.object().shape({
  newPassword: passwordValidationSchema,
  passwordConfirmation: Yup.string()
    .trim()
    .required('Confirmation cannot be blank')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
})

const ResetPassword = ({ classes, resetToken, resetPassword }) => {
  const onFormSubmit = ({ newPassword, passwordConfirmation }) =>
    resetPassword(resetToken, newPassword)

  return (
    <EntryLayout>
      <Grid
        container
        className={classes.container}
        alignItems='center'
        justifyContent='center'
      >
        <Formik
          initialValues={InitialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={onFormSubmit}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form onSubmit={handleSubmit} className={classes.form}>
              <Grid container direction='column'>
                <Grid item xs>
                  <Paper>
                    <Grid container direction='column'>
                      <Grid item className={classes.bottomBorder}>
                        <TextInput
                          placeholder='New Password'
                          label='New Password'
                          name='newPassword'
                          type='password'
                          autoComplete='new-password'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Grid>
                      <Grid item>
                        <TextInput
                          placeholder='Confirm Password'
                          label='Confirm Password'
                          name='passwordConfirmation'
                          type='password'
                          autoComplete='new-password'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid
                  item
                  container
                  xs
                  justifyContent='center'
                  className={classes.buttonsContainer}
                >
                  <Grid item>
                    <Button
                      color='primary'
                      variant='contained'
                      type='submit'
                      className={classes.button}
                    >
                      Reset Password
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </EntryLayout>
  )
}

const styles = theme => ({
  form: {
    maxWidth: 500,
    width: '100%'
  },
  buttonsContainer: {
    marginTop: theme.spacing(3)
  },
  button: {
    width: 205,
    height: 52
  },
  bottomBorder: {
    borderBottom: '1px solid #ecf3fb'
  }
})

const actionCreators = {
  resetPassword: actions.users.usersResetPassword
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(actionCreators, dispatch)

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(ResetPassword)
