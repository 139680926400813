import React from 'react'
import { Grid, Card, Typography, withStyles } from '@material-ui/core'
import { Gmaps, Marker } from 'react-gmaps'
import * as R from 'ramda'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import StyledButton from 'src/components/buttons/styledButton'

const mapParams = {
  v: '3.exp',
  key: 'AIzaSyAV4kKPFEpW2EFQ657AHQsMrrsdGZMrjos'
}

const onSavedMapCreated = map => {
  map.setOptions({
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    draggable: false,
    scrollwheel: false,
    panControl: false
  })
}

const LocationCard = ({ classes, handleEditLocationClick, location }) => {
  const indicatorClass = location => {
    if (!location) {
      return classes.greyCircle
    }
    if (location.active) {
      return classes.greenCircle
    } else {
      return classes.redCircle
    }
  }

  return (
    <Grid>
      <Card className={classes.infoCard}>
        <Grid
          container
          justifyContent='flex-end'
          className={classes.circleParent}
        >
          <div
            justifyContent='flex-end'
            className={classNames(indicatorClass(location), classes.circle)}
          />
        </Grid>
        {location ? (
          <Grid item>
            <Gmaps
              width={'180px'}
              height={'180px'}
              lat={location.latitude}
              lng={location.longitude}
              zoom={13}
              loadingMessage={'Loading'}
              params={mapParams}
              onMapCreated={onSavedMapCreated}
              style={{ maxWidth: '100%' }}
            >
              <Marker
                lat={location.latitude}
                lng={location.longitude}
                draggable={false}
              />
            </Gmaps>
          </Grid>
        ) : (
          <Grid
            container
            direction='column'
            className={classes.emptyLocationCard}
            alignItems='center'
            justifyContent='center'
          >
            <Typography className={classes.text}>
              No location to display
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography
            variant='button'
            className={classNames(
              location ? classes.dataTitle : classes.greyDataTitle
            )}
          >
            ADDRESS
          </Typography>
          <Typography className={classes.greyDataTitle}>
            {location ? location.address : '- - -'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='button'
            className={classNames(
              location ? classes.dataTitle : classes.greyDataTitle
            )}
          >
            PHONE NUMBER
          </Typography>
          <Typography className={classes.greyDataTitle}>
            {location ? location.phone : '- - -'}
          </Typography>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          className={classes.button}
        >
          {location ? (
            <StyledButton colorType={'blue'} onClick={handleEditLocationClick}>
              Edit
            </StyledButton>
          ) : (
            <StyledButton colorType={'blue'} disabled>
              Edit
            </StyledButton>
          )}
        </Grid>
      </Card>
    </Grid>
  )
}

LocationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  handleEditLocationClick: PropTypes.func,
  location: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      address: PropTypes.string.isRequired,
      companyId: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      phone: PropTypes.string.isRequired
    })
  ]).isRequired
}

const styles = theme => ({
  infoCard: {
    bordeRadius: 5,
    width: 200,
    margin: '5px 10px 10px',
    padding: 10
  },
  circle: {
    width: '15px',
    height: '15px',
    borderRadius: 100,
    position: 'absolute',
    zIndex: 1,
    boxShadow: '0px 0px 4px 4px #e3e7e9'
  },
  circleParent: {
    position: 'relative'
  },
  redCircle: {
    backgroundColor: theme.palette.buttons.red
  },
  greenCircle: {
    backgroundColor: theme.palette.buttons.green
  },
  greyCircle: {
    backgroundColor: theme.palette.buttons.grey
  },
  dataTitle: {
    padding: '5px 0px 0px 0px',
    fontSize: 12
  },
  map: {
    width: 180,
    height: 180,
    backgroundColor: theme.palette.lights.grey
  },
  emptyLocationCard: {
    width: 180,
    height: 180,
    backgroundColor: theme.palette.lights.lightGrey,
    borderStyle: 'dashed',
    borderColor: theme.palette.lights.grey
  },
  text: {
    color: theme.palette.lights.grey3,
    fontSize: 16
  },
  greyDataTitle: {
    color: theme.palette.lights.grey3,
    fontSize: 12
  }
})

export default R.pipe(withStyles(styles))(LocationCard)
