import React, { useEffect } from 'react'
import { Grid, Typography, useMediaQuery, withStyles } from '@material-ui/core'

import FormItem from 'src/components/forms/form-item'
import SelectItem from 'src/components/forms/select-item'
import * as Yup from 'yup'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { get } from 'lodash'
import usersRedux from 'src/redux/users'
import camelCase from 'camelcase'
import FlagImage from 'src/components/FlagImage'
import {
  getFormStyles,
  selectSignupStyles
} from 'src/components/forms/verification/common'
import { useTheme } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Loading from 'src/components/loading'

const LEGAL_NAME_LABEL = 'Legal Business Name'
const TRADING_NAME_LABEL = 'Business Trading Name'
const BUSINESS_TYPE_LABEL = 'Business Type'
const CATEGORY_LABEL = 'Business Category'
const ADDRESS_LABEL = 'Business Address'
const COUNTRY_LABEL = 'Country'
const PARISH_LABEL = 'Parish'
const PHONE_LABEL = 'Business Phone Number'

export const formValues = {
  legalBusinessName: '',
  businessTradingName: '',
  businessType: '',
  businessCategory: '',
  businessAddress: '',
  country: '',
  parish: '',
  businessPhoneNumber: ''
}

export const validationRules = {
  legalBusinessName: Yup.string()
    .trim()
    .required('Legal business name cannot be blank'),
  businessTradingName: Yup.string()
    .trim()
    .required('Business trading name cannot be blank'),
  businessAddress: Yup.string()
    .trim()
    .required('Business address cannot be blank'),
  businessPhoneNumber: Yup.string()
    .trim()
    .required('Business phone number cannot be blank'),
  businessType: Yup.string().required('Select a business type'),
  businessCategory: Yup.string().required('Select a category'),
  country: Yup.string().required('Select a country'),
  parish: Yup.string().required('Select a state/parish')
}

export const businessTypes = [
  {
    name: 'Sole Proprietorship',
    value: 'individual'
  },
  {
    name: 'Partnership',
    value: 'partnership'
  },
  {
    name: 'International/Domestic Company',
    value: 'corporation'
  },
  {
    name: 'External Company',
    value: 'foreigncorp'
  },
  {
    name: 'Non Profit',
    value: 'nonprofit'
  }
]
const GET_COUNTRIES = gql`
  query {
    countries {
      alpha2
      name
      currencyCode
      subdivisions {
        name
      }
    }
  }
`

export const formatOptionLabel = ({ name, ...props }) => (
  <Grid container item alignItems='center'>
    <FlagImage {...props} />
    <Typography align='center'>{name}</Typography>
  </Grid>
)

const BusinessInformation = ({
  classes,
  isSignup,
  formikProps,
  categoryOptions,
  getCategories
}) => {
  useEffect(() => {
    getCategories()
  }, [])

  const { values, setFieldValue } = formikProps

  const handleCountryChange = value => {
    setFieldValue(camelCase(COUNTRY_LABEL), value)
    setFieldValue(camelCase(PARISH_LABEL), '')

    const country = getCountry(value.value)
    const currencyCode = get(country, ['currencyCode'], '')
    setFieldValue('currencyCode', currencyCode)
  }

  const borderBottomField = `${classes.field} ${classes.borderBottom}`
  const borderBottomRight = `${classes.borderBottom} ${classes.borderRight}`

  const theme = useTheme()
  const noSpacing = useMediaQuery(theme.breakpoints.down('xs')) || isSignup

  const { data, error, loading } = useQuery(GET_COUNTRIES)

  if (loading) return <Loading />
  if (error) return 'Error'

  const getCountry = countryName =>
    R.find(R.propEq('name', countryName), data.countries)

  const countries = R.map(
    ({ name, alpha2 }) => ({ name, value: name, code: alpha2 }),
    data.countries
  )
  const selectedCountry = getCountry(values.country.value)

  const selectedCountryStates = R.pipe(
    R.propOr([], 'subdivisions'),
    R.map(({ name }) => ({ name, value: name }))
  )(selectedCountry)

  return (
    <Grid container direction='column'>
      <Grid item container spacing={noSpacing ? 0 : 3}>
        <Grid
          item
          sm={isSignup ? 12 : 6}
          xs={12}
          className={isSignup ? borderBottomField : ''}
        >
          <FormItem
            item={LEGAL_NAME_LABEL}
            formik={formikProps}
            variant={isSignup ? 'standard' : 'outlined'}
            InputProps={{
              disableUnderline: isSignup
            }}
          />
        </Grid>
        <Grid
          item
          sm={isSignup ? 12 : 6}
          xs={12}
          className={isSignup ? borderBottomField : ''}
        >
          <FormItem
            item={TRADING_NAME_LABEL}
            formik={formikProps}
            variant={isSignup ? 'standard' : 'outlined'}
            InputProps={{
              disableUnderline: isSignup
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={noSpacing ? 0 : 3}>
        <Grid
          item
          sm={6}
          xs={isSignup ? 6 : 12}
          className={isSignup ? borderBottomRight : ''}
        >
          <SelectItem
            item={BUSINESS_TYPE_LABEL}
            formikProps={formikProps}
            options={businessTypes}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.value}
            customStyles={isSignup ? selectSignupStyles : {}}
            labelStyles={isSignup ? classes.label : ''}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={isSignup ? 6 : 12}
          className={isSignup ? classes.borderBottom : ''}
        >
          <SelectItem
            item={CATEGORY_LABEL}
            formikProps={formikProps}
            options={categoryOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.value}
            customStyles={isSignup ? selectSignupStyles : {}}
            labelStyles={isSignup ? classes.label : ''}
          />
        </Grid>
      </Grid>

      <Grid item xs className={isSignup ? borderBottomField : ''}>
        <FormItem
          item={ADDRESS_LABEL}
          formik={formikProps}
          variant={isSignup ? 'standard' : 'outlined'}
          InputProps={{
            disableUnderline: isSignup
          }}
        />
      </Grid>

      <Grid item container spacing={noSpacing ? 0 : 3}>
        <Grid
          item
          sm={6}
          xs={isSignup ? 6 : 12}
          className={isSignup ? borderBottomRight : ''}
        >
          <SelectItem
            item={COUNTRY_LABEL}
            formikProps={formikProps}
            formatOptionLabel={formatOptionLabel}
            options={countries}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.value}
            onChange={handleCountryChange}
            customStyles={isSignup ? selectSignupStyles : {}}
            labelStyles={isSignup ? classes.label : ''}
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={isSignup ? 6 : 12}
          className={isSignup ? classes.borderBottom : ''}
        >
          <SelectItem
            item={PARISH_LABEL}
            formikProps={formikProps}
            options={selectedCountryStates}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.value}
            disabled={!selectedCountry}
            customStyles={isSignup ? selectSignupStyles : {}}
            labelStyles={isSignup ? classes.label : ''}
          />
        </Grid>
      </Grid>
      <Grid item xs className={isSignup ? classes.field : ''}>
        <FormItem
          item={PHONE_LABEL}
          formik={formikProps}
          variant={isSignup ? 'standard' : 'outlined'}
          InputProps={{
            disableUnderline: isSignup
          }}
        />
      </Grid>
    </Grid>
  )
}

const styles = theme => getFormStyles(theme)

const mapStateToProps = state => ({
  categoryOptions: usersRedux.selectors.categoryOptions(state)
})

const mapDispatchToProps = dispatch => ({
  getCategories: () => dispatch(actions.users.getCategories())
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BusinessInformation)
