/* eslint-disable */
import React from 'react'
import { Helmet } from 'react-helmet'
import jsxToString from 'jsx-to-string'

export const PAYMENT_BUTTON_SECTION_ID = 'paymentButtonSection'
export const PAYMENT_BUTTON_ID_DEFAULT = 'payment-button'
export let createTestUid = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')

export const DEFAULT_ONLINE_PAYMENT_OPTIONS = {
  // base: "https://api-qa.bitt.com",
  base: process.env.REACT_APP_API_HOST,
  path: '/merchant/js/mmoney-payment.js',

  amount: '1.00',
  currency: '',
  merchant_order_id: createTestUid(),
  api_key: '',
  paymentButtonId: PAYMENT_BUTTON_ID_DEFAULT,
  title: undefined
}

export const BLANK_ONLINE_PAYMENT_OPTIONS = {
  // base: "https://api-qa.bitt.com",
  base: process.env.REACT_APP_API_HOST,
  path: '/merchant/js/mmoney-payment.js',

  amount: '',
  currency: '',
  merchant_order_id: '',
  api_key: '',
  paymentButtonId: PAYMENT_BUTTON_ID_DEFAULT,
  title: undefined
}

export const DUMMY_ONLINE_PAYMENT_OPTIONS = {
  amount: 'COST_OF_ITEM',
  // currency: 'CHOSEN_CURRENCY_CODE',
  merchant_order_id: 'UNIQUE_IDENTIFIER',
  paymentButtonId: PAYMENT_BUTTON_ID_DEFAULT
}

export let deleteChildrenNodes = (id = '') => {
  // var elem = document.getElementById(id)
  // if (elem) {
  //   elem.innerHTML = ""
  // }
  var parentNode = document.getElementById(id)
  while (
    parentNode != null &&
    parentNode.firstChild
    // && parentNode.firstChild != parentNode.lastChild
  ) {
    parentNode.removeChild(parentNode.firstChild)
  }
}

export let deleteButtonSection = (id = PAYMENT_BUTTON_SECTION_ID) => {
  deleteChildrenNodes(id)
}

let getSnippet_paymentButton = (id = PAYMENT_BUTTON_ID_DEFAULT) => {
  // deleteButtonSection(id)
  return <div id={id}> </div>
}

export let getSnippet_paymentScript = (src = '', forExecution = false) => {
  if (!src)
    src =
      DEFAULT_ONLINE_PAYMENT_OPTIONS.base + DEFAULT_ONLINE_PAYMENT_OPTIONS.path
  let paymentScript = (
    <script type='text/javascript' src={src}>
      {' '}
    </script>
  )
  if (forExecution) return <Helmet>{paymentScript}</Helmet>
  return paymentScript
}

export let getSnippet_stylesTags = (id = 'codePrettify') => {
  return (
    <Helmet>
      <script src='https://cdn.rawgit.com/google/code-prettify/master/loader/run_prettify.js' />
      <link
        id={id}
        href='https://cdn.rawgit.com/google/code-prettify/master/styles/desert.css'
        rel='stylesheet'
      />
      {/* <link href="https://jmblog.github.io/color-themes-for-google-code-prettify/themes/atelier-lakeside-light.min.css" rel='stylesheet'></link> */}
    </Helmet>
  )
}

let getSnippet_paymentRenderString = ({
  amount,
  currency,
  merchant_order_id,
  api_key,
  paymentButtonId
}) => {
  return `
      function show_mMoney_button(){
          let paymentParams = {
                amount            : ${amount},         // Cost of item; Must be FLOAT type, eg. 1.99
                currency          : '${currency}',     // Currency
                merchant_order_id : '${merchant_order_id}',          // Your cart ID or invoice ID; Must be UNIQUE string type

                // API Key from mMoney Merchant Panel with ONLY permission to receive payments enabled
                api_key   : '${api_key}', 
                
                onPaid    : function(details) {        // Optional function called when payment is completed
                  console.log('User paid:', details);
                },
                onCancel  : function()        {        // Optional function called when payment window is cancelled
                  console.log('User cancelled');
                },
                onError  : function(err)        {      // Optional function called when payment window throws an error
                  console.log('User error', err);
                }
          };

          // Send payment parameters and show button
          mMoney.payment.button.render( paymentParams, '#${paymentButtonId}' ); 
      }

      show_mMoney_button(); // To show the mMoney button
  `
}

let getSnippet_paymentRenderScript = ({
  amount,
  currency,
  merchant_order_id,
  api_key,
  paymentButtonId
}) => {
  return (
    <script>
      {`
        ${getSnippet_paymentRenderString({
          amount,
          currency,
          merchant_order_id,
          api_key,
          paymentButtonId
        })}
      `}
    </script>
  )
}

export let getRunCodeSnippet = (payment_options = {}) => {
  // let { base, path, amount, currency, merchant_order_id, api_key, paymentButtonId } = { ...DEFAULT_ONLINE_PAYMENT_OPTIONS, ...payment_options }
  let { amount, currency, merchant_order_id, api_key, paymentButtonId } = {
    ...DEFAULT_ONLINE_PAYMENT_OPTIONS,
    ...payment_options
  }

  return (
    <div>
      <div id={PAYMENT_BUTTON_SECTION_ID}>
        {/* Insert the mMoney button in your website, where you want it to be seen */}
        {getSnippet_paymentButton(paymentButtonId)}
      </div>

      <Helmet>
        {/* Place this script tag above your custom js */}
        {/* {getSnippet_paymentScript(base + path)} */}

        {/* // Use this script or run this function inside your custom js */}
        {getSnippet_paymentRenderScript({
          amount,
          currency,
          merchant_order_id,
          api_key,
          paymentButtonId
        })}
      </Helmet>
    </div>
  )
}

export const getDisplaySnippet = (payment_options = {}) => {
  if (!payment_options.apiKey) return ''
  let {
    threeSteps,
    separateCode,
    asCode,
    base,
    path,
    amount,
    currency,
    merchant_order_id,
    apiKey,
    paymentButtonId
  } = {
    ...DEFAULT_ONLINE_PAYMENT_OPTIONS,
    ...payment_options,
    ...(payment_options.noDummyData ? {} : DUMMY_ONLINE_PAYMENT_OPTIONS)
  }

  let paymentButtonString = `
    <!-- Insert the mMoney button in your website, where you want it to be seen -->
    ${jsxToString(getSnippet_paymentButton(paymentButtonId)).replace(
      /\n/g,
      ' '
    )}
  `
  let paymentScriptString = `
    <!-- Place this script tag above your custom js -->
    ${jsxToString(getSnippet_paymentScript(base + path)).replace(/\n/g, ' ')}
  `
  let paymentRenderString = `
    <!-- Use this script or run this function inside your custom js -->
    <script>
      ${getSnippet_paymentRenderString({
        amount,
        currency,
        merchant_order_id,
        api_key: apiKey,
        paymentButtonId
      })}
    </script>
  `

  const wrapCode = codeString => <pre>{codeString}</pre>

  if (asCode) {
    if (threeSteps) {
      return {
        firstStep: paymentButtonString,
        secondStep: paymentScriptString,
        thirdStep: paymentRenderString
      }
    }
    if (separateCode) {
      return (
        <React.Fragment>
          {wrapCode(paymentButtonString)}
          {wrapCode(paymentScriptString)}
          {wrapCode(paymentRenderString)}
        </React.Fragment>
      )
    } else {
      return wrapCode(`
        ${paymentButtonString}
        ${paymentScriptString}
        ${paymentRenderString}
      `)
    }
  } else {
    return `
      ${paymentButtonString}
      ${paymentScriptString}
      ${paymentRenderString}
    `
  }
}
