import React, { useState } from 'react'
import { withStyles, Tab } from '@material-ui/core'
import GenerateKeys from 'src/pages/manageAPI/generate-keys'
import TestLiveOnlinePayments from 'src/pages/manageAPI/TestLiveOnlinePayments'
import WebHooks from 'src/pages/manageAPI/web-hooks'
import TabsComponent from 'src/components/tabs'

const ManageApi = () => {
  const [apiKeyIdToTest, setApiKeyIdToTest] = useState(undefined)

  const handleTestApiKeyClick = setTab => ({ id }) => {
    setApiKeyIdToTest(id)
    setTab(1)
  }

  const renderTabs = (tab, setTabs) => {
    switch (tab) {
      case 0:
        return <GenerateKeys onTestApiClick={handleTestApiKeyClick(setTabs)} />
      case 1:
        return <TestLiveOnlinePayments initialApiKeyId={apiKeyIdToTest} />
      case 2:
        return <WebHooks />
      default:
        return null
    }
  }

  return (
    <TabsComponent
      title={'Manage API Keys'}
      renderTabs={renderTabs}
      tabsLabels={[
        <Tab label='Generate Keys' />,
        <Tab label='Test Live Online Payments' />,
        <Tab label='Web Hooks' />
      ]}
    />
  )
}

const styles = theme => ({})

export default withStyles(styles)(ManageApi)
