import React, { useEffect } from 'react'
import { Grid, Card, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { actions } from 'src/redux'
import FormikTextField from 'src/components/formik/FormikTextField'
import dialingCodes from 'src/assets/countryCodes'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Select from 'src/components/forms/entry/Select'
import resourcesRedux from 'src/redux/resources'
import FlagImage from 'src/components/FlagImage'
import { currenciesForSelectPropTypes } from 'src/pages/transact/proptypes'
import StyledButton from 'src/components/buttons/styledButton'
import theme from 'src/theme'

export const formatOptionLabel = ({ name, ...props }) => (
  <Grid container item alignItems='center'>
    <FlagImage {...props} />
    <Typography align='center'>{name}</Typography>
  </Grid>
)

const validationSchema = object().shape({
  email: string()
    .required('An email address is required')
    .email('This email is invalid'),
  phoneNumber: string()
    .trim()
    .required('A phone number is required')
})
const initialValues = (
  { contactEmail = '', phoneNumber = '', defaultCurrency } = {},
  currencies
) => ({
  email: contactEmail,
  phoneNumber,
  defaultCurrency: currencies.find(
    currency => currency.code === defaultCurrency
  ),
  countryCode: ''
})

const handleSubmit = onCompanyInformationSave => (values, actions) => {
  onCompanyInformationSave({ ...values })
}

export const CompanyInformation = ({
  classes,
  onCompanyInformationSave,
  requestMerchantInfo,
  currencies,
  currentCompanyInformation
}) => {
  useEffect(() => {
    requestMerchantInfo()
    return () => {}
  }, [])
  return (
    <Grid container direction='column'>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues(currentCompanyInformation, currencies)}
        isInitialValid={validationSchema.isValidSync(initialValues)}
        onSubmit={handleSubmit(onCompanyInformationSave)}
      >
        {({ handleSubmit, isSubmitting, ...formikProps }) => (
          <form onSubmit={handleSubmit}>
            <Card direction='column' className={classes.card}>
              <Grid item>
                <Typography className={classes.heading}>
                  Company Information
                </Typography>
              </Grid>

              <Grid container item direction='column' xs={12}>
                <Grid item lg={5} data-testid='selectCurrencyContainer'>
                  <Typography
                    variant='caption'
                    className={classes.inputDescription}
                  >
                    DEFAULT CURRENCY
                  </Typography>
                  <Select
                    data-testid='selectCurrency'
                    name='defaultCurrency'
                    options={currencies}
                    formatOptionLabel={formatOptionLabel}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.code}
                    placeholder='Currency'
                    className={classes.textField}
                    formikProps={formikProps}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant='caption'
                    className={classes.inputDescription}
                  >
                    CONTACT PHONE NUMBER
                  </Typography>
                </Grid>
                <Grid item container direction='row'>
                  <Grid item lg={2} xs={4}>
                    <Select
                      name='countryCode'
                      getOptionLabel={option => option.nameWithCode}
                      getOptionValue={option => option.callingCode}
                      options={dialingCodes}
                      className={classes.textField}
                      formikProps={formikProps}
                    />
                  </Grid>
                  <Grid item lg={3} xs={8}>
                    <FormikTextField
                      name='phoneNumber'
                      type='text'
                      placeholder='Phone number'
                      variant='outlined'
                      fullWidth
                      formik={formikProps}
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={5}>
                  <Typography
                    variant='caption'
                    className={classes.inputDescription}
                  >
                    CONTACT EMAIL
                  </Typography>
                  <FormikTextField
                    name='email'
                    type='text'
                    placeholder='Contact Email'
                    variant='outlined'
                    fullWidth
                    formik={formikProps}
                    className={classes.textField}
                  />
                </Grid>
                <StyledButton
                  colorType={'green'}
                  type='submit'
                  style={{ width: 90, margin: theme.spacing(1) }}
                >
                  Save
                </StyledButton>
              </Grid>
            </Card>
          </form>
        )}
      </Formik>
    </Grid>
  )
}

CompanyInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  onCompanyInformationSave: PropTypes.func.isRequired,
  requestMerchantInfo: PropTypes.func.isRequired,
  currencies: currenciesForSelectPropTypes,
  currentCompanyInformation: PropTypes.shape({
    contactEmail: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    defaultCurrency: PropTypes.string.isRequired
  })
}

const styles = theme => ({
  card: {
    padding: 15,
    marginBottom: 15
  },
  heading: {
    fontWeight: 300,
    fontSize: 28,
    marginBottom: 30
  },
  inputDescription: {
    fontWeight: 600,
    margin: '0px 0px 5px 5px'
  },
  textField: {
    marginBottom: 5,
    padding: 5
  }
})

const mapDispatchToProps = dispatch => ({
  onCompanyInformationSave: values =>
    dispatch(actions.settings.updateCompanyInformation(values)),
  requestMerchantInfo: () => dispatch(actions.resources.getMerchantInfo())
})

const mapStateToProps = state => ({
  currencies: resourcesRedux.selectors.currenciesForSelect(state),
  currentCompanyInformation: resourcesRedux.selectors.currentCompanyInformation(
    state
  )
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CompanyInformation)
