import React from 'react'
import { Grid, Typography, withStyles, Card } from '@material-ui/core'
import EntryLayout from 'src/components/layouts/entry-layout'

import { H2, H5 } from 'src/pages/signup/privacy-policy'

const TermsAndConditions = ({ classes }) => {
  return (
    <EntryLayout>
      <Grid
        container
        item
        justifyContent='center'
        alignContent='center'
        alignItems='center'
        direction='column'
      >
        <Card className={classes.card}>
          <Grid
            container
            item
            justifyContent='center'
            alignContent='center'
            alignItems='center'
            direction='column'
          >
            <H2 variant='h2'>mMoney Merchant Terms & Conditions</H2>

            <H5 variant='h4'>Barbados</H5>
            <Typography paragraph>
              <Typography component='span' className={classes.bold}>
                THIS MERCHANT SERVICES AGREEMENT
              </Typography>{' '}
              (the "Agreement") is a legal and binding agreement between you of
              the one part and{' '}
              <Typography component='span' className={classes.bold}>
                MMONEY INC.,{' '}
              </Typography>
              a company incorporated under the laws of Barbados with company no.
              50183, whose registered office is situate at #8 Hastings Plaza,
              Hastings, Christ Church in the island of Barbados of the other
              part.
            </Typography>
            <Typography paragraph className={classes.bold}>
              IF YOU DO NOT AGREE TO BE SO BOUND, PLEASE DO NOT SELECT THE
              CHECKBOX OR CLICK THE “CREATE ACCOUNT” BUTTON AND DO NOT USE THE
              SERVICES.
            </Typography>
            <Typography paragraph>
              By applying for the Services (as defined below) through our online
              application process or otherwise, selecting the “Create Account”
              button at the end of this Agreement or by using the Services, you
              confirm that you are a duly authorised representative of the
              Merchant and acknowledge that you have read and agree to be bound
              by (and that the Merchant will be bound by) all of the terms and
              conditions of this Agreement and any documents incorporated by
              reference.
            </Typography>
            <Typography paragraph>
              <Typography component='span' className={classes.bold}>
                AND IT IS HEREBY AGREED
              </Typography>{' '}
              as follows:
            </Typography>

            <H5 variant='h4'>1. INTRODUCTION</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Introduction. In this Merchant Service Agreement (“Agreement”),
                "Merchant", "you" and "your" refer to each customer (" Merchant
                ") and its designated agents, including your administrative
                contact, and "Company", "we", "us" and "our" refer collectively
                to MMONEY INC. #8, Hastings Plaza, Hastings, Christ Church,
                Barbados ("Company"). This Agreement explains our obligations to
                you, and your obligations to us in relation to the service(s)
                you signed up for. By signing up for the service(s) you agree to
                establish an account with us for such services. When you use
                your account or permit someone else to use your account to
                purchase or otherwise acquire access to additional service(s) or
                to modify or cancel your service(s) (even if we were not
                notified of such authorisation), this Agreement as amended
                covers any such service or actions. Additionally, you agree that
                the administrative contact for any services provided to you is
                your agent with full authority to act on your behalf with
                respect to such services, as permitted by the Services and
                related documentation, including (but not limited to) the
                authority to terminate, transfer (where transfer is permitted by
                the Agreement), or modify such services, or purchase additional
                services. Any acceptance of your application(s) or requests for
                our services and the performance of our services will be deemed
                to occur at our offices in Barbados.
              </Typography>
            </Typography>
            <H5 variant='h4'>2. DEFINITIONS</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='span' className={classes.bold}>
                  “Financial Institution”{' '}
                </Typography>{' '}
                shall mean banks or financial institutions having business
                relationships with one or more Financial Processors that have
                agreed to evaluate and provide merchant accounts and payment
                authorisation services to merchants.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='span' className={classes.bold}>
                  “Financial Processor”{' '}
                </Typography>
                shall mean an entity with which the Company has established a
                relationship that performs the back-end authorisation and
                processing of Transactions between the Merchant's Financial
                Institution.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='span' className={classes.bold}>
                  “Software”{' '}
                </Typography>
                shall mean the mMoney application and mMoney merchant
                application, any Software development kits (“SDKs”) or other
                client applications, as well as any HTML, application
                programming interfaces (APIs), related documentation and other
                client software or code which the Company provides to Merchant,
                including updates, to enable the Company to provide the Services
                to Merchant. Unless otherwise specified, Software shall not
                include any source code. The Software is proprietary to the
                Company.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='span' className={classes.bold}>
                  “mMoney Merchant App”{' '}
                </Typography>
                means the software application available in the Appstore and
                Google Playstore that enables digital fiat currency and other
                digital asset payments to be used in exchange for goods and
                services.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='span' className={classes.bold}>
                  “Transaction”{' '}
                </Typography>
                shall mean information related to the purchase of goods and
                services from Merchant by a third party. Specifically a
                Transaction is an authorisation, delayed capture, sale, void,
                voice authorisation or credit data transmission between Company
                and its back end processors.
              </Typography>
            </Typography>
            <H5 variant='h4'>3. MERCHANT OBLIGATIONS</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  General Service Requirements.
                </Typography>{' '}
                Merchant shall be solely responsible for:
              </Typography>
              <Typography component='ol'>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  Establishing, hosting and maintenance of its connection to the
                  Internet, fulfilling all orders for products and services sold
                  by Merchant to its users via the mMoney Merchant App,
                  including without limitation transmitting Merchant's
                  registration information and Transaction data to Company
                  servers or via the mMoney Merchant App and ensuring that any
                  data stored or transmitted by Merchant in conjunction with the
                  Services and for enrollment for the Services is accurate,
                  complete and in the form as requested by Company, is securely
                  collected and is not corrupted due to Merchant's systems.
                  Merchant is also responsible for reviewing the Transactions in
                  its account on a regular basis and notifying Company promptly
                  of suspected unauthorised activity through its account;
                </Typography>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  Keeping its login name and password confidential. Merchant
                  shall be solely responsible for (i) updating its passwords for
                  access to the Services periodically, and (ii) creating
                  passwords that are reasonably "strong" under the
                  circumstances, both in accordance with Company's requirements.
                  A "strong" password is at least six characters long, does not
                  contain all or part of the user's account name, and contains
                  at least three of the four following categories of characters:
                  uppercase characters, lowercase characters, base 10 digits,
                  and symbols found on the keyboard (such as !, @, #). Strong
                  passwords should be generated in such a way that knowledge of
                  one does not lead to knowledge of another.
                </Typography>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  Maintaining commercially reasonable business practices in
                  conjunction with use of the Services, collecting, storing and
                  transmitting its customer data in a secure manner and
                  protecting the privacy of its customer data. Merchant shall
                  comply with Company's requests for reasonable action on
                  Merchant's part, to the extent necessary, to maintain security
                  and integrity of the Services;
                </Typography>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  Updating to the most current Software version and security
                  updates and patches necessary to properly operate the Services
                  and keeping all Merchant enrollment and payment information
                  current and updated on the mMoney Merchant App; and
                </Typography>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  Merchant agrees, and hereby represents and warrants that
                  Merchant shall (A) use the Services in accordance with the
                  applicable user guides and other documentation; and (B) not
                  use or permit others to use information obtained through the
                  use of the Services for any purpose other than in conjunction
                  with the Services and in a manner described in the
                  documentation for the Services.
                </Typography>
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Proprietary Rights.</Typography>{' '}
                Except as otherwise set forth herein, all right, title and
                interest in and to all, (i) registered and unregistered
                trademarks, service marks and logos; (ii) patents, patent
                applications, and patentable ideas, inventions, and/or
                improvements; (iii) trade secrets, proprietary information, and
                know-how; (iv) all divisions, continuations, reissues, renewals,
                and extensions thereof now existing or hereafter filed, issued,
                or acquired; (v) registered and unregistered copyrights
                including, without limitation, any forms, images, audiovisual
                displays, text, software and (vi) all other intellectual
                property, proprietary rights or other rights related to
                intangible property which are used, developed, comprising,
                embodied in, or practiced in connection with any of the Services
                identified herein ("mMoney Intellectual Property Rights") are
                owned by Company or its licensors, and you agree to make no
                claim of interest in or ownership of any such mMoney
                Intellectual Property Rights. You acknowledge that no title to
                the mMoney Intellectual Property Rights is transferred to you,
                and that you do not obtain any rights, express or implied, in
                the Company or its licensors' service, other than the rights
                expressly granted in this Agreement. To the extent that you
                create any Derivative Work (any work that is based upon one or
                more preexisting versions of a work provided to you, such as an
                enhancement or modification, revision, translation, abridgement,
                condensation, expansion, collection, compilation or any other
                form in which such preexisting works may be recast, transformed
                or adapted) such Derivative Work shall be owned by Company and
                all existing and future copyright and other right, title and
                interest in and to each such Derivative Work, are assigned to,
                and shall automatically vest in, Company. Company shall have no
                obligation to grant you any right in any such Derivative Work.
                Except to the extent permitted by applicable law, Merchant shall
                not disassemble, decompile, decrypt, extract, reverse engineer,
                prepare a derivative work based upon, distribute, or time share
                the Services or any components thereof, or otherwise apply any
                procedure or process to the Services or components thereof in
                order to ascertain, derive, and/or appropriate for any reason or
                purpose, the source code or source listings or any algorithm,
                data, process, procedure or other information contained therein.
                Merchant shall not rent, sell, resell, lease, sublicense, loan
                or otherwise transfer the Services or components thereof.
              </Typography>
            </Typography>
            <H5 variant='h4'>4. COMPANY'S OBLIGATIONS</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Services.</Typography> Subject to the
                terms in this Agreement, Company agrees to (i) provide to
                Merchant the Services for which Merchant enrolls; (ii) provide
                to the Merchant, without limitation, the transmission of
                Transaction information to Financial Processors; and (iii)
                provide Merchant with access to standardised reports regarding
                Merchant's Transactions processed using the Services and certain
                reporting tools to assist Merchant in accounting activities.
                Company hereby grants to Merchant the right to access and use
                the Services in accordance with the Agreement. Company is not
                bound by nor should Merchant rely on any representation by (i)
                any agent, representative or employee of any third party that
                Merchant may use to apply for our services; or in (ii)
                information posted on our Web site of a general informational
                nature.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  Modification of Terms; Changes to Services.
                </Typography>{' '}
                Except as otherwise provided in this Agreement, Merchant agrees
                that Company may: (1) revise the terms and conditions of this
                Agreement, including without limitation modifying the service
                fees or payment terms; and/or (2) change part of the Services
                provided under this Agreement at any time. Any such revision or
                change will be binding and effective either, at Company's sole
                discretion, 30 days after posting of the revised Agreement or
                change to the Services on the Company Website, or upon
                electronic or written notification to you. You agree to
                periodically review the Company website, including the current
                version of this Agreement available on the Company Website, to
                be aware of any such revisions. If you do not agree with any
                revision to the Agreement, you may terminate this Agreement at
                any time by providing us with notice as set forth in this
                Agreement. Notice of your termination will be effective on
                receipt and processing by us. Any fees paid by you if you
                terminate your Agreement with us are non-refundable, except as
                otherwise expressly stated herein, but you will not incur any
                additional fees. By continuing to use mMoney services after any
                revision to this Agreement or change in Services, you agree to
                abide by and be bound by any such revisions or changes. We are
                not bound by nor should you rely on any representation by (i)
                any agent, representative or employee of any third party that
                you may use to apply for our Services; or in (ii) information
                posted on our Website of a general informational nature. No
                employee, contractor, agent or representative of Company is
                authorised to alter or amend the terms and conditions of this
                Agreement.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Secure Transactions. </Typography>{' '}
                Company has implemented and will maintain security systems for
                the transmission of Merchant's Transactions, consisting of
                encryption and "firewall" technologies that are understood in
                the industry to provide adequate security for the transmission
                of such information over the Internet. mMoney does not guarantee
                the security of the Services or Transaction data, and Company
                will not be responsible in the event of any infiltration of its
                security systems, provided that Company has used commercially
                reasonable efforts to prevent any such infiltration. Merchant
                further acknowledges and agrees that Merchant, and not Company,
                is responsible for the security of Transaction data or
                information or any other information stored on Merchant's
                servers, and that Company is not responsible for any other
                party's servers (other than subcontractors of Company solely to
                the extent Company is liable for its own actions hereunder).
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  Technical Support for Services.
                </Typography>{' '}
                Company shall provide the technical support services to
                Merchants, specific to the support package selected by Merchant
                during enrollment. Company's then-current, standard technical
                support descriptions for these Services shall be posted at the
                URL: https://support.mmoneybb.com
              </Typography>
            </Typography>
            <H5 variant='h4'>5. PRIVACY</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  The Company Privacy Policy.
                </Typography>{' '}
                Our Privacy Policy for the Services is located on our Website at{' '}
                <a href='https://www.mmoneybb.com/policy/privacy-policy'>
                  https://www.mmoneybb.com/policy/privacy-policy
                </a>{' '}
                and is incorporated herein by reference, as it is applicable to
                the Services. The privacy policy sets forth your and our rights
                and responsibilities with regard to your personal information.
                You agree that we may, in our sole discretion, modify our
                privacy policy. We will post such revised statement of policy on
                our Website. You agree to monitor our Website periodically to
                review such revisions. By using our Services after modifications
                to the Privacy Policy, you have agreed to these modifications.
                You acknowledge that if you do not agree to any such
                modification, you may terminate this Agreement.
              </Typography>

              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Use of the Data.</Typography> Merchant
                acknowledges and agrees that in the course of providing the
                Services, Company will capture certain transaction and user
                information (collectively, the “Data”). Merchant agrees to
                provide to Company, and Company shall capture, such Data as
                Company may consider appropriate. Subject to the Company Privacy
                Policy, Company agrees to use Data in its personally
                identifiable form only as necessary to provide the Services. For
                the avoidance of doubt, Company shall have the rights (i) to use
                the Data as necessary to perform the Services contemplated in
                this Agreement (including distributing the Data to third parties
                providing services requested by Merchant); (ii) to maintain the
                Data as long as necessary or as required by law and used
                internally for record keeping, internal reporting, and support
                purposes; (iii) to compile and disclose Data in the aggregate
                where individual merchant Data is not identifiable, including
                without limitation, calculating merchant averages by region or
                industry; and (iv) to provide the Data as required by law or
                court order, or to defend Company's rights in a legal dispute.
                You represent and warrant that you have provided notice to, and
                obtained consent from, any third party individuals whose
                personal data you supply to us as part of our services with
                regard to: (i) the purposes for which such third party's
                personal data has been collected, (ii) the intended recipients
                or categories of recipients of the third party's personal data,
                (iii) which parts of the third party's data are obligatory and
                which parts, if any, are voluntary; and (iv) how the third party
                can access and, if necessary, rectify the data you hold about
                them. You further agree to provide such notice and obtain such
                consent with regard to any third party personal data you supply
                to us in the future. We are not responsible for any consequences
                resulting from your failure to provide notice or receive consent
                from such individuals nor for your providing outdated,
                incomplete or inaccurate information.
              </Typography>
            </Typography>
            <H5 variant='h4'>6. FEES AND PAYMENT TERMS</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Company agrees to withhold and the Merchant agrees to have
                withheld such fees as set out in the Schedule hereto, unless
                otherwise agreed in writing between the Parties.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Merchants are hereby prohibited from imposing on its customers
                any charges and/or fees in addition to the advertised price of a
                product or service on the basis of a consumer's choice of mMoney
                as their method of payment. For the avoidance of doubt, this
                prohibition applies to transactions concluded on business
                premises (for example, a shop), away from business premises (for
                example, at the customer's home) or at a distance (for example,
                mail order, internet, telephone or email).
              </Typography>
            </Typography>
            <H5 variant='h4'>7. WARRANTY; DISCLAIMER</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Company represents and warrants that (a) it has all requisite
                corporate or other power to enter into this Agreement and to
                carry out the terms of this Agreement; (b) all corporate action
                on the part of Company, its officers, board of directors and
                stockholders necessary for the performance of its obligations
                under this Agreement has been taken.
              </Typography>
              <Typography component='ol'>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  Except as expressly set forth above and to the extent
                  permitted by applicable law, Company and its licensors, as
                  applicable, make no warranty of any kind, express, implied or
                  statutory, regarding the services or software,
                </Typography>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  To the maximum extent permitted by law, all such conditions
                  and warranties, including without limitation the implied
                  warranties of merchantability, fitness for particular purpose
                  and non-infringement are hereby expressly disclaimed by
                  Company and its licensors, except any implied condition or
                  warranty the exclusion of which would contravene any statute
                  or cause any part of this clause to be void.
                </Typography>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  Company's liability to Merchant for breach of any
                  non-excludable condition is limited, at Company's option, to
                  refunding the price of the Services in respect of which the
                  breach occurred or to replacing or providing those Services
                  again.
                </Typography>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  Merchant acknowledges that neither Company nor its licensors
                  have represented or warranted that the services will be
                  uninterrupted, error free or without delay or without
                  compromise of the security systems related to the services or
                  that all errors will be corrected.
                </Typography>
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Merchant represents and warrants that it shall comply with all
                applicable privacy, consumer and other laws and regulations with
                respect to its (i) provision, use and disclosure of the Data;
                (ii) dealings with the users providing the Data; and (iii) use
                of the Services. Additionally, Merchant represents and warrants
                that (a) it has all requisite corporate or other power to enter
                into this Agreement and to carry out the terms of this
                Agreement; (b) all corporate action on the part of Merchant, its
                officers, board of directors and stockholders necessary for the
                performance of its obligations under this Agreement has been
                taken; (c) this Agreement constitutes its valid and legally
                binding obligation, enforceable against it in accordance with
                the terms hereof; (d) if Merchant is a corporation, then it is a
                corporation in good standing in its jurisdiction of
                incorporation; (e) it has read and understands the entire
                Agreement and desires to be bound thereby, and it has been
                represented by counsel of its own choosing; and (f) it
                represents and warrants that, except as expressly set forth
                herein, no representations of any kind or character have been
                made to induce it to execute and enter into this Agreement.
              </Typography>
            </Typography>
            <H5 variant='h4'>8. INDEMNIFICATION</H5>
            <Typography paragraph>
              This section is subject to the terms of Section 9.
            </Typography>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Either party will defend, indemnify, save and hold harmless the
                other party and the officers, directors, agents, affiliates,
                distributors, franchisees and employees of the other party from
                any and all third party claims, demands, liabilities, costs or
                expenses, including reasonable attorneys' fees, resulting from
                the indemnifying party's material breach of any duty,
                representation or warranty of this Agreement. A party's right to
                indemnification under the Agreement ("indemnified party") is
                conditioned upon the following: prompt written notice to the
                party obligated to provide indemnification ("indemnifying
                party") of any claim, action or demand for which indemnity is
                sought; control of the investigation, preparation, defense and
                settlement thereof by the indemnifying party; and such
                reasonable cooperation by the indemnified part, at the
                indemnifying party's request and expense, in the defense of the
                claim. The indemnified party shall have the right to participate
                in the defense of a claim by the indemnifying party with counsel
                of the indemnified party's choice at the indemnified party's
                expense. The indemnifying party shall not, without the prior
                written consent of the indemnified party, settle, compromise or
                consent to the entry of any judgment that makes any admissions
                in the indemnified party's name or imposes any liability upon
                the indemnified party.
              </Typography>
            </Typography>
            <H5 variant='h4'>9. LIMITATIONS ON LIABILITY</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Merchant acknowledges that the Company is not a financial or
                credit reporting institution. Company is responsible only for
                providing data transmission to effect or direct certain payment
                authorisations for Merchant and is not responsible for the
                results of any credit inquiry, the operation of web sites of
                ISPs or Financial Institutions or the availability or
                performance of the Internet, or for any damages or costs
                Merchant suffers or incurs as a result of any instructions
                given, actions taken or omissions made by Merchant, Merchant's
                financial processor(s), Merchant's Financial Institution or any
                ISP. In no event will Company's liability (including liability
                for negligence) arising out of this Agreement exceed the fee
                paid to Company by Merchant hereunder during the twelve (12)
                month period immediately preceding the event which gave rise to
                the claim for damages. In no event will Company or its licensors
                have any liability (including liability for negligence) to
                merchant or any other party for any lost opportunity or profits,
                costs of procurement of substitute goods or services, or for any
                indirect, incidental, consequential, punitive or special damages
                arising out of this Agreement, under any cause of action or
                theory of liability (including negligence), and whether or not
                Company has been advised of the possibility of such damage.
                These limitations will apply notwithstanding any failure of
                essential purpose of any limited remedy. Notwithstanding the
                above, the limitations set forth above shall be enforceable to
                the maximum extent allowed by applicable law.
              </Typography>
            </Typography>
            <H5 variant='h4'>10. TERM AND TERMINATION</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Term; Renewal.</Typography> This
                Agreement will commence on the later of (i) the date Merchant
                accepts the terms of this Agreement (the "Effective Date"), or
                (ii) the date that Merchant's mMoney account is activated for
                live Transactions, if different;{' '}
                <Typography component='span' className={classes.redText}>
                  {' '}
                  and will continue for a period of one (1) year, unless
                  terminated earlier or suspended according to the provisions of
                  this Agreement. This Agreement will thereafter automatically
                  renew for successive twelve (12) month terms, unless either
                  party gives the other party written or electronic notice, in
                  accordance with the terms herein, of its intention not to
                  renew the Agreement, at least thirty (30) days prior to the
                  end of the then-current term or renewal term if termination is
                  by Company.
                </Typography>{' '}
                Any renewal of your Services is subject to our then-current
                terms and conditions, successful completion of any applicable
                authentication procedure, if any, and payment of all applicable
                service fees at the time of renewal. Additional payment terms
                may apply to the Company Services you enrolled for, as set forth
                herein and in the applicable Schedule(s) to this Agreement.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Suspension and Termination. Either party hereto may, at its
                option, and without notice, terminate this Agreement, effective
                immediately, should the other party hereto (i) admit in writing
                its inability to pay its debts generally as they become due;
                (ii) make a general assignment for the benefit of creditors;
                (iii) institute proceedings to be adjudicated a voluntary
                bankrupt, or consent to the filing of a petition of bankruptcy
                against it; (iv) be adjudicated by a court of competent
                jurisdiction as being bankrupt or insolvent; (v) seek
                reorganisation under any bankruptcy act, or consent to the
                filing of a petition seeking such reorganisation; or (vi) have a
                decree entered against it by a court of competent jurisdiction
                appointing a receiver liquidate, trustee, or assignee in
                bankruptcy or in insolvency covering all or substantially all of
                such Party's property or providing for the liquidation of such
                party's property or business affairs.
              </Typography>
              <Typography component='ol'>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  By Merchant. Merchant may terminate this Agreement upon prior
                  written notice to Company by notifying Company's customer
                  support electronically or in writing and following the
                  instructions for cancellation either (i) prior to the end of
                  the initial period or any renewal period; or (ii) for
                  convenience. Subject to the above, Company shall use
                  commercially reasonable efforts to cancel the Services within
                  seven (7) business days following such written notice from
                  Merchant. Merchant shall be responsible for the payment of all
                  fees due and payable through the effective date of
                  termination. Termination requests for non-Company, third party
                  services may not be made through Company. Merchant must
                  instead contact such third parties directly to cancel such
                  services.
                </Typography>
                <Typography
                  paragraph
                  component='li'
                  className={classes.lowerAlpha}
                >
                  By Company. Notwithstanding Section 10.1, Company may suspend
                  Merchant's access to the Services or terminate this Agreement
                  as follows:
                </Typography>
                <Typography component='ol'>
                  <Typography
                    paragraph
                    component='li'
                    className={classes.upperRoman}
                  >
                    Following ten (10) days prior electronic or written notice
                    (such as an overdue invoice) if (a) Merchant breaches the
                    Agreement, (b) perpetrates fraud, (c) causes or fails to fix
                    a security breach relating to the Services, (d) fails to
                    comply with Company's best practices requirements for
                    security management or to respond to an inquiry from
                    Company, concerning the accuracy or completeness of the
                    information Merchant is required to provide pursuant to this
                    Agreement, (e) if Company reasonably suspects fraudulent
                    activity on Merchant's payment services account, (if such
                    breach is not cured within such 10-day period), (f) in the
                    event that certain third party licenses or access to third
                    party components of the Services are terminated, or (g) non
                    payment of invoice; or
                  </Typography>
                  <Typography
                    paragraph
                    component='li'
                    className={classes.upperRoman}
                  >
                    Immediately, without prior notice, if Company reasonably
                    believes Merchant's breach compromises the security of the
                    Services in any material fashion, if fraudulent Transactions
                    are being run on your account, or Merchant's financial
                    processor or Financial Institution with which Merchant has a
                    merchant account requires such termination or suspension.
                  </Typography>
                </Typography>
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                Effect of Termination. Company will cease providing the Services
                and cease charging any Service fees as of the expiration of
                period in which the termination is effective. If termination of
                this Agreement is due to your default hereunder, you shall bear
                all costs of such termination, including any reasonable costs
                Company incurs in closing your account. You agree to pay any and
                all costs incurred by Company in enforcing your compliance with
                this Section. Upon termination, your rights to use the Services,
                and any other rights granted hereunder, shall immediately cease,
                and you shall destroy any copy of the materials licensed to you
                hereunder and referenced herein. Each party will be released
                from all obligations and liabilities to the other occurring or
                arising after the date of such termination, except that any
                termination of this Agreement will not relieve Company or
                Merchant from any liability arising prior to the termination of
                this Agreement. To the extent permitted by applicable law and
                regulatory requirements, you agree that upon termination for any
                reason, we may delete all information relating to your use of
                the Service, as we deem fit. Notwithstanding the foregoing, the
                provisions of Sections 5, 7-12, Merchant's obligations to pay
                all fees due through the effective date of termination and terms
                in the Schedule(s) relating to indemnity, warranties or terms
                which by their nature are required to survive contract
                termination will survive any termination of this Agreement.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  Reinstatement of Services.
                </Typography>{' '}
                If Services are suspended or terminated by Company due to lack
                of payment by Merchant, reinstatement of Services shall be
                subject to Merchant paying Company, as applicable: (i) new set
                up fees, at Company's then-current rates; and (ii) all past due
                annual or monthly fees and Transaction fees.
              </Typography>
            </Typography>
            <H5 variant='h4'>11. CONFIDENTIALITY</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Confidential Information.</Typography>{' '}
                "Confidential Information" means any confidential, trade secret
                or proprietary information (which may be business, financial or
                technical information) disclosed by one party to the other under
                this Agreement that is marked confidential or if disclosed
                orally designated as confidential at the time of disclosure or
                that should be reasonably understood to be confidential. All
                source code and the terms of this Agreement will be considered
                Confidential Information.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  Limitation of Confidentiality.
                </Typography>{' '}
                The Obligations set forth in Section 11.2 ("Confidentiality
                Obligations") above do not apply to information that (i) is in
                or enters the public domain without breach of this Agreement,
                (ii) the receiving party lawfully receives from a third party
                without restriction on disclosure and without breach of a
                non-disclosure obligation, (iii) the receiving party knew prior
                to receiving such information from the disclosing party or
                develops independently without access or reference to the
                Confidential Information, (iv) is disclosed with the written
                approval of the disclosing party, or (v) is disclosed five (5)
                years from the effective date of termination or expiration of
                this Agreement.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  Exceptions to Confidentiality.
                </Typography>{' '}
                Notwithstanding the Confidentiality Obligations set forth in
                Section 11.2 above, each party may disclose Confidential
                Information of the other party (i) to the extent required by a
                court of competent jurisdiction or other governmental authority
                or otherwise as required by law but only after alerting the
                other party of such disclosure requirement and, prior to any
                such disclosure, allowing (where practicable to do so) the other
                party a reasonable period of time within which to seek a
                protective order against the proposed disclosure, or (ii) on a
                "need-to-know" basis under an obligation of confidentiality
                substantially similar in all material respects to those
                confidentiality obligations in this Section 10 to its legal
                counsel, accountants, contractors, consultants, banks and other
                financing sources.
              </Typography>
            </Typography>

            <H5 variant='h4'>12. MISCELLANEOUS TERMS</H5>
            <Typography component='ol'>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Force Majeure</Typography> (Events
                Beyond the Parties' Control). Neither party shall be deemed in
                default hereunder, nor shall it hold the other party responsible
                for, any cessation, interruption or delay in the performance of
                its obligations hereunder, except for Merchant's payment
                obligations hereunder, due to earthquake, flood, fire, storm,
                natural disaster, act of God, war, terrorism, armed conflict,
                labor strike, lockout, or boycott, provided that the party
                relying upon this Section shall give the other party written
                notice thereof promptly and, in any event, within five (5) days
                of discovery thereof, and (ii) shall take all steps reasonably
                necessary under the circumstances to mitigate the effects of the
                force majeure event upon which such notice is based; provided,
                however, that in the event a force majeure event described in
                this Section extends for a period in excess of thirty (30) days
                in the aggregate, either party may immediately terminate the
                Agreement.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  Entire Agreement and Modification.
                </Typography>{' '}
                The terms in this Agreement constitute the entire agreement
                between Company and Merchant regarding its subject matter and
                its terms supersede any prior or simultaneous agreement, terms,
                negotiations, whether written or oral, or whether established by
                custom, practice, policy or precedent, between the parties
                hereto. Except as otherwise provided for herein, any waiver,
                modification, or amendment of any provision of this Agreement
                will be effective only if in writing and signed by the parties
                herein. Merchant acknowledges and agrees that in the event a
                purchase order ("PO") contains additional terms, provisions or
                language (" PO Terms "), those PO Terms shall be null and void
                and the terms of the Agreement shall prevail.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Severability.</Typography> In the
                event that any provision of this Agreement is unenforceable or
                invalid such unenforceability or invalidity will not render this
                Agreement unenforceable or invalid as a whole, and in such
                event, such provision will be changed and interpreted so as to
                best accomplish the objectives of such unenforceable or invalid
                provision within the limits of applicable law or applicable
                court decisions.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>No Assignment.</Typography> Merchant
                may not assign this Agreement without the prior written consent
                of Company.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  Governing Law and Jurisdiction.
                </Typography>{' '}
                This Agreement will be governed by and construed in accordance
                with the laws of Barbados without reference to its conflicts of
                laws principles. Each party consents to the exclusive venue and
                jurisdiction of the court in Barbados for any dispute arising
                out of or related to this Agreement. The parties acknowledge and
                agree that this Agreement is made and performed in Barbados. The
                parties hereby waive any right to jury trial with respect to any
                action brought in connection with this Agreement. The
                application of the United Nations Convention of Contracts for
                the International Sale of Goods is expressly excluded.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Export Restrictions.</Typography>{' '}
                Merchant acknowledges and agrees that it shall not import,
                export, or re-export directly or indirectly, any commodity,
                including Merchant's products incorporating or using any Company
                products in violation of the laws and regulations of any
                applicable jurisdiction.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Notice.</Typography> Except as
                otherwise expressly stated in this Agreement, all notices to
                Company shall be in writing and delivered, via courier or
                certified or registered mail, to Company, Attention: Compliance
                Officer, #8, Hastings Plaza, Hastings, Christ Church, Barbados,
                or any other address provided by Company. All notices to you
                shall be delivered to your mailing address or e-mail address as
                provided by you in your account information, as updated by you
                pursuant to this Agreement. Unless you choose to opt-out of
                receiving marketing notices, you authorise Company to notify you
                as our customer, via commercial e-mails, telephone calls and
                other means of communication, of information that we deem is of
                potential interest to you, including without limitation
                communications describing upgrades, new products and services or
                other information pertaining to the Services or other Company
                offerings relating to Internet security or to enhancing your
                identity on the Internet. Notwithstanding the above, Merchant
                shall not have the right to opt-out of service or support
                notices relating to the Services, including without limitation,
                notices of service modifications, security, performance issues
                or technical difficulties.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Headings.</Typography> The section
                headings appearing in the Agreement are inserted only as a
                matter of convenience and in no way define, limit, construe or
                describe the scope or extent of such section or in any way
                affect such section.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Independent Contractors.</Typography>{' '}
                Neither party nor their employees, consultants, contractors or
                agents are agents, employees or joint ventures of the other
                party, and they do not have any authority to bind the other
                party by contract or otherwise to any obligation. Each party
                shall ensure that the foregoing persons shall not represent to
                the contrary, either expressly, implicitly, by appearance or
                otherwise.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>
                  Non-Disparagement; Publicity.
                </Typography>{' '}
                During the term of the Agreement, neither party will disparage
                the other party or the other party's trademarks, web sites,
                products or services, or display any such items in a derogatory
                or negative manner on any Website or in any public forum or
                press release. Unless otherwise stated herein, neither party
                shall issue a press release or otherwise advertise, make a
                public statement or disclose to any third party information
                pertaining to the relationship arising under this Agreement, the
                existence or terms of the Agreement, the underlying transactions
                between Company and Merchant, or referring to the other party in
                relation to the Agreement without the other party's prior
                written approval.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Costs.</Typography> Except as
                expressly stated in the Agreement, each party shall be solely
                responsible for the costs and expenses of performing its
                obligations hereunder.
              </Typography>
              <Typography
                paragraph
                component='li'
                className={classes.upperRoman}
              >
                <Typography component='u'>Equipment.</Typography> Any equipment
                distributed to Merchants, including mobile devices, tablets etc,
                shall remain the property of Company notwithstanding the fact
                that the Company hereby grants permission to the Merchant to use
                the equipment according to the terms of this Agreement. For the
                avoidance of doubt, Company retains the right to require the
                return and/or inspection of the equipment at any time, and may
                in its absolute discretion, modify, add, remove or replace
                software or hardware. The Merchant is granted no interest in the
                equipment and shall not represent themselves as the owner to any
                third-party for any reason, including for unauthorised sale,
                repair or other service.
              </Typography>
            </Typography>
            <Typography paragraph>
              <Typography component='span' className={classes.bold}>
                IN WITNESS WHEREOF
              </Typography>{' '}
              the Parties have executed this Agreement as of the day and year it
              has been agreed to for and on behalf of the said{' '}
              <Typography component='span' className={classes.bold}>
                MERCHANT
              </Typography>{' '}
              by its duly authorised representative as evidenced by the
              selection of the “ACCEPT” button below:
            </Typography>

            <H5 variant='h4'>SCHEDULE 1</H5>
            <H5 variant='h4'>MERCHANT PRICING & LICENSE TIERS</H5>

            <Typography component='div' className={classes.pricing}>
              <Typography component='div' className={classes.pricingContent}>
                <H5 variant='h4' className={classes.center}>
                  MERCHANT
                </H5>
                <Typography paragraph className={classes.center}>
                  This is ideal for merchants who want to try mMoney
                </Typography>
                <Typography component='ol'>
                  <Typography component='li' className={classes.circle}>
                    Accepts mMoney
                  </Typography>
                  <Typography component='li' className={classes.circle}>
                    $0.20 per transaction
                  </Typography>
                </Typography>
              </Typography>
            </Typography>

            <Typography component='div' className={classes.pricing}>
              <Typography component='div' className={classes.pricingContent}>
                <H5 variant='h4' className={classes.center}>
                  MERCHANT-TELLER
                </H5>
                <Typography paragraph className={classes.center}>
                  This is ideal for merchants who want to fully-utilize mMoney,
                  have lower transaction fees and Cash In and Cash Out mMoney
                  Wallets
                </Typography>
                <Typography component='ol'>
                  <Typography component='li' className={classes.circle}>
                    Accepts mMoney
                  </Typography>
                  <Typography component='li' className={classes.circle}>
                    $0.05 per transaction
                  </Typography>
                  <Typography component='li' className={classes.circle}>
                    <Typography component='span' className={classes.bold}>
                      Teller Feature
                    </Typography>
                    : manage physical AND digital currency
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Grid>
        </Card>
      </Grid>
    </EntryLayout>
  )
}

const styles = theme => ({
  card: {
    maxWidth: 1100,
    flex: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1)
  },
  bold: {
    fontWeight: 600,
    fontSize: 18
  },
  upperRoman: {
    listStyle: 'upper-roman'
  },
  lowerAlpha: {
    listStyle: 'lower-alpha'
  },
  circle: {
    listStyle: 'disc'
  },
  redText: {
    color: theme.palette.buttons.red
  },
  center: {
    textAlign: 'center'
  },
  pricing: {
    padding: theme.spacing(6),
    maxWidth: 800,
    border: '1px solid' + theme.palette.darks.black,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: -1
  },
  pricingContent: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
})

export default withStyles(styles)(TermsAndConditions)
