import React from 'react'
import { Typography, withStyles } from '@material-ui/core'

const Label = ({ classes, label }) => {
  return (
    <div className={classes.background}>
      <Typography className={classes.text}>{label}</Typography>
    </div>
  )
}

const styles = theme => ({
  background: {
    display: 'inline-block',
    marginRight: 10,
    marginBottom: 3,
    backgroundColor: theme.palette.lights.grey3,
    padding: '5px 10px',
    borderRadius: 5,
    fontWeight: 500,
    fontSize: 14
  },
  text: {
    color: theme.palette.lights.white
  }
})

export default withStyles(styles)(Label)
