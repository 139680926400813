import React, { useState, useEffect } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  DEFAULT_ONLINE_PAYMENT_OPTIONS,
  getRunCodeSnippet
} from 'src/services/merchant/snippet'
import numbro from 'numbro/dist/numbro'

const mmoneyButtonWrapper = ({ classes, apiKeySelected, values }) => {
  const { amount, currency, paymentButtonId, orderId } = values
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    var timeoutId = setTimeout(() => setLoading(false), 100)

    return () => clearTimeout(timeoutId)
  }, [values])
  return (
    <>
      {loading ? (
        <div className={classes.buttonStub} />
      ) : (
        <Grid item>
          {getRunCodeSnippet({
            ...DEFAULT_ONLINE_PAYMENT_OPTIONS,
            amount: numbro(amount || '1.00').format('0,00'),
            currency: currency.code,
            api_key: apiKeySelected.apiKey,
            merchant_order_id: orderId,
            paymentButtonId
          })}
        </Grid>
      )}
    </>
  )
}

const styles = theme => ({
  buttonStub: {
    height: '58px'
  }
})
mmoneyButtonWrapper.propTypes = {
  values: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  apiKeySelected: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    receiveFunds: PropTypes.bool.isRequired,
    sendRefunds: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
  })
}

export default withStyles(styles)(mmoneyButtonWrapper)
