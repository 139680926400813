import React, { useState, useEffect } from 'react'
import { withStyles, useTheme } from '@material-ui/core/styles'

import Page from 'src/components/page'
import * as R from 'ramda'
import connect from 'react-redux/es/connect/connect'
import { Typography, Grid, useMediaQuery } from '@material-ui/core'
import { Line } from 'react-chartjs-2'
import PropTypes from 'prop-types'

import { actions } from 'src/redux'
import chartData from 'src/redux/chart'
import numbro from 'numbro/dist/numbro'

const ChartOption = ({ type, desc, setter, classes }) => {
  return (
    <Grid item>
      <Typography onClick={() => setter(type)} className={classes}>
        {desc}
      </Typography>
    </Grid>
  )
}

const TransactionMode = ({ type, desc, setter, styling }) => {
  return (
    <Grid item>
      <Typography onClick={() => setter(type)} className={styling}>
        {desc}
      </Typography>
    </Grid>
  )
}

const ChartHeader = ({
  classes,
  requestChartData,
  period,
  transactionType
}) => {
  const [transTab, setTransTab] = useState(
    transactionType === 'merchant' ? 0 : 1
  )
  const [dateRange, setDateRange] = useState(period)

  useEffect(() => {
    requestChartData(dateRange, transTab === 0 ? 'merchant' : 'teller')
  }, [dateRange, transTab])

  return (
    <Grid container justifyContent='space-between' spacing={3} direction='row'>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <TransactionMode
            type={0}
            desc={'Merchant'}
            styling={transTab === 0 ? classes.active : classes.titleText}
            setter={setTransTab}
          />
          <TransactionMode
            type={1}
            desc={'Teller'}
            styling={transTab === 1 ? classes.active : classes.titleText}
            setter={setTransTab}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <ChartOption
            type={0}
            desc={'Today'}
            classes={dateRange === 0 ? classes.active : classes.titleText}
            setter={setDateRange}
          />
          <ChartOption
            type={1}
            desc={'Week'}
            classes={dateRange === 1 ? classes.active : classes.titleText}
            setter={setDateRange}
          />
          <ChartOption
            type={2}
            desc={'Month'}
            classes={dateRange === 2 ? classes.active : classes.titleText}
            setter={setDateRange}
          />
          <ChartOption
            type={3}
            desc={'Year'}
            classes={dateRange === 3 ? classes.active : classes.titleText}
            setter={setDateRange}
          />
          <ChartOption
            type={4}
            desc={'All'}
            classes={dateRange === 4 ? classes.active : classes.titleText}
            setter={setDateRange}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

const ChartSummary = ({ classes, total, transactionNum }) => {
  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.down('xs'))
  const totalString = isXS ? 'transaction #' : 'total transaction'

  return (
    <Grid
      className={classes.summary}
      container
      direction='row'
      alignItems='center'
      justifyContent='space-evenly'
      spacing={4}
    >
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Typography className={classes.summaryTitle}>total sales</Typography>
          <Typography className={classes.summaryData}>
            {numbro(total).formatCurrency('0,0.00')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Typography className={classes.summaryTitle}>
            {totalString}
          </Typography>
          <Typography className={classes.summaryData}>
            {transactionNum}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const overview = ({
  classes,
  requestChartData,
  chartData: { data = [{}], transactionNum = 0, total = 0 },
  ...props
} = {}) => {
  const revenueData = {
    labels: data.map(({ toShow }) => toShow),
    datasets: [
      {
        label: 'BBD $',
        borderColor: '#0057b8',
        backgroundColor: 'rgba(0,87,184,0.35)',
        fill: true,
        data: data.map(({ totalForLabel }) => totalForLabel)
      },
      {
        label: 'Number of Transactions',
        borderColor: '#d9534f',
        backgroundColor: 'rgba(0,87,184,0.35)',
        fill: false,
        data: data.map(({ count }) => count)
      }
    ]
  }

  return (
    <Page title='Overview'>
      <Grid container direction='column'>
        <Grid
          container
          className={classes.container}
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <ChartHeader
            classes={classes}
            requestChartData={requestChartData}
            {...props}
          />
          <ChartSummary
            classes={classes}
            transactionNum={transactionNum}
            total={total}
          />
          <Line className={classes.lineChart} data={revenueData} />
        </Grid>
      </Grid>
    </Page>
  )
}

const styles = theme => ({
  container: {
    padding: '0 2vw',
    maxWidth: '1270px',
    margin: '50px auto',
    width: '100%',
    backgroundColor: theme.palette.lights.white,
    borderRadius: '5px',
    boxShadow: '2px 2px 2px #f1f2f4',
    overflow: 'hidden'
  },
  lineChart: {
    marginBottom: '50px'
  },
  titleText: {
    padding: '10px',
    minWidth: '65px',
    textAlign: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    color: '#d1d6dd',
    fontWeight: '600'
  },
  summary: {
    height: '200px',
    backgroundColor: '#eaf0f8',
    padding: '35px 5vw',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center'
  },
  summaryTitle: {
    textTransform: 'uppercase',
    color: theme.palette.text.dark,
    fontWeight: '700',
    fontSize: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  },
  summaryData: {
    fontSize: '45px',
    lineHeight: '45px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px',
      lineHeight: '40px'
    }
  },
  active: {
    color: theme.palette.darks.light,
    fontWeight: '700',
    minWidth: '65px',
    textAlign: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    padding: '10px'
  },
  transactRow: {
    padding: '25px 35px',
    margin: 0,
    borderBottom: '2px solid' + theme.palette.lights.grey,
    color: theme.palette.text.inactive,
    fontWeight: 'bold'
  }
})

TransactionMode.propTypes = {
  setter: PropTypes.func,
  desc: PropTypes.string,
  type: PropTypes.number
}

ChartOption.propTypes = {
  setter: PropTypes.func,
  desc: PropTypes.string,
  type: PropTypes.number,
  classes: PropTypes.string.isRequired
}

ChartHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

overview.propTypes = {
  classes: PropTypes.object.isRequired
}

ChartSummary.propTypes = {
  classes: PropTypes.object.isRequired
}

Line.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string,
  legend: PropTypes.object,
  options: PropTypes.object,
  redraw: PropTypes.bool,
  getDatasetAtEvent: PropTypes.func,
  getElementAtEvent: PropTypes.func,
  getElementsAtEvent: PropTypes.func
}

Line.defaultProps = {
  data: {
    labels: ['foo', 'foo', 'foo', 'foo', 'foo', 'foo', 'foo', 'foo'],
    datasets: [
      {
        label: '$',
        borderColor: '#0057b8',
        backgroundColor: 'rgba(0,87,184,0.35)',
        fill: true,
        data: [456, 6, 18, 17.78, 15.44, 22, 19, 34]
      }
    ]
  },
  options: {
    legend: {
      display: true
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0
          }
        }
      ]
    }
  }
}

const mapDispatchToProps = dispatch => ({
  requestChartData: (period, transactionType) =>
    dispatch(actions.chartData.getChartData(period, transactionType))
})

const mapStateToProps = state => ({
  chartData: chartData.selectors.chartData(state),
  period: chartData.selectors.period(state),
  transactionType: chartData.selectors.transactionType(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(overview)
