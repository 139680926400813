import helpers from 'src/redux/api'
import { put, call, takeLatest, select, take } from 'redux-saga/effects'
import { actions } from 'src/redux'
import { getAuthToken } from 'src/tools/token'
import { fmtErrors, isInvoicePaid } from 'src/redux/resources/helpers'
import {
  addApiKeyData,
  deleteApiKeyData,
  getApiKeysData,
  addWebHookData,
  getWebHooksData,
  deleteWebHookData
} from 'src/services/merchant/api'
import { mapApiKeys, mapWebhooks } from 'src/redux/api/helpers'
import { Socket } from 'phoenix'
import { eventChannel } from 'redux-saga'
import { mapInvoice } from 'src/redux/transactions/sagas'
import resourcesRedux from 'src/redux/resources'
import usersRedux from 'src/redux/users'
import transactionsRedux from 'src/redux/transactions'
const { selectors: resourceSelectors } = resourcesRedux
const { types: usersActionTypes } = usersRedux

export const eventTypes = {
  INVOICE_CHANGE: 'invoice:change',
  BALANCE_CHANGE: 'balance:change',
  TRANSACTION_CHANGE: 'transaction:change',
  CURRENCY_CHANGE: 'currency:change',
  INFO_CHANGE: 'info:change',
  UNAUTHORIZED: 'unauthorized',
  ON_ERROR: 'onError',
  ON_CLOSE: 'onClose'
}

export const getWebsocketURL = () => {
  return process.env.REACT_APP_MERCHANT_WEBSOCKET_HOST
    ? process.env.REACT_APP_MERCHANT_WEBSOCKET_HOST
    : 'ws://ec2-35-167-227-237.us-west-2.compute.amazonaws.com:1313/socket'
}

const logWesocketEvents = true // change to true to see events
export const logEvent = (type = 'Ooops, undefined... ', event) => {
  if (logWesocketEvents && process.env.NODE_ENV !== 'production') {
    console.log(`----- MORPHEUS WEBSOCKET EVENT™ ----- ${type}`, event)
  }
}

export const createSocketChannel = (socket, { companyId, userId }) => {
  return eventChannel(emit => {
    socket.onOpen(e => {
      const companyChannel = socket.channel(`company:${companyId}`, {})

      companyChannel.on('invoice:change', payload =>
        emit({ type: 'invoice:change', payload })
      )
      companyChannel.on('balance:change', payload => {
        emit({ type: 'balance:change', payload })
      })
      companyChannel.on('transaction:change', payload => {
        emit({ type: 'transaction:change', payload })
      })
      companyChannel.on('currency:change', payload => {
        emit({ type: 'currency:change', payload })
      })

      companyChannel
        .join()
        .receive('ok', resp => {
          logEvent('Joined Company successfully', resp)
        })
        .receive('error', resp => {
          logEvent('Unable to join', resp)
        })

      const userChannel = socket.channel(`user:${userId}`, {})

      userChannel.on('info:change', payload => {
        emit({ type: 'info:change', payload })
      })

      userChannel.on('unauthorized', payload => {
        emit({ type: 'unauthorized', payload })
      })

      userChannel
        .join()
        .receive('ok', resp => {
          logEvent('Joined User successfully', resp)
        })
        .receive('error', resp => {
          logEvent('Unable to join', resp)
        })
    })

    socket.onError(event => {
      emit({ type: 'onError', event })
    })
    socket.onClose(event => {
      emit({ type: 'onClose', event })
    })

    socket.connect()

    const unsubscribe = () => {
      logEvent('Unsubscribe')
    }
    return unsubscribe
  })
}

export function * invoiceChangeSaga (payload) {
  try {
    const lastInvoiceId = yield select(
      transactionsRedux.selectors.lastInvoiceId
    )

    if (payload && lastInvoiceId === payload.id) {
      const mappedInvoiceData = yield call(mapInvoice, payload)
      const receivePaymentType = 'other'
      const isPaid = yield call(isInvoicePaid, mappedInvoiceData)
      yield put(
        actions.transactions.transactionsInvoiceUpdate(
          mappedInvoiceData,
          receivePaymentType
        )
      )
      if (isPaid) {
        yield put(
          actions.notification.notificationShow({
            message: 'Invoice has been fully paid!',
            type: 'success'
          })
        )
        yield put(actions.transactions.transactionsResetInvoice())
        yield put(actions.transactions.transactionsGetLists())
      } else {
        yield put(
          actions.notification.notificationShow({
            message: 'Invoice has been partially paid!',
            type: 'success'
          })
        )
      }
    }
  } catch (e) {}
}

export function * disconnectWebsocketSaga () {
  try {
    const socket = yield select(usersRedux.selectors.socket)
    if (socket) {
      yield call(socket.disconnect)
    }
  } catch (e) {
    // TODO
    yield call(logEvent, 'Problems with disconnecting: ', e)
  }
}

export const constructSocket = (websocketURL, params) => {
  return new Socket(websocketURL, params)
}

export function * connectWebsocketSaga () {
  try {
    const socketFromStore = yield select(usersRedux.selectors.socket)
    // eslint-disable-next-line
    if (!!socketFromStore) {
      return
    }
    const token = yield select(getAuthToken)
    const companyId = yield select(resourceSelectors.companyId)
    const user = yield select(resourceSelectors.userInfo)
    const userId = user && user.userId
    if (token.length > 0) {
      const websocketURL = yield call(getWebsocketURL)
      const socket = yield call(constructSocket, websocketURL, {
        params: { token: 'bitt ' + token }
      })
      const socketChannel = yield call(createSocketChannel, socket, {
        userId,
        companyId
      })
      yield put(actions.users.socketConnected(socket))

      while (true) {
        const { type, payload } = yield take(socketChannel)

        if (type === eventTypes.INVOICE_CHANGE) {
          yield call(logEvent, 'INVOICE_CHANGE, event: ', payload)
          yield call(invoiceChangeSaga, payload)
        }
        if (type === eventTypes.BALANCE_CHANGE) {
          yield call(logEvent, 'BALANCE_CHANGE, event: ', payload)
          if (payload && payload.currencies) {
            yield put(actions.resources.balanceUpdate(payload.currencies))
          }
        }
        if (type === eventTypes.TRANSACTION_CHANGE) {
          yield call(logEvent, 'TRANSACTION_CHANGE, event: ', payload)
          yield put(actions.resources.pendingUpdate(payload.pending))
        }
        if (type === eventTypes.CURRENCY_CHANGE) {
          yield call(logEvent, 'CURRENCY_CHANGE, event: ', payload)
          yield put(actions.resources.currencyChange(payload.currencies))
        }
        if (type === eventTypes.INFO_CHANGE) {
          yield call(logEvent, 'INFO_CHANGE, event: ', payload)
          // TODO
        }
        if (type === eventTypes.UNAUTHORIZED || type === eventTypes.ON_CLOSE) {
          yield call(logEvent, 'UNAUTHORIZED || ON_CLOSE, event: ', payload)
          yield call(socket.disconnect)
        }
        if (type === eventTypes.ON_ERROR) {
          yield call(logEvent, 'ON_ERROR, event: ', payload)
          const tokenExist = yield call(getAuthToken)
          if (!tokenExist) {
            yield call(socket.disconnect)
          }
        }
      }
    }
  } catch (e) {
    yield call(
      logEvent,
      'Problems with connecting socket or handling an event: ',
      e
    )
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * addApiKeySaga ({ apiKey, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    const response = yield call(addApiKeyData, { apiKey, token })
    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'API key added successfully',
          type: 'success'
        })
      )
      yield put(actions.api.addApiKeySuccess())
      yield call(getApiKeysSaga)
      yield call(onFinish)
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * getApiKeysSaga () {
  try {
    const token = yield select(getAuthToken)
    // for api_key
    // eslint-disable-next-line
    const { api_keys } = yield call(getApiKeysData, { token })
    const mappedApiKeys = yield call(mapApiKeys, api_keys)

    yield put(actions.api.getApiKeysSuccess(mappedApiKeys))
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * deleteApiKeySaga ({ id, onCancel }) {
  try {
    const token = yield select(getAuthToken)
    const response = yield call(deleteApiKeyData, { id, token })
    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'API key deleted successfully',
          type: 'success'
        })
      )
      yield put(actions.api.deleteApiKeySuccess())
      yield call(getApiKeysSaga)
      yield call(onCancel)
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * addWebHookSaga ({ webhook, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    const response = yield call(addWebHookData, { webhook, token })
    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'Web Hook added successfully',
          type: 'success'
        })
      )
      yield put(actions.api.addWebHookSuccess())
      yield call(getWebHooksSaga)
      yield call(onFinish)
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * getWebHooksSaga () {
  try {
    const token = yield select(getAuthToken)
    const { webhooks } = yield call(getWebHooksData, { token })
    const webHooksList = yield call(mapWebhooks, webhooks)
    yield put(actions.api.getWebHooksSuccess(webHooksList))
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export function * deleteWebHookSaga ({ id, onCancel }) {
  try {
    const token = yield select(getAuthToken)
    const response = yield call(deleteWebHookData, { id, token })
    if (response.success) {
      yield put(
        actions.notification.notificationShow({
          message: 'Webhook deleted successfully',
          type: 'success'
        })
      )
      yield put(actions.api.deleteWebHookSuccess())
      yield call(getWebHooksSaga)
      yield call(onCancel)
    } else {
      yield put(
        actions.notification.notificationShow({
          message: fmtErrors(response.errors),
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'error'
      })
    )
  }
}

export default function * root () {
  yield takeLatest(helpers.types.ADD_API_KEY, addApiKeySaga)
  yield takeLatest(helpers.types.GET_API_KEYS, getApiKeysSaga)
  yield takeLatest(helpers.types.DELETE_API_KEY, deleteApiKeySaga)
  yield takeLatest(helpers.types.ADD_WEB_HOOK, addWebHookSaga)
  yield takeLatest(helpers.types.GET_WEB_HOOKS, getWebHooksSaga)
  yield takeLatest(helpers.types.DELETE_WEB_HOOK, deleteWebHookSaga)
  yield takeLatest(usersActionTypes.USERS_LOGOUT, disconnectWebsocketSaga)
  yield takeLatest(
    resourcesRedux.types.GET_MERCHANT_INFO_SUCCESS,
    connectWebsocketSaga
  )
}
