import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import * as R from 'ramda'
import FormItem from 'src/components/forms/form-item'
import SelectItem from 'src/components/forms/select-item'
import { get } from 'lodash'
import FlagImage from 'src/components/FlagImage'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Loading from 'src/components/loading'

const GET_COUNTRIES = gql`
  query {
    countries {
      alpha2
      name
      currencyCode
      subdivisions {
        name
      }
    }
  }
`

export const formatOptionLabel = ({ name, ...props }) => (
  <Grid container item alignItems='center'>
    <FlagImage {...props} />
    <Typography align='center'>{name}</Typography>
  </Grid>
)

const ExecutiveForm = ({ classes, formikProps }) => {
  const { values, setFieldValue } = formikProps

  const { data, error, loading } = useQuery(GET_COUNTRIES)

  if (loading) return <Loading />
  if (error) return 'Error'

  const getCountry = countryName =>
    R.find(R.propEq('name', countryName), data.countries)

  const countries = R.map(
    ({ name, alpha2 }) => ({ name, value: name, code: alpha2 }),
    data.countries
  )
  const selectedCountry = getCountry(values.country.value)

  const selectedCountryStates = R.pipe(
    R.propOr([], 'subdivisions'),
    R.map(({ name }) => ({ name, value: name }))
  )(selectedCountry)

  const handleCountryChange = value => {
    setFieldValue('country', value)
    setFieldValue('parish', '')

    const country = getCountry(value.value)
    const currencyCode = get(country, ['currencyCode'], '')
    setFieldValue('currencyCode', currencyCode)
  }

  return (
    <Grid container direction='column'>
      <Grid className={classes.gridRow} item container spacing={2}>
        <Grid item xs={6} sm={4}>
          <FormItem item='Title' formik={formikProps} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormItem item='First Name' formik={formikProps} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormItem item='Last Name' formik={formikProps} />
        </Grid>
      </Grid>
      <Grid className={classes.gridRow} item container spacing={2}>
        <Grid item xs={6} sm={4}>
          <FormItem item='Email' formik={formikProps} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormItem item='Phone Number' formik={formikProps} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormItem item='Date of birth' formik={formikProps} type='date' />
        </Grid>
      </Grid>
      <Grid className={classes.gridRow} item container spacing={2}>
        <Grid item xs={6} sm={4}>
          <SelectItem
            item='Country'
            formikProps={formikProps}
            formatOptionLabel={formatOptionLabel}
            options={countries}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.value}
            onChange={handleCountryChange}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <SelectItem
            item='Parish'
            formikProps={formikProps}
            options={selectedCountryStates}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.value}
            disabled={!selectedCountry}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormItem item='Zip' formik={formikProps} />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs>
          <FormItem item='Address' formik={formikProps} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  gridRow: {
    marginBottom: theme.spacing(0.5)
  }
})

ExecutiveForm.propTypes = {
  classes: PropTypes.object,
  formikProps: PropTypes.object.isRequired
}

export default withStyles(styles)(ExecutiveForm)
