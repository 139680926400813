import React from 'react'
import {
  Grid,
  Typography,
  Card,
  withStyles,
  useMediaQuery
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Divider from 'src/components/divider'
import {
  DEFAULT_ONLINE_PAYMENT_OPTIONS,
  createTestUid
} from 'src/services/merchant/snippet'
import Select from 'src/components/forms/entry/Select'
import * as Yup from 'yup'
import { Formik } from 'formik'
import FormikTextField from 'src/components/formik/FormikTextField'
import { useTheme } from '@material-ui/core/styles'
import { currenciesPropTypes } from 'src/pages/transact/proptypes'
import MmoneyButtonWrapper from 'src/pages/manageAPI/mmoneyButtonWrapper'

const InitialFormValues = ({
  amount,
  currency,
  // eslint-disable-next-line
  merchant_order_id,
  paymentButtonId
}) => {
  return {
    amount,
    currency: currency || { name: 'Barbados', code: 'BBD' },
    orderId: merchant_order_id,
    paymentButtonId
  }
}

const ValidationSchema = Yup.object().shape({
  amount: Yup.number().required('Amount is required'),
  orderId: Yup.string()
    .trim()
    .required('A Unique Merchant Order Identifier is required'),
  paymentButtonId: Yup.string()
    .trim()
    .required('A Payment Button Identifier is required ')
})

const RunLiveTest = ({ classes, apiKeySelected, currencies }) => {
  const defaultApi = DEFAULT_ONLINE_PAYMENT_OPTIONS
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Card direction='column' className={classes.card}>
      <Formik
        initialValues={InitialFormValues(defaultApi)}
        validationSchema={ValidationSchema}
      >
        {({ handleSubmit, ...formikProps }) => (
          <>
            <Grid
              container
              direction={isSmallScreen ? 'column' : 'row'}
              justifyContent='flex-start'
              spacing={1}
            >
              <Grid item container direction='column' xs={12} md={3}>
                <Grid item>
                  <Typography variant='button'>AMOUNT</Typography>
                </Grid>
                <Grid item>
                  <FormikTextField
                    disabled={!apiKeySelected}
                    name='amount'
                    type='number'
                    variant='outlined'
                    fullWidth
                    formik={formikProps}
                  />
                </Grid>
              </Grid>
              <Grid item container direction='column' xs={12} md={3}>
                <Grid item>
                  <Typography variant='button'>CURRENCY</Typography>
                </Grid>
                <Grid item>
                  <Select
                    isDisabled={!apiKeySelected}
                    name='currency'
                    options={currencies}
                    placeholder='Currency'
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.code}
                    isMulti={false}
                    formikProps={formikProps}
                  />
                </Grid>
              </Grid>
              <Grid item container direction='column' xs={12} md={3}>
                <Grid item>
                  <Typography variant='button'>MERCHANT ORDER ID</Typography>
                </Grid>
                <Grid item>
                  <FormikTextField
                    disabled={!apiKeySelected}
                    name='orderId'
                    value={createTestUid()}
                    type='text'
                    variant='outlined'
                    fullWidth
                    formik={formikProps}
                  />
                </Grid>
              </Grid>
              <Grid item container direction='column' xs={12} md={3}>
                <Grid item>
                  <Typography variant='button'>HTML BUTTON ID</Typography>
                </Grid>
                <Grid item>
                  <FormikTextField
                    disabled={!apiKeySelected}
                    name='paymentButtonId'
                    type='text'
                    variant='outlined'
                    fullWidth
                    formik={formikProps}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.dividerHolder}>
              <Divider />
            </Grid>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              className={classes.footerContainer}
            >
              <Grid item>
                {apiKeySelected ? (
                  <MmoneyButtonWrapper
                    apiKeySelected={apiKeySelected}
                    values={formikProps.values}
                  />
                ) : (
                  <Typography variant='body2' className={classes.footer}>
                    * The mMoney payment button will appear here after values
                    are entered & confirmed *
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Card>
  )
}

RunLiveTest.propTypes = {
  classes: PropTypes.object.isRequired,
  apiKeySelected: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    receiveFunds: PropTypes.bool.isRequired,
    sendRefunds: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
  }),
  currencies: currenciesPropTypes
}

const styles = theme => ({
  card: {
    flex: 1,
    padding: '20px 35px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  textField: {
    backgroundColor: theme.palette.lights.light,
    color: theme.palette.lights.grey2,
    fontSize: 14
  },
  currencyTextfield: {
    backgroundColor: theme.palette.lights.grey1,
    color: theme.palette.lights.grey2,
    fontSize: 14
  },
  dividerHolder: {
    margin: '20px 0'
  },
  footer: {
    color: theme.palette.primary.main,
    fontSize: 18
  },
  footerContainer: {
    padding: 15
  }
})

export default withStyles(styles)(RunLiveTest)
