import React from 'react'
import { Button, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const getColorStyle = (colorType, classes) => {
  switch (colorType) {
    case 'red':
      return classes.red
    case 'blue':
      return classes.blue
    case 'green':
      return classes.green
    case 'grey':
      return classes.grey
    default:
      return classes.blue
  }
}
export const StyledButton = ({
  classes,
  children,
  onClick,
  colorType,
  ...props
}) => {
  const colorClass = getColorStyle(colorType, classes)
  return (
    <Button
      {...props}
      variant='contained'
      onClick={onClick}
      className={classNames(classes.button, colorClass)}
    >
      {children}
    </Button>
  )
}

StyledButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  colorType: PropTypes.oneOf(['red', 'blue', 'green']).isRequired
}

const styles = theme => ({
  button: {
    color: theme.palette.buttons.white,
    fontWeight: 400,
    borderRadius: 5,
    height: 40
  },
  blue: {
    backgroundColor: theme.palette.buttons.blue,
    '&:hover': {
      backgroundColor: theme.palette.hovers.blue
    }
  },
  green: {
    backgroundColor: theme.palette.buttons.green,
    '&:hover': {
      backgroundColor: theme.palette.hovers.green
    }
  },
  red: {
    backgroundColor: theme.palette.buttons.red,
    '&:hover': {
      backgroundColor: theme.palette.hovers.red
    }
  }
})

export default withStyles(styles)(StyledButton)
