import * as R from 'ramda'
import uuidv4 from 'uuid/v4'

export const combineModules = reduxModules =>
  R.reduce(
    (accumulator, { STATE_KEY, actions, reducers }) =>
      R.evolve({
        actions: R.assoc(STATE_KEY, actions),
        reducers: R.assoc(STATE_KEY, reducers)
      })(accumulator),
    { actions: {}, reducers: {} }
  )(reduxModules)

export const findMissingKeys = handlers => R.reject(R.has(R.__, handlers))

export const validateReduxHandlers = (stateKey, types, handlers) =>
  R.pipe(
    R.keys,
    findMissingKeys(R.defaultTo({}, handlers)),
    R.unless(R.isEmpty, warnMissingKeys(stateKey))
  )(types)

export const warnMissingKeys = R.curry((stateKey, missingKeys) => {
  const message = `[ERROR]: Missing the following handlers for redux module "${stateKey}": ${JSON.stringify(
    missingKeys
  )}`
  if (process.env.NODE_ENV === 'production') {
    console.error(message)
  } else {
    throw new Error(message)
  }
})

export const generateGUID = () => uuidv4()

export const generateDefaultHandler = () => (state, _args) => state
