import { navigate } from '@reach/router'

export const BasePath = ''

const NavigateWrapper = (to, options) => {
  if (!BasePath) return navigate(to, options)

  if (to[0] === '/') {
    navigate(`${BasePath}${to}`, options)
  } else {
    navigate(to, options)
  }
}

export default NavigateWrapper
