import React, { useState } from 'react'
import AddDocumentModal from 'src/pages/verifications/document/add-document-modal'
import {
  getDocumentTypeTextFromValue,
  documentTypes
} from 'src/pages/verifications/helpers'

import { Grid, Typography, withStyles } from '@material-ui/core'
import * as R from 'ramda'
import AddEditButton from 'src/components/buttons/addEditButton'
import StyledButton from 'src/components/buttons/styledButton'
import PropTypes from 'prop-types'
import shortid from 'shortid'

const displayDocuments = (classes, documents, removeDocument) =>
  documents.map((document, index) => (
    <Grid
      container
      data-testid='document-row'
      item
      alignItems='center'
      className={classes.documentRow}
      spacing={2}
      key={document.id}
    >
      <Grid item xs={6}>
        <Typography variant='subtitle2'>
          {getDocumentTypeTextFromValue(document.documentType, documentTypes)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='subtitle1' noWrap>
          {R.pathOr('', ['file', 'name'], document)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <StyledButton colorType={'red'} onClick={() => removeDocument(index)}>
          Remove
        </StyledButton>
      </Grid>
    </Grid>
  ))

export const DocumentUpload = ({
  classes,
  documents,
  handleAddDocument,
  removeDocument
}) => {
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false)

  const hasDocuments = () => documents.length > 0

  return (
    <>
      <Grid container justifyContent='center'>
        {!hasDocuments() ? (
          <Grid
            item
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
            className={classes.view}
          >
            <Typography variant='h6' paragraph>
              Documents Empty
            </Typography>

            <AddEditButton onClick={() => setOpenAddDocumentModal(true)}>
              Add Document
            </AddEditButton>
          </Grid>
        ) : (
          <>
            <Grid
              container
              item
              className={`${classes.documentsView} ${classes.view}`}
              justifyContent='flex-start'
              alignItems='flex-start'
              direction='column'
              wrap='nowrap'
              sm={10}
              xs={12}
            >
              {displayDocuments(classes, documents, removeDocument)}
            </Grid>
            <Grid container item justifyContent='center'>
              <AddEditButton onClick={() => setOpenAddDocumentModal(true)}>
                Add Document
              </AddEditButton>
            </Grid>
          </>
        )}
      </Grid>

      <AddDocumentModal
        documentTypes={documentTypes}
        handleClose={() => setOpenAddDocumentModal(false)}
        handleAddDocuments={(document, type) => {
          handleAddDocument({
            id: shortid.generate(),
            file: document,
            documentType: type
          })

          setOpenAddDocumentModal(false)
        }}
        hasBase64={false}
        open={openAddDocumentModal}
      />
    </>
  )
}

const styles = theme => ({
  documents: {
    flex: '0.8'
  },
  documentRow: {
    marginBottom: theme.spacing(1)
  },
  buttons: {
    flex: '0.2'
  },
  button: {
    width: 142
  },
  removeDocumentButton: {
    backgroundColor: theme.palette.buttons.red
  },
  verifyButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.lights.white
  },
  documentsView: {
    minWidth: 516,
    overflow: 'scroll',
    padding: `0 ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(2)
  },
  view: {
    height: 400
  }
})

DocumentUpload.propTypes = {
  classes: PropTypes.object,
  documents: PropTypes.array.isRequired,
  handleAddDocument: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired
}

export default withStyles(styles)(DocumentUpload)
