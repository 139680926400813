import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  withStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import * as R from 'ramda'
import { getDisplaySnippet } from 'src/services/merchant/snippet'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
// import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'
import CopyToClipboard from 'src/components/copyToClipboard'
import Divider from 'src/components/divider'
import StyledButton from 'src/components/buttons/styledButton'

const IntegrateCodeSnippet = ({ apiKey, open, onCancel, classes }) => {
  const { firstStep, secondStep, thirdStep } = getDisplaySnippet({
    ...apiKey,
    asCode: true,
    threeSteps: true,
    noDummyData: true
  })

  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='lg'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
      data-testid='howToIntegrateDialog'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        <b>The Code Snippet Is Made Of 3 - Steps</b>
      </DialogTitle>
      <Grid>
        <Grid container item direction='column'>
          <DialogContent>
            <Grid item container direction='column' justifyContent='flex-end'>
              <Typography variant='body1'>
                STEP 1: Insert the mMoney button in your website, where you want
                it to be seen
              </Typography>
              <SyntaxHighlighter language='javascript'>
                {firstStep}
              </SyntaxHighlighter>
              <Grid item container justifyContent='flex-end'>
                <CopyToClipboard
                  toCopy={firstStep}
                  beforeCopyLabel='COPY STEP 1 CODE'
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider style={classes.divider} />
            </Grid>
            <Grid item container direction='column' justifyContent='flex-end'>
              <Typography variant='body1'>
                STEP 2: Place this script tag above your custom js
              </Typography>
              <SyntaxHighlighter language='javascript'>
                {secondStep}
              </SyntaxHighlighter>
              <Grid item container justifyContent='flex-end'>
                <CopyToClipboard
                  toCopy={secondStep}
                  beforeCopyLabel='COPY STEP 2 CODE'
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider style={classes.divider} />
            </Grid>
            <Grid item container direction='column' justifyContent='flex-end'>
              <Typography variant='body1'>
                STEP 3: Use this script or run this function inside your custom
                js
              </Typography>
              <SyntaxHighlighter language='javascript'>
                {thirdStep}
              </SyntaxHighlighter>
              <Grid item container justifyContent='flex-end'>
                <CopyToClipboard
                  toCopy={thirdStep}
                  beforeCopyLabel='COPY STEP 3 CODE'
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider style={classes.divider} />
            </Grid>
          </DialogContent>
          <DialogActions className={classes.buttonsConfirm}>
            <Grid
              container
              direction='row'
              spacing={1}
              justifyContent='flex-end'
            >
              <Grid item>
                <StyledButton onClick={onCancel} colorType={'red'}>
                  Cancel
                </StyledButton>
              </Grid>
              <Grid item>
                <CopyToClipboard
                  toCopy={apiKey}
                  beforeCopyLabel='COPY ALL SNIPPETS'
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  )
}

IntegrateCodeSnippet.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  apiKey: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    receiveFunds: PropTypes.bool.isRequired,
    sendRefunds: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
  }),
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  title: {
    paddingBottom: 0
  },
  cancelButton: {
    backgroundColor: theme.palette.buttons.red,
    color: theme.palette.lights.white
  },
  copyButton: {
    backgroundColor: theme.palette.buttons.blue,
    color: theme.palette.lights.white
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    height: '2px',
    backgroundColor: theme.palette.lights.dividerGrey
  }
})

export default R.pipe(withStyles(styles))(IntegrateCodeSnippet)
