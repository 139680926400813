import { Grid, Typography, Paper, Button, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import navigate from 'src/tools/navigate-wrapper'
import React, { useEffect } from 'react'
import { SetTouchFields } from 'src/pages/signup/helpers'
import { FormFieldNames as OwnerFormFieldNames } from 'src/pages/signup/owner-information'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import usersRedux from 'src/redux/users'

import BusinessInfomationForm from 'src/components/forms/verification/business-information'

export const FormFieldNames = [
  'businessAddress',
  'businessCategory',
  'businessPhoneNumber',
  'businessTradingName',
  'businessType',
  'country',
  'legalBusinessName',
  'parish'
]

const BusinessInformation = ({ classes, ...formikProps }) => {
  const {
    setFieldTouched,
    values,
    validateForm,
    submitForm,
    isSubmitting
  } = formikProps
  useEffect(() => {
    R.pipe(
      R.pick,
      R.pickBy(fieldValue => fieldValue !== ''),
      R.when(R.isEmpty, () => navigate('owner-info'))
    )(OwnerFormFieldNames, values)
  }, [])

  const handleButtonClick = () => {
    validateForm().then(businessErrors => {
      SetTouchFields(FormFieldNames, setFieldTouched)
      R.when(R.isEmpty, () => submitForm())(businessErrors)
    })
  }

  return (
    <>
      <Grid container className={classes.container} direction='column'>
        <Grid item>
          <Typography
            variant='h4'
            align='center'
            gutterBottom
            className={classes.whiteText}
          >
            Basic Business Information
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='subtitle1'
            align='center'
            className={classes.whiteText}
          >
            Great. Your email address was confirmed.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='subtitle1'
            align='center'
            className={classes.whiteText}
          >
            Tell us a bit about your business and you're good to go.
          </Typography>
        </Grid>
        <Grid item className={classes.formContainer}>
          <Paper className={classes.paper}>
            <BusinessInfomationForm formikProps={formikProps} isSignup />
          </Paper>
          <Grid container justifyContent='center'>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='button'
                className={classes.nextStepBtn}
                onClick={handleButtonClick}
                disabled={isSubmitting}
              >
                Next Step
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <input type='hidden' name='currencyCode' />
      </Grid>
    </>
  )
}

const styles = theme => ({
  formContainer: {
    marginTop: 24
  },
  paper: {
    borderRadius: 8
  },
  nextStepBtn: {
    width: 205,
    padding: '12px 18px',
    marginTop: 24
  },
  errorMessage: {
    padding: '0 32px 6px',
    color: '#d9534f'
  },
  bottomBorder: {
    borderBottom: '1px solid #ecf3fb'
  },
  rightBorder: {
    borderRight: '1px solid #ecf3fb'
  },
  whiteText: {
    color: 'white'
  }
})

BusinessInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        subdivisions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired
          })
        ).isRequired
      })
    ).isRequired
  }).isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    businessAddress: PropTypes.string,
    businessCategory: PropTypes.string,
    businessPhoneNumbe: PropTypes.string,
    businessTradingName: PropTypes.string,
    businessType: PropTypes.string,
    country: PropTypes.string,
    legalBusinessName: PropTypes.string,
    parish: PropTypes.string
  }).isRequired,
  categoryOptions: PropTypes.array
}

const mapStateToProps = state => ({
  categoryOptions: usersRedux.selectors.categoryOptions(state)
})

const mapDispatchToProps = dispatch => ({
  getCategories: () => dispatch(actions.users.getCategories())
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BusinessInformation)
