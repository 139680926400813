import React, { useEffect, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { actions } from 'src/redux'
import ActivationPrompt from 'src/pages/dashboard/activation-prompt'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Loading from 'src/components/loading'
import { getAuthToken } from 'src/tools/token'
import ErrorReload from 'src/components/error-reload'
import navigate from 'src/tools/navigate-wrapper'

export const ME_QUERY = gql`
  query RootQueryType {
    me {
      active
      email
      firstName
      lastName
      verified
    }
  }
`
const showOverviewAsIndex = path => {
  if (path !== undefined && path === '') navigate('/dashboard/overview')
}

export const Dashboard = ({ classes, children, resendEmail, ...props }) => {
  const [token, setToken] = useState(undefined)

  const currentPath = props['*']

  useEffect(() => {
    const storedToken = getAuthToken()
    setToken(storedToken)
  }, [token])

  useEffect(() => {
    if (token) showOverviewAsIndex(currentPath)
  }, [currentPath])

  const { data, error, loading, refetch, networkStatus } = useQuery(ME_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  })

  if (loading || networkStatus === 4) return <Loading />
  if (error) return <ErrorReload error={error} reload={() => refetch()} />

  const { email, verified } = data.me || {}

  return (
    <Grid className={classes.container}>
      {!verified && token ? (
        <ActivationPrompt
          email={email}
          resendEmail={resendEmail}
          token={token}
        />
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </Grid>
  )
}

const styles = theme => ({
  container: {
    width: '100%'
  }
})

const mapDispatchToProps = dispatch => ({
  resendEmail: (email, token) =>
    dispatch(actions.users.usersResendEmail(email, token))
})

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(Dashboard)
