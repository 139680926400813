import React from 'react'
import { Formik } from 'formik'
import {
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@material-ui/core'
import FormikTextField from 'src/components/formik/FormikTextField'
import * as Yup from 'yup'
import * as R from 'ramda'
import GoogleMap from './googleMap'
import PropTypes from 'prop-types'
import StyledButton from 'src/components/buttons/styledButton'
import { bpMaxSM } from 'src/tools/screenSize'

const initialFormValues = ({ active = true, address, phone, coordinates }) => ({
  active,
  address,
  phone,
  coordinates
})

const validationSchema = Yup.object().shape({
  address: Yup.string()
    .trim()
    .required('Address cannot be blank'),
  phone: Yup.string()
    .trim()
    .required('A phone number is required')
})

const handleSubmit = (onConfirm, onCancel, id) => (values, actions) => {
  const onFinish = () => {
    onCancel()
    actions.setSubmitting(false)
  }
  onConfirm({ id, ...values }, onFinish)
}

const AddEditLocationDialog = ({
  editing,
  open,
  onCancel,
  onAddLocation,
  onEditLocation,
  classes,
  location
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title' className={classes.dialogTitle}>
        {editing ? `Edit` : `Add`}
        {' Location'}
      </DialogTitle>
      <Grid>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialFormValues({
            active: location.active,
            address: location.address,
            phone: location.phone,
            coordinates: { lat: location.latitude, lng: location.longitude }
          })}
          isInitialValid={validationSchema.isValidSync(
            initialFormValues({
              active: location.active,
              address: location.address,
              phone: location.phone,
              coordinates: { lat: location.latitude, lng: location.longitude }
            })
          )}
          onSubmit={handleSubmit(
            editing ? onEditLocation : onAddLocation,
            onCancel,
            location && location.id
          )}
        >
          {({ handleSubmit, ...formikProps }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                item
                direction='column'
                className={classes.content}
              >
                <DialogContent>
                  <Grid
                    container
                    direction='column'
                    spacing={1}
                    className={classes.containers}
                  >
                    <Grid
                      item
                      container
                      direction='row'
                      alignContent='center'
                      alignItems='center'
                    >
                      <Switch
                        name='active'
                        checked={formikProps.values['active']}
                        onChange={formikProps.handleChange}
                        value={formikProps.values['active']}
                        className={classes.switch}
                      />
                      <Typography align='center' variant='caption'>
                        ACTIVE
                      </Typography>
                    </Grid>
                    <Grid item>
                      <GoogleMap
                        name='coordinates'
                        values={formikProps.values['coordinates']}
                        onChange={values =>
                          formikProps.setFieldValue('coordinates', values)
                        }
                        formikProps={formikProps}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant='body1'>Address</Typography>
                      <FormikTextField
                        name='address'
                        type='string'
                        variant='outlined'
                        placeholder='Address'
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant='body1'>Phone Number</Typography>
                      <FormikTextField
                        name='phone'
                        type='string'
                        variant='outlined'
                        placeholder='Phone Number'
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsConfirm}>
                  <Grid
                    container
                    direction='row'
                    spacing={1}
                    justifyContent='flex-end'
                  >
                    <Grid item>
                      <StyledButton onClick={onCancel} colorType={'red'}>
                        Cancel
                      </StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledButton type='submit' colorType={'green'}>
                        {editing ? `Edit` : `Add`}
                        {' Location'}
                      </StyledButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  )
}

AddEditLocationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddLocation: PropTypes.func.isRequired,
  onEditLocation: PropTypes.func.isRequired,
  location: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    address: PropTypes.string.isRequired,
    companyId: PropTypes.number,
    id: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    phone: PropTypes.string.isRequired
  })
}

AddEditLocationDialog.defaultProps = {
  location: {
    active: false,
    address: '',
    companyId: undefined,
    id: undefined,
    latitude: undefined,
    longitude: undefined,
    phone: ''
  }
}

const styles = theme => ({
  containers: {
    padding: theme.spacing(1)
  },
  dialogTitle: {
    padding: theme.spacing(3, 3, 0),
    [`${bpMaxSM}`]: {
      padding: theme.spacing(2, 2, 0)
    }
  },
  dialogContent: {
    backgroundColor: theme.palette.buttons.green,
    padding: theme.spacing(0),
    margin: 0,
    maxWidth: 600
  },
  switch: {
    color: theme.palette.buttons.green
  },
  buttonsConfirm: {
    padding: theme.spacing(0, 3, 3, 0)
  }
})

export default R.pipe(withStyles(styles))(AddEditLocationDialog)
