import * as R from 'ramda'
import { createActions, createReducer } from 'reduxsauce'
import { validateReduxHandlers } from 'src/redux/helpers'
import { createSelector } from 'reselect'
import usersRedux from 'src/redux/users'

const { types: UsersTypes } = usersRedux

const STATE_KEY = 'transactions'
const initialState = {
  message: undefined,
  lastInvoice: undefined,
  error: undefined,
  transactionsListLeft: undefined,
  transactionsListRight: undefined,
  isLoading: false
}

const { Types, Creators } = createActions({
  transactionsSendFund: ['payload', 'onFinish', 'onCancel'],
  transactionsSendFundSuccess: ['message'],
  transactionsSendFundFail: ['error'],
  transactionsRequestPayment: ['payload', 'onFinish'],
  transactionsRequestPaymentSuccess: [
    'mappedInvoiceData',
    'receivePaymentType'
  ],
  transactionsInvoiceUpdate: ['mappedInvoiceData', 'receivePaymentType'],
  transactionsRequestPaymentFail: ['error'],
  transactionsResetInvoice: [],
  transactionsWithdraw: ['payload', 'onFinish', 'onCancel'],
  transactionsWithdrawSuccess: ['message'],
  transactionsWithdrawFail: ['error'],
  transactionsDeposit: ['payload', 'onFinish', 'onCancel'],
  transactionsDepositSuccess: ['message'],
  transactionsDepositFail: ['error'],
  transactionsGetLists: [],
  transactionsGetListLeft: [],
  transactionsGetListRight: [],
  transactionsGetListLeftSuccess: ['list'],
  transactionsGetListLeftFail: ['list'],
  transactionsGetListRightSuccess: ['list'],
  transactionsGetListRightFail: ['list'],
  transactionsGetListsFail: ['error']
})

const selectors = {
  qrCodeValue: createSelector(
    R.path([STATE_KEY, 'lastInvoice', 'qrCodeValue']),
    R.identity
  ),
  lastInvoiceId: createSelector(
    R.path([STATE_KEY, 'lastInvoice', 'id']),
    R.identity
  ),
  showQRcode: createSelector(
    R.path([STATE_KEY, 'showQRcode']),
    R.identity
  ),
  lastInvoice: createSelector(
    R.path([STATE_KEY, 'lastInvoice']),
    R.identity
  ),
  transactionsListLeft: createSelector(
    R.path([STATE_KEY, 'transactionsListLeft']),
    R.identity
  ),
  transactionsListRight: createSelector(
    R.path([STATE_KEY, 'transactionsListRight']),
    R.identity
  ),
  isLoading: createSelector(
    R.path([STATE_KEY, 'isLoading']),
    R.identity
  )
}

const errorFunc = (state, { error }) =>
  R.merge(state, {
    error: error.message
  })

const sendFund = (state, _args) => state

const sendFundSuccess = (state, { message }) =>
  R.evolve({
    transactions: R.merge(R.__, {
      message,
      error: null
    })
  })(state)

const sendFundFail = errorFunc

const requestPayment = (state, _args) => state

const requestPaymentSuccess = (
  state,
  { mappedInvoiceData, receivePaymentType }
) =>
  R.merge(state, {
    lastInvoice: mappedInvoiceData,
    showQRcode: receivePaymentType !== 'email',
    error: null
  })

const invoiceUpdate = requestPaymentSuccess

const resetInvoice = (state, _args) =>
  R.merge(state, {
    lastInvoice: undefined,
    showQRcode: false,
    error: null
  })

const requestPaymentFail = errorFunc

const withdraw = (state, _args) => state

const withdrawSuccess = (state, { message }) =>
  R.evolve({
    transactions: R.merge(R.__, {
      message,
      error: null
    })
  })(state)

const withdrawFail = errorFunc

const deposit = (state, _args) => state

const depositSuccess = (state, { message }) =>
  R.evolve({
    transactions: R.merge(R.__, {
      message,
      error: null
    })
  })(state)

const depositFail = errorFunc

const getTransactionListLeft = (state, _args) => state

const transactionsGetLists = (state, _args) =>
  R.merge(state, {
    isLoading: true
  })

const getTransactionListLeftSuccess = (state, { list }) =>
  R.merge(state, {
    transactionsListLeft: list,
    error: null,
    isLoading: false
  })

const getTransactionListLeftFail = (state, _args) =>
  R.merge(state, {
    isLoading: false
  })

const getTransactionListRight = (state, _args) => state

const getTransactionListRightSuccess = (state, { list }) =>
  R.merge(state, {
    transactionsListRight: list,
    error: null,
    isLoading: false
  })

const getTransactionListRightFail = (state, _args) =>
  R.merge(state, {
    isLoading: false
  })

const logout = () => initialState

const handlers = {
  [Types.TRANSACTIONS_SEND_FUND]: sendFund,
  [Types.TRANSACTIONS_SEND_FUND_SUCCESS]: sendFundSuccess,
  [Types.TRANSACTIONS_SEND_FUND_FAIL]: sendFundFail,
  [Types.TRANSACTIONS_REQUEST_PAYMENT]: requestPayment,
  [Types.TRANSACTIONS_REQUEST_PAYMENT_SUCCESS]: requestPaymentSuccess,
  [Types.TRANSACTIONS_REQUEST_PAYMENT_FAIL]: requestPaymentFail,
  [Types.TRANSACTIONS_RESET_INVOICE]: resetInvoice,
  [Types.TRANSACTIONS_WITHDRAW]: withdraw,
  [Types.TRANSACTIONS_WITHDRAW_SUCCESS]: withdrawSuccess,
  [Types.TRANSACTIONS_WITHDRAW_FAIL]: withdrawFail,
  [Types.TRANSACTIONS_DEPOSIT]: deposit,
  [Types.TRANSACTIONS_DEPOSIT_SUCCESS]: depositSuccess,
  [Types.TRANSACTIONS_DEPOSIT_FAIL]: depositFail,
  [Types.TRANSACTIONS_GET_LIST_LEFT]: getTransactionListLeft,
  [Types.TRANSACTIONS_GET_LISTS]: transactionsGetLists,
  [Types.TRANSACTIONS_GET_LIST_LEFT_SUCCESS]: getTransactionListLeftSuccess,
  [Types.TRANSACTIONS_GET_LIST_LEFT_FAIL]: getTransactionListLeftFail,
  [Types.TRANSACTIONS_GET_LIST_RIGHT]: getTransactionListRight,
  [Types.TRANSACTIONS_GET_LIST_RIGHT_SUCCESS]: getTransactionListRightSuccess,
  [Types.TRANSACTIONS_GET_LIST_RIGHT_FAIL]: getTransactionListRightFail,
  [Types.TRANSACTIONS_GET_LISTS_FAIL]: getTransactionListLeftSuccess,
  [Types.TRANSACTIONS_INVOICE_UPDATE]: invoiceUpdate,
  [UsersTypes.USERS_LOGOUT]: logout
}
validateReduxHandlers(STATE_KEY, Types, handlers)

const reducers = createReducer(initialState, handlers)

export default {
  actions: Creators,
  handlers,
  reducers,
  selectors,
  STATE_KEY,
  types: Types
}
