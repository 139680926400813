import PropTypes from 'prop-types'

export const filteredRoleProptype = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.number
})

export const roleProptype = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  deposit_funds: PropTypes.bool.isRequired,
  inserted_at: PropTypes.string.isRequired,
  manage_all_transactions: PropTypes.bool.isRequired,
  manage_api: PropTypes.bool.isRequired,
  manage_employee: PropTypes.bool.isRequired,
  manage_information: PropTypes.bool.isRequired,
  manage_own_transactions: PropTypes.bool.isRequired,
  manage_payments: PropTypes.bool.isRequired,
  manage_permission: PropTypes.bool.isRequired,
  receive_funds: PropTypes.bool.isRequired,
  send_funds: PropTypes.bool.isRequired,
  send_refunds: PropTypes.bool.isRequired,
  view_all_transactions: PropTypes.bool.isRequired,
  view_balances: PropTypes.bool.isRequired,
  view_own_transactions: PropTypes.bool.isRequired,
  withdraw_funds: PropTypes.bool.isRequired
})
