import React from 'react'
import FormikTextField from 'src/components/formik/FormikTextField'
import camelCase from 'camelcase'
import Label from 'src/components/forms/label'

const FormItem = ({ item, name, type, placeholder, label, ...props }) => (
  <React.Fragment>
    <Label>{label || item.toUpperCase()}</Label>
    <FormikTextField
      fullWidth
      variant='outlined'
      name={name || camelCase(item)}
      placeholder={placeholder || item}
      type={type}
      {...props}
    />
  </React.Fragment>
)

export default FormItem
