import React, { useState } from 'react'
import { Grid, Card, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import * as R from 'ramda'
import EditCurrencyInformationDialog from 'src/pages/settings/editCurrencyInformationDialog'
import classNames from 'classnames'
import resourcesRedux from 'src/redux/resources'
import { actions } from 'src/redux'
import FlagImage from 'src/components/FlagImage'
import PropTypes from 'prop-types'
import { currenciesPropTypes } from 'src/pages/transact/proptypes'
import StyledButton from 'src/components/buttons/styledButton'

export const CurrencyInformation = ({
  classes,
  currencies,
  updateCurrencyInformation
}) => {
  const [currency, setCurrency] = useState(undefined)
  const [
    editCurrencyInformationOpen,
    setEditCurrencyInformationOpen
  ] = useState(false)

  const handleEditCurrencyInformationClick = currency => () => {
    setCurrency(currency)
    setEditCurrencyInformationOpen(true)
  }

  const handleCancelCurrencyInformationClick = () => {
    setEditCurrencyInformationOpen(false)
    setCurrency(undefined)
  }

  const indicatorClass = currency => {
    if (!currency) {
      return classes.greyCircle
    }
    if (currency.active) {
      return classes.greenCircle
    } else {
      return classes.redCircle
    }
  }

  return (
    <Grid container direction='column'>
      <Card direction='column' className={classes.card}>
        <Grid item>
          <Typography className={classes.heading}>
            mMoney Teller Limits
          </Typography>
        </Grid>
        {currencies &&
          currencies.map(currency => (
            <Grid key={currency.id} container>
              <Card className={classes.infoCard}>
                <Grid
                  container
                  justifyContent='flex-end'
                  className={classes.circleParent}
                >
                  <div
                    className={classNames(
                      indicatorClass(currency),
                      classes.circle
                    )}
                  />
                </Grid>
                <Grid
                  container
                  item
                  alignItems='center'
                  className={classes.codeHolder}
                >
                  {currency ? (
                    <FlagImage code={currency.code} img={currency.img} />
                  ) : (
                    <div />
                  )}
                  <Typography
                    variant='h3'
                    className={classNames(
                      currency ? classes.code : classes.greyCode
                    )}
                  >
                    {currency ? currency.code : 'Country name'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant='button'
                    className={classNames(
                      currency ? classes.dataTitle : classes.greyDataTitle
                    )}
                  >
                    MAX CASH-IN AMOUNT
                  </Typography>
                  <Typography
                    className={classNames(
                      currency ? classes.dataTitle : classes.greyDataTitle
                    )}
                  >
                    {currency ? currency.maxDepositFloat : '- - - '}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant='button'
                    className={classNames(
                      currency ? classes.dataTitle : classes.greyDataTitle
                    )}
                  >
                    MAX CASH-OUT AMOUNT
                  </Typography>
                  <Typography
                    className={classNames(
                      currency ? classes.dataTitle : classes.greyDataTitle
                    )}
                  >
                    {currency ? currency.maxWithdrawFloat : '- - - '}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='center'
                  className={classes.button}
                >
                  {currency ? (
                    <StyledButton
                      colorType={'blue'}
                      onClick={handleEditCurrencyInformationClick(currency)}
                    >
                      Edit
                    </StyledButton>
                  ) : (
                    <StyledButton disabled>Edit</StyledButton>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
        {currency && (
          <EditCurrencyInformationDialog
            open={editCurrencyInformationOpen}
            onCancel={handleCancelCurrencyInformationClick}
            onConfirm={updateCurrencyInformation}
            currency={currency}
          />
        )}
      </Card>
    </Grid>
  )
}
CurrencyInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  currencies: currenciesPropTypes,
  updateCurrencyInformation: PropTypes.func.isRequired
}

const styles = theme => ({
  card: {
    padding: 15,
    marginBottom: 15
  },
  circleParent: {
    position: 'relative'
  },
  heading: {
    fontWeight: 300,
    fontSize: 28,
    marginBottom: 30
  },
  infoCard: {
    bordeRadius: 5,
    width: 200,
    margin: '5px 10px 10px',
    padding: 10
  },
  code: {
    fontSize: 22,
    fontWeight: 700
  },
  greyCode: {
    fontSize: 22,
    fontWeight: 700,
    color: theme.palette.lights.grey3
  },
  codeHolder: {
    margin: '0px 0px 15px'
  },
  circle: {
    width: '15px',
    height: '15px',
    borderRadius: 100,
    position: 'absolute',
    zIndex: 1,
    boxShadow: '0px 0px 4px 4px #e3e7e9'
  },
  redCircle: {
    backgroundColor: theme.palette.buttons.red
  },
  greenCircle: {
    backgroundColor: theme.palette.buttons.green
  },
  greyCircle: {
    backgroundColor: theme.palette.buttons.grey
  },
  dataTitle: {
    padding: '5px 0px 0px 0px',
    fontSize: 12
  },
  greyDataTitle: {
    color: theme.palette.lights.grey3,
    fontSize: 12
  }
})

const mapDispatchToProps = dispatch => ({
  updateCurrencyInformation: (currency, onFinish) =>
    dispatch(actions.settings.updateCurrencyInformation(currency, onFinish))
})

const mapStateToProps = state => ({
  currencies: resourcesRedux.selectors.currencies(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CurrencyInformation)
