import React, { useEffect, useState } from 'react'
import { Grid, Card, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { actions } from 'src/redux'
import LocationCard from 'src/pages/settings/locationCard'
import AddEditLocationDialog from 'src/pages/settings/addEditLocationDialog'
import settingsRedux from 'src/redux/settings'
import AddEditButton from 'src/components/buttons/addEditButton'
import theme from 'src/theme'

export const Location = ({
  classes,
  addLocation,
  editLocation,
  getLocations,
  locations
}) => {
  useEffect(() => {
    getLocations()
    return () => {}
  }, [])

  const [location, setLocation] = useState(undefined)

  const [addEditLocationOpen, setAddEditLocationOpen] = useState(false)

  const handleAddLocationClick = () => {
    setLocation(undefined)
    setAddEditLocationOpen(true)
  }

  const handleEditLocationClick = location => () => {
    setLocation(location)
    setAddEditLocationOpen(true)
  }

  const handleCancelAddEditLocationClick = () => {
    setAddEditLocationOpen(false)
    setLocation(undefined)
  }

  return (
    <Grid container direction='column'>
      <Card direction='column' className={classes.card}>
        <Grid item>
          <Typography className={classes.heading}>Location</Typography>
        </Grid>
        <AddEditButton
          onClick={handleAddLocationClick}
          style={{ margin: theme.spacing(2) }}
        >
          Add Location
        </AddEditButton>
        <Grid container direction='row' wrap='wrap'>
          {locations && locations.length ? (
            locations.map(location => (
              <LocationCard
                key={location.id}
                location={location}
                handleEditLocationClick={handleEditLocationClick(location)}
              />
            ))
          ) : (
            <LocationCard location={false} />
          )}
        </Grid>
        <AddEditLocationDialog
          open={addEditLocationOpen}
          onCancel={handleCancelAddEditLocationClick}
          editing={!!location}
          onAddLocation={addLocation}
          onEditLocation={editLocation}
          location={location}
        />
      </Card>
    </Grid>
  )
}

Location.propTypes = {
  classes: PropTypes.object.isRequired,
  addLocation: PropTypes.func.isRequired,
  editLocation: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      address: PropTypes.string.isRequired,
      companyId: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      phone: PropTypes.string.isRequired
    })
  ).isRequired
}

const styles = theme => ({
  card: {
    padding: 15,
    marginBottom: 15
  },
  heading: {
    fontWeight: 300,
    fontSize: 28,
    marginBottom: 30
  }
})

const mapDispatchToProps = dispatch => ({
  getLocations: () => dispatch(actions.settings.getLocations()),
  addLocation: (location, onFinish) =>
    dispatch(actions.settings.addLocation(location, onFinish)),
  editLocation: (location, onFinish) =>
    dispatch(actions.settings.editLocation(location, onFinish))
})

const mapStateToProps = state => ({
  locations: settingsRedux.selectors.locations(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Location)
