import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import AddDocumentModal from 'src/pages/verifications/document/add-document-modal'
import AddIDModal from 'src/pages/verifications/document/add-id-modal'
import * as R from 'ramda'
import {
  ID_BACK,
  ID_FRONT,
  POA_IMAGE_TYPE,
  PROOF_OF_ADDRESS,
  ACCEPTED_FILES_EXTENSIONS,
  POA_TYPES,
  transformUserDocuments
} from 'src/pages/verifications/helpers'

import * as Yup from 'yup'
import { Formik } from 'formik'
import ExecutiveForm from 'src/pages/verifications/document/executive-form'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  parish: '',
  dateOfBirth: '',
  country: '',
  zip: '',
  address: '',
  title: ''
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First name cannot be blank'),
  lastName: Yup.string()
    .trim()
    .required('Last name cannot be blank'),
  phoneNumber: Yup.string()
    .trim()
    .required('Phone cannot be blank'),
  email: Yup.string()
    .trim()
    .required('Email cannot be blank')
    .email('This email is invalid'),
  parish: Yup.string()
    .trim()
    .required('Parish cannot be blank'),
  dateOfBirth: Yup.date().required('Date of birth cannot be blank'),
  country: Yup.string()
    .trim()
    .required('Country cannot be blank'),
  zip: Yup.string(),
  address: Yup.string()
    .trim()
    .required('Address cannot be blank')
})

const mergeIntoExecutiveDocuments = (addedDocuments, executiveDocuments) =>
  R.pipe(
    R.fromPairs,
    R.mergeRight(executiveDocuments)
  )(addedDocuments)

const addIdentification = (
  action,
  executiveDocuments,
  updateDocumentErrors
) => documents => {
  const updatedDocuments = mergeIntoExecutiveDocuments(
    documents,
    executiveDocuments
  )
  action(updatedDocuments)
  updateDocumentErrors()
}

const addProofOfAddress = (
  action,
  executiveDocuments,
  updateDocumentErrors
) => document => {
  const documents = [
    [PROOF_OF_ADDRESS, { file: document, documentType: POA_IMAGE_TYPE }]
  ]

  const updatedDocuments = mergeIntoExecutiveDocuments(
    documents,
    executiveDocuments
  )

  action(updatedDocuments)
  updateDocumentErrors()
}

const validateDocuments = executiveDocuments => {
  const proofOfAddressError = 'Proof of address required'
  const identityError = 'Identity documents required'

  const errors = {}
  if (R.isEmpty(executiveDocuments)) {
    errors['poa'] = proofOfAddressError
    errors['id'] = identityError

    return errors
  }

  if (!executiveDocuments[PROOF_OF_ADDRESS]) {
    errors['poa'] = proofOfAddressError
  }

  if (!executiveDocuments[ID_FRONT]) {
    errors['id'] = identityError
  }

  return errors
}

const handleSubmit = (
  executiveDocuments,
  submit,
  effect,
  setDocumentErrors
) => (values, _actions) => {
  const consumerInfo = {
    ...values,
    fname: values.firstName,
    lname: values.lastName,
    country: values.country.code,
    dob: values.dateOfBirth,
    phn: values.phoneNumber,
    city: values.parish.value,
    state: values.parish.value
  }

  const documentErrors = validateDocuments(executiveDocuments)

  const documents = transformUserDocuments(executiveDocuments)

  if (!R.isEmpty(documentErrors)) {
    return setDocumentErrors(documentErrors)
  }

  const payload = {
    id: Date.now(),
    consumerInfo,
    formData: values,
    formDocuments: executiveDocuments,
    userDocuments: documents
  }

  submit(payload)
  effect()
}

const displayExecutives = (
  executives,
  classes,
  viewExecutive,
  removeExecutive
) =>
  executives.map(executive => {
    const consumerInfo = executive.consumerInfo
    return (
      <Grid
        container
        data-testid='executive-row'
        item
        className={classes.executiveRow}
        alignItems='center'
        key={executive.id}
      >
        <Grid item xs={8} data-testid='executive-name'>{`${consumerInfo &&
          consumerInfo.title} ${consumerInfo &&
          consumerInfo.firstName} ${consumerInfo &&
          consumerInfo.lastName}`}</Grid>
        <Grid item xs={2}>
          <Button
            color='secondary'
            variant='contained'
            size='small'
            onClick={() => viewExecutive(executive)}
          >
            Edit
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            color='primary'
            variant='contained'
            size='small'
            type='submit'
            onClick={() => removeExecutive(executive.id)}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    )
  })

const viewExecutive = (setMode, setExecutive) => executive => {
  setMode('edit')
  setExecutive(executive)
}

const updateExecutive = (
  selectedExecutive,
  updateExecutiveInfo
) => executiveInfo => {
  const payload = {
    ...executiveInfo,
    id: selectedExecutive.id
  }
  updateExecutiveInfo(payload)
}

const getInitialFormValues = (initialValues, selectedExecutive) =>
  (selectedExecutive && selectedExecutive.formData) || initialValues

const removeDocumentError = (prop, documentErrors, setDocumentErrors) => () => {
  if (documentErrors[prop]) setDocumentErrors(R.dissoc(prop, documentErrors))
}

const submitSideEffect = (
  setMode,
  setDocuments,
  setDocumentErrors,
  setSelectedExecutive
) => () => {
  setMode('view')
  setDocuments({})
  setDocumentErrors({})
  setSelectedExecutive(undefined)
}

export const ExecutiveDocuments = ({
  classes,
  addExecutiveInfo,
  executives,
  updateExecutiveInfo,
  removeExecutive
}) => {
  const [mode, setMode] = useState('view')
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false)
  const [openIDModal, setOpenIDModal] = useState(false)
  const [documents, setDocuments] = useState({})
  const [selectedExecutive, setSelectedExecutive] = useState(undefined)
  const [documentErrors, setDocumentErrors] = useState({})

  useEffect(() => {
    if (selectedExecutive !== undefined) {
      setDocuments(selectedExecutive.formDocuments || {})
    }
  }, [selectedExecutive])

  return (
    <>
      <Grid className={classes.container} container justifyContent='center'>
        {(mode === 'add' || mode === 'edit') && (
          <Grid item xs={12} sm={10}>
            <Formik
              validationSchema={validationSchema}
              initialValues={getInitialFormValues(
                initialValues,
                selectedExecutive
              )}
              onSubmit={handleSubmit(
                documents,
                mode === 'add'
                  ? addExecutiveInfo
                  : updateExecutive(selectedExecutive, updateExecutiveInfo),
                submitSideEffect(
                  setMode,
                  setDocuments,
                  setDocumentErrors,
                  setSelectedExecutive
                ),
                setDocumentErrors
              )}
            >
              {({ handleSubmit, isSubmitting, ...formikProps }) => (
                <form onSubmit={handleSubmit}>
                  <Typography paragraph variant='h6'>
                    Personal Details
                  </Typography>
                  <ExecutiveForm formikProps={formikProps} />
                  <Typography
                    className={classes.headerMarginTop}
                    paragraph
                    variant='h6'
                  >
                    Documents
                  </Typography>
                  <Grid container className={classes.documents} spacing={3}>
                    <Grid item container direction='column' xs={6}>
                      <Typography
                        className={classes.documentsSubheader}
                        gutterBottom
                      >
                        IDENTITY
                      </Typography>
                      <Typography>
                        {documents[ID_FRONT]
                          ? `${documents[ID_FRONT].file &&
                              documents[ID_FRONT].file.name}`
                          : 'No identity submitted.'}
                      </Typography>
                      <Typography paragraph>
                        {documents[ID_BACK]
                          ? `${documents[ID_BACK].file &&
                              documents[ID_BACK].file.name}`
                          : ' '}
                      </Typography>
                      {documentErrors.id ? (
                        <Typography
                          className={classes.documentError}
                          gutterBottom
                          variant='caption'
                        >
                          {documentErrors.id}
                        </Typography>
                      ) : (
                        <span />
                      )}
                      <Grid container>
                        <Button
                          color='primary'
                          variant='contained'
                          size='small'
                          onClick={() => setOpenIDModal(true)}
                        >
                          + Add Document
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item container direction='column' xs={6}>
                      <Typography
                        className={classes.documentsSubheader}
                        gutterBottom
                        variant='subtitle1'
                      >
                        PROOF OF ADDRESS
                      </Typography>
                      <Typography paragraph>
                        {documents[PROOF_OF_ADDRESS]
                          ? documents[PROOF_OF_ADDRESS].file &&
                            documents[PROOF_OF_ADDRESS].file.name
                          : 'No proof of address submitted.'}
                      </Typography>
                      {documentErrors.poa ? (
                        <Typography
                          className={classes.documentError}
                          gutterBottom
                          variant='caption'
                        >
                          {documentErrors.poa}
                        </Typography>
                      ) : (
                        <span />
                      )}
                      <Grid container item>
                        <Button
                          color='primary'
                          variant='contained'
                          size='small'
                          onClick={() => setOpenAddDocumentModal(true)}
                        >
                          + Add Document
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent='center'>
                    <Button
                      className={classes.button}
                      color='primary'
                      variant='contained'
                      type='submit'
                    >
                      {mode === 'add' ? 'Add Director' : 'Update'}
                    </Button>
                    <Button
                      className={classes.button}
                      color='secondary'
                      variant='contained'
                      onClick={submitSideEffect(
                        setMode,
                        setDocuments,
                        setDocumentErrors,
                        setSelectedExecutive
                      )}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        )}
        {mode === 'view' && (
          <Grid container item xs={12} sm={8} justifyContent='center'>
            {executives.length <= 0 ? (
              <Grid
                item
                container
                justifyContent='center'
                alignItems='center'
                direction='column'
                className={classes.view}
              >
                <Typography variant='h6' paragraph>
                  Executive Records Empty
                </Typography>

                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setMode('add')
                  }}
                >
                  + Add Executive
                </Button>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  className={`${classes.executivesView} ${classes.view}`}
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  direction='column'
                  wrap='nowrap'
                >
                  {displayExecutives(
                    executives,
                    classes,
                    viewExecutive(setMode, setSelectedExecutive),
                    removeExecutive
                  )}
                </Grid>
                <Grid container item justifyContent='center'>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => setMode('add')}
                  >
                    + Add Executive
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>
      <AddIDModal
        handleAddDocuments={addIdentification(
          setDocuments,
          documents,
          removeDocumentError('id', documentErrors, setDocumentErrors)
        )}
        handleClose={() => setOpenIDModal(false)}
        hasBase64
        open={openIDModal}
      />

      <AddDocumentModal
        acceptedFileExtensions={ACCEPTED_FILES_EXTENSIONS}
        documentTypes={POA_TYPES}
        handleClose={() => setOpenAddDocumentModal(false)}
        handleAddDocuments={addProofOfAddress(
          setDocuments,
          documents,
          removeDocumentError('poa', documentErrors, setDocumentErrors)
        )}
        hasBase64
        open={openAddDocumentModal}
        promptText='Click or drag to add proof of address'
        title='Add Proof of Address'
      />
    </>
  )
}

const styles = theme => ({
  documents: {
    marginBottom: theme.spacing(4)
  },
  documentError: {
    color: '#f44336'
  },
  documentsSubheader: {
    fontWeight: 600
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    width: 130
  },
  executiveRow: {
    marginTop: 12
  },
  executivesView: {
    minWidth: 516,
    overflow: 'scroll',
    padding: `0 ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(2)
  },
  view: {
    height: 400
  },
  headerMarginTop: {
    marginTop: theme.spacing(3)
  }
})

ExecutiveDocuments.propTypes = {
  classes: PropTypes.object,
  addExecutiveInfo: PropTypes.func.isRequired,
  executives: PropTypes.array.isRequired,
  updateExecutiveInfo: PropTypes.func.isRequired,
  removeExecutive: PropTypes.func.isRequired
}

export default withStyles(styles)(ExecutiveDocuments)
