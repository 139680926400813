import { Grid, ListItem, Typography } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import {
  transactionListType,
  transactionItemType
} from 'src/pages/transact/proptypes'
import { format, parseISO } from 'date-fns'

export const TransactList = ({ items, classes, testId }) => (
  <Grid data-testid={testId} container>
    {items &&
      items.map((item, index) => (
        <TransactListItem
          key={item.date}
          classes={classes}
          item={item}
          showDivider={index < items.length - 1}
        />
      ))}
  </Grid>
)

TransactList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: transactionListType
}

const TransactListItem = ({ item, classes, showDivider }) => (
  <ListItem divider={showDivider} disableGutters>
    <Grid container item direction='column'>
      <Grid item container justifyContent='space-between'>
        <Grid>
          <Typography className={classes.transactType}>
            {item.displayType}
          </Typography>
        </Grid>
        <Grid>
          <Typography className={classes.transactType}>
            {item.amount}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container justifyContent='space-between'>
        <Grid>
          <Typography className={classes.description}>{item.name}</Typography>
        </Grid>
        <Grid>
          <Typography className={classes.description}>
            {format(parseISO(item.date), 'MMM dd yyyy')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </ListItem>
)

TransactListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  showDivider: PropTypes.bool,
  item: transactionItemType
}
