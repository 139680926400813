import React from 'react'
import { Grid, Typography, withStyles, Card } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import EntryLayout from 'src/components/layouts/entry-layout'

export const H2 = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  color: theme.palette.primary.main
}))

export const H5 = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  textDecoration: 'underline',
  color: theme.palette.primary.main
}))

const H4 = H5

const PrivacyPolicy = ({ classes }) => {
  return (
    <EntryLayout>
      <Grid
        container
        item
        justifyContent='center'
        alignContent='center'
        alignItems='center'
        direction='column'
      >
        <Card className={classes.card}>
          <Grid container item direction='column'>
            <H2 variant='h2' className={classes.centered}>
              mMoney Privacy Policy
            </H2>
            <Typography paragraph>
              MMONEY INC., a company incorporated and existing under the laws of
              Barbados with company number 50183 and registered office at #8
              Hastings Plaza, Hastings, Christ Church, Barbados and doing
              business as (“<b>mMoney</b>”) welcomes you on its platform (the “
              <b>Platform</b>”).
            </Typography>
            <Typography paragraph>
              This privacy policy (the “<b>Privacy Policy</b>”) explains how we
              collect, use, share, disclose and protect personal information
              about the users of the Platform, and the visitors of Platform
              (jointly and severally referred to as “<b>you</b>” or “
              <b>Users</b>” in this Privacy Policy). We created this Privacy
              Policy to demonstrate our commitment to the protection of your
              privacy and your personal information. Your use of and access to
              the Platform is subject to this Privacy Policy.
            </Typography>
            <Typography paragraph>
              The Platform may in its entirety or in part be protected by
              copyright, trademark, and/or other laws. You acknowledge and agree
              that the Platform and content thereon, including all associated
              intellectual property rights, are the property of Platform and/or
              its licensors or authorising third-parties. You will not remove,
              alter or obscure any copyright, trademark, service mark or other
              proprietary rights notices incorporated in or accompanying the
              Platform or any content. All trademarks, service marks, logos,
              trade names, and any other source identifiers of Platform used on
              or in connection with the Platform vest solely with Platform.
              Trademarks, service marks, logos, trade names and any other
              proprietary designations of third parties used on or in connection
              with the Platform and/or the content are used for identification
              purposes only and may be the property of their respective owners.
            </Typography>

            <H4 variant='h4' className={classes.centered}>
              COLLECTION OF PERSONAL INFORMATION
            </H4>
            <Typography paragraph>
              Generally, some aspects of the Platform require us to know who you
              are so that we can best meet your needs. When you access the
              Platform, or through any interaction with us via emails, telephone
              calls or other correspondence, we may ask you to voluntarily
              provide us with certain information that personally identifies you
              or could be used to personally identify you. You hereby consent to
              the collection of such information by Platform. Without prejudice
              to the generality of the above, information collected by us from
              you may include (but is not limited to) the following:
            </Typography>

            <ol>
              <li>
                contact data (such as your email address and phone number);
              </li>
              <li>
                demographic data (such as your gender, your date of birth and
                your pin code);
              </li>
              <li>
                data regarding your usage of the Platform and history of the use
                of Platform;
              </li>
              <li>
                other information that you voluntarily choose to provide to us
                (such as information shared by you with us through emails,
                instant messaging or letters).
              </li>
              <li>
                financial information such as wallet address, bank accounts,
                credit and debit card details or other payment instrument
                details; and,
              </li>
              <li>
                details as provided at the time of registration or thereafter.
              </li>
            </ol>
            <Typography paragraph>
              Platform will be free to use, collect and disclose information
              that is freely available in the public domain without your
              consent.
            </Typography>

            <H4 variant='h4' className={classes.centered}>
              PRIVACY STATEMENTS
            </H4>
            <Typography paragraph>
              A condition of each User’s use of and access to the Platform is
              their acceptance of the terms of this Privacy Policy. Any User
              that does not agree with any provisions of the same has the option
              to discontinue the Platform immediately.
            </Typography>
            <Typography paragraph>
              All the information provided to Platform by a User is voluntary.
              You understand that Platform may use certain information of yours
              (a) for the purpose of providing you the Platform, (b) for
              commercial purposes and in an aggregated or non-personally
              identifiable form for research, statistical analysis and business
              intelligence purposes, (c) for sale or transfer of such research,
              statistical or intelligence data in an aggregated or
              non-personally identifiable form to third parties and affiliates
              (d) for communication purpose so as to provide You a better way of
              booking appointments and for obtaining feedback, (e) debugging
              customer support related issues.
            </Typography>
            <Typography paragraph>
              Platform also reserves the right to use information provided by or
              about the User for the following purposes:
            </Typography>
            <ol>
              <li>Contacting Users for offering new products or services.</li>
              <li>Contacting Users for taking product and Service feedback.</li>
              <li>
                Analysing software usage patterns for improving product design
                and utility.
              </li>
              <li>
                Analysing anonymized practice information for commercial use.
              </li>
            </ol>

            <Typography paragraph>
              By affirming your assent to this Privacy Policy, you provide your
              consent to such use, collection and disclosure as required under
              applicable law.
            </Typography>

            <Typography paragraph>
              You are responsible for maintaining the accuracy of the
              information you submit to us, such as your contact information
              provided as part of account registration. If your personal
              information changes, you may correct, delete inaccuracies, or
              amend information by making the change on our member information
              page or by contacting us. We will make good faith efforts to make
              requested changes in our then active databases as soon as
              reasonably practicable. If you provide any information that is
              untrue, inaccurate, out of date or incomplete (or becomes untrue,
              inaccurate, out of date or incomplete), or Platform has reasonable
              grounds to suspect that the information provided by you is untrue,
              inaccurate, out of date or incomplete, Platform may, at its sole
              discretion, discontinue the provision of the Platform to you.
              There may be circumstances where Platform will not correct, delete
              or update your personal data, including (a) where the personal
              data is opinion data that is kept solely for evaluative purpose;
              and (b) the personal data is in documents related to a prosecution
              if all proceedings relating to the prosecution have not been
              completed.
            </Typography>

            <Typography paragraph>
              If you wish to cancel your account or request that we no longer
              use your information to provide you Platform, contact us. We will
              retain your information for as long as your account with the
              Platform is active and as needed to provide you the Platform. We
              shall not retain such information for longer than is required for
              the purposes for which the information may lawfully be used or is
              otherwise required under any other law for the time being in
              force. After a period of time, your data may be anonymized and
              aggregated, and then may be held by us as long as necessary for us
              to provide our Platform effectively, but our use of the anonymized
              data will be solely for analytic purposes. Please note that your
              withdrawal of consent, or cancellation of account may result in
              Platform being unable to provide you with its Platform or to
              terminate any existing relationship Platform may have with you.
            </Typography>

            <Typography paragraph>
              If you wish to opt-out of receiving non-essential communications
              such as promotional and marketing-related information regarding
              the Platform, please send us an email.
            </Typography>
            <Typography paragraph>
              Platform may require the User to pay with a wallet address, credit
              card, wire transfer, debit card or cheque for Platform for which
              amount(s) is/are payable. Platform will collect such User’s wallet
              address, credit card number and/or other financial institution
              information such as bank account numbers and will use that
              information for the billing and payment processes, including but
              not limited to the use and disclosure of such credit card number
              and information to third parties as necessary to complete such
              billing operation. Verification of credit information, however, is
              accomplished solely by the User through the authentication
              process. User’s credit-card/debit card details are transacted upon
              secure sites of approved payment gateways which are digitally
              under encryption, thereby providing the highest possible degree of
              care as per current technology. However, Platform provides you an
              option not to save your payment details. Users are advised,
              however, that internet technology is not full proof safe and Users
              should exercise discretion on using the same.
            </Typography>

            <Typography paragraph>
              Due to the communications standards on the Internet, when a User
              or the End-User or anyone who visits the Platform, Platform
              automatically receives the URL of the site from which anyone
              visits. Platform also receives the Internet Protocol (IP) address
              of each User’s computer (or the proxy server a User used to access
              the World Wide Web), User’s computer operating system and type of
              web browser the User is using, email patterns, as well as the name
              of User’s ISP. This information is used to analyse overall trends
              to help Platform improve its Service. The linkage between User’s
              IP address and User’s personally identifiable information is not
              shared with or disclosed to third parties. Notwithstanding the
              above, Platform may share and/or disclose some of the aggregate
              findings (not the specific data) in anonymized form (i.e.,
              non-personally identifiable) with advertisers, sponsors,
              investors, strategic partners, and others in order to help grow
              its business.
            </Typography>

            <Typography paragraph>
              The Platform uses temporary cookies to store certain information
              that is used by Platform and its service providers for the
              technical administration of the Platform, research and
              development, and for User administration. In the course of serving
              advertisements or optimising services to its Users, Platform may
              allow authorised third parties to place or recognize a unique
              cookie on the User’s browser. The cookies however, do not store
              any personal information of the User. You may adjust your internet
              browser to disable cookies. If cookies are disabled you may still
              use the Platform, but the Platform may be limited in the use of
              some of the features.
            </Typography>

            <Typography paragraph>
              A User may have limited access to the Platform without creating an
              account on the Platform. In order to have access to all the
              features and benefits on our Platform, a User must first create an
              account on our Platform. To create an account, a User is required
              to provide the following information, which such User recognizes
              and expressly acknowledges is personal information allowing
              others, including Platform, to identify the User: name, User ID,
              email address, state, ZIP/postal code, age, phone number, password
              chosen by the User and valid financial account information. Other
              information requested on the registration page, including the
              ability to receive promotional offers from Platform, is optional.
              Platform may, in future, include other optional requests for
              information from the User to help Platform to customise the
              Platform to deliver personalised information to the User.
            </Typography>

            <Typography paragraph>
              Platform does not exercise control over the sites displayed as
              search results or links from within its Platform. These other
              sites may place their own cookies or other files on the Users’
              computer, collect data or solicit personal information from the
              Users, for which Platform is not responsible or liable.
              Accordingly, Platform does not make any representations concerning
              the privacy practices or policies of such third parties or terms
              and conditions of such platforms, nor does Platform guarantee the
              accuracy, integrity, or quality of the information, data, text,
              software, sound, photographs, graphics, videos, messages or other
              materials available on such Platforms. If you decide to visit a
              third-party Platform linked to the Platform, you do this entirely
              at your own risk.
            </Typography>

            <Typography paragraph>
              Platform has implemented best international market practices and
              security policies, rules and technical measures to protect the
              personal data that it has under its control from unauthorised
              access, improper use or disclosure, unauthorised modification and
              unlawful destruction or accidental loss. However, for any data
              loss or theft due to unauthorised access to the User’s electronic
              devices through which the User avails the Platform, Platform shall
              not be held liable for any loss whatsoever incurred by the User.
            </Typography>

            <Typography paragraph>
              Platform takes your right to privacy very seriously and other than
              as specifically stated in this Privacy Policy, will only disclose
              your personal information in the event it is required to do so by
              law, rule, regulation, law enforcement agency, governmental
              official, legal authority or similar requirements or when
              Platform, in its sole discretion, deems it necessary in order to
              protect its rights or the rights of others, to prevent harm to
              persons or property, to fight fraud and credit risk, or to enforce
              or apply the Terms and Conditions.
            </Typography>

            <H4 variant='h4' className={classes.centered}>
              CONFIDENTIALITY AND SECURITY
            </H4>

            <Typography paragraph>
              Your personal information is maintained by Platform in electronic
              form on its equipment, and on the equipment of its employees. Such
              information may also be converted to physical form from time to
              time. Platform takes all necessary precautions to protect your
              personal information both online and off-line, and implements
              reasonable security practices and measures including certain
              managerial, technical, operational and physical security control
              measures that are commensurate with respect to the information
              being collected and the nature of Platform’s business.
            </Typography>

            <Typography paragraph>
              No administrator at Platform will have knowledge of your password.
              It is important for you to protect against unauthorised access to
              your password, your computer and your mobile phone. Be sure to log
              off from the Platform when finished. Platform does not undertake
              any liability for any unauthorised use of your account and
              password. If you suspect any unauthorised use of your account, you
              must immediately notify Platform by sending an email. You shall be
              liable to indemnify Platform due to any loss suffered by it due to
              such unauthorised use of your account and password.
            </Typography>

            <Typography paragraph>
              Platform makes all User information accessible to its employees,
              agents or partners and third parties only on a need-to-know basis,
              and binds only its employees to strict confidentiality
              obligations.
            </Typography>

            <Typography paragraph>
              Platform is not responsible for the confidentiality, security or
              distribution of your personal information by our partners and
              third parties outside the scope of our agreement with such
              partners and third parties. Further, Platform shall not be
              responsible for any breach of security or for any actions of any
              third parties or events that are beyond the reasonable control of
              Platform including but not limited to, acts of government,
              computer hacking, unauthorised access to computer data and storage
              device, computer crashes, breach of security and encryption, poor
              quality of Internet service or telephone service of the User etc.
            </Typography>

            <H4 variant='h4' className={classes.centered}>
              CHANGE TO PRIVACY POLICY
            </H4>

            <Typography paragraph>
              Platform may update this Privacy Policy at any time, with or
              without advance notice. In the event there are significant changes
              in the way Platform treats User’s personally identifiable
              information, or in the Privacy Policy itself, Platform will
              display a notice on the Platform or send Users an email, as
              provided for above, so that you may review the changed terms prior
              to continuing to use the Platform. As always, if you object to any
              of the changes to our terms, and you no longer wish to use the
              Platform, you may contact us to deactivate your account. Unless
              stated otherwise, Platform’s current Privacy Policy applies to all
              information that Platform has about you and your account.
            </Typography>

            <Typography paragraph>
              If a User uses the Platform or accesses the Platform after a
              notice of changes has been sent to such User or published on the
              Platform, such User hereby provides his/her/its consent to the
              changed terms.
            </Typography>

            <H4 variant='h4' className={classes.centered}>
              YOUR RIGHTS
            </H4>

            <Typography paragraph>You have the following rights:</Typography>

            <ol>
              <li>
                <b>Right to Access–</b> You can send a request to us for getting
                a copy of the personal information which is in our possession.
                Upon receiving the same, we will, without any cost, provide the
                details of the same.
              </li>
              <li>
                <b>Right to Amend/Rectify-</b> In the event that you wish to
                update or change any information which is or becomes inaccurate,
                we shall amend the same upon receiving the request from you.
              </li>
              <li>
                <b>Right to Delete (or Right to be Forgotten)-</b> Upon
                receiving the request from you for deletion of personal
                information, we shall delete the same subject to applicable
                laws.
              </li>
              <li>
                <b>Right to Restriction of Processing–</b> In the event that you
                wish to restrict or process the personal information due to the
                information (i) being incorrect (ii) being not lawfully
                processed (iii) being deemed by you as not required by us or
                (iv) you decide so in sole discretion, we shall cease from
                processing your personal information upon receiving a written
                request.
              </li>
              <li>
                <b>Right of Data Transfer–</b> Upon receiving a request from
                you, we shall provide personal information available with us to
                you in a commonly used machine-readable format.
              </li>
              <li>
                <b>Right of Notification-</b> If in case, despite our best
                efforts, we suffer a data breach which in our opinion may put
                your personal information at risk, we shall notify you and
                relevant authorities within 72 hours of us becoming aware of
                such breach.
              </li>
            </ol>

            <H4 variant='h4' className={classes.centered}>
              GRIEVANCE REDRESSAL
            </H4>

            <Typography paragraph>
              For any clarifications, issues, matters, complaints and grievances
              pertaining to this Privacy Policy, please contact us at{' '}
              <a href='mailto:support@mmoneybb.com'>support@mmoneybb.com</a>.
            </Typography>

            <H4 variant='h4' className={classes.centered}>
              GOVERNING LAW
            </H4>

            <Typography paragraph>
              This Privacy Policy shall be governed by the laws of Barbados;
              and, shall be subject to the exclusive jurisdiction of courts at
              Barbados.
            </Typography>
          </Grid>
        </Card>
      </Grid>
    </EntryLayout>
  )
}

const styles = theme => ({
  card: {
    maxWidth: 1000,
    flex: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1)
  },
  container: {
    padding: theme.spacing(1, 1)
  },
  centered: {
    textAlign: 'center'
  }
})
export default withStyles(styles)(PrivacyPolicy)
