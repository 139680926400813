import React from 'react'
import { Formik } from 'formik'
import {
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@material-ui/core'
import FormikTextField from 'src/components/formik/FormikTextField'
import * as Yup from 'yup'
import * as R from 'ramda'
import gql from 'graphql-tag'
import StyledButton from 'src/components/buttons/styledButton'
import startAdornment from 'src/components/inputFields/startAdornment'

import { useMutation } from '@apollo/react-hooks'
import { GET_PAYMENTS } from 'src/pages/staticPayments/static-payments'

const ADD_PAYMENT = gql`
  mutation RootMutationType($amount: Int!, $name: String!) {
    createStaticPayment(staticPayment: { amount: $amount, name: $name }) {
      id
      active
      amount
      name
    }
  }
`

const EDIT_PAYMENT = gql`
  mutation RootMutationType(
    $id: Int!
    $name: String!
    $amount: Int!
    $active: String!
  ) {
    updateStaticPayment(
      id: $id
      changes: { name: $name, amount: $amount, active: $active }
    ) {
      id
      name
      amount
      active
    }
  }
`

const initialFormValues = ({ name = '', amount = '', active = true }) => ({
  name,
  amount,
  active
})

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Name cannot be blank'),
  amount: Yup.number()
    .positive('Amount cannot be negative')
    .required('Must be a valid amount')
})

const updateOption = (cache, { data: { createStaticPayment } }) => {
  const { staticPayments } = cache.readQuery({ query: GET_PAYMENTS })

  cache.writeQuery({
    query: GET_PAYMENTS,
    data: { staticPayments: R.insert(0, createStaticPayment, staticPayments) }
  })
}

const handleSubmit = (mutation, isEditing, id) => (values, _actions) => {
  const { active, name, amount } = values

  const payload = isEditing
    ? { variables: { id, active, amount: amount * 100, name } }
    : { variables: { amount: amount * 100, name }, update: updateOption }

  mutation(payload)
}

const AddEditPayment = ({
  open,
  onCancel,
  editing,
  classes,
  showNotification,
  payment = {}
}) => {
  const [mutation, { loading }] = useMutation(
    editing ? EDIT_PAYMENT : ADD_PAYMENT,
    {
      onCompleted: () => onCancel(),
      onError: () => showNotification('Failed to add static paymennt', 'error')
    }
  )

  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        {editing ? `Edit` : `Add`}
        {' Static Payment'}
      </DialogTitle>
      <Grid>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialFormValues(payment)}
          isInitialValid={validationSchema.isValidSync(
            initialFormValues(payment)
          )}
          onSubmit={handleSubmit(mutation, editing, payment.id, onCancel)}
        >
          {({ handleSubmit, ...formikProps }) => (
            <form onSubmit={handleSubmit}>
              <Grid container item direction='column'>
                <DialogContent>
                  <Grid
                    container
                    direction='column'
                    spacing={1}
                    className={classes.containers}
                  >
                    <Grid item container direction='row' alignItems='center'>
                      <Switch
                        name='active'
                        checked={formikProps.values['active']}
                        defaultChecked
                        onChange={formikProps.handleChange}
                        value={formikProps.values['active']}
                        className={classes.switch}
                      />
                      <Typography align='center' variant='caption'>
                        ACTIVE
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant='caption'>Name</Typography>
                      <FormikTextField
                        name='name'
                        type='text'
                        variant='outlined'
                        placeholder='Name'
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant='caption'>Amount</Typography>
                      <FormikTextField
                        name='amount'
                        type='number'
                        variant='outlined'
                        placeholder='Amount'
                        InputProps={startAdornment('$')}
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsConfirm}>
                  <Grid
                    container
                    direction='row'
                    spacing={1}
                    justifyContent='flex-end'
                  >
                    <Grid item>
                      <StyledButton onClick={onCancel} colorType={'red'}>
                        Cancel
                      </StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledButton
                        type='submit'
                        colorType={'green'}
                        disabled={loading}
                      >
                        {editing ? `Edit` : `Create Payment`}
                      </StyledButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  )
}

const styles = theme => ({
  card: {
    flex: 1,
    padding: theme.spacing(1)
  },
  title: {
    paddingBottom: 0
  },
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  },
  switch: {
    color: theme.palette.buttons.green
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  }
})

export default R.pipe(withStyles(styles))(AddEditPayment)
