import helpers from 'src/redux/transactions'
import { put, call, takeLatest, select } from 'redux-saga/effects'
import { actions } from 'src/redux'
import { getAuthToken } from 'src/tools/token'
import { mergingTransactionData } from 'src/redux/transactions/helpers'
import * as transactions from 'src/services/merchant/transation'
import { generateGUID } from 'src/redux/helpers'

export function * sendFundSaga ({ payload, onFinish, onCancel }) {
  try {
    const token = yield select(getAuthToken)
    const guid = yield call(generateGUID)

    const { message } = yield call(
      transactions.sendFund,
      { guid, ...payload },
      token
    )
    yield put(actions.transactions.transactionsSendFundSuccess(message))
    yield put(
      actions.notification.notificationShow({
        message: 'Your payment has been sent.',
        type: 'success'
      })
    )
    yield call(getTransactionsSaga)
    yield call(onFinish)
    yield call(onCancel)
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: 'Your payment has not been sent.',
        type: 'fail'
      })
    )
    yield put(actions.transactions.transactionsSendFundFail(e))
    yield call(onFinish)
  }
}

export function * withdrawSaga ({ payload, onFinish, onCancel }) {
  try {
    const token = yield select(getAuthToken)
    const guid = yield call(generateGUID)

    const { message } = yield call(
      transactions.withdraw,
      { guid, ...payload },
      token
    )
    yield put(actions.transactions.transactionsWithdrawSuccess(message))
    yield put(
      actions.notification.notificationShow({
        message: 'Your payment has been withdrawn.',
        type: 'success'
      })
    )
    yield call(getTransactionsSaga)
    yield call(onFinish)
    yield call(onCancel)
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: 'Your payment has not been withdrawn.',
        type: 'fail'
      })
    )
    yield put(actions.transactions.transactionsWithdrawFail(e))
    yield call(onFinish)
  }
}

export function * depositSaga ({ payload, onFinish, onCancel }) {
  try {
    const token = yield select(getAuthToken)
    const guid = yield call(generateGUID)

    const { message } = yield call(
      transactions.deposit,
      { guid, ...payload },
      token
    )
    yield put(actions.transactions.transactionsDepositSuccess(message))
    yield put(
      actions.notification.notificationShow({
        message: 'You have successfully deposited.',
        type: 'success'
      })
    )
    yield call(getTransactionsSaga)
    yield call(onFinish)
    yield call(onCancel)
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: 'Deposit has failed.',
        type: 'fail'
      })
    )
    yield put(actions.transactions.transactionsDepositFail(e))
    yield call(onFinish)
  }
}

export function * getTransactionsSaga () {
  try {
    const token = yield call(getAuthToken)
    const { transactions: sentTransactions } = yield call(
      transactions.getTransactions,
      {
        token,
        type: 'debit'
      }
    )
    const { transactions: receivedTransactions } = yield call(
      transactions.getTransactions,
      {
        token,
        type: 'invoice'
      }
    )
    const { transactions: withdrawTransactions } = yield call(
      transactions.getWithdrawTransactions,
      {
        token,
        type: 'withdraw'
      }
    )

    const curatedSentReceived = yield call(
      mergingTransactionData,
      sentTransactions,
      receivedTransactions
    )
    const curatedWithdrawDeposit = yield call(
      mergingTransactionData,
      withdrawTransactions,
      []
    )
    yield put(
      actions.transactions.transactionsGetListLeftSuccess(curatedSentReceived)
    )
    yield put(
      actions.transactions.transactionsGetListRightSuccess(
        curatedWithdrawDeposit
      )
    )
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'fail'
      })
    )
  }
}

export const mapInvoice = invoice => ({
  id: invoice.id,
  address: invoice.address,
  qrCodeValue: invoice.qr_str,
  priceFloat: invoice.price_float,
  tellerPayout: invoice.teller_payout,
  transactionFeeFloat: invoice.transaction_fee_float,
  paidFloat: invoice.paid_float,
  currency: invoice.currency
})

export function * requestPaymentSaga ({ payload, onFinish }) {
  try {
    const token = yield call(getAuthToken)
    const { invoice } = yield call(transactions.requestPayment, {
      token,
      ...payload
    })
    const mappedInvoiceData = yield call(mapInvoice, invoice)
    const receivePaymentType = payload.customerEmail ? 'email' : 'other'

    yield call(onFinish)
    yield put(
      actions.transactions.transactionsRequestPaymentSuccess(
        mappedInvoiceData,
        receivePaymentType
      )
    )

    const message =
      receivePaymentType === 'email'
        ? 'Payment requested successfully'
        : 'Show the QR code to be scanned'
    yield put(
      actions.notification.notificationShow({
        message,
        type: 'success'
      })
    )
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'fail'
      })
    )
  }
}

export default function * root () {
  yield takeLatest(helpers.types.TRANSACTIONS_SEND_FUND, sendFundSaga)
  yield takeLatest(helpers.types.TRANSACTIONS_WITHDRAW, withdrawSaga)
  yield takeLatest(helpers.types.TRANSACTIONS_DEPOSIT, depositSaga)
  yield takeLatest(helpers.types.TRANSACTIONS_GET_LISTS, getTransactionsSaga)
  yield takeLatest(
    helpers.types.TRANSACTIONS_REQUEST_PAYMENT,
    requestPaymentSaga
  )
}
