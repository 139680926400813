import * as R from 'ramda'
import { merchantApiUrl } from 'src/services/lib/url-builders'
import { fetchJSON } from 'src/services/lib/fetch'

export const postJSON = (
  path,
  data,
  token = null,
  fetcher = fetchJSON,
  headers = {}
) => generateAPIHandler(path, data, token, fetcher, 'POST', headers)

export const deleteJSON = (
  path,
  data,
  token = null,
  fetcher = fetchJSON,
  headers = {}
) => generateAPIHandler(path, data, token, fetcher, 'DELETE', headers)

export const postFormData = (
  path,
  data,
  token = null,
  method = 'POST',
  fetcher = fetchJSON
) =>
  fetcher(merchantApiUrl(path), {
    body: data,
    headers: scrubNullHeaders({
      Authorization: 'bitt ' + token
    }),
    method
  })

export const generateAPIHandler = (
  path,
  data,
  token = null,
  fetcher = fetchJSON,
  method = 'POST',
  headers = {}
) =>
  fetcher(merchantApiUrl(path), {
    body: data ? JSON.stringify(data) : '',
    headers: scrubNullHeaders({
      'Content-Type': 'application/json',
      Authorization: 'bitt ' + token,
      ...headers
    }),
    method
  })

export const getJSON = (path, queryParams, token, fetcher = fetchJSON) =>
  fetcher(merchantApiUrl(path, R.defaultTo({}, queryParams)), {
    headers: scrubNullHeaders({ Authorization: 'bitt ' + token }),
    method: 'GET'
  })

export const scrubNullHeaders = headers =>
  R.pipe(
    R.toPairs,
    R.reject(
      R.pipe(
        R.tail,
        R.isNil
      )
    ),
    R.fromPairs
  )(headers)
