import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import * as R from 'ramda'
import StyledButton from 'src/components/buttons/styledButton'

const CancelRefund = ({
  actionLabel,
  buttonDisabled,
  actionType,
  onCancel,
  onRefund,
  classes,
  isCancel,
  ...props
}) => (
  <React.Fragment>
    {actionType === 'enabled' && (
      <Grid item>
        <StyledButton
          colorType={'red'}
          className={
            actionType === 'enabled' ? classes.enabled : classes.disabled
          }
          disabled={buttonDisabled}
          onClick={isCancel ? onCancel : onRefund}
          data-testid={actionLabel}
        >
          {actionLabel}
        </StyledButton>
      </Grid>
    )}
    {actionType === 'disabled' && (
      <Grid item data-testid={actionLabel}>
        <Typography className={classes.disabled}>{actionLabel}</Typography>
      </Grid>
    )}
    {actionType === 'none' && null}
  </React.Fragment>
)

const styles = theme => ({
  enabled: {
    backgroundColor: theme.palette.buttons.red,
    color: theme.palette.lights.white
  },
  disabled: {
    color: theme.palette.buttons.green,
    fontWeight: 600
  }
})

export default R.pipe(withStyles(styles))(CancelRefund)
