import React from 'react'
import camelCase from 'camelcase'
import Label from 'src/components/forms/label'
import Select from 'src/components/forms/entry/Select'

const SelectItem = ({
  item,
  name,
  placeholder,
  label,
  labelStyles,
  ...props
}) => (
  <React.Fragment>
    <Label customStyles={labelStyles || ''}>
      {label || item.toUpperCase()}
    </Label>
    <Select
      name={name || camelCase(item)}
      placeholder={placeholder || item}
      {...props}
    />
  </React.Fragment>
)

export default SelectItem
