import React from 'react'
import { Grid, withStyles, IconButton } from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import * as R from 'ramda'
import PropTypes from 'prop-types'

const TablePaginationActions = ({
  event,
  onPageChange,
  classes,
  count,
  page,
  rowsPerPage
}) => {
  function handleFirstPageButtonClick () {
    onPageChange(event, 0)
  }

  function handleBackButtonClick () {
    if (page === 1) {
      onPageChange(event, page - 2)
    } else {
      onPageChange(event, page - 1)
    }
  }

  function handleNextButtonClick () {
    onPageChange(event, page + 1)
  }

  function handleLastPageButtonClick () {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Grid container className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='First Page'
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='Previous Page'
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='Next Page'
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='Last Page'
      >
        <LastPageIcon />
      </IconButton>
    </Grid>
  )
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const styles = theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
})

export default R.pipe(withStyles(styles))(TablePaginationActions)
