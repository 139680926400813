import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'

import Header from 'src/components/headers/dashboard-header'
import Footer from 'src/components/footer'
import useDocumentTitle from 'src/tools/hooks/use-document-title'

const page = ({ classes, children, title = '', withHeader = true }) => {
  useDocumentTitle(title)

  return (
    <Grid container direction='column' wrap='nowrap' className={classes.page}>
      {withHeader && (
        <Grid item className={classes.header}>
          <Header title={title} />
        </Grid>
      )}
      <Grid
        container
        item
        direction='column'
        justifyContent='space-between'
        wrap='nowrap'
        className={classes.content}
      >
        <Grid item>{children}</Grid>
        <Grid item className={classes.footer}>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  )
}

page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  classes: PropTypes.object,
  withHeader: PropTypes.bool
}

const styles = theme => ({
  page: {
    height: '100vh',
    width: '100%'
  },
  content: {
    flex: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
    overflow: 'auto'
  },
  footer: {
    paddingTop: theme.spacing(2)
  }
})

export default withStyles(styles)(page)
