import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'

const Label = ({ children, classes, customStyles, ...props }) => {
  return (
    <Typography
      variant='caption'
      className={customStyles || classes.label}
      {...props}
    >
      {children}
    </Typography>
  )
}

const styles = theme => ({
  label: {
    fontWeight: 600
  }
})

Label.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
  customStyles: PropTypes.string
}

export default withStyles(styles)(Label)
