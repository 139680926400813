import React from 'react'
import { List } from '@material-ui/core'
import PropTypes from 'prop-types'
import EmptyListComponent from 'src/pages/manageAPI/emptyListComponent'
import { filteredRoleProptype } from 'src/pages/manageMembers/proptypes'
import ResourceItem from 'src/pages/manageMembers/resourceItem'

export const ResourceList = ({
  classes,
  employees,
  onEditClick,
  handleDeleteEmployeeClick
}) =>
  employees && employees.length ? (
    <List disablePadding>
      {employees.map((employee, index) => (
        <ResourceItem
          key={employee.id}
          classes={classes}
          onClick={() => {}}
          onEditClick={onEditClick}
          showDivider={index < employees.length - 1}
          handleDeleteEmployeeClick={handleDeleteEmployeeClick}
          employeefilteredRoles={employee.filteredRoles}
          {...employee}
        />
      ))}
    </List>
  ) : (
    <EmptyListComponent message={'No items in the list'} />
  )

ResourceList.propTypes = {
  classes: PropTypes.object.isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      filteredRoles: PropTypes.arrayOf(filteredRoleProptype),
      employee: PropTypes.object
    })
  ),
  onEditClick: PropTypes.func.isRequired,
  handleDeleteEmployeeClick: PropTypes.func.isRequired
}
