import React from 'react'
import {
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import * as R from 'ramda'
import StyledButton from 'src/components/buttons/styledButton'
import PropTypes from 'prop-types'

export const ConfirmDialog = ({
  open,
  onCancel,
  onConfirm,
  classes,
  children
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        <b>Confirm Action</b>
      </DialogTitle>
      <Grid>
        <Grid container item direction='column'>
          <DialogContent>
            <Grid
              container
              direction='column'
              spacing={1}
              className={classes.containers}
            >
              <Grid item>
                <Typography variant='body1'>{children}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              wrap='wrap'
              justifyContent='space-between'
            />
          </DialogContent>
          <DialogActions className={classes.buttonsConfirm}>
            <Grid
              container
              direction='row'
              spacing={1}
              justifyContent='flex-end'
            >
              <Grid item>
                <StyledButton onClick={onCancel} colorType={'red'}>
                  Cancel
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton onClick={onConfirm} colorType={'green'}>
                  Confirm
                </StyledButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node
}

ConfirmDialog.defaultProps = {
  children: 'Press confirm to finish your action'
}

const styles = theme => ({
  title: {
    paddingBottom: 0
  },
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  }
})

export default R.pipe(withStyles(styles))(ConfirmDialog)
