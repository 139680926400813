import React, { useEffect } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { Formik } from 'formik'
import { number, object, string } from 'yup'
import { actions } from 'src/redux'
import resourcesRedux from 'src/redux/resources'
import * as R from 'ramda'
import { connect } from 'react-redux'
import Select from 'src/components/forms/entry/Select'
import Label from 'src/components/forms/label'
import { formatOptionLabel } from 'src/pages/settings/companyInformation'
import FormItem from 'src/components/forms/form-item'
import AddEditButton from 'src/components/buttons/addEditButton'
import PropTypes from 'prop-types'
import { currenciesPropTypes } from 'src/pages/transact/proptypes'
import { KeyboardDatePicker } from '@material-ui/pickers'

const validationSchema = object().shape({
  minimumAmount: number().moreThan(0, 'Must be greater than 0'),
  maximumAmount: number().moreThan(0, 'Must be greater than 0'),
  clerk: string(),
  currency: string(),
  address: string(),
  status: string(),
  invoiceNumber: number().moreThan(0, 'Must be greater than 0'),
  merchantOrderId: string(),
  typeOfActivity: string(),
  typeOfTransaction: string()
})

const initialValues = {
  startDate: null,
  endDate: null,
  minimumAmount: undefined,
  maximumAmount: undefined,
  clerk: '',
  currency: '',
  address: undefined,
  status: '',
  invoiceNumber: undefined,
  merchantOrderId: undefined,
  typeOfActivity: 'All',
  typeOfTransaction: 'All'
}

const handleSubmit = onRunReport => (values, actions) => {
  onRunReport({ ...values, page: 0, pageSize: 10 })
  actions.setSubmitting(false) // TODO remove and apply the callback commented out below
}

const statuses = [
  { label: 'New', value: 'new' },
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Complete', value: 'complete' },
  { label: 'Paid', value: 'paid' },
  { label: 'Accept', value: 'accept' }
]

const activityTypes = [
  { label: 'All', value: 'all' },
  { label: 'Teller', value: 'teller' },
  { label: 'Merchant', value: 'merchant' },
  { label: 'Withdraw', value: 'withdraw' }
]

const transactionTypes = [
  { label: 'All', value: 'all' },
  { label: 'Invoice', value: 'invoice' },
  { label: 'Debit', value: 'debit' }
]

const emptyClerk = { email: '-', id: '' }
const emptyCurrency = { name: '-', code: '' }
const emptyStatus = { label: '-', value: '' }

export const ReportForm = ({
  classes,
  runReport,
  clerks = [],
  currencies = [],
  requestEmployees,
  requestMerchantInfo
}) => {
  useEffect(() => {
    requestMerchantInfo()
    requestEmployees()
    return () => {}
  }, [])
  return (
    <Grid>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        isInitialValid={validationSchema.isValidSync(initialValues)}
        onSubmit={handleSubmit(runReport)}
      >
        {({ handleSubmit, isSubmitting, ...formikProps }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.containers}>
              <Grid item container direction='column' md={6} xs={12}>
                <Grid item>
                  <Label>START DATE</Label>
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    inputProps={{ 'data-testid': 'startDate' }}
                    autoOk
                    fullWidth
                    placeholder='dd/mm/yyyy'
                    disableFuture
                    variant='inline'
                    inputVariant='outlined'
                    format='dd/MM/yyyy'
                    value={formikProps.values['startDate']}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => {
                      formikProps.setFieldValue('startDate', date)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction='column' md={6} xs={12}>
                <Grid item>
                  <Label>END DATE</Label>
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    inputProps={{ 'data-testid': 'endDate' }}
                    autoOk
                    fullWidth
                    placeholder='dd/mm/yyyy'
                    disableFuture
                    variant='inline'
                    inputVariant='outlined'
                    format='dd/MM/yyyy'
                    value={formikProps.values['endDate']}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => {
                      formikProps.setFieldValue('endDate', date)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormItem
                  item='Minimum Amount'
                  name='minimumAmount'
                  type='number'
                  classes={classes}
                  formik={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormItem
                  item='Maximum Amount'
                  name='maximumAmount'
                  type='number'
                  classes={classes}
                  formik={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label>CLERKS</Label>
                <Select
                  name='clerk'
                  options={[emptyClerk, ...clerks]}
                  getOptionLabel={option => option.email}
                  getOptionValue={option => option.id}
                  placeholder='Clerks'
                  formikProps={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label>CURRENCY</Label>
                <Select
                  name='currency'
                  options={[emptyCurrency, ...currencies]}
                  formatOptionLabel={formatOptionLabel}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.code}
                  placeholder='Currency'
                  formikProps={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormItem
                  name='invoiceNumber'
                  placeholder='Invoice #'
                  label='INVOICE #'
                  classes={classes}
                  formik={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label>STATUS</Label>
                <Select
                  name='status'
                  options={[emptyStatus, ...statuses]}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  placeholder='Status'
                  formikProps={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormItem
                  name='address'
                  placeholder='Transaction (Address)'
                  label='TRANSACTION ADDRESS'
                  classes={classes}
                  formik={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormItem
                  name='merchantOrderId'
                  placeholder='Checkout ID'
                  label='CHECKOUT ID'
                  classes={classes}
                  formik={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label>TYPE OF ACTIVITY</Label>
                <Select
                  name='typeOfActivity'
                  options={activityTypes}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  placeholder='Type of Activity'
                  formikProps={formikProps}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label>TYPE OF TRANSACTION</Label>
                <Select
                  name='typeOfTransaction'
                  options={transactionTypes}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  placeholder='Type of Activity'
                  formikProps={formikProps}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='center' className={classes.buttons}>
              <Grid item>
                <AddEditButton type='submit' data-testid='runReport'>
                  Run Report
                </AddEditButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  )
}

ReportForm.propTypes = {
  classes: PropTypes.object.isRequired,
  runReport: PropTypes.func.isRequired,
  requestEmployees: PropTypes.func.isRequired,
  requestMerchantInfo: PropTypes.func.isRequired,
  clerks: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  currenciesPropTypes,
  page: PropTypes.number,
  pageSize: PropTypes.number
}

ReportForm.defaultProps = {
  page: 0,
  pageSize: 10
}

const styles = theme => ({
  containers: {
    padding: theme.spacing(2)
  },
  textField: {
    backgroundColor: theme.palette.lights.white
  },
  formControl: {
    backgroundColor: theme.palette.lights.white
  },
  buttons: {
    padding: theme.spacing(2)
  },
  root: {
    width: '100%',
    marginTop: '2px'
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: 'auto'
  }
})

const mapStateToProps = state => ({
  clerks: resourcesRedux.selectors.clerks(state),
  currencies: resourcesRedux.selectors.currencies(state)
})

const mapDispatchToProps = dispatch => ({
  requestMerchantInfo: () => dispatch(actions.resources.getMerchantInfo()),
  requestEmployees: () => dispatch(actions.resources.getEmployees()),
  runReport: payload => dispatch(actions.reports.getReport(payload))
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReportForm)
