import React, { useState } from 'react'
import PropTypes from 'prop-types'
import copy from 'clipboard-copy'
import StyledButton from 'src/components/buttons/styledButton'

const CopyToClipboard = ({
  toCopy,
  classes,
  beforeCopyLabel,
  afterCopyLabel,
  ...props
}) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = () => {
    copy(toCopy)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 3000)
  }

  const buttonLabel = () => {
    if (isCopied) {
      return afterCopyLabel || 'Copied!'
    } else {
      return beforeCopyLabel || 'Copy Key'
    }
  }

  return (
    <StyledButton colorType={'blue'} onClick={copyToClipboard} {...props}>
      {buttonLabel()}
    </StyledButton>
  )
}

CopyToClipboard.propTypes = {
  toCopy: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  classes: PropTypes.object,
  beforeCopyLabel: PropTypes.string,
  afterCopyLabel: PropTypes.string
}

export default CopyToClipboard
