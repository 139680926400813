import React from 'react'
import * as R from 'ramda'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SimpleForm from './simpleForm'

const handleSubmit = (onWithdraw, onCancel) => (values, actions) => {
  const onFinish = () => {
    actions.setSubmitting(false)
  }

  onWithdraw(
    {
      amount: values.amount,
      method: 'cash',
      reference: 'Beneficiary Name: '
    },
    onFinish,
    onCancel
  )
}

export const WithdrawForm = ({ onConfirmWithdraw, onCancel }) => (
  <SimpleForm
    onConfirm={onConfirmWithdraw}
    onCancel={onCancel}
    handleSubmit={handleSubmit}
    label='Withdraw BBD via cash'
    description='Please enter the amount you wish to withdraw from the account.'
    testId={'withdrawForm'}
  />
)

// TODO use it in the future
// eslint-disable-next-line
// const withdrawFrom = ({ classes }) => (
//   <React.Fragment>
//     <Grid container>
//       <Typography variant='button'>WITHDRAWAL FROM:</Typography>
//     </Grid>
//     <ListItem divider disableGutters>
//       <Grid container direction='row' spacing={1} alignItems='center'>
//         <Grid item xs={3}>
//           <Typography variant='body1'>Headquarters</Typography>
//         </Grid>
//         <Grid item>
//           <TextField
//             name='withdrawal-amount'
//             defaultValue='2,500'
//             placeholder='0,00'
//             variant='outlined'
//             InputProps={startAdornment('$')}
//           />
//         </Grid>
//         <Grid item>
//           <Typography variant='caption' className={classes.caption}>
//             New Balance: $2,900
//           </Typography>
//         </Grid>
//       </Grid>
//     </ListItem>
//     <ListItem divider disableGutters>
//       <Grid container direction='row' spacing={1} alignItems='center'>
//         <Grid item xs={3}>
//           <Typography variant='body1'>Center Street</Typography>
//         </Grid>
//         <Grid item>
//           <TextField
//             name='withdrawal-amount'
//             defaultValue='0'
//             placeholder='0,00'
//             variant='outlined'
//             InputProps={startAdornment('$')}
//           />
//         </Grid>
//       </Grid>
//     </ListItem>
//     <ListItem divider={false} disableGutters>
//       <Grid container direction='row' spacing={1} alignItems='center'>
//         <Grid item xs={3}>
//           <Typography variant='body1'>Main Street</Typography>
//         </Grid>
//         <Grid item>
//           <TextField
//             name='withdrawal-amount'
//             defaultValue='0'
//             placeholder='0,00'
//             variant='outlined'
//             InputProps={startAdornment('$')}
//           />
//         </Grid>
//       </Grid>
//     </ListItem>
//   </React.Fragment>
// )

const mapDispatchToProps = dispatch => ({
  onConfirmWithdraw: (payload, onFinish, onCancel) =>
    dispatch(
      actions.transactions.transactionsWithdraw(payload, onFinish, onCancel)
    )
})

WithdrawForm.propTypes = {
  onConfirmWithdraw: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default R.pipe(
  connect(
    null,
    mapDispatchToProps
  )
)(WithdrawForm)
