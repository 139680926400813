import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import {
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import FormikTextField from 'src/components/formik/FormikTextField'
import * as Yup from 'yup'
import * as R from 'ramda'
import FormikCheckbox from 'src/components/formik/FormikCheckbox'
import StyledButton from 'src/components/buttons/styledButton'

const initialFormValues = ({ isOnlinePayment }) => ({
  title: isOnlinePayment ? 'ONLINE_PAYMENT_KEY' : 'NEW_KEY',
  receiveFunds: isOnlinePayment
})

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Title cannot be blank')
})

const handleSubmit = (onAddKey, onCancel) => (values, actions) => {
  const onFinish = () => {
    onCancel()
    actions.setSubmitting(false)
  }
  onAddKey({ ...values }, onFinish)
}

export const AddKey = ({
  open,
  onCancel,
  onAddKey,
  classes,
  isOnlinePayment
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      disableEscapeKeyDown
      maxWidth='sm'
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancel(event, reason)
        }
      }}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className={classes.title} id='form-dialog-title'>
        <b>
          {isOnlinePayment ? 'Add Online Payment Key Title' : 'Add Key Title'}
        </b>
      </DialogTitle>
      <Grid>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialFormValues({ isOnlinePayment })}
          isInitialValid={validationSchema.isValidSync(
            initialFormValues({ isOnlinePayment })
          )}
          onSubmit={handleSubmit(onAddKey, onCancel)}
        >
          {({ handleSubmit, ...formikProps }) => (
            <form onSubmit={handleSubmit}>
              <Grid container item direction='column'>
                <DialogContent>
                  <Grid
                    container
                    direction='column'
                    spacing={1}
                    className={classes.containers}
                  >
                    <Grid item>
                      <Typography
                        variant='caption'
                        className={classes.titleHeader}
                      >
                        TITLE
                      </Typography>
                      <FormikTextField
                        name='title'
                        placeholder={isOnlinePayment ? 'Title' : 'NEW_KEY'}
                        type='text'
                        variant='outlined'
                        fullWidth
                        formik={formikProps}
                      />
                    </Grid>
                    <Grid item>
                      <FormikCheckbox
                        name={isOnlinePayment ? 'receiveFunds' : 'sendRefunds'}
                        label={
                          isOnlinePayment ? 'RECEIVE FUNDS' : 'SEND REFUNDS'
                        }
                        color='primary'
                        formik={formikProps}
                        inputProps={{ 'data-testid': 'checkbox' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    wrap='wrap'
                    justifyContent='space-between'
                  />
                </DialogContent>
                <DialogActions className={classes.buttonsConfirm}>
                  <Grid
                    container
                    direction='row'
                    spacing={1}
                    justifyContent='flex-end'
                  >
                    <Grid item>
                      <StyledButton onClick={onCancel} colorType={'red'}>
                        Cancel
                      </StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledButton
                        type='submit'
                        colorType={'green'}
                        data-testid='submitAddKey'
                      >
                        Add Key
                      </StyledButton>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  )
}

AddKey.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddKey: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isOnlinePayment: PropTypes.bool.isRequired
}

const styles = theme => ({
  title: {
    paddingBottom: 0
  },
  titleHeader: {
    fontWeight: 600
  },
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  },
  cancelButton: {
    backgroundColor: theme.palette.buttons.red,
    color: theme.palette.lights.white
  },
  editButton: {
    backgroundColor: theme.palette.buttons.green,
    color: theme.palette.lights.white
  },
  buttonsConfirm: {
    padding: '0 24px 24px 0'
  }
})

export default R.pipe(withStyles(styles))(AddKey)
