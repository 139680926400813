import React from 'react'
import { Grid, Typography, ListItem, List, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import EmptyListComponent from 'src/pages/manageAPI/emptyListComponent'
import numbro from 'numbro/dist/numbro'
import StyledButton from 'src/components/buttons/styledButton'
import * as R from 'ramda'
import { bpMaxSM } from 'src/tools/screenSize'

const PaymentItemComponent = ({
  classes,
  onEditClick,
  onViewClick,
  showDivider,
  ...payment
}) => (
  <Grid container wrap='wrap'>
    <ListItem divider={showDivider} className={classes.listItem}>
      <Grid item xs={12} md={4}>
        <Typography>
          <b>{payment.name.toString()}</b>
        </Typography>
      </Grid>
      <Grid item xs={6} md={2}>
        <Typography align='center'>
          <b>{numbro(payment.amount).formatCurrency('0,0.00')}</b>
        </Typography>
      </Grid>
      <Grid item xs={6} md={2}>
        <Typography
          align='center'
          className={payment.active ? classes.active : classes.inactive}
        >
          <b>{payment.active ? 'Active' : 'Inactive'}</b>
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        direction='row'
        justifyContent='center'
        spacing={1}
      >
        <Grid item>
          <StyledButton onClick={onViewClick} colorType={'green'}>
            View
          </StyledButton>
        </Grid>
        <Grid item>
          <StyledButton onClick={onEditClick} colorType={'blue'}>
            Edit
          </StyledButton>
        </Grid>
      </Grid>
    </ListItem>
  </Grid>
)

PaymentItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  employee: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  showDivider: PropTypes.bool.isRequired
}

const styles = theme => ({
  listItem: {
    padding: theme.spacing(3),
    flexDirection: 'row',
    [`${bpMaxSM}`]: {
      padding: theme.spacing(2)
    }
  }
})

const PaymentItem = R.pipe(withStyles(styles))(PaymentItemComponent)
export default PaymentItem

export const PaymentsList = ({ classes, payments, onEditClick, onViewClick }) =>
  payments && payments.length ? (
    <List disablePadding>
      {payments.map((payment, index) => (
        <PaymentItem
          key={payment.name}
          classes={classes}
          onClick={() => {}}
          onEditClick={onEditClick(payment)}
          onViewClick={onViewClick(payment)}
          showDivider={index < payments.length - 1}
          {...payment}
        />
      ))}
    </List>
  ) : (
    <EmptyListComponent message={'No items in the list'} />
  )
