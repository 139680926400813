import { put, call, takeLatest, select } from 'redux-saga/effects'
import { actions } from 'src/redux'
import { getAuthToken } from 'src/tools/token'
import {
  getTransactionsForReports,
  downloadCvvReport,
  cancelInvoice,
  refundInvoice
} from 'src/services/merchant/transation'
import reportsRedux from 'src/redux/reports'
import fileDownload from 'js-file-download'
import resourcesRedux from 'src/redux/resources'
import usersRedux from 'src/redux/users'
import * as authServices from 'src/services/merchant/authentication'
import { generateGUID } from 'src/redux/helpers'
import { mapReportsResults } from 'src/redux/reports/helpers'

export function * getReportSaga ({ payload }) {
  try {
    const token = yield select(getAuthToken)
    const permissions = yield select(resourcesRedux.selectors.permissions)
    if (
      !permissions.view_all_transactions &&
      permissions.view_own_transactions
    ) {
      payload.employee = permissions.id
    }

    if (
      !permissions.view_all_transactions &&
      !permissions.view_own_transactions
    ) {
      yield put(
        actions.notification.notificationShow({
          message: 'You have no permission to generate report',
          type: 'fail'
        })
      )
      return
    }
    const { pagination, transactions } = yield call(getTransactionsForReports, {
      token,
      params: payload
    })
    const mappedResults = yield call(mapReportsResults, {
      transactions,
      typeOfActivity: payload.typeOfActivity,
      sendRefundPermission: permissions.send_funds
    })
    yield put(
      actions.reports.getReportSuccess(mappedResults, pagination, payload)
    )
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'fail'
      })
    )
  }
}

export function * getReportPageSaga ({ payload: { page, rowsPerPage } }) {
  try {
    const currentPayload = yield select(reportsRedux.selectors.payload)
    const currentPage = yield select(reportsRedux.selectors.pageNumber)
    const noNewPage = yield call(isNaN, page)
    const newPage = noNewPage ? currentPage : page

    const currentRowsPerPage = yield select(reportsRedux.selectors.rowsPerPage)
    const newRowsPerPage = rowsPerPage || currentRowsPerPage

    const token = yield select(getAuthToken)
    const payload = {
      ...currentPayload,
      page: newPage,
      pageSize: newRowsPerPage
    }
    const { pagination, transactions } = yield call(getTransactionsForReports, {
      token,
      params: payload
    })
    const permissions = yield select(resourcesRedux.selectors.permissions)

    const mappedResults = yield call(mapReportsResults, {
      transactions,
      typeOfActivity: payload.typeOfActivity,
      sendRefundPermission: permissions.send_funds
    })
    yield put(
      actions.reports.getReportSuccess(mappedResults, pagination, payload)
    )
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'fail'
      })
    )
  }
}

export function * downloadReportSaga () {
  try {
    const token = yield select(getAuthToken)
    const payload = yield select(reportsRedux.selectors.payload)

    const data = yield call(downloadCvvReport, {
      token,
      params: payload
    })
    if (data) {
      yield call(fileDownload, data, 'report.csv')
      yield put(actions.reports.downloadReportSuccess())
      yield put(
        actions.notification.notificationShow({
          message: 'Successfully generated report',
          type: 'success'
        })
      )
    } else {
      yield put(actions.reports.downloadReportFail())
      yield put(
        actions.notification.notificationShow({
          message: 'Failed to generate report',
          type: 'fail'
        })
      )
    }
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: e.errorTxt,
        type: 'fail'
      })
    )
  }
}

export function * requestInvoiceCancelSaga ({ password, id, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    const email = yield select(usersRedux.selectors.userEmail)
    yield call(authServices.login, email, password)
    yield call(cancelInvoice, { id, token })
    yield call(refreshCurrentPageSaga)
    yield call(onFinish)

    yield put(actions.reports.requestInvoiceCancelSuccess())
    yield put(
      actions.notification.notificationShow({
        message: 'Invoice canceled successfully',
        type: 'success'
      })
    )
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: 'Failed to cancel invoice',
        type: 'fail'
      })
    )
  }
}

export function * requestInvoiceRefundSaga ({ password, id, onFinish }) {
  try {
    const token = yield select(getAuthToken)
    const email = yield select(usersRedux.selectors.userEmail)
    yield call(authServices.login, email, password)
    const guid = yield call(generateGUID)
    yield call(refundInvoice, { id, token, guid })
    yield call(refreshCurrentPageSaga)
    yield call(onFinish)

    yield put(actions.reports.requestInvoiceRefundSuccess())
    yield put(
      actions.notification.notificationShow({
        message: 'Invoice refunded successfully',
        type: 'success'
      })
    )
  } catch (e) {
    yield put(
      actions.notification.notificationShow({
        message: 'Failed to refund invoice',
        type: 'fail'
      })
    )
  }
}

export function * refreshCurrentPageSaga () {
  try {
    const currentPage = yield select(reportsRedux.selectors.pageNumber)
    const currentRowsPerPage = yield select(reportsRedux.selectors.rowsPerPage)
    const payload = {
      page: currentPage,
      pageSize: currentRowsPerPage
    }

    yield call(getReportPageSaga, { payload })
  } catch (e) {
    throw new Error(e)
  }
}

export default function * root () {
  yield takeLatest(reportsRedux.types.GET_REPORT, getReportSaga)
  yield takeLatest(reportsRedux.types.GET_REPORT_PAGE, getReportPageSaga)
  yield takeLatest(reportsRedux.types.DOWNLOAD_REPORT, downloadReportSaga)
  yield takeLatest(
    reportsRedux.types.REQUEST_INVOICE_CANCEL,
    requestInvoiceCancelSaga
  )
  yield takeLatest(
    reportsRedux.types.REQUEST_INVOICE_REFUND,
    requestInvoiceRefundSaga
  )
}
