import React from 'react'
import { Formik } from 'formik'
import { number, object } from 'yup'
import { Grid, withStyles } from '@material-ui/core'
import FormikTextField from 'src/components/formik/FormikTextField'
import * as R from 'ramda'
import { actions } from 'src/redux'
import { connect } from 'react-redux'
import startAdornment from 'src/components/inputFields/startAdornment'
import PropTypes from 'prop-types'
import Select from 'src/components/forms/entry/Select'
import Label from 'src/components/forms/label'
import StyledButton from 'src/components/buttons/styledButton'

const types = [
  { type: 'Merchandise', value: 'merchant' },
  { type: 'Physical', value: 'teller' }
]

const typeOfMerchant = types[0]

const validationSchema = object().shape({
  amount: number()
    .moreThan(0, 'Must be greater than 0')
    .required('Amount is required')
})

const initialValues = {
  email: '',
  amount: '',
  note: '',
  type: typeOfMerchant
}

const handleSubmit = (onConfirmRequest, onCancel) => (values, actions) => {
  const onFinish = () => {
    values.email && onCancel()
    actions.setSubmitting(false)
  }
  onConfirmRequest(
    {
      customerEmail: values.email,
      amount: values.amount,
      comment: values.note,
      type: values.type.value
    },
    onFinish
  )
}

export const RequestForm = ({ onConfirmRequest, onCancel, classes }) => (
  <Grid data-testid='requestForm'>
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      onSubmit={handleSubmit(onConfirmRequest, onCancel)}
    >
      {({ handleSubmit, isSubmitting, ...formikProps }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction='row' spacing={1}>
            <Grid item xs={6}>
              <Label>ENTER AMOUNT</Label>
              <FormikTextField
                name='amount'
                type='number'
                placeholder='0.00'
                variant='outlined'
                fullWidth
                formik={formikProps}
                step={0.01}
                InputProps={{ ...startAdornment('$'), step: 0.01 }}
              />
            </Grid>
            <Grid item xs={6}>
              <Label>TYPE OF INVOICE</Label>
              <Select
                aria-label={'type of invoice'}
                name='type'
                options={types}
                placeholder='Type'
                getOptionLabel={option => option.type}
                getOptionValue={option => option.value}
                formikProps={formikProps}
              />
            </Grid>
            <Grid
              container
              direction='row'
              spacing={1}
              className={classes.containers}
            >
              <Grid item xs={6}>
                <Label>
                  EMAIL ADDRESS <b>(REQUEST PAYMENT)</b>
                </Label>
                <FormikTextField
                  name='email'
                  type='text'
                  variant='outlined'
                  fullWidth
                  formik={formikProps}
                  inputProps={{
                    'data-testid': 'emailAdress'
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>NOTE (OPTIONAL)</Label>
                <FormikTextField
                  name='note'
                  type='text'
                  variant='outlined'
                  fullWidth
                  formik={formikProps}
                  inputProps={{
                    'data-testid': 'note'
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              spacing={1}
              justifyContent='flex-end'
              className={classes.buttons}
            >
              <Grid item>
                <StyledButton onClick={onCancel} colorType={'red'}>
                  CANCEL
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  type='submit'
                  disabled={isSubmitting}
                  colorType={'green'}
                  data-testid='submitRequestForm'
                >
                  CONFIRM
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  </Grid>
)

RequestForm.propTypes = {
  onConfirmRequest: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  }
})

const mapDispatchToProps = dispatch => ({
  onConfirmRequest: (payload, onFinish) =>
    dispatch(actions.transactions.transactionsRequestPayment(payload, onFinish))
})

export default R.pipe(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(RequestForm)
