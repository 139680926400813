import React, { useState, useEffect } from 'react'
import { Grid, Card, Typography, Button, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ConfirmDialog from 'src/pages/manageAPI/confirmDialog'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { actions } from 'src/redux'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import resourcesRedux from 'src/redux/resources'

export const EnableTeller = ({
  classes,
  enableTeller,
  isTellerEnabled,
  requestMerchantInfo
}) => {
  useEffect(() => {
    requestMerchantInfo()
    return () => {}
  }, [])
  const [enableOpen, setEnableOpen] = useState(false)

  const handleEnableClick = () => {
    setEnableOpen(true)
  }

  const handleCancelEnableClick = () => {
    setEnableOpen(false)
  }

  return (
    <Grid container direction='column'>
      <Card direction='column' className={classes.card}>
        <Grid item>
          <Typography className={classes.heading}>
            Welcome to the Teller Network
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' className='classes.space'>
            Please note: By Enabling yourself as a mMoney Merchant Teller, you
            will be required to help CashIN and CashOUT customers upon their
            request. If you are not willing to do this, you can remain as a
            mMoney Merchant.
            <br />
            As a Teller you will incur a fee of $0.05 instead of $0.20 per
            mMoney transaction.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' className={classes.space}>
            For more information and help please contact{' '}
            <a title='support@mmoneybb.com' href='mailto:support@mmoneybb.com'>
              support@mmoneybb.com
            </a>
          </Typography>
        </Grid>
        <Grid item>
          {isTellerEnabled ? (
            <Button className={classes.buttonEnableConfirmed}>
              ENABLED <CheckCircleIcon />
            </Button>
          ) : (
            <Button
              className={classes.buttonEnable}
              onClick={handleEnableClick}
            >
              <div className={classes.circle} />
              ENABLE
            </Button>
          )}
        </Grid>
        <ConfirmDialog
          open={enableOpen}
          onCancel={handleCancelEnableClick}
          onConfirm={() => enableTeller(handleCancelEnableClick)}
        >
          <Typography variant='body1'>
            Once you click 'Confirm' and activate Teller you will have to
            contact mMoney Merchant Services support to revert.
          </Typography>
        </ConfirmDialog>
      </Card>
    </Grid>
  )
}

EnableTeller.propTypes = {
  classes: PropTypes.object.isRequired,
  enableTeller: PropTypes.func.isRequired,
  isTellerEnabled: PropTypes.bool,
  requestMerchantInfo: PropTypes.func.isRequired
}

const styles = theme => ({
  card: {
    padding: 15,
    marginBottom: 15
  },
  heading: {
    fontWeight: 300,
    fontSize: 28,
    marginBottom: 30
  },
  space: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 18
  },
  buttonEnable: {
    width: 120,
    height: 35,
    border: '2px solid',
    borderColor: theme.palette.buttons.green,
    borderRadius: 25,
    justifyContent: 'space-between',
    alignContent: 'flex-start',
    lineHeight: 0,
    padding: 3,
    paddingRight: 10
  },
  circle: {
    width: '25px',
    height: '25px',
    borderRadius: 100,
    backgroundColor: theme.palette.buttons.green
  },
  buttonEnableConfirmed: {
    width: 120,
    height: 35,
    color: theme.palette.buttons.green,
    justifyContent: 'space-between'
  }
})

const mapDispatchToProps = dispatch => ({
  requestMerchantInfo: () => dispatch(actions.resources.getMerchantInfo()),
  enableTeller: onFinish => dispatch(actions.settings.enableTeller(onFinish))
})

const mapStateToProps = state => ({
  isTellerEnabled: resourcesRedux.selectors.isTellerEnabled(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnableTeller)
