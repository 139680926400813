import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, withStyles } from '@material-ui/core'
import * as R from 'ramda'
import startAdornment from 'src/components/inputFields/startAdornment'
import { number, object } from 'yup'
import { Formik } from 'formik'
import FormikTextField from 'src/components/formik/FormikTextField'
import StyledButton from 'src/components/buttons/styledButton'

const validationSchema = object().shape({
  amount: number()
    .moreThan(0, 'Must be greater than 0')
    .required('Amount is required')
})

const initialValues = { amount: '' }

const SimpleForm = ({
  onConfirm,
  onCancel,
  showNotification,
  handleSubmit,
  classes,
  label,
  description,
  testId
}) => (
  <Grid data-testid={testId}>
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      onSubmit={handleSubmit(onConfirm, onCancel)}
    >
      {({ handleSubmit, ...formikProps }) => (
        <form onSubmit={handleSubmit}>
          <Grid container item direction='column'>
            <Grid item container justifyContent='space-between'>
              <Grid
                container
                direction='column'
                spacing={1}
                className={classes.containers}
              >
                <Grid item>
                  <Typography variant='button'>{label}</Typography>
                  <FormikTextField
                    name='amount'
                    placeholder='0.00'
                    variant='outlined'
                    fullWidth
                    formik={formikProps}
                    step={0.01}
                    InputProps={{ ...startAdornment('$'), step: 0.01 }}
                  />
                  <Typography variant='button'>{description}</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction='row'
                spacing={1}
                justifyContent='flex-end'
                className={classes.buttons}
              >
                <Grid item>
                  <StyledButton onClick={onCancel} colorType={'red'}>
                    CANCEL
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    type='submit'
                    colorType={'green'}
                    data-testid='submitSimpleForm'
                  >
                    CONFIRM
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  </Grid>
)

const styles = theme => ({
  card: {
    flex: 1,
    padding: theme.spacing(1)
  },
  buttons: {
    padding: theme.spacing(1)
  },
  containers: {
    padding: theme.spacing(1)
  },
  caption: {
    color: '#A0A0A0'
  }
})

SimpleForm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

export default R.pipe(withStyles(styles))(SimpleForm)
