import numbro from 'numbro/dist/numbro'

export const thousandSeparator = number =>
  numbro(number).format({ thousandSeparated: true })

export const parseEmailFromToken = token => {
  if (!token) return undefined

  const claims = token.split('.')[1]

  const data = JSON.parse(window.atob(claims))

  if (!data.email) {
    return undefined
  } else {
    return data.email
  }
}
