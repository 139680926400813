import React, { useEffect, useState } from 'react'
import { Card, Typography, Grid, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Page from 'src/components/page'
import * as R from 'ramda'
import SendForm from 'src/pages/transact/sendForm'
import RequestForm from 'src/pages/transact/requestForm'
import WithdrawForm from 'src/pages/transact/withdrawForm'
import DepositForm from 'src/pages/transact/depositForm'
import QRpayment from 'src/pages/transact/QRpayment'
import { TransactList } from './transactList'
import { actions } from '../../redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import transactionsRedux from 'src/redux/transactions'
import { transactionListType } from 'src/pages/transact/proptypes'
import Loader from 'src/pages/transact/loader'
import AddEditButton from 'src/components/buttons/addEditButton'

const TransactMode = {
  LEFT: 'left',
  RIGHT: 'right',
  LIST: 'list',
  LOADER: 'loader'
}

const TransactHeader = ({ title }) => (
  <Grid container item justifyContent='space-between'>
    <Grid item>
      <Typography>{title}</Typography>
    </Grid>
  </Grid>
)

TransactHeader.propTypes = {
  title: PropTypes.string.isRequired
}

const TransactionsHolder = ({
  classes,
  isTransfers,
  onRequest,
  onSend,
  onWithdraw,
  onDeposit,
  ...props
}) => (
  <Grid item className={classes.holder}>
    <TransactHeader
      title={isTransfers ? 'Transfers' : 'Withdrawals/Deposits'}
    />
    <Card className={classes.card}>
      <Grid container direction='row' spacing={1}>
        <Grid item xs={6}>
          <AddEditButton fullWidth onClick={isTransfers ? onSend : onWithdraw}>
            {isTransfers ? `SEND` : `WITHDRAW`}
          </AddEditButton>
        </Grid>
        <Grid item xs={6}>
          <AddEditButton
            fullWidth
            onClick={isTransfers ? onRequest : onDeposit}
          >
            {isTransfers ? `REQUEST` : `DEPOSIT`}
          </AddEditButton>
        </Grid>
      </Grid>
      <TransactContent {...props} />
    </Card>
  </Grid>
)

TransactionsHolder.propTypes = {
  classes: PropTypes.object.isRequired,
  isTransfers: PropTypes.bool.isRequired,
  onRequest: PropTypes.func,
  onSend: PropTypes.func,
  onWithdraw: PropTypes.func,
  onDeposit: PropTypes.func,
  holder: PropTypes.oneOf(Object.values(TransactMode)),
  leftNode: PropTypes.element,
  rightNode: PropTypes.element,
  listNode: PropTypes.element
}

const TransactContent = ({ holder, leftNode, rightNode, listNode }) => {
  switch (holder) {
    case TransactMode.LEFT:
      return leftNode
    case TransactMode.RIGHT:
      return rightNode
    case TransactMode.LIST:
      return listNode
    case TransactMode.LOADER:
      return <Loader />
    default:
      return listNode
  }
}

TransactContent.propTypes = {
  holder: PropTypes.oneOf(Object.values(TransactMode)),
  leftNode: PropTypes.element.isRequired,
  rightNode: PropTypes.element.isRequired,
  listNode: PropTypes.element.isRequired
}

export const Transact = ({
  classes,
  getTransactions,
  transactionsListLeft,
  transactionsListRight,
  showQRcode,
  isLoading
}) => {
  const [leftHolder, setLeftHolder] = useState(TransactMode.LOADER)
  const [rightHolder, setRightHolder] = useState(TransactMode.LOADER)
  useEffect(() => {
    getTransactions()
    return () => {}
  }, [])

  useEffect(() => {
    setLeftHolder(TransactMode.LOADER)
    setRightHolder(TransactMode.LOADER)
    return () => {}
  }, [isLoading])

  useEffect(() => {
    setLeftHolder(TransactMode.LIST)
    setRightHolder(TransactMode.LIST)
    return () => {}
  }, [transactionsListLeft, transactionsListRight])

  const leftChange = type => setLeftHolder(type)

  const rightChange = type => setRightHolder(type)

  const openLeft = onChange => () => onChange(TransactMode.LEFT)
  const openRight = onChange => () => onChange(TransactMode.RIGHT)
  const openList = onChange => () => onChange(TransactMode.LIST)

  const rightNode = showQRcode ? (
    <QRpayment onCancel={openList(leftChange)} />
  ) : (
    <RequestForm onCancel={openList(leftChange)} />
  )

  return (
    <Page title='Transact'>
      <Grid container direction='row' spacing={4}>
        <TransactionsHolder
          title={'Transfers'}
          isTransfers
          holder={leftHolder}
          onSend={openLeft(leftChange)}
          onRequest={openRight(leftChange)}
          leftNode={<SendForm onCancel={openList(leftChange)} />}
          rightNode={rightNode}
          listNode={
            <TransactList
              testId={'leftList'}
              items={transactionsListLeft}
              classes={classes}
            />
          }
          classes={classes}
        />
        <TransactionsHolder
          title={'Withdrawals/Deposits'}
          isTransfers={false}
          holder={rightHolder}
          onWithdraw={openLeft(rightChange)}
          onDeposit={openRight(rightChange)}
          leftNode={<WithdrawForm onCancel={openList(rightChange)} />}
          rightNode={<DepositForm onCancel={openList(rightChange)} />}
          listNode={
            <TransactList
              testId={'rightList'}
              items={transactionsListRight}
              classes={classes}
            />
          }
          classes={classes}
        />
      </Grid>
    </Page>
  )
}

Transact.propTypes = {
  classes: PropTypes.object.isRequired,
  getTransactions: PropTypes.func.isRequired,
  transactionListLeft: transactionListType,
  transactionListRight: transactionListType,
  showQRcode: PropTypes.bool
}

const styles = theme => ({
  holder: {
    flex: 1,
    padding: theme.spacing(1)
  },
  card: {
    flex: 1,
    minHeight: 350,
    minWidth: 320,
    padding: theme.spacing(1)
  },
  transactType: {
    fontWeight: 700
  }
})

const actionCreators = {
  getTransactions: actions.transactions.transactionsGetLists
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(actionCreators, dispatch)

const mapStateToProps = state => ({
  transactionsListLeft: transactionsRedux.selectors.transactionsListLeft(state),
  transactionsListRight: transactionsRedux.selectors.transactionsListRight(
    state
  ),
  showQRcode: transactionsRedux.selectors.showQRcode(state),
  isLoading: transactionsRedux.selectors.isLoading(state)
})

export default R.pipe(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Transact)
