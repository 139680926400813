import React from 'react'
import * as firebase from 'firebase/app'
import 'firebase/analytics'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MESUREMENT_ID
}

const firebaseInit = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
    const analytics = firebase.analytics()
    return { analytics, error: undefined }
  }
  return { analytics: undefined, error: 'no init' }
}
export const AnalyticsContext = React.createContext()

export default firebaseInit
